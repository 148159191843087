(function () {
  'use strict';

  /* global FormData */
  class UploadProntuarioCtrl {
    constructor($uibModalInstance, $scope, AlertMessage, Restangular, moment, FactoryVfiltro, Keys,
      motoristaId, arquivo, user, $translate) {
      this.modalInstance = $uibModalInstance;
      this.scope = $scope;
      this.alertMessage = AlertMessage;
      this.restangular = Restangular;
      this.moment = moment;
      this.keys = Keys;
      this.translate = $translate;

      this.motoristaId = motoristaId;
      this.arquivo = arquivo;
      this.user = user;
      this.model = {};
      this.infracao = null;
      this.anexo = null;
      this.TAMANHO_MAX_UPLOAD = 5000000;
      this.TIPOS_EXTENSAO_FILE = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'bmp',
        'pdf',
        'rar',
        'zip'
      ];

      FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name,
          disabled: true,
          id: 'filtro-modal-uo',
          size: 12
        },
        {
          name: 'uoDonaId',
          key: this.keys.uoParceira.name,
          disabled: true,
          id: 'filtro-modal-uo-parceira',
          size: 6
        },
        {
          name: 'idEntidade',
          required: true,
          key: this.keys.veiculo.name,
          ignoreValidation: true,
          id: 'filtro-modal-veiculo',
          size: 6
        }
      ], false, {
        uoId: this.user.uo.id,
        idEntidade: this.arquivo && this.arquivo.infracao ? this.arquivo.infracao.veiculoId : null
      })
      .then((e) => {
        this.filtro = e.filtroCompleto.filter(x => x.key === this.keys.veiculo.name);
        this.init();
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.upload.naofoiPossivelCarregarFiltro'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    init() {
      this.infracao = this.arquivo && this.arquivo.infracao;
      this.anexo = this.arquivo && this.arquivo.anexo;
      this.model = this.buildModelInfracoes();
    }

    buildModelInfracoes() {
      const editar = !!this.infracao;
      return {
        file: null,
        tipo: editar ? this.anexo.tipo : 'INFRACAO',
        descricao: editar ? this.anexo.descricao : null,
        data: editar ? this.moment(this.infracao.dataHoraInfracao).format('DD-MM-YYYY') : null,
        hora: editar ? this.moment(this.infracao.dataHoraInfracao).format('HH:mm') : null,
        multa: editar ? this.infracao.valorMulta : null,
        pontos: editar ? this.infracao.pontosCnh : null
      };
    }

    salvar() {
      this.restangular.all(this.getURLPost())
      .withHttpConfig({transformRequest: angular.identity})
      .customPOST(
        this.getModelInfracoes(),
        undefined,
        undefined,
        {'Content-Type': undefined}
      )
      .then(() => {
        this.modalInstance.close();
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.erroSalvar'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    getURLPost() {
      return `/motorista/motoristas/${this.motoristaId}/infracoes-transito` +
        `${!this.infracao ? '' : `/${this.infracao.id}/update`}`;
    }

    getModelInfracoes() {
      let date = this.moment(this.model.data, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          formData = new FormData();
      if (this.model.file) {
        formData.append('file', this.model.file[0]);
      }
      formData.append('content', angular.toJson({
        motoristaId: this.motoristaId,
        veiculoId: this.filtro.find(x => x.key === this.keys.veiculo.name).value.id,
        pontosCnh: this.model.pontos,
        dataHoraInfracao: this.moment(`${date} ${this.model.hora}:00`).format(),
        valorMulta: this.model.multa,
        anexoDocumento: {
          uoId: this.user.uo.id,
          motoristaId: this.motoristaId,
          descricao: this.model.descricao,
          tipo: this.model.tipo
        }
      }));
      return formData;
    }

    setUploadFile(element) {
      this.scope.$apply(() => {
        if (element.files && element.files.length > 0) {
          this.model.file = null;
          if (element.files[0].size > this.TAMANHO_MAX_UPLOAD) {
            this.alertMessage.create({
              type: 'error',
              message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.upload.arquivoExcedeuLimite'),
              appendTo: '.alert-file'
            });
            angular.element('#upload').val('');
          } else if (this.isInvalidFile(element.files[0].name.split('.').slice(-1)[0])) {
            this.alertMessage.create({
              type: 'error',
              message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.upload.extensaoInvalida'),
              appendTo: '.alert-file'
            });
            angular.element('#upload').val('');
          } else {
            this.model.file = element.files;
            this.scope.formUpload.$setDirty();
          }
        }
      });
    }

    isInvalidFile(type) {
      return this.TIPOS_EXTENSAO_FILE.filter(t => t === String(type).toLowerCase()).length === 0;
    }

    isRadioButtonMulta() {
      return this.model.tipo === 'INFRACAO';
    }

    dismiss() {
      this.modalInstance.dismiss();
    }

    isNotDataFutura() {
      return () => {
        let dataInput = this.moment(this.scope.formUpload.data.$viewValue, 'DD/MM/YYYY', true);
        if (dataInput.isValid()) {
          this.scope.formUpload.hora.$setValidity('isHoraFutura', this.isNotHoraFutura()());
          return dataInput.isBefore(this.moment());
        }
        return true;
      };
    }

    isNotHoraFutura() {
      return () => {
        let dataInput = this.moment(this.scope.formUpload.data.$viewValue, 'DD/MM/YYYY', true),
            horainput = this.moment(this.scope.formUpload.hora.$viewValue, 'HH:mm', true);
        if (dataInput.isValid() && horainput.isValid() && dataInput.isSame(this.moment().startOf('day'))) {
          return horainput.isSameOrBefore(this.moment());
        }
        return true;
      };
    }

    getFileName() {
      return !this.model.file ? this.translate.instant('ce.common.nenhumArquivoSelecionado')
       : this.model.file[0].name;
    }
  }

  angular
    .module('ProntuarioModule')
    .controller('UploadProntuarioCtrl', UploadProntuarioCtrl);
}());
