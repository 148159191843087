(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.configNotificacao', {
      url: '/configuracao-notificacao?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'configuracoes/config-notificacao/config-notificacao.tpl.html',
          controller: 'ConfigNotificacaoCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'ce.configuracao.eventos.itensConfiguraveis.ocorrencias.btnGerenciarNotificacoes.descricao',
        role: 'CONFIG_OCORRENCIA_VISUALIZAR'
      }
    });
  }

  angular
    .module('configEventos')
    .config(pageConfig);
}());
