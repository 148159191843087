(function () {
  'use strict';

  class ControleCamerasCtrl {
    constructor($translate, AuditingEventsEnum) {
      this.translate = $translate;
      this.auditingEventsEnum = AuditingEventsEnum;
      this.qtdCameras = 0;
      this.viewIndex = 0;
      this.cameraSelected = null;
    }

    $onChanges() {
      this.updateControls();
    }

    classCamera(value) {
      return value === this.indiceDisponiveis[this.viewIndex] ? 'fa-circle' : 'fa-circle-o';
    }

    setIndex(value) {
      this.cameraSelected = value;
      this.viewIndex = this.indiceDisponiveis.indexOf(value);
      this.emitToIndex(this.viewIndex);
    }

    updateControls() {
      if (this.indiceDisponiveis.length !== this.qtdCameras) {
        this.qtdCameras = this.indiceDisponiveis.length;

        const firstCamera = this.indiceDisponiveis[0];
        this.setIndex(firstCamera);
      }
    }

    next() {
      if (this.viewIndex !== this.qtdCameras - 1) {
        this.viewIndex++;
        this.cameraSelected = this.indiceDisponiveis[this.viewIndex];
        this.emitToIndex(this.viewIndex);
      }
    }

    previous() {
      if (this.viewIndex !== 0) {
        this.viewIndex--;
        this.cameraSelected = this.indiceDisponiveis[this.viewIndex];
        this.emitToIndex(this.viewIndex);
      }
    }

    isNextDisable() {
      return this.viewIndex === this.qtdCameras - 1;
    }

    isPreviousDisable() {
      return this.viewIndex === 0;
    }

    emitToIndex(value) {
      this.onToIndex({indice: value, cameraSelected: this.cameraSelected});
    }

    getNextEvidenceTooltip() {
      return this.getEvidenceTooltip(true);
    }

    getPreviousEvidenceTooltip() {
      return this.getEvidenceTooltip(false);
    }

    getEvidenceTooltip(isNext) {
      if (!this.camerasEvidence || this.camerasEvidence.length === 0) {
        return '';
      }

      const {labelSet, cameraSet} = this.getLabelAndCameraSets(isNext),
          evento = labelSet[0],
          eventos = labelSet.slice(1).join(', '),
          camera = cameraSet[0],
          cameras = cameraSet.slice(1).join(', ');

      if (labelSet.length === 0) {
        return '';
      }

      return this.buildTooltipText(labelSet.length, cameraSet.length, {evento, eventos, camera, cameras});
    }

    getLabelAndCameraSets(isNext) {
      const labelSet = [],
          cameraSet = [];

      this.camerasEvidence.forEach(evidence => {
        const relevantIndexes = evidence.indexes.filter(index => isNext ? index > this.cameraSelected : index < this.cameraSelected);
        if (relevantIndexes.length > 0) {
          const translatedLabel = this.translate.instant(this.auditingEventsEnum.getDescriptionById(evidence.label));
          if (!labelSet.includes(translatedLabel)) {
            labelSet.push(translatedLabel);
          }
          relevantIndexes.forEach(index => {
            if (!cameraSet.includes(index + 1)) {
              cameraSet.push(index + 1);
            }
          });
        }
      });

      return {labelSet, cameraSet};
    }

    buildTooltipText(labelCount, cameraCount, params) {
      const {evento, eventos, camera, cameras} = params,
          baseText = this.translate.instant('ce.mapa.historicoDetalhado.camerasTooltip.moreCameraEvidence.moreEvidenceOf', {evento});
      let specificText = '';

      switch (true) {
        case labelCount === 1 && cameraCount === 1:
          specificText = this.translate.instant('ce.mapa.historicoDetalhado.camerasTooltip.moreCameraEvidence.singleCamera', {camera});
          break;
        case labelCount > 1 && cameraCount === 1:
          specificText = this.translate.instant('ce.mapa.historicoDetalhado.camerasTooltip.moreCameraEvidence.multiEventsSingleCamera', {eventos, camera});
          break;
        case labelCount === 1 && cameraCount > 1:
          specificText = this.translate.instant('ce.mapa.historicoDetalhado.camerasTooltip.moreCameraEvidence.singleEventMultiCameras', {camera, cameras});
          break;
        case labelCount > 1 && cameraCount > 1:
          specificText = this.translate.instant('ce.mapa.historicoDetalhado.camerasTooltip.moreCameraEvidence.multiEventsMultiCameras', {eventos, camera, cameras});
          break;
        default:
          return '';
      }

      return `${baseText}${specificText}`;
    }
  }

  angular
    .module('historicoTimeline')
    .component('controleCameras', {
      controller: ControleCamerasCtrl,
      templateUrl: 'historico-timeline/visao-cameras/controle-cameras/controle-cameras.tpl.html',
      bindings: {
        indiceDisponiveis: '<',
        camerasEvidence: '<',
        onToIndex: '&'
      }
    });
}());
