(function () {
  'use strict';

  angular
    .module('ProntuarioV2Module', [
      'ui.router',
      'ui.bootstrap',
      'restangular',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'safetyModule',
      'v3gAuthenticatorModule'
    ])
    .factory('ProntuarioV2', Restangular => Restangular.service('boletim/ranking/pontuacao-motoristas/mensal'));
}());
