/* global L*/
(function () {
  'use strict';

  const BuscaRegiaoShape = L.Rectangle.extend({});

  class BuscaPorRegiaoMapService {
    constructor(PontosReferenciaTools) {
      this.pontosReferenciaToolsService = PontosReferenciaTools;

      this.map = null;
      this.layer = null;
      this.isZoomInadequate = true;
      this.raioLimite = 200;
      this.zoomMinimoBuscaRegiao = 15;
      // A API cadastra tilenumbers com zoom 17 como default
      this.zoomBuscaDefault = 17;

      this.init();
    }

    init() {
      this.declareCustomShape();
    }

    setMap(map) {
      this.map = map;
      this.map.on(L.Draw.Event.CREATED, () => this.fitsInTileArea());
      this.map.on(L.Draw.Event.EDITED, () => this.fitsInTileArea());
      this.setVerifyZoom();
    }

    declareCustomShape() {
      /* eslint-disable */
      const BuscaRegiaoShapeEdit = L.Edit.SimpleShape.extend({
        _createMoveMarker: function () {
          let bounds = this._shape.getBounds(),
              center = bounds.getCenter();
          this.options.moveIcon.options.iconSize = [100, 100];
          this._moveMarker = this._createMarker(center, this.options.moveIcon);
        },

        _createResizeMarker: function () {
          // Empty: Since this rectangle doesn't allow resize, it doesn't need icons.
          this._resizeMarkers = [];
        },

        _move: function (newCenter) {
          let latlngs = this._shape._defaultShape ? this._shape._defaultShape() : this._shape.getLatLngs(),
              bounds = this._shape.getBounds(),
              center = bounds.getCenter(),
              offset, newLatLngs = [];

          // Offset the latlngs to the new center
          for (let i = 0, l = latlngs.length; i < l; i++) {
            offset = [latlngs[i].lat - center.lat, latlngs[i].lng - center.lng];
            newLatLngs.push([newCenter.lat + offset[0], newCenter.lng + offset[1]]);
          }

          this._shape.setLatLngs(newLatLngs);

          this._map.fire(L.Draw.Event.EDITMOVE, {
            layer: this._shape
          });
        },

        _onMarkerDragEnd: function (e) {
          this._map.fire(L.Draw.Event.EDITED);
          L.Edit.SimpleShape.prototype._onMarkerDragEnd.call(this, e);
        },

        setPositionMoveMarker(position) {
          this._moveMarker.setLatLng(position);
        }
      });

      BuscaRegiaoShape.addInitHook(function () {
        this.editing = new BuscaRegiaoShapeEdit(this);

        if (this.options.editable) {
          this.editing.enable();
        }

        this.on('add', function () {
          this._map.fire(L.Draw.Event.CREATED);
        });
      });
      /* eslint-enable */
    }

    render() {
      if (this.map) {
        this.layer = this.drawArea();
        this.map.addLayer(this.layer);
      }
    }

    drawArea() {
      let posicao = this.getLatLongFromCenter();

      return new BuscaRegiaoShape([
        [posicao.latlngLeftBottom.latitude, posicao.latlngLeftBottom.longitude],
        [posicao.latlngRightTop.latitude, posicao.latlngRightTop.longitude]
      ], {color: '#000', fillOpacity: 0.5, weight: 1, editable: true});
    }

    getLatLongFromCenter() {
      let center = this.map.getCenter();
      return this.pontosReferenciaToolsService.geraLatLngsExternos(center.lat, center.lng, this.raioLimite);
    }

    setVerifyZoom() {
      this.verifyZoom();
      this.map.on('zoomend', this.verifyZoom, this);
    }

    verifyZoom() {
      this.isZoomInadequate = this.map.getZoom() < this.zoomMinimoBuscaRegiao;
    }

    removeLayerMap() {
      if (this.map && this.layer) {
        this.map.removeLayer(this.layer);
      }
      this.layer = null;
    }

    fitsInTileArea() {
      if (this.layer) {
        let tileBoundBox = L.latLngBounds(this.tileToBoundBox());

        this.layer.setBounds(tileBoundBox);
        this.centralizaMarker(tileBoundBox.getCenter());
      }
    }

    tileToBoundBox(zoom = this.zoomBuscaDefault) {
      let tile = this.toTileNumber(zoom),
          swLon = this.tileToLon(tile.x, zoom),
          swLat = this.tileToLat(tile.y + 1, zoom),
          neLon = this.tileToLon(tile.x + 1, zoom),
          netLat = this.tileToLat(tile.y, zoom);

      return [[swLat, swLon], [netLat, neLon]];
    }

    toTileNumber(zoom) {
      let center = this.layer.getCenter();
      /* jslint bitwise: true */
      return {
        x: parseInt(Math.floor((center.lng + 180) / 360 * (1 << zoom)), 10),
        y: parseInt(Math.floor((1 - Math.log(Math.tan(center.lat.toRad()) + 1 / Math.cos(center.lat.toRad())) / Math.PI) / 2 * (1 << zoom)), 10)
      };
    }

    tileToLon(x, z) {
      return x / Math.pow(2, z) * 360 - 180;
    }

    tileToLat(y, z) {
      let toDegrees = radians => radians * 180 / Math.PI,
          n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
      return toDegrees(Math.atan(Math.sinh(n)));
    }

    centralizaMarker(center) {
      this.layer.editing.setPositionMoveMarker({lat: center.lat, lng: center.lng});
    }
  }

  angular
    .module('mapControlModule')
    .service('BuscaPorRegiaoMapService', BuscaPorRegiaoMapService);
}());
