(function () {
  'use strict';

  class ModalResetEventos {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }

    ok() {
      this.modalInstance.close();
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('ModalEventos')
    .controller('ModalResetEventosCtrl', ModalResetEventos);
}());
