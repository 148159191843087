(function () {
  'use strict';

  function menuDefaultDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuDefaultCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuDefaultCtrl {
    constructor($scope, PontosReferenciaTools, PontosReferenciaMap, PontosReferenciaMenu, PontosReferencia,
      CercaEletronicaService, $rootScope, $translate, ServiceVfiltro, GoogleTagManagerUtils, MapasUtil,
      Authenticator) {
      this.scope = $scope;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.cercaEletronicaService = CercaEletronicaService;
      this.rootScope = $rootScope;
      this.translate = $translate;
      this.serviceVfiltro = ServiceVfiltro;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.mapasUtilService = MapasUtil;
      this.authenticator = Authenticator;

      this.user = null;
      this.rootScope.$on('init-menu-map', () => {
        this.setup();
      });
      this.setup();
    }

    setup() {
      this.authenticator.getUser().then(user => {
        this.user = user;
        this.init();
      });
    }

    init() {
      let buttons = [
          {
            titulo: this.translate.instant('ce.mapa.common.tituloBtPesquisar'),
            icon: 'fa fa-search',
            color: 'default',
            tipo: 'pesquisar',
            funcao: () => this.mapasUtilService.activateAllMapLayers(this.user.id)
          }
          ], cadastroConfig = this.pontosReferenciaMapService.cadastroPontoConfig;

      if (this.cercaEletronicaService.roleCadastrar) {
        buttons.unshift({
          titulo: this.translate.instant('ce.mapa.common.menuDefaultDirective.tituloBtAdicionarCerca'),
          icon: 'trimble-control-area',
          color: 'default',
          tipo: 'adicionar-cerca-eletronica',
          funcao: () => this.mapasUtilService.activateAllMapLayers(this.user.id)
        });
      }

      if (this.pontosReferenciaService.roleCadastrar &&
        cadastroConfig !== null &&
        angular.isDefined(cadastroConfig.permissaoDeEdicao) &&
        this.pontosReferenciaMapService.cadastroPontoConfig.permissaoDeEdicao
      ) {
        buttons.unshift({
          titulo: this.translate.instant('ce.mapa.common.menuDefaultDirective.tituloBtAdicionarPontoReferencia'),
          icon: 'fa fa-map-marker',
          color: 'default',
          tipo: 'adicionar',
          funcao: () => this.mapasUtilService.activateAllMapLayers(this.user.id)
        });
      }

      if (this.scope.$parent.pontosProximos) {
        buttons.push({
          titulo: this.translate.instant('ce.mapa.common.menuDefaultDirective.tituloBtAdicionarPontosProx'),
          icon: 'fa fa-thumb-tack',
          color: 'default',
          tipo: 'adicionar-pontos-proximos',
          funcao: () => {
            this.googleTagManagerUtils.sendEventClickGTM(
              'add_ponto_proximo',
              this.serviceVfiltro.factoryVfiltro.user
            );
            this.pontosReferenciaMenuService.setFABActive('adicionar-pontos-proximos');
            this.mapasUtilService.activateAllMapLayers(this.user.id);
          }
        });
      }

      if (this.pontosReferenciaMenuService.idMapa === 'historico-map') {
        buttons.push({
          titulo: this.translate.instant('ce.mapa.common.menuDefaultDirective.tituloBtReproducaoRotas'),
          icon: 'fa fa-play',
          color: 'default',
          tipo: 'reproducao-rotas',
          funcao: () => {
            this.googleTagManagerUtils.sendEventClickGTM(
              'reproducao_rota_visualizar',
              this.serviceVfiltro.factoryVfiltro.user
            );
            this.pontosReferenciaMenuService.setFABActive('reproducao-rotas');
            this.mapasUtilService.activateAllMapLayers(this.user.id);
          }
        });
      }
      this.pontosReferenciaMenuService.setMenu(buttons);
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuDefaultCtrl', MenuDefaultCtrl)
    .directive('menuDefaultDirective', menuDefaultDirective);
}());
