(function () {
  'use strict';

  class VisaoCamerasCtrl {
    constructor($translate, FeatureHubConfigKeys, FeaturehubJsSdk) {
      this.translate = $translate;
      this.indice = undefined;
      this.indicesDisponiveis = [];
      this.labelsEvents = this.labelsFilterEvents();
      this.camerasEvidence = null;
      this.cameraSelected = null;
      this.featureHubConfigKeys = FeatureHubConfigKeys;
      this.featurehubJsSdk = FeaturehubJsSdk;
    }

    $onChanges() {
      if (this.snapshots && this.snapshots.length) {
        this.setIndicesDisponiveis();
        this.getCamerasEvidence();
        this.getAlertNoSnapshots();
      }
    }

    labelsFilterEvents() {
      return {
        onSelectionChanged: () => {
          this.labelEvents();
        }
      };
    }

    setIndicesDisponiveis() {
      this.indicesDisponiveis = [
        ...new Set(
          this.snapshots.reduce((acc, next) => [...acc, ...next.cameras.map(cam => cam.indice)], [])
        )
      ].sort();

      this.indice = angular.isDefined(this.indice) ? this.indice : this.indicesDisponiveis.indexOf(this.indicesDisponiveis[0]);
    }

    changeIndiceCamera(value, cameraSelected) {
      this.indice = value;
      this.cameraSelected = cameraSelected;
    }

    verNoMapa(posicao) {
      this.onVerNoMapa({posicao});
    }

    changePage(page) {
      this.handleChangePage({page});
      angular.element('#snapshots').scrollTop(0);
    }

    getCamerasEvidence() {
      const isOnlyNoLabelActived = this.labelsActived.length === 1 && this.labelsActived[0].id === 'NO_LABEL',
          labelsActivedId = new Set(this.labelsActived.map(label => label.id)),
          labelMap = new Map();

      if (!this.snapshots || this.snapshots.length === 0 || this.labelsActived.length === 0 || isOnlyNoLabelActived) {
        return null;
      }

      this.snapshots.forEach(snapshot => {
        (snapshot.cameraLabels || []).forEach(cameraLabel => {
          const commonLabels = cameraLabel.labels.filter(label => labelsActivedId.has(label));
          commonLabels.forEach(label => {
            if (!labelMap.has(label)) {
              labelMap.set(label, new Set());
            }
            labelMap.get(label).add(cameraLabel.index);
          });
        });
      });

      this.camerasEvidence = Array.from(labelMap.entries()).map(([label, indexesSet]) => ({
        label,
        indexes: Array.from(indexesSet)
      }));
    }

    getAlertNoSnapshots() {
      const hasNoIndexes = this.indicesDisponiveis.length === 0,
          hasNoLabels = !this.labelsActived || this.labelsActived.length === 0,
          alertNoImagesCondition = !this.snapshots || this.snapshots.length === 0 && hasNoIndexes && hasNoLabels,
          noImagesPeriod = this.translate.instant('ce.mapa.common.visaoCameras.naoExistemImagensPeriodo'),
          noFilterSelected = this.translate.instant('ce.mapa.common.visaoCameras.cameraNoFilterSelected');

      return alertNoImagesCondition ? noImagesPeriod : noFilterSelected;
    }

    isFeatureSnapshotLabelActived() {
      return this.featurehubJsSdk.isFeatureEnabled(this.featureHubConfigKeys.ENABLE_SNAPSHOTS_LABELS_FILTER);
    }
  }

  angular
    .module('historicoTimeline')
    .component('visaoCameras', {
      controller: VisaoCamerasCtrl,
      templateUrl: 'historico-timeline/visao-cameras/visao-cameras.tpl.html',
      bindings: {
        snapshots: '<',
        labels: '<',
        labelsActived: '=',
        labelEvents: '&',
        onVerNoMapa: '&',
        navigationSetting: '<',
        handleChangePage: '&'
      }
    });
}());
