(function () {
  'use strict';

  angular
    .module('videoPlayer')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.video', {
        url: '/video/:idVideo/:datahora',
        views: {
          'content@': {
            templateUrl: 'video/video-player/multi-video-player.tpl.html',
            controller: 'MultiVideoPlayerCtrl',
            controllerAs: 'MultiVideoPlayerCtrl'
          }
        },
        data: {
          hideTitulo: true,
          roles: [['VIDEO_VISUALIZAR']]
        }
      })
      .state('root.snapshot', {
        url: '/video/:snapshotId/:datahora/:latitude/:longitude/:tipoEvento/:vehicleId/:vehiclePrefix/:vehiclePlate/:driverName',
        views: {
          'content@': {
            templateUrl: 'video/video-player/multi-video-player.tpl.html',
            controller: 'MultiVideoPlayerCtrl',
            controllerAs: 'MultiVideoPlayerCtrl'
          }
        },
        data: {
          hideTitulo: true,
          roles: [['VIDEO_VISUALIZAR']]
        }
      });
  }
}());
