(function () {
  'use strict';

  class RegraEspecificaRotograma {
    constructor(id = null, grupoVeiculo = null, tipoVeiculo = null, grupoVelocidade = null, rotogramaId = null) {
      this.id = id;
      this.grupoVeiculo = grupoVeiculo;
      this.tipoVeiculo = tipoVeiculo;
      this.grupoVelocidade = grupoVelocidade;
      this.rotogramaId = rotogramaId;
    }

    equals(regra) {
      return this.grupoVeiculo && regra.grupoVeiculo && this.grupoVeiculo.id === regra.grupoVeiculo.id &&
        (this.tipoVeiculo === regra.tipoVeiculo ||
          this.tipoVeiculo && regra.tipoVeiculo && this.tipoVeiculo.id === regra.tipoVeiculo.id) &&
        this.grupoVelocidade && regra.grupoVelocidade && this.grupoVelocidade.id === regra.grupoVelocidade.id;
    }

    toBackendFormat() {
      return {
        id: this.id,
        grupoVelocidade: this.grupoVelocidade,
        grupoVeiculo: this.grupoVeiculo.nome,
        grupoVeiculoId: this.grupoVeiculo.id,
        tipoVeiculo: this.tipoVeiculo && this.tipoVeiculo.nome
      };
    }

    isValid() {
      return !!(this.grupoVeiculo && this.grupoVelocidade);
    }
  }

  /* global _ */
  class CadastroRotogramaService {
    constructor(RotogramaComponentStates, RotogramaMapService, ControleVelocidadeRotograma,
      Restangular, $uibModal, $rootScope, $q, AlertMessage, PosicoesRotogramas, $translate) {
      this.rotogramaComponentStates = RotogramaComponentStates;
      this.rotogramaMapService = RotogramaMapService;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.rootScope = $rootScope;
      this.q = $q;
      this.alertMessage = AlertMessage;
      this.posicoesRotogramas = PosicoesRotogramas;
      this.translate = $translate;

      this.geradorId = 0;
      this.rotogramaDefaultState = this.rotogramaComponentStates.INICIAL;
    }

    iniciarCadastro(rotogramasCadastrados = []) {
      this.agrupamentoAtivo = {rotogramas: [], controleVelocidade: this.controleVelocidadeRotograma.CONFIGURADO};
      this.agrupamentos = [this.agrupamentoAtivo];
      return this.q.all([
        this.restangular.one('/ponto-referencia/grupos-velocidade').get(),
        this.restangular.one('/veiculo/veiculos/grupos').get(),
        this.restangular.one('/veiculo/veiculos/permitidos/tipos').get(),
        this.q.all(rotogramasCadastrados.map(r => this.restangular.one(`/ponto-referencia/config-rotograma?rotograma=${r.id}`).get()))
      ])
      .then(([gruposVelocidade, gruposVeiculo, tiposVeiculo, configsRotogramasData]) => {
        const associateBy = (objetos, propriedade) => {
          return objetos.reduce((map, obj) => {
            map[obj[propriedade]] = obj;
            return map;
          }, {});
        };
        this.gruposVelocidadePorId = associateBy(gruposVelocidade.plain(), 'id');
        this.gruposVeiculoPorId = associateBy(gruposVeiculo.plain(), 'id');
        this.tiposVeiculoPorNome = associateBy(tiposVeiculo.plain(), 'nome');
        this.configsPorRotograma = _.chain(configsRotogramasData)
          .map(data => data.plain())
          .flatten()
          .map(config => {
            return new RegraEspecificaRotograma(
              config.id,
              this.gruposVeiculoPorId[config.grupoVeiculoId],
              this.tiposVeiculoPorNome[config.tipoVeiculo],
              config.grupoVelocidade,
              config.rotograma
            );
          })
          .groupBy(regra => regra.rotogramaId)
          .value();
      });
    }

    adicionarRegra(agrupamento) {
      if (!agrupamento.regras) {
        agrupamento.regras = [];
      }
      agrupamento.regras.push(new RegraEspecificaRotograma());
    }

    getGruposVelocidade() {
      return _.values(this.gruposVelocidadePorId).sort((a, b) =>
      a.velocidade.toString().localeCompare(b.velocidade.toString(), undefined, {numeric: true}));
    }

    getGruposVeiculo() {
      return _.values(this.gruposVeiculoPorId);
    }

    getTiposVeiculo() {
      return _.values(this.tiposVeiculoPorNome);
    }

    salvar(pontoReferenciaId) {
      const rotogramas = _.chain(this.agrupamentos)
          .map(agrupamento => this.mapearRotogramas(agrupamento, pontoReferenciaId))
          .flatten()
          .value();

      return this.restangular.all(`/ponto-referencia/rotogramas/${pontoReferenciaId}/batch`).post(rotogramas)
        .then(rotogramasResponse => {
          this.rootScope.$broadcast('rotogramasCadastrados', {
            rotogramas: rotogramasResponse.plain(),
            pontoReferenciaId: pontoReferenciaId
          });
          this.encerrarCadastro();
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.common.modal.cadastroRotograma.errorCadastrar'),
            appendTo: '.alerta-aqui-cadastro-ponto'
          });
        });
    }

    excluir(pontoReferenciaId) {
      this.restangular.all(`/ponto-referencia/rotogramas/${pontoReferenciaId}/batch`).post([])
      .then(() => {
        this.rootScope.$broadcast('rotogramasDeletados', {
          rotogramaIds: this.getRotogramas().map(rotograma => rotograma.id),
          pontoReferenciaId: pontoReferenciaId
        });
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.cadastroRotograma.errorExcluir'),
          appendTo: '.alerta-aqui-cadastro-ponto'
        });
      });
    }

    encerrarCadastro() {
      this.rotogramaMapService.encerrarCadastro();
    }

    mapearRotogramas(agrupamento, pontoReferenciaId) {
      const configsAgrupamento = agrupamento.usarRegrasEspecificas ?
        agrupamento.regras.map(regra => regra.toBackendFormat()) : [];

      return agrupamento.rotogramas.map(rotograma => {
        const angulo = this.rotogramaMapService.getAngulo(rotograma),
            rotogramaInterno = angulo === null;
        let grupoVelocidade;
        if (agrupamento.controleVelocidade.value !== this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR.value) {
          grupoVelocidade = agrupamento.grupoVelocidade;
        }
        return {
          rotograma: {
            id: rotograma.id,
            pontoReferencia: pontoReferenciaId,
            latitude: rotograma.marker.getLatLng().lat,
            longitude: rotograma.marker.getLatLng().lng,
            grau: angulo === 360 ? 0 : angulo,
            status: rotogramaInterno ?
              this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value : agrupamento.controleVelocidade.value,
            grupoVelocidadePadrao: grupoVelocidade
          },
          configs: configsAgrupamento
        };
      });
    }

    adicionarAgrupamento() {
      this.agrupamentoAtivo = {rotogramas: [], controleVelocidade: this.controleVelocidadeRotograma.CONFIGURADO};
      this.agrupamentos.push(this.agrupamentoAtivo);
      this.destacarRotogramasDisponiveis();
    }

    setAgrupamentoAtivo(agrupamento) {
      if (this.agrupamentoAtivo.rotogramas.length === 0) {
        this.agrupamentos = this.agrupamentos.filter(a => a !== this.agrupamentoAtivo);
      }
      this.agrupamentoAtivo = agrupamento;
    }

    destacarRotogramasDisponiveis() {
      this.rotogramaDefaultState = this.rotogramaComponentStates.DESTAQUE;
    }

    getRotograma(idInterno) {
      return _.chain(this.agrupamentos)
        .map(agrupamento => agrupamento.rotogramas)
        .flatten()
        .find(r => r.idInterno === idInterno);
    }

    getRotogramas() {
      return _.flatten(_.map(this.agrupamentos, agrupamento => agrupamento.rotogramas));
    }

    adicionarRotograma(marker, rotogramaCadastrado) {
      const rotograma = {
        id: rotogramaCadastrado && rotogramaCadastrado.id,
        idInterno: ++this.geradorId,
        state: this.rotogramaComponentStates.ATIVO,
        marker: marker
      };

      if (rotogramaCadastrado) {
        if (rotogramaCadastrado.status === this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value) {
          rotogramaCadastrado.status = this.controleVelocidadeRotograma.CONFIGURADO.value;
        }
        this.agrupar(rotograma, rotogramaCadastrado);
        return rotograma.idInterno;
      }
      this.agrupamentoAtivo.rotogramas.push(rotograma);
      this.indexarRotogramas();
      return rotograma.idInterno;
    }

    agrupar(rotograma, rotogramaCadastrado) {
      const regrasEspecificasRotograma = this.configsPorRotograma[rotogramaCadastrado.id],
          indice = this.getIndiceAgrupamento(rotogramaCadastrado, regrasEspecificasRotograma);
      if (this.agrupamentos[indice].rotogramas.length === 0) {
        this.setPropriedadesAgrupamento(this.agrupamentos[indice], rotogramaCadastrado, regrasEspecificasRotograma);
        if (this.getRotogramas().length < 4) {
          this.adicionarAgrupamento();
        }
      }
      this.agrupamentos[indice].rotogramas.push(rotograma);
      this.indexarRotogramas();
    }

    getIndiceAgrupamento(rotogramaCadastrado, regrasEspecificasRotograma) {
      const indice = _.findIndex(this.agrupamentos, agrupamento => {
        if (agrupamento.rotogramas.length === 0) {
          return false;
        }
        return this.gruposVelocidadeIguais(agrupamento.grupoVelocidade, rotogramaCadastrado.grupoVelocidadePadrao) &&
          this.controlesVelocidadeIguais(agrupamento.controleVelocidade.value, rotogramaCadastrado.status) &&
          this.regrasEspecificasIguais(agrupamento, regrasEspecificasRotograma);
      });
      return indice !== -1 ? indice : this.agrupamentos.length - 1;
    }

    setPropriedadesAgrupamento(agrupamento, rotogramaCadastrado, regrasEspecificasRotograma) {
      const controleVelocidade = rotogramaCadastrado.status === this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value ?
          this.controleVelocidadeRotograma.CONFIGURADO : this.controleVelocidadeRotograma[rotogramaCadastrado.status];
      agrupamento.grupoVelocidade = rotogramaCadastrado.grupoVelocidadePadrao;
      agrupamento.controleVelocidade = controleVelocidade;

      if (regrasEspecificasRotograma && regrasEspecificasRotograma.length > 0) {
        agrupamento.usarRegrasEspecificas = true;
        agrupamento.regras = regrasEspecificasRotograma;
      }
    }

    controlesVelocidadeIguais(controleVelocidade1, controleVelocidade2) {
      if (controleVelocidade1 === controleVelocidade2) {
        return true;
      }
      const controlesVelocidade = new Set([controleVelocidade1, controleVelocidade2]);
      return controlesVelocidade.has(this.controleVelocidadeRotograma.CONFIGURADO.value) &&
        controlesVelocidade.has(this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value);
    }

    gruposVelocidadeIguais(grupo1, grupo2) {
      return _.isEqual(grupo1, grupo2) || grupo1 && grupo2 && grupo1.id === grupo2.id;
    }

    regrasEspecificasIguais(agrupamento, regrasEspecificasRotograma) {
      if (angular.isUndefined(agrupamento.regras) && (angular.isUndefined(regrasEspecificasRotograma) ||
        regrasEspecificasRotograma.length === 0)) {
        return true;
      }
      return angular.isDefined(agrupamento.regras) && angular.isDefined(regrasEspecificasRotograma) &&
        agrupamento.regras.length === regrasEspecificasRotograma.length &&
        agrupamento.regras.every(regraAgrupamento => regrasEspecificasRotograma.some(regra => regra.equals(regraAgrupamento)));
    }

    removerRotograma(idInterno) {
      const agrupamentoRotograma = _.find(
        this.agrupamentos,
        agrupamento => _.some(agrupamento.rotogramas, rotograma => rotograma.idInterno === idInterno)
      );
      agrupamentoRotograma.rotogramas = agrupamentoRotograma.rotogramas.filter(rotograma => rotograma.idInterno !== idInterno);
      if (agrupamentoRotograma.rotogramas.length === 0 && this.agrupamentos.length > 1) {
        this.agrupamentos = this.agrupamentos.filter(agrupamento => agrupamento !== agrupamentoRotograma);
        if (agrupamentoRotograma === this.agrupamentoAtivo) {
          this.agrupamentoAtivo = this.agrupamentos[this.agrupamentos.length - 1];
        }
      }
      this.indexarRotogramas();
    }

    getState(idInterno) {
      const rotograma = this.getRotograma(idInterno);
      return rotograma && rotograma.state || this.rotogramaDefaultState;
    }

    indexarRotogramas() {
      _.chain(this.agrupamentos)
        .map(agrupamento => agrupamento.rotogramas)
        .flatten()
        .forEach((rotograma, i) => {
          rotograma.indice = i + 1;
        });
      this.rotogramaDefaultState = this.rotogramaComponentStates.INICIAL;
    }

    isCadastroValido() {
      const temQuantidadeMinimaRotogramas = this.getRotogramas().length >= 2,
          agrupamentosValidos = this.agrupamentos && this.agrupamentos.every(agrupamento => this.isAgrupamentoValido(agrupamento));
      return temQuantidadeMinimaRotogramas && agrupamentosValidos;
    }

    isAgrupamentoValido(agrupamento) {
      const agrupamentoVazio = agrupamento.rotogramas.length === 0,
          grupoVelocidadeValido = agrupamento.grupoVelocidade && agrupamento.controleVelocidade !== this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR ||
            agrupamento.controleVelocidade === this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR &&
              agrupamento.rotogramas.every(rotograma => rotograma.marker.options.posicao !== this.posicoesRotogramas.CENTRO),
          regrasValidas = !agrupamento.regras || agrupamento.regras.every(regra => {
            return regra.isValid() && !this.isRegraDuplicada(regra, agrupamento.regras);
          });
      return agrupamentoVazio || grupoVelocidadeValido && regrasValidas;
    }

    isRegraDuplicada(regra, regras) {
      return regras
        .slice(0, regras.indexOf(regra))
        .some(outraRegra => outraRegra.equals(regra));
    }
  }

  angular
    .module('rotogramaModule')
    .service('CadastroRotogramaService', CadastroRotogramaService)
    .service('RegraEspecificaRotogramaFactory', function () {
      return {
        new: (id, grupoVeiculo, tipoVeiculo, grupoVelocidade, rotogramaId) =>
          new RegraEspecificaRotograma(id, grupoVeiculo, tipoVeiculo, grupoVelocidade, rotogramaId)
      };
    });
}());
