(function () {
  'use strict';

  angular.module('requisicaoVideo', [
    'AlertasModule',
    'angularMoment',
    'restangular',
    'alert-message',
    'pascalprecht.translate',
    'ServiceVfiltroModule',
    'ConstantModule'
  ]);
}());
