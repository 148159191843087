(function () {
  'use strict';

  /* global FileReader, atob, Blob */
  class v3gImageCropCtrl {
    constructor($scope, MB_IN_BYTES, AlertMessage) {
      this.scope = $scope;
      this.alertMessage = AlertMessage;
      this.required = $scope.required || true;
      this.maxSize = $scope.maxSize || 5 * MB_IN_BYTES;
      this.MB_IN_BYTES = MB_IN_BYTES;
      this.TIPOS_EXTENSAO_IMAGE = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'bmp'
      ];

      this.fotoOriginal = null;
      this.fotoFinal = null;
    }

    handleChangeImage(el) {
      let file = el.files[0],
          extension = file.name.split('.').slice(-1)[0],
          reader = new FileReader();

      if (this.isValidImage(extension)) {
        reader.onload = this.onLoadFileReader();
        reader.readAsDataURL(file);
        this.scope.onChangeFile(file);
      } else {
        this.scope.$apply(() => {
          this.alertMessage.create({
            type: 'error',
            message: 'A extensão do arquivo deve ser JPG, JPEG, PNG, GIF ou BMP.',
            appendTo: '.alert-file'
          });
          angular.element('#imagem').val('');
        });
      }
    }

    onLoadFileReader() {
      return (evt) => {
        this.scope.$apply(() => {
          this.fotoOriginal = evt.target.result;
          this.fotoFinal = evt.target.result;
        });
      };
    }

    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(',')[1]),
          mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0],
          ab = new ArrayBuffer(byteString.length),
          ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: mimeString});
    }

    onCrop() {
      if (this.fotoFinal !== null) {
        this.scope.onCrop(this.dataURItoBlob(this.fotoFinal));
      }
    }

    maxSizeLabel() {
      return `${this.maxSize / this.MB_IN_BYTES}MB`;
    }

    isValidImage(type) {
      return this.TIPOS_EXTENSAO_IMAGE.find(tipo => type.includes(tipo));
    }
  }

  angular
    .module('VImageCrop')
    .constant('MB_IN_BYTES', 1000000)
    .controller('v3gImageCropCtrl', v3gImageCropCtrl);
}());
