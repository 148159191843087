(function () {
  'use strict';

  class CamerasPaginacaoCtrl {
    constructor() {
      this.totalPages = 0;
      this.pageArray = [];
      this.page = 0;
      this.isFirst = true;
      this.isLast = false;
    }

    $onChanges() {
      if (this.navigationSetting) {
        this.updateState();
      }
    }

    updateState() {
      this.totalPages = this.navigationSetting.totalPages;
      this.pageArray = Array.from(Array(this.totalPages).keys());
      this.page = this.navigationSetting.page;
      this.isFirst = this.navigationSetting.first;
      this.isLast = this.navigationSetting.last;
    }

    handleChangeSelectPage() {
      this.handleChangePage({page: this.page});
    }

    handlePreviousPage() {
      this.handleChangePage({page: this.isFirst ? 0 : this.page - 1});
    }

    handleNextPage() {
      this.handleChangePage({page: this.isLast ? this.page : this.page + 1});
    }
  }

  angular
    .module('historicoTimeline')
    .component('camerasPaginacao', {
      controller: CamerasPaginacaoCtrl,
      templateUrl: 'historico-timeline/visao-cameras/cameras-paginacao/cameras-paginacao.tpl.html',
      bindings: {
        navigationSetting: '<',
        handleChangePage: '&'
      }
    });
}());
