(function () {
  'use strict';

  /* @ngdoc object
   * @name mapasGrid
   * @description
   *
   */
  angular
    .module('mapasGrid', [
      'ui.router',
      'leaflet-directive',
      'ModuleMapasUtil',
      'ModuleConvertersUtil',
      'ConstantModule',
      'angularMoment',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'mapas',
      'leaflet-directive',
      'ui.bootstrap.modal',
      'alert-message',
      'enumModule',
      'urlConfigModule',
      'v3gAuthenticatorModule',
      'blockUI',
      'httpServiceV3gModule',
      'pascalprecht.translate',
      'UrlModule'
    ])
    .factory('StatusVeiculo', (Restangular) => {
      return Restangular.service('sensor/status-veiculo');
    })
    .factory('StatusVeiculoPassivelManutencao', (Restangular) => {
      const url = 'alerta/passiveis-manutencao';
      let service = Restangular.service(url);

      service.getStatusVeiculos = (uoId) => {
        return Restangular.one(`${url}/${uoId}/status-veiculos`).get();
      };
      return service;
    });
}());
