/* global L */
(function () {
  'use strict';

  const CAMADA_PARAM = 'camada';

  class MapLayerSwitcherCtrl {
    constructor(Restangular, UrlService, $window, $timeout, MapasUtil) {
      this.restangular = Restangular;
      this.urlService = UrlService;
      this.window = $window;
      this.timeout = $timeout;
      this.mapasUtil = MapasUtil;
      this.layerIds = {
        ESTRADAS: 'ESTRADAS',
        SATELITE: 'SATELITE',
        ESTRADAS_DIA: 'ESTRADAS_DIA',
        ESTRADAS_NOITE: 'ESTRADAS_NOITE'
      };
      this.defaultOptions = {
        [this.layerIds.ESTRADAS]: {
          label: 'ce.utils.mapa.estradas',
          value: this.layerIds.ESTRADAS,
          iconClass: 'fa fa-road'
        },
        [this.layerIds.SATELITE]: {
          label: 'ce.utils.mapa.satelite',
          value: this.layerIds.SATELITE,
          iconClass: 'fa fa-globe'
        }
      };
    }

    $onInit() {
      this.shouldSetUrlParam = angular.isUndefined(this.shouldSetUrlParam) ? true : this.shouldSetUrlParam;
      this.availableOptions = this.getAvailableOptions();
      this.selectedOption = this.getLastSelectedOption();
      this.fetchLayerData();
    }

    $onDestroy() {
      if (this.urlExpirationTimeout) {
        this.timeout.cancel(this.urlExpirationTimeout);
      }
    }

    getLastSelectedOption() {
      return this.availableOptions[this.urlService.getQueryString(CAMADA_PARAM)] ||
        this.availableOptions[this.window.localStorage.getItem(CAMADA_PARAM)] ||
        Object.values(this.availableOptions)[0];
    }

    getAvailableOptions() {
      if (this.useNightAndDayOptions) {
        return {
          [this.layerIds.ESTRADAS_DIA]: {
            label: 'ce.utils.mapa.estradasDia',
            value: this.layerIds.ESTRADAS_DIA,
            iconClass: 'fa fa-road'
          },
          [this.layerIds.ESTRADAS_NOITE]: {
            label: 'ce.utils.mapa.estradasNoite',
            value: this.layerIds.ESTRADAS_NOITE,
            iconClass: 'fa fa-moon-o'
          },
          [this.layerIds.SATELITE]: this.defaultOptions.SATELITE
        };
      }
      return this.defaultOptions;
    }

    fetchLayerData() {
      this.layersData = {
        [this.layerIds.SATELITE]: this.mapasUtil.tiles.trimbleMaps,
        [this.layerIds.ESTRADAS]: this.mapasUtil.tiles.veltec
      };
      this.onSelectOption();
    }

    onSelectOption() {
      this.onLayerSwitch({layerWrapper: this.getLayerWrapper(this.selectedOption.value)});
      if (this.shouldSetUrlParam) {
        this.urlService.setQueryString(CAMADA_PARAM, this.selectedOption.value);
      }
      this.window.localStorage.setItem(CAMADA_PARAM, this.selectedOption.value);
    }

    getLayerWrapper(layerId) {
      if (layerId === this.defaultOptions.ESTRADAS.value || layerId === this.defaultOptions.SATELITE.value) {
        return {
          getLayerConfig: () => this.layersData[this.selectedOption.value],
          getLayerObject: () => L.tileLayer(this.layersData[this.selectedOption.value].url, {
            attribution: this.layersData[this.selectedOption.value].options.attribution,
            subdomains: this.layersData[this.selectedOption.value].options.subdomains,
            options: this.layersData[this.selectedOption.value]
          })
        };
      } else if (this.useNightAndDayOptions) {
        return {
          getLayerConfig: () => Object.assign(
            {colorFilter: layerId === this.layerIds.ESTRADAS_NOITE ? ['inv:100%', 'bri:60%', 'gray:50%', 'hue:175deg'] : []},
            this.layersData[this.layerIds.ESTRADAS]
          ),
          getLayerObject: () => L.tileLayer.colorFilter(this.layersData[this.layerIds.ESTRADAS].url, {
            filter: layerId === this.layerIds.ESTRADAS_NOITE ? ['inv:100%', 'bri:60%', 'gray:50%', 'hue:175deg'] : [],
            attribution: this.layersData[this.layerIds.ESTRADAS].options.attribution,
            subdomains: this.layersData[this.layerIds.ESTRADAS].options.subdomains,
            options: this.layersData[this.layerIds.ESTRADAS]
          })
        };
      }
    }
  }

  angular
    .module('mapas')
    .component('mapLayerSwitcher', {
      templateUrl: 'mapas/map-layer-switcher/map-layer-switcher-component.tpl.html',
      controller: MapLayerSwitcherCtrl,
      bindings: {
        onLayerSwitch: '&',
        useNightAndDayOptions: '<',
        shouldSetUrlParam: '<?'
      }
    });
}());
