(function () {
  'use strict';

  /* global _ */
  class EnviarComando {
    constructor($uibModalInstance, veiculoIds, AlertMessage, HttpServiceV3g, $httpParamSerializer, TiposComando, $translate, ServiceVfiltro, GoogleTagManagerUtils) {
      this.modalInstance = $uibModalInstance;
      this.alertMessage = AlertMessage;
      this.httpService = HttpServiceV3g;
      this.httpParamSerializer = $httpParamSerializer;
      this.veiculoIds = veiculoIds;
      this.tiposComando = TiposComando;
      this.translate = $translate;
      this.serviceVfiltro = ServiceVfiltro;
      this.googleTagManagerUtils = GoogleTagManagerUtils;

      this.comandos = [];
      this.getComandos();
    }

    getComandos() {
      this.httpService.get(`/comando/tipos?veiculos=${this.veiculoIds.join()}`)
        .success(data => {
          this.comandos = data;
          if (this.hasVeiculosAfetados()) {
            this.defaultOption = this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.selecionarComando');
          } else {
            this.defaultOption = this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.nenhumComandoDisponivel');
          }
        })
        .error(() => {
          this.erro = true;
          this.defaultOption = this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.nenhumComandoDisponivel');
          this.msgErro = this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.erroCarregarComando');
        });
    }

    limpaErro() {
      this.erro = false;
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    hasVeiculosAfetados() {
      return _.some(this.comandos, comando => comando.veiculos.length > 0);
    }

    efetuaEnvioComando() {
      if (angular.isDefined(this.model)) {
        this.sendCommandInfoToGTM(this.model.comandoSelected.tipo);
        this.httpService.post('/comando/comandos', this.model.comandoSelected)
          .success(() => {
            if (this.model.comandoSelected.veiculos.length) {
              this.modalInstance.close({hasAfetados: true, msg: this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.requisicaoEnvioComando')});
            } else {
              this.modalInstance.close({hasAfetados: false, msg: this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.nenhumVeiculoAfetado')});
            }
          })
          .error(() => {
            this.erro = true;
            this.msgErro = this.translate.instant('ce.mapa.localizacaoDaFrota.enviarComando.ocorreuUmErro');
          });
      }
    }

    sendCommandInfoToGTM(tipoComando) {
      switch (tipoComando) {
        case 'ACIONAR_BUZZER':
          this.googleTagManagerUtils.sendEventClickGTM('localizacao_frota_comando_acionar_buzzer', this.serviceVfiltro.factoryVfiltro.user);
          break;
        case 'DESACIONAR_BUZZER':
          this.googleTagManagerUtils.sendEventClickGTM('localizacao_frota_comando_desacionar_buzzer', this.serviceVfiltro.factoryVfiltro.user);
          break;
        case 'DESACIONAR_BLOQUEIO':
          this.googleTagManagerUtils.sendEventClickGTM('localizacao_frota_comando_desacionar_bloqueio', this.serviceVfiltro.factoryVfiltro.user);
          break;
        default:
          break;
      }
    }
  }

  angular
    .module('mapasGrid')
    .controller('EnviarComandoCtrl', EnviarComando)
    .constant('TiposComando', {
      ACIONAR_BUZZER: 'ce.mapa.localizacaoDaFrota.enviarComando.acionarBuzzer',
      DESACIONAR_BUZZER: 'ce.mapa.localizacaoDaFrota.enviarComando.desacionarBuzzer',
      DESACIONAR_BLOQUEIO: 'ce.mapa.localizacaoDaFrota.enviarComando.desacionarBloqueio'
    });
}());
