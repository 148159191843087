(function () {
  'use strict';

  const tipoPermissao = {
    EDITAR: 'E',
    VISUALIZAR: 'V',
    podeEditar: (item) => item === tipoPermissao.EDITAR,
    podeVisualizar: (item) => item === tipoPermissao.VISUALIZAR || tipoPermissao.podeEditar(item)
  };

  angular
    .module('enumModule')
    .constant('TipoPermissaoEnum', tipoPermissao);
}());
