(function () {
  'use strict';

  function alertaTurnoOnePageCardDirective() {
    return {
      templateUrl: 'one-page/directives/alerta-turno/card/alerta-turno-one-page-card.tpl.html',
      controller: 'AlertaTurnoOnePageCardCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class AlertaTurnoOnePageCardCtrl {
    constructor($scope, $window, $q, $httpParamSerializer, moment, FiltroAlertaService, AlertaVeiculoService,
      OnePageService, CustomGridster, Uos, UoArvoreNivel, Authenticator, Keys, UrlService) {
      this.scope = $scope;
      this.window = $window;
      this.q = $q;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.filtroAlertaService = FiltroAlertaService;
      this.alertaVeiculoService = AlertaVeiculoService;
      this.onePageService = OnePageService;
      this.customGridster = CustomGridster;
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.authenticator = Authenticator;
      this.keys = Keys;
      this.urlService = UrlService;

      this.onePageCtrl = this.scope.$parent.$parent.onePage;

      this.gridsterAlertaTurno = this.customGridster.getOptions(150);

      this.alertaTurno = {};
      this.turnos = [];
      this.tableState = null;

      this.listaAlertaWatch = [];
      this.alertaTipo = '';
      this.alertaSelecionado = {};
      this.uoFiltro = null;
      this.posicoesGridster = {};
      this.visualizarHistoricoAlerta = false;
      this.collapseAlertasControle = true;

      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateAlertaTurno();
        this.removeTimer();
        this.alertaVeiculoService.stop();
      });

      this.authenticator.getUser().then(user => {
        const retornoCollapse = angular.fromJson(this.window.localStorage.getItem(`collapse-alertas-${user.id}`));
        if (retornoCollapse !== null) {
          this.collapseAlertasControle = retornoCollapse;
        }
        if (this.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.visualizarHistoricoAlerta = true;
        }
        this.loadUos()
        .then(x => {
          this.uos = x;
          this.init(user);
        });
      });
    }

    init(user) {
      this.user = user;
      this.alertaVeiculoService.setUoId(user.uo.id);
      this.alertaVeiculoService.checkAlertaPorTipo();
      if (this.onePageService.listaCacheadaAlertaTurno) {
        this.buildCardAlertaTurno(this.onePageService.listaCacheadaAlertaTurno);
      }
      this.onePageService.onUpdateAlertaTurno(data => {
        if (data.turnos && data.turnos.length > 0) {
          this.buildCardAlertaTurno(data);
        }
      }, 'onepage');
    }

    loadUos() {
      return this.q(resolve => {
        this.uoService.getFilhas().then(
          uos => {
            uos = this.uoArvoreNivel.converterEmLista(uos);
            resolve(uos.map(u => ({id: u.id, label: u.nome, arvoreNivel: u.arvoreNivel})));
          },
          () => {

          });
      });
    }

    buildCardAlertaTurno(data) {
      let tipoAlertas = angular.copy(this.alertaVeiculoService.alertaMapPtBr),
          indexTurno = 0,
          listaAlerta = [],
          trocarLista = false,
          fimTurno;

      const uoId = data.turnos[0].uoId;
      if (this.uoFiltro !== uoId) {
        this.uoFiltro = uoId;
        trocarLista = true;
      }

      this.turnos = data.turnos.map((x, y) => {
        const atual = this.moment(),
            inicio = this.moment(x.inicio),
            fim = this.moment(x.fim),
            ativo = atual.isBetween(inicio, fim);

        if (ativo) {
          indexTurno = y;
          fimTurno = fim;
        }

        return {
          ativo,
          inicioCompleto: inicio.format(),
          fimCompleto: fim.format(),
          inicio: inicio.format('HH:mm'),
          fim: fim.format('HH:mm')
        };
      });

      Object.keys(tipoAlertas).forEach(alerta => {
        tipoAlertas[alerta].color = tipoAlertas[alerta].chartColor;
        tipoAlertas[alerta].totalEventos = 0;
        tipoAlertas[alerta].urlTotalEventos = '';
        tipoAlertas[alerta].totalResolvidos = 0;
        tipoAlertas[alerta].urlTotalResolvidos = '';
        tipoAlertas[alerta].uoContagensTurno = [];
        tipoAlertas[alerta].uoContagensTurnoTotal = [];
        tipoAlertas[alerta].sort = {};

        const itemData = data.paineis.filter(painel => painel.label === tipoAlertas[alerta].key)[0];
        if (itemData) {
          let totalOcorridos = [0, 0, 0, 0],
              totalResolvidos = [0, 0, 0, 0],
              totalUrl = ['', '', '', ''];

          tipoAlertas[alerta].uoContagensTurno = itemData.uoContagensTurno.map(y => {
            const ocorridos = this.somatorioArray(y.contagensTurno.map(z => z.ocorridos)),
                resolvidos = this.somatorioArray(y.contagensTurno.map(z => z.resolvidos));
            return {
              uo: y.uo,
              contagensTurno: y.contagensTurno.map((t, index) => {
                totalOcorridos[index] += t.ocorridos;
                totalResolvidos[index] += t.resolvidos;
                if (totalUrl[index] === '' && this.visualizarHistoricoAlerta) {
                  totalUrl[index] = this.buildObjectTurnoFiltro(this.uoFiltro, index, itemData.label);
                }
                return {
                  ocorridos: t.ocorridos,
                  resolvidos: t.resolvidos,
                  ativo: indexTurno === index,
                  url: this.visualizarHistoricoAlerta ? this.buildObjectTurnoFiltro(y.uo.id, index, itemData.label) : ''
                };
              }),
              ocorridos,
              resolvidos,
              porcentagem: ocorridos === 0 ? 100 : Math.round(resolvidos / ocorridos * 100 * 1e2) / 1e2
            };
          });

          tipoAlertas[alerta].uoContagensTurnoTotal = {
            totalOcorridos,
            totalResolvidos,
            totalUrl
          };

          tipoAlertas[alerta].totalEventos = this.somatorioArray(tipoAlertas[alerta].uoContagensTurno.map(x => x.ocorridos));
          tipoAlertas[alerta].urlTotalEventos = this.buildObjectTotalFiltro(fimTurno, alerta, false);
          tipoAlertas[alerta].totalResolvidos = tipoAlertas[alerta].totalEventos !== 0 ? Math.round(this.somatorioArray(tipoAlertas[alerta].uoContagensTurno.map(x => x.resolvidos)) / tipoAlertas[alerta].totalEventos * 100 * 1e2) / 1e2 : 100;
          tipoAlertas[alerta].urlTotalResolvidos = this.buildObjectTotalFiltro(fimTurno, alerta, true);
          listaAlerta.push(tipoAlertas[alerta]);
        }
      });

      this.alertaTurno = listaAlerta;
      if (trocarLista) {
        this.onePageService.alertaTurno = listaAlerta;
      }
      this.setPosicaoGraficoAlertaTurno();
    }

    getAlertaTurno(tipo) {
      if (this.alertaTipo !== tipo || this.alertaTurno.length === 1) {
        const alerta = this.alertaTurno.filter(x => x.key === tipo)[0];

        this.alertaTipo = tipo;
        this.alertaSelecionado = alerta;
      }

      return this.alertaSelecionado;
    }

    somatorioArray(itens) {
      if (itens.length > 0) {
        return itens.reduce((anterior, atual) => {
          return anterior + atual;
        });
      }
      return 0;
    }

    goToHistoricoAlerta(item) {
      if (this.visualizarHistoricoAlerta) {
        this.window.location.href = this.filtroAlertaService.filtroLink(this.onePageCtrl.getParamValue(this.keys.uo.name), item);
      }
    }

    objectFiltro() {
      return {
        filtros: [],
        tableState: {
          sort: {},
          search: {},
          pagination: {
            start: 0,
            totalItemCount: 0,
            number: 10,
            numberOfPages: 1
          }
        }
      };
    }

    buildObjectTotalFiltro(fim, tipo, resolvidos) {
      let dataHojeFim = this.moment().endOf('day'),
          endDate = fim.isBefore(dataHojeFim) ? fim : dataHojeFim,
          startDate = angular.copy(fim),
          filtro = [
              {
                key: this.keys.dateTime.name,
                value: {
                  tipo: 'personalizado',
                  endDate: endDate.format(),
                  startDate: startDate.subtract(1, 'day').format()
                }
              },
              {
                key: this.keys.uo.name,
                value: {
                  id: this.uoFiltro
                }
              },
              {
                key: this.keys.tipos.name,
                value: {
                  id: tipo === 'EXCESSO_VELOCIDADE_20' || tipo === 'EXCESSO_VELOCIDADE_30' || tipo === 'EXCESSO_VELOCIDADE_ACIMA_30' ? 'EXCESSO_VELOCIDADE' : tipo
                }
              }
          ];

      if (tipo === 'EXCESSO_VELOCIDADE_20') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'BAIXO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'MEDIO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_ACIMA_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'ALTO'}});
      }

      if (resolvidos) {
        filtro.push(
          {key: this.keys.status.name, value: {id: 'RESOLVIDO'}}
        );
      }

      return '/historico-alerta?vfiltro=' + this.urlService.getObjectVfiltro(filtro);
    }

    buildObjectTurnoFiltro(uo, index, tipo) {
      let dataHojeFim = this.moment().endOf('day'),
          fim = this.moment(this.turnos[index].fimCompleto),
          endDate = fim.isBefore(dataHojeFim) ? fim : dataHojeFim,
          filtro = [
              {
                key: this.keys.dateTime.name,
                value: {
                  startDate: this.turnos[index].inicioCompleto,
                  endDate: endDate.format(),
                  tipo: 'personalizado'
                }
              },
              {
                key: this.keys.tipos.name,
                value: {
                  id: tipo === 'EXCESSO_VELOCIDADE_20' || tipo === 'EXCESSO_VELOCIDADE_30' || tipo === 'EXCESSO_VELOCIDADE_ACIMA_30' ? 'EXCESSO_VELOCIDADE' : tipo
                }
              }
          ];

      if (this.uos.filter(x => x.id === uo).length > 0) {
        filtro.push({key: this.keys.uo.name, value: {id: uo}});
      } else {
        filtro.push(
          {key: this.keys.uo.name, value: {id: this.uoFiltro}},
          {key: this.keys.uoParceira.name, value: {id: uo}}
        );
      }

      if (tipo === 'EXCESSO_VELOCIDADE_20') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'BAIXO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'MEDIO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_ACIMA_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'ALTO'}});
      }

      return '/historico-alerta?vfiltro=' + this.urlService.getObjectVfiltro(filtro);
    }

    unbindScopeWatch() {
      if (this.listaAlertaWatch.length > 0) {
        this.listaAlertaWatch.forEach(e => e());
        this.listaAlertaWatch = [];
      }
    }

    buildObjectGridster(name) {
      let listaPosicoes = [],
          listaGridster = [],
          listaLocalstorage = {},
          criarNovaLista = false;

      this.alertaTurno.map((alerta, index) => {
        listaPosicoes[index] = {row: Math.floor(index / 3) * 2, col: index % 3 * 4, sizeX: 4, sizeY: 2, minSizeY: 1, maxSizeY: 6, minSizeX: 4, maxSizeX: 12, sort: {}, tipo: alerta.key};
      });

      listaGridster = listaPosicoes.filter(i => i.tipo !== '');
      listaLocalstorage = this.customGridster.getLocalStorage(name);

      if (listaLocalstorage && listaLocalstorage.versao && listaLocalstorage.versao === this.onePageCtrl.versaoLocalstorage) {
        let copyListaGridster = angular.copy(listaGridster),
            copylistaLocalstorage = angular.copy(listaLocalstorage.alertas);

        copyListaGridster = copyListaGridster.map(x => x.tipo).sort().toString();
        copylistaLocalstorage = copylistaLocalstorage.map(x => x.tipo).sort().toString();
        if (copyListaGridster !== copylistaLocalstorage) {
          criarNovaLista = true;
        }
      } else {
        criarNovaLista = true;
        this.customGridster.setLocalStorage(name, {name: name, alertas: listaGridster, versao: this.onePageCtrl.versaoLocalstorage});
      }

      if (criarNovaLista) {
        this.posicoesGridster = listaGridster;
        this.customGridster.setLocalStorage(name, {name: name, alertas: listaGridster, versao: this.onePageCtrl.versaoLocalstorage});
      } else {
        this.posicoesGridster = listaLocalstorage.alertas;
      }
    }

    setPosicaoGraficoAlertaTurno() {
      const name = `c-alertaTurno-${this.user.id}-${this.uoFiltro}`;

      this.unbindScopeWatch();
      this.buildObjectGridster(name);

      for (let i = 0; i < this.posicoesGridster.length; i++) {
        this.listaAlertaWatch.push(this.onePageCtrl.setPosicaoLocalStorageAlertas(this.posicoesGridster[i], name));
      }
    }

    collapseAlertas() {
      this.collapseAlertasControle = !this.collapseAlertasControle;
      this.window.localStorage.setItem(`collapse-alertas-${this.user.id}`, angular.toJson(this.collapseAlertasControle));
    }
  }

  angular
    .module('onePageModule')
    .controller('AlertaTurnoOnePageCardCtrl', AlertaTurnoOnePageCardCtrl)
    .directive('alertaTurnoOnePageCardDirective', alertaTurnoOnePageCardDirective);
}());
