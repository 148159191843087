(function () {
  'use strict';

  angular
    .module('segurancaModule')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.insights', {
        url: '/seguranca',
        views: {
          'content@': {
            templateUrl: 'insights/seguranca/seguranca.tpl.html',
            controller: 'SegurancaCtrl',
            controllerAs: 'seguranca'
          }
        },
        data: {
          hideTitulo: true,
          roles: [['PREDICAO_ACIDENTE_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
        }
      });
  }
}());
