(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider
      .state('root.bdv', {
        url: '/relatorios/bdv?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'relatorios/bdv/bdv.tpl.html',
            controller: 'BdvCtrl',
            controllerAs: 'bdv'
          }
        },
        data: {
          titulo: 'ce.boletim.diarioVeiculo.titulo',
          tituloDescricao: 'ce.boletim.diarioVeiculo.tituloDescricao',
          role: 'BOLETIM_VEICULO_VISUALIZAR'
        }
      })
      .state('root.bdvDetalhes', {
        url: '/relatorios/bdv/detalhes/:veiculoId/:dia',
        views: {
          'content@': {
            templateUrl: 'relatorios/bdv/bdv-detalhes/bdv-detalhes.tpl.html',
            controller: 'BdvDetalhesCtrl',
            controllerAs: 'bdvDetalhes'
          }
        },
        data: {
          titulo: 'ce.boletim.diarioVeiculoDetalhes.titulo',
          tituloDescricao: 'ce.boletim.diarioVeiculoDetalhes.tituloDescricao',
          backButton: true,
          backState: 'root.bdv',
          role: 'BOLETIM_VEICULO_VISUALIZAR'
        }
      })
      .state('root.bdvChecklist', {
        url: '/relatorios/bdv/checklist/:veiculoId/:dia',
        views: {
          'content@': {
            templateUrl: 'relatorios/bdv/bdv-checklist/bdv-checklist.tpl.html',
            controller: 'BdvChecklistCtrl',
            controllerAs: 'bdvChecklist'
          }
        },
        data: {
          titulo: 'ce.boletim.diarioVeiculoChecklist.titulo',
          tituloDescricao: 'ce.boletim.diarioVeiculoChecklist.tituloDescricao',
          backButton: true,
          backState: 'root.bdv',
          role: 'CONFIG_VDRIVERS_VISUALIZAR'
        }
      });
  }

  angular
    .module('relatorios.bdv')
    .config(pageConfig);
}());
