(function () {
  'use strict';

  class SelectUoCtrl {
    constructor(Uos, UoArvoreNivel, Authenticator) {
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.authenticator = Authenticator;
      this.uos = [];
      this.authenticator.getUser().then(user => {
        this.carregarUos(user.uo.id);
      });
    }

    carregarUos(uoId) {
      this.uoService.getFilhas(uoId).then(data => {
        this.uos = this.uoArvoreNivel.converterEmLista(data.plain());
      });
    }
  }

  angular
    .module('conducaoEconomica')
    .component('selectUo', {
      controller: SelectUoCtrl,
      templateUrl: 'directives/select-uo/select-uo.tpl.html',
      bindings: {
        model: '=',
        selectRequired: '=',
        selectName: '@',
        selectDisabled: '<'
      }
    });
}());
