(function () {
  'use strict';

  angular
    .module('mapas')
    .constant('MapLayerVisibility', {
      PONTO_REFERENCIA: 'ponto-referencia',
      CERCA_ELETRONICA: 'cerca-eletronica'
    });
}());
