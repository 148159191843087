(function () {
  'use strict';

  function tabelaPontosProximosDirective() {
    return {
      templateUrl: 'directives/map-control/tabela-pontos-proximos/tabela-pontos-proximos.tpl.html',
      controller: 'TabelaPontosProximosCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class TabelaPontosProximosCtrl {
    constructor($scope, moment, PontosReferenciaMap, PontosReferenciaMenu, PontosProximos) {
      this.moment = moment;
      this.historicoTimelineCtrl = $scope.$parent.$parent.$parent.$parent.historicoTimeline;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosProximosService = PontosProximos;

      this.idLayer = 0;
      this.layerEvento = null;
      this.showBtn = false;

      $scope.$watch(() => this.pontosProximosService.listaPontosProximos.length, (qnt) => {
        if (qnt === 0) {
          this.showBtn = false;
          this.pontosReferenciaMenuService.tabelaPontosProximosClose();
        } else {
          this.showBtn = true;
        }
      });
    }

    formatDate(date) {
      return date ? this.moment(date).format('DD/MM/YYYY HH:mm:ss') : '-';
    }

    removeItem(idLayer) {
      this.pontosProximosService.removePontoProximo(idLayer);
      this.pontosReferenciaMapService.removeLayerPontoProximo(this.pontosReferenciaMapService.pontosProximosLayersBackup.getLayer(idLayer));
      this.pontosReferenciaMapService.removeDemoPontoProximo();
    }

    focusItem(item) {
      this.idLayer = item.idLayer;
      this.closePopup();

      if (item.evento) {
        const layerHistorico = this.historicoTimelineCtrl.historico.find(i => i.data === item.evento.data && i.descricao === item.evento.evento);

        this.historicoTimelineCtrl.markersGroup.eachLayer(layer => {
          if (layerHistorico && layerHistorico.index === layer.options.index) {
            layer.openPopup();
            this.layerEvento = layer;
          }
        });

        this.pontosReferenciaMapService.focusPontoProximo(item, !!this.layerEvento);
      } else {
        this.pontosReferenciaMapService.removeDemoPontoProximo();
        this.pontosReferenciaMapService.focusMarker(item);
      }
    }

    closePopup() {
      if (this.layerEvento) {
        this.layerEvento.closePopup();
        this.layerEvento = null;
      }
    }

    toggleTable() {
      if (this.pontosReferenciaMenuService.tabelaPontosProximos) {
        this.pontosReferenciaMenuService.tabelaPontosProximosClose();
        this.pontosReferenciaMapService.removeDemoPontoProximo();
        this.closePopup();
      } else {
        this.pontosReferenciaMenuService.tabelaPontosProximosOpen();
      }
    }

    removeAllPontosProximos() {
      this.pontosReferenciaMapService.removeAllPontosProximos();
      this.pontosProximosService.listaPontosProximos = [];
    }
  }

  angular
    .module('mapControlModule')
    .controller('TabelaPontosProximosCtrl', TabelaPontosProximosCtrl)
    .directive('tabelaPontosProximosDirective', tabelaPontosProximosDirective);
}());
