(function () {
  'use strict';

  const fields = [
        'veiculo.placa',
        'veiculo.prefixo',
        'login',
        'comando.nome',
        'dataHoraCriacao',
        'dataHoraConfirmacao',
        'status'
      ], HIST_COMANDO_URL = '/comando/historicos';

  angular
    .module('relatorios.historicoComando', [
      'ui.router',
      'restangular',
      'angularMoment',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'ConstantVfiltroModule',
      'VtabelaModule',
      'pascalprecht.translate',
      'urlConfigModule'
    ])
    .factory('HistoricoComando', Restangular => Restangular.one(HIST_COMANDO_URL + `?fields=${fields.join()}`))
    .constant('StatusComandoEnum', {
      AGUARDANDO_ENVIO: 'ce.enum.statusComando.aguardandoEnvio',
      AGUARDANDO_CONFIRMACAO: 'ce.enum.statusComando.aguardandoConfirmacao',
      CONFIRMADO: 'ce.enum.statusComando.confirmado',
      CANCELADO: 'ce.enum.statusComando.cancelado',
      DOWNLOAD_SOLICITADO: 'ce.enum.statusComando.downloadSolicitado',
      NAO_MAPEADO: 'ce.enum.statusComando.naoMapeado'
    });
}());
