(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider
      .state('root.bdm', {
        url: '/relatorios/bdm?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'relatorios/bdm/bdm.tpl.html',
            controller: 'BdmCtrl',
            controllerAs: 'bdm'
          }
        },
        data: {
          titulo: 'ce.boletim.diarioMotorista.titulo',
          tituloDescricao: 'ce.boletim.diarioMotorista.tituloDescricao',
          roles: [['BOLETIM_MOTORISTA_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
        }
      })
      .state('root.bdmDetalhes', {
        url: '/relatorios/bdm/detalhes/:motoristaId/:dia',
        views: {
          'content@': {
            templateUrl: 'relatorios/bdm/bdm-detalhes.tpl.html',
            controller: 'BdmDetalhesCtrl',
            controllerAs: 'bdmDetalhes'
          }
        },
        data: {
          titulo: 'ce.boletim.diarioMotoristaDetalhes.titulo',
          tituloDescricao: 'ce.boletim.diarioMotoristaDetalhes.tituloDescricao',
          backButton: true,
          backState: 'root.bdm',
          roles: [['BOLETIM_MOTORISTA_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
        }
      });
  }

  angular
    .module('relatorios.bdm')
    .config(pageConfig);
}());
