(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.historicoComando', {
      url: '/relatorios/historico-comando?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/historico-comando/historico-comando.tpl.html',
          controller: 'HistoricoComandoCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'ce.mapa.localizacaoDaFrota.historicoComando.titulo',
        role: 'COMANDO_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.historicoComando')
    .config(pageConfig);
}());
