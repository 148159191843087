(function () {
  'use strict';

  angular
    .module('relatorios.bds', [
      'ui.router',
      'restangular',
      'pascalprecht.translate',
      'ModuleMapasUtil',
      'leaflet-directive',
      'ModuleEventos',
      'vflashModule',
      'mapas',
      'angularMoment',
      'onboarding',
      'confluenceID',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'urlConfigModule',
      'smart-table',
      'relatorios',
      'cadastros.veiculo'
    ])
    .factory('Bds', Restangular => {
      const url = 'boletim/bs';

      let service = Restangular.service(url);

      service.getDetalhes = (execucaoServico) => {
        return Restangular.one(`${url}/${execucaoServico}`).get();
      };
      return service;
    });
}());
