(function () {
  'use strict';

  angular
    .module('relatorios.abastecimento', [
      'ui.router',
      'restangular',
      'angularMoment',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'ConstantVfiltroModule',
      'VtabelaModule'
    ])
    .factory('Abastecimento', Restangular => Restangular.service('/abastecimento/historico-abastecimento'));
}());
