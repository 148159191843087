(function () {
  'use strict';
  /* global moment */
  class ValidateUtil {
    temLogradouro(logradouro) {
      return !!logradouro &&
                logradouro.trim() !== '-' &&
                logradouro.trim().length > 0;
    }

    isNotDataFutura(inputScope) {
      return () => {
        let dataInput = moment(inputScope.$viewValue, 'DD/MM/YYYY', true);
        if (dataInput.isValid()) {
          let dataValidation = dataInput.isBefore(moment().startOf('day'));
          return dataValidation;
        }
        return true;
      };
    }
  }

  angular
  .module('validateUtilModule', [])
  .service('ValidateUtil', ValidateUtil);
}());
