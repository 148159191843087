(function () {
  'use strict';

  function InputNumber() {
    return {
      templateUrl: `configuracoes/components/number/input-number.tpl.html`,
      controllerAs: 'ctrl',
      restrict: 'E',
      require: '^form',
      replace: true,
      scope: {
        type: '@',
        numberMask: '=?',
        model: '=',
        disabled: '=',
        name: '@',
        enable: '=',
        mask: '=',
        min: '=?',
        max: '=?',
        maxlength: '=?'
      },
      link: function (scope, element, attrs, formCtrl) {
        scope.form = formCtrl;
        if (angular.isUndefined(scope.min)) {
          scope.min = 1;
        }
        if (angular.isUndefined(scope.max)) {
          scope.max = 999;
        }
        if (angular.isUndefined(scope.maxlength)) {
          scope.maxlength = 7;
        }
      }
    };
  }

  angular
    .module('InputNumberModule', [])
    .directive('inputNumber', InputNumber);
}());
