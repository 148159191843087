(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.planos', {
      url: '/planos-solucoes',
      views: {
        'content@': {
          templateUrl: 'planos-solucoes/planos-solucoes.tpl.html',
          controller: 'PlanosSolucoesCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        hideTitulo: true
      }
    });
  }

  angular
    .module('planosSolucoesModule')
    .config(pageConfig);
}());
