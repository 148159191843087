(function () {
  'use strict';

  class SemSinalModalCtrl {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }
  }

  angular
    .module('onePageModule')
    .controller('SemSinalModalCtrl', SemSinalModalCtrl);
}());
