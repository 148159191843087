(function () {
  'use strict';

  /* @ngdoc object
   * @name cadastros.devices
   * @description
   *
   */
  angular
    .module('cadastros.devices', [
      'ui.router',
      'restangular',
      'angularMoment',
      'alert-message',
      'httpServiceV3gModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'pascalprecht.translate',
      'modalInfoConfirmModule',
      'FeatureFlagModule',
      'relatorios'
    ]);
}());
