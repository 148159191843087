(function () {
  'use strict';

  /* @ngdoc object
   * @name insights
   * @description
   *
   */
  angular
    .module('insightsModule', ['segurancaModule']);
}());
