(function () {
  'use strict';

  const CERCAS_ELETRONICAS_URL = 'ponto-referencia/cercas-eletronicas',
      fields = [
        'id',
        'nome',
        'originalId',
        'uoId',
        'categoriaId',
        'observacao',
        'geometria',
        'localizacao',
        'logradouro',
        'tempoPermanenciaMinimo',
        'categoria.id',
        'categoria.nome',
        'categoria.cor',
        'categoria.uoId',
        'categoria.garagem',
        'categoria.areaRisco',
        'categoria.ativaSensorPonto',
        'tipoPermissao'
      ];

  class CercaEletronicaService {
    constructor(HttpServiceV3g) {
      this.http = HttpServiceV3g;
      this.roleCadastrar = false;
      this.roleVisualizar = false;
    }

    getListCercaEletronica() {
      return this.http.get(`/${CERCAS_ELETRONICAS_URL}?fields=${fields.join()}`);
    }

    getCercaEletronica(idCercaEletronica) {
      return this.http.get(`/${CERCAS_ELETRONICAS_URL}?ids=${idCercaEletronica}&fields=${fields.join()}`)
        .then(cercas => cercas.data.length ? cercas.data[0] : null);
    }

    createCercaEletronica(cercaEletronica) {
      return this.http.post(`/${CERCAS_ELETRONICAS_URL}`, cercaEletronica);
    }

    updateCercaEletronica(cercaEletronica) {
      return this.http.patch(`/${CERCAS_ELETRONICAS_URL}/${cercaEletronica.id}`, cercaEletronica);
    }

    deleteCercaEletronica(id) {
      return this.http.delete(`/${CERCAS_ELETRONICAS_URL}/${id}`);
    }
  }

  angular
    .module('mapControlModule')
    .service('CercaEletronicaService', CercaEletronicaService);
}());
