(function () {
  'use strict';

  angular
    .module('indicadores', [
      'indicadores.ConducaoEconomica',
      'indicadores.DirecaoSegura',
      'indicadores.UtilizacaoVeiculo',
      'indicadores.powerBi',
      'ui.router',
      'core-ce.constants',
      'onboarding',
      'angular-intro',
      'confluenceID',
      'angularMoment',
      'UrlModule',
      'ObjetosVfiltroModule'
    ]);
}());
