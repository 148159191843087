(function () {
  'use strict';

  function onMousewheelDirective() {
    return {
      restrict: 'A',
      scope: {
        onMousewheel: '&'
      },
      link: function (scope, element) {
        element.bind('mousewheel', (e) => {
          scope.$applyAsync(scope.onMousewheel(e));
        });
      }
    };
  }

  angular
    .module('mousewheelDirectiveModule')
    .directive('onMousewheel', onMousewheelDirective);
}());
