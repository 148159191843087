(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.identificacoes', {
      url: '/relatorios/identificacoes?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/identificacoes/identificacoes.tpl.html',
          controller: 'IdentificacoesCtrl',
          controllerAs: 'identificacoes'
        }
      },
      data: {
        titulo: 'ce.analise.identificacoes.titulo',
        roles: ['IDENTIFICACOES_VISUALIZAR']
      }
    });
  }

  angular
    .module('relatorios.identificacoes')
    .config(pageConfig);
}());
