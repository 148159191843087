/* global geolib*/
(function () {
  'use strict';

  class PontosReferenciaTools {
    retornaPontosComDistancia(latlngs, metros) {
      let p2 = geolib.computeDestinationPoint({lat: latlngs[0].lat, lon: latlngs[0].lng}, metros, 0),
          p3 = geolib.computeDestinationPoint({lat: p2.latitude, lon: p2.longitude}, metros, 90),
          novaLatLng = [
              {
                lat: latlngs[0].lat,
                lng: latlngs[0].lng
              },
              {
                lat: p3.latitude,
                lng: latlngs[0].lng
              },
              {
                lat: p2.latitude,
                lng: p3.longitude
              },
              {
                lat: latlngs[0].lat,
                lng: p3.longitude
              }
          ];

      return novaLatLng;
    }

    geraDiametro(latlngs) {
      return Math.ceil(geolib.getDistance({latitude: latlngs[0].lat, longitude: latlngs[0].lng}, {latitude: latlngs[1].lat, longitude: latlngs[1].lng}, 1, 3));
    }

    geraLatLngCentro(latlngs) {
      return geolib.getCenter(latlngs.map(item => angular.extend({latitude: item.lat, longitude: item.lng})));
    }

    geraLatLngsExternos(latCenter, lngCenter, raio) {
      return {
        latlngLeftBottom: geolib.computeDestinationPoint({lat: latCenter, lon: lngCenter}, raio * 1.414213562, 225),
        latlngRightTop: geolib.computeDestinationPoint({lat: latCenter, lon: lngCenter}, raio * 1.414213562, 45)
      };
    }

    geraLatlngs(latlngs, latCenter, lngCenter, raio) {
      let pontos = this.geraLatLngsExternos(latCenter, lngCenter, raio);

      latlngs[0].lat = pontos.latlngLeftBottom.latitude;
      latlngs[0].lng = pontos.latlngLeftBottom.longitude;

      latlngs[1].lat = pontos.latlngRightTop.latitude;
      latlngs[1].lng = pontos.latlngLeftBottom.longitude;

      latlngs[2].lat = pontos.latlngRightTop.latitude;
      latlngs[2].lng = pontos.latlngRightTop.longitude;

      latlngs[3].lat = pontos.latlngLeftBottom.latitude;
      latlngs[3].lng = pontos.latlngRightTop.longitude;

      return latlngs;
    }

    geraLatLngsRotograma(angulo, raio, latitude, longitude) {
      let i, sentido;
      if (angulo >= 0 && angulo <= 45) {
        sentido = 360;
        i = Math.cos(angulo * Math.PI / 180);
      } else if (angulo > 45 && angulo <= 90) {
        sentido = 90;
        i = Math.sin(angulo * Math.PI / 180);
      } else if (angulo > 90 && angulo <= 135) {
        sentido = 90;
        i = Math.cos((angulo - 90) * Math.PI / 180);
      } else if (angulo > 135 && angulo <= 180) {
        sentido = 180;
        i = Math.sin((angulo - 90) * Math.PI / 180);
      } else if (angulo > 180 && angulo <= 225) {
        sentido = 180;
        i = Math.cos((angulo - 180) * Math.PI / 180);
      } else if (angulo > 225 && angulo <= 270) {
        sentido = 270;
        i = Math.sin((angulo - 180) * Math.PI / 180);
      } else if (angulo > 270 && angulo <= 315) {
        sentido = 270;
        i = Math.cos((angulo - 270) * Math.PI / 180);
      } else {
        sentido = 360;
        i = Math.sin((angulo - 270) * Math.PI / 180);
      }

      const hip = raio / i,
          latlng = geolib.computeDestinationPoint({lat: latitude, lon: longitude}, hip, angulo);
      return {latlng, sentido};
    }

    geraLatLngsLabel(raio, latitude, longitude) {
      const ajusteAlturaLabel = 1.75, hip = raio / ajusteAlturaLabel,
          latlng = geolib.computeDestinationPoint({lat: latitude, lon: longitude}, hip, 360);
      return latlng;
    }

    geraLatLngsPontoLargura(raio, latitude, longitude) {
      const latlngDireta = geolib.computeDestinationPoint({lat: latitude, lon: longitude}, raio, 90),
          latlngEsquerda = geolib.computeDestinationPoint({lat: latitude, lon: longitude}, raio, 270);
      return {latlngDireta, latlngEsquerda};
    }

    geraWidth(map, pontoA, pontoB) {
      return map.latLngToLayerPoint({lat: pontoA.latitude, lng: pontoA.longitude})
        .distanceTo(map.latLngToLayerPoint({lat: pontoB.latitude, lng: pontoB.longitude}));
    }

    geraLatlngsResize(latlngs, latlngsOriginal) {
      let latlngsOriginalCentro = geolib.getCenter(latlngsOriginal),
          latlngsCentro = geolib.getCenter(latlngs),
          countAngulo = 45,
          dif = geolib.getDistance(latlngs[0], latlngsCentro, 1, 3) / 1.414213562;

      dif = Math.max(10, Math.min(2000, dif)) * 1.414213562;

      for (let i = 0; i < 4; i++) {
        let ponto = geolib.computeDestinationPoint({lat: latlngsOriginalCentro.latitude, lon: latlngsOriginalCentro.longitude}, dif, countAngulo);
        countAngulo += 90;
        latlngs[i].lat = ponto.latitude;
        latlngs[i].lng = ponto.longitude;
      }

      return latlngs;
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosReferenciaTools', PontosReferenciaTools);
}());
