(function () {
  'use strict';

  class v3gTableLimitService {
    constructor($window) {
      this.window = $window;
      this.stItemsByPage = 10;
      this.user = null;
    }

    init(user) {
      this.user = user;
      const local = angular.fromJson(this.window.localStorage.getItem(`table-limit-${this.user.id}`));

      if (local) {
        this.stItemsByPage = local;
      } else {
        this.salvarLimite(10);
      }

      return {
        number: this.stItemsByPage
      };
    }

    salvarLimite(e) {
      this.stItemsByPage = e;
      this.window.localStorage.setItem(`table-limit-${this.user.id}`, this.stItemsByPage);
    }
  }

  angular
    .module('smart-table')
    .service('v3gTableLimitService', v3gTableLimitService);
}());
