(function () {
  'use strict';

  angular
    .module('mapas')
    .factory('$mapas', ($uibModal) => ({
      open: (posicao) => $uibModal.open({
        templateUrl: 'mapas/mapa.tpl.html',
        controller: 'MapasCtrl',
        controllerAs: 'mapas',
        size: 'lg',
        resolve: {
          posicao: () => posicao,
          latitude: () => posicao.latitude,
          longitude: () => posicao.longitude
        }
      })
    }));
}());
