(function () {
  'use strict';

  angular
    .module('relatorios.rankingSafety', [
      'ui.router',
      'uoModule',
      'motoristaModule',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate',
      'VtabelaModule',
      'smart-table',
      'onboarding',
      'urlConfigModule'
    ])
    .factory('RankingSafety', Restangular => Restangular.service('boletim/ranking/safety'));
}());
