/* global _ */
(function () {
  'use strict';
  class OcorrenciaEventosCtrl {
    constructor(AlertasEnum, Ocorrencias) {
      this.alertasEnum = AlertasEnum;
      this.ocorrenciasService = Ocorrencias;
      this.showLabelVideo = false;
    }

    $onInit() {
      this.ocorrencia = _.cloneDeep(this.item);
      this.linkHistoricoDetalhado = this.getLinkHistoricoDetalhado(this.ocorrencia);
      this.geraAlertasAgrupados();
    }

    getLinkHistoricoDetalhado() {
      const paramVfiltro = this.ocorrenciasService.getParamsHistDetalhado(this.ocorrencia);
      return `historico-timeline?${paramVfiltro}`;
    }

    geraAlertasAgrupados() {
      this.alertasAgrupados = _(this.ocorrencia.alertasGerados)
        .groupBy('tipo')
        .map((alertas, tipo) => {
          return {
            tipo,
            quantidade: alertas.reduce((acc, alerta) => acc + alerta.quantidade, 0),
            videoIds: alertas.reduce((acc, alerta) => alerta.videoIds ?
              [...acc, ...alerta.videoIds] :
              acc,
            [])
          };
        })
        .value();
    }
  }

  angular
    .module('ocorrencia.component')
    .component('ocorrenciaEventos', {
      controller: OcorrenciaEventosCtrl,
      templateUrl: 'ocorrencia/ocorrencia-eventos/ocorrencia-eventos.tpl.html',
      bindings: {
        item: '='
      }
    });
}());
