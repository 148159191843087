(function () {
  'use strict';

  class ReportWrongSpeedViaService {
    constructor($httpParamSerializer, $translate, URL_GOOGLE_FORMS, FORM_FIELD_NOME, FORM_FIELD_EMAIL, FORM_FIELD_COORDENADAS, UO_IDS_PERMITIDAS) {
      this.httpParamSerializer = $httpParamSerializer;
      this.translate = $translate;

      this.URL_GOOGLE_FORMS = URL_GOOGLE_FORMS;
      this.FORM_FIELD_NOME = FORM_FIELD_NOME;
      this.FORM_FIELD_EMAIL = FORM_FIELD_EMAIL;
      this.FORM_FIELD_COORDENADAS = FORM_FIELD_COORDENADAS;
      this.UO_IDS_PERMITIDAS = UO_IDS_PERMITIDAS;
    }

    getLinkToFormHtml(usuario, latitude, longitude) {
      if (usuario && this.UO_IDS_PERMITIDAS.includes(usuario.uo.id)) {
        const params = this.httpParamSerializer({
          [this.FORM_FIELD_NOME]: usuario.nome,
          [this.FORM_FIELD_EMAIL]: usuario.login,
          [this.FORM_FIELD_COORDENADAS]: `${latitude}, ${longitude}`
        });

        return `<a href="${this.URL_GOOGLE_FORMS}?${params}"` +
        ` target="_blank"><i class="trimble-wrong-speed"></i> ${this.translate.instant('ce.mapa.common.modal.popupService.reportarVelocidadeIncorreta')}</a><br/>`;
      }
      return '';
    }
  }

  angular
    .module('reportwrongspeedviaModule')
    .service('ReportWrongSpeedViaService', ReportWrongSpeedViaService);
}());
