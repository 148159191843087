(function () {
  'use strict';

  /* global _ */
  function alertaTurnoOnePageGraficoDirective() {
    return {
      templateUrl: 'one-page/directives/alerta-turno/grafico/alerta-turno-one-page-grafico.tpl.html',
      controller: 'AlertaTurnoOnePageGraficoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class AlertaTurnoOnePageGraficoCtrl {
    constructor($scope, $window, $q, $httpParamSerializer, $timeout, DrilldownChart, moment, Authenticator,
      OnePageService, Uos, UoArvoreNivel, Keys, UrlService, ServiceVfiltro, Restangular, FactoryVfiltro,
      AlertaVeiculoService, $translate, GoogleTagManagerUtils) {
      this.scope = $scope;
      this.restangular = Restangular;
      this.window = $window;
      this.q = $q;
      this.httpParamSerializer = $httpParamSerializer;
      this.timeout = $timeout;
      this.moment = moment;
      this.authenticator = Authenticator;
      this.alertaVeiculoService = AlertaVeiculoService;
      this.onePageService = OnePageService;
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.keys = Keys;
      this.urlService = UrlService;
      this.serviceVfiltro = ServiceVfiltro;
      this.factoryVfiltro = FactoryVfiltro;
      this.onePageCtrl = this.scope.$parent.$parent.onePage;
      this.translate = $translate;
      this.googleTagManagerUtils = GoogleTagManagerUtils;

      this.alertaTurno = DrilldownChart.get();
      this.alertaTurno.setClickEvent(this.clickBinding().onClickColumn);

      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateAlertaTurno();
        this.onePageService.removeTimer();
        this.alertaVeiculoService.stop();
      });

      this.loadFiltro();
    }

    loadFiltro() {
      this.authenticator.getUser().then(user => {
        this.alertaVeiculoService.setUoId(user.uo.id);
        this.alertaVeiculoService.checkAlertaPorTipo();
        if (this.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.visualizarHistoricoAlerta = true;
        }
        this.loadUos()
          .then(x => {
            this.uos = x;
            this.serviceVfiltro.init(this.factoryVfiltro.get([
              {key: this.keys.uo.name}
            ]))
            .then((filtro) => {
              this.uoId = filtro.find(k => k.key === this.keys.uo.name).value.id;
              this.init(user);
            });
          });
      });
    }

    verificarAlertasInativos() {
      this.restangular.one(`configuracao/configuracoes/${this.uoId}/alerta`).get()
      .then(alertas => {
        this.erroAlertasInativos = _.values(alertas.configuracoes).filter(item => item.ativo).length === 0;
        if (this.erroAlertasInativos) {
          this.mensagemDeErro = 'ce.tempoReal.statusFrota.charts.alertaSeguranca.nenhumAlertaAtivo';
          this.linkConfigAlertas = this.receberUrlConfigAlertas();
        } else if (!this.erroAlertasInativos) {
          this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.mensagemSucesso');
        }
      }, () => {
        this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
      });
    }

    loadUos() {
      return this.q(resolve => {
        this.uoService.getFilhas().then(
          uos => {
            uos = this.uoArvoreNivel.converterEmLista(uos);
            resolve(uos.map(u => ({id: u.id, label: u.nome, arvoreNivel: u.arvoreNivel})));
          },
          () => {

          });
      });
    }

    init(user) {
      this.user = user;
      this.onePageService.checkAlertaTurno({uoId: this.scope.onePage.getParamValue(this.keys.uo.name)})
      .then(() => {
        if (this.onePageService.listaCacheadaAlertaTurno) {
          let chart = angular.element('#highcharts-alertaturno').highcharts();
          if (chart) {
            if (this.alertaTurno.isDrillDown) {
              chart.drillUp();
            }
            this.alertaTurno.setData(this.buildCardAlertaTurno(this.onePageService.listaCacheadaAlertaTurno));
            if (this.alertaTurno.erroSemData) {
              this.verificarAlertasInativos();
            }
            chart.reflow();
          }
        }
        this.onePageService.onUpdateAlertaTurno(data => {
          let chart = angular.element('#highcharts-alertaturno').highcharts();
          if (chart) {
            if (this.alertaTurno.isDrillDown) {
              chart.drillUp();
            }
            this.alertaTurno.setData(this.buildCardAlertaTurno(data));
            if (this.alertaTurno.erroSemData) {
              this.verificarAlertasInativos();
            }
            chart.reflow();
          }
        }, 'onepage');

        this.onePageService.onUpdateAlertaTurnoError(error => {
          if (error) {
            this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
          }
        }, 'onepageError');
      });
    }

    buildCardAlertaTurno(data) {
      let tipoAlertas = angular.copy(this.alertaVeiculoService.alertaMapPtBr),
          retorno = {series: [], turnoAtual: 1, drilldown: []},
          turnos = [];

      turnos = data.turnos.map((x, y) => {
        const atual = this.moment(),
            inicio = this.moment(x.inicio),
            fim = this.moment(x.fim),
            ativo = atual.isBetween(inicio, fim);

        if (ativo) {
          retorno.turnoAtual = y + 1;
        }

        return inicio.format('HH:mm') + ' - ' + fim.format('HH:mm');
      });

      Object.keys(tipoAlertas).forEach(alerta => {
        tipoAlertas[alerta].uoContagensTurno = [];

        const itemData = data.paineis.filter(painel => painel.label === tipoAlertas[alerta].key)[0];
        if (itemData) {
          let eventosResolvidos = [],
              eventosOcorridos = [];
          const color = tipoAlertas[alerta].chartColor;

          for (let i = 0; i < turnos.length; i++) {
            eventosResolvidos.push({
              name: turnos[i],
              y: this.countTurno(itemData, i, 'resolvidos'),
              color,
              drilldown: tipoAlertas[alerta].key + '_RESOLVIDO_' + i
            });
            eventosOcorridos.push({
              name: turnos[i],
              y: this.countTurno(itemData, i, 'ocorridos'),
              color,
              drilldown: tipoAlertas[alerta].key + '_OCORRIDO_' + i
            });

            const evento = tipoAlertas[alerta].key,
                turno = data.turnos[i];

            retorno.drilldown.push({
              id: tipoAlertas[alerta].key + '_RESOLVIDO_' + i,
              name: tipoAlertas[alerta].texto,
              data: this.itemDrillDown(itemData, i, 'resolvidos', evento, turno)
            });
            retorno.drilldown.push({
              id: tipoAlertas[alerta].key + '_OCORRIDO_' + i,
              name: tipoAlertas[alerta].texto,
              data: this.itemDrillDown(itemData, i, 'ocorridos', evento, turno)
            });
          }

          retorno.series.push({name: tipoAlertas[alerta].texto, stack: 'male', data: eventosResolvidos, color, id: tipoAlertas[alerta].key});
          retorno.series.push({name: tipoAlertas[alerta].texto, stack: 'female', data: eventosOcorridos, color, id: tipoAlertas[alerta].key + '_2', linkedTo: tipoAlertas[alerta].key});
        }
      });
      return retorno;
    }

    countTurno(itemData, i, tipo) {
      return itemData.uoContagensTurno.map((e) => e.contagensTurno[i][tipo]).reduce((a, b) => a + b, 0);
    }

    itemDrillDown(itemData, i, tipo, evento, turno) {
      return itemData.uoContagensTurno.map(e => {
        return {
          name: e.uo.nome,
          uo: e.uo.id,
          evento,
          turno,
          y: e.contagensTurno[i][tipo]
        };
      });
    }

    clickBinding() {
      let self = this;

      return {
        onClickColumn: function () {
          self.googleTagManagerUtils.sendEventClickGTM('status_frota_drill_down', self.serviceVfiltro.factoryVfiltro.user);
          if (this.category) {
            self.redirectTo(self.buildObjectTurnoFiltro(this.options.uo, this.options.turno.inicio, this.options.turno.fim, this.options.evento));
          }
        }
      };
    }

    redirectTo(url) {
      this.window.location.href = url;
    }

    buildObjectTurnoFiltro(uo, inicio, fim, tipo) {
      let dataHojeFim = this.moment().endOf('day'),
          fimMoment = this.moment(fim),
          endDate = fimMoment.isBefore(dataHojeFim) ? fimMoment : dataHojeFim,
          filtro = [
              {
                key: this.keys.dateTime.name,
                value: {
                  startDate: this.moment(inicio).format(),
                  endDate: endDate.format(),
                  tipo: 'personalizado'
                }
              },
              {
                key: this.keys.tipos.name,
                value: {
                  id: tipo === 'EXCESSO_VELOCIDADE_20' || tipo === 'EXCESSO_VELOCIDADE_30' || tipo === 'EXCESSO_VELOCIDADE_ACIMA_30' ? 'EXCESSO_VELOCIDADE' : tipo
                }
              }
          ];

      if (this.uos.filter(x => x.id === uo).length > 0) {
        filtro.push({key: this.keys.uo.name, value: {id: uo}});
      } else {
        filtro.push(
          {key: this.keys.uo.name, value: {id: this.onePageCtrl.getParamValue(this.keys.uo.name)}},
          {key: this.keys.uoParceira.name, value: {id: uo}}
        );
      }

      if (tipo === 'EXCESSO_VELOCIDADE_20') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'BAIXO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'MEDIO'}});
      } else if (tipo === 'EXCESSO_VELOCIDADE_ACIMA_30') {
        filtro.push({key: this.keys.nivel.name, value: {id: 'ALTO'}});
      }

      return '/historico-alerta?vfiltro=' + this.urlService.getObjectVfiltro(filtro);
    }

    receberUrlConfigAlertas() {
      if (this.erroAlertasInativos) {
        return `/configuracoes-eventos?vfiltro=${this.urlService.getObjectVfiltro(this.serviceVfiltro.filtroTopbar)}`;
      }
    }
  }

  angular
    .module('onePageModule')
    .controller('AlertaTurnoOnePageGraficoCtrl', AlertaTurnoOnePageGraficoCtrl)
    .directive('alertaTurnoOnePageGraficoDirective', alertaTurnoOnePageGraficoDirective);
}());
