/* global document, _, geolib, L*/
(function () {
  'use strict';

  const ZOOM_MINIMO_RENDER_MARKER = 13;

  class PontosReferenciaMap {
    constructor($timeout, $rootScope, $uibModal, AlertMessage, PopupService,
      PontosReferencia, PontosReferenciaTools, PontosReferenciaMenu, PontosReferenciaFiltro, PontosProximos,
      RotogramaMapService, PosicoesRotogramas, CadastroRotogramaService, CategoriaPontosReferencia,
      ControleVelocidadeRotograma, $compile, LayerTypeEnum, MapControlService, $translate, Authenticator, FeatureFlag,
      ReportWrongSpeedViaService, $sanitize) {
      this.timeout = $timeout;
      this.rootScope = $rootScope;
      this.modalService = $uibModal;
      this.$sanitize = $sanitize;
      this.alertMessage = AlertMessage;
      this.popupService = PopupService;
      this.pontosReferenciaService = PontosReferencia;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.pontosProximosService = PontosProximos;
      this.rotogramaMapService = RotogramaMapService;
      this.posicoesRotogramas = PosicoesRotogramas;
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.pontosCategoriaReferenciaService = CategoriaPontosReferencia;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.compile = $compile;
      this.layerTypeEnum = LayerTypeEnum;
      this.mapControlService = MapControlService;
      this.translate = $translate;
      this.lastLayerSuggestionClicked = null;
      this.viaRapida = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.viaRapida');
      this.avenida = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.avenida');
      this.rua = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.rua');
      this.pistaDuplicada = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.pistaDuplicada');
      this.pistaSimples = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.pistaSimples');
      this.vicinal = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.vicinal');
      this.ruralOutras = this.translate.instant('ce.mapa.common.modal.popupService.tipoVia.ruralOutras');
      this.featureFlag = FeatureFlag;
      this.authenticator = Authenticator;
      this.reportWrongSpeedViaService = ReportWrongSpeedViaService;
      this.authenticator.getUser().then(user => {
        this.user = user;
      });

      this.retornaTipoVia = {
        MOTORWAY: (urbano) => {
          return urbano ? {veiculoLeve: 100, veiculoPesado: 90, tipoVia: this.viaRapida} : {veiculoLeve: 120, veiculoPesado: 100, tipoVia: this.pistaDuplicada};
        },
        TRUNK: (urbano) => {
          return urbano ? {veiculoLeve: 100, veiculoPesado: 90, tipoVia: this.viaRapida} : {veiculoLeve: 120, veiculoPesado: 100, tipoVia: this.pistaDuplicada};
        },
        PRIMARY: (urbano) => {
          return urbano ? {veiculoLeve: 80, veiculoPesado: 70, tipoVia: this.avenida} : {veiculoLeve: 110, veiculoPesado: 90, tipoVia: this.pistaSimples};
        },
        SECONDARY: (urbano) => {
          return urbano ? {veiculoLeve: 80, veiculoPesado: 70, tipoVia: this.rua} : {veiculoLeve: 80, veiculoPesado: 80, tipoVia: this.vicinal};
        },
        default: (urbano) => {
          return urbano ? {veiculoLeve: 60, veiculoPesado: 50, tipoVia: this.rua} : {veiculoLeve: 80, veiculoPesado: 60, tipoVia: this.ruralOutras};
        }
      };

      this.logradouroLayers = new L.FeatureGroup();

      this.setVariaveis();

      this.rootScope.$on('rotogramasCadastrados', (evt, data) => {
        const ponto = _.find(
          this.pontosReferencia,
          p => p.entidade.id === data.pontoReferenciaId
        );
        if (ponto.rendered) {
          this.unRender(ponto);
        }
        ponto.layers = null;
        ponto.entidade.rotogramas = [];
        data.rotogramas.forEach(rotogramaCadastrado => {
          const grupoVelocidade = rotogramaCadastrado.rotograma.grupoVelocidadePadrao,
              rotograma = Object.assign({}, rotogramaCadastrado.rotograma, {grupoVelocidadePadrao: grupoVelocidade});
          ponto.entidade.rotogramas.push(rotograma);
        });
        this.render(ponto);
        this.map.invalidateSize();
        this.map.panTo([ponto.entidade.latitude, ponto.entidade.longitude]);
      });

      this.rootScope.$on('rotogramasDeletados', (evt, data) => {
        const ponto = _.find(
          this.pontosReferencia,
          p => p.entidade.id === data.pontoReferenciaId
        );
        if (ponto.rendered) {
          this.unRender(ponto);
        }
        ponto.layers = null;
        ponto.entidade.rotogramas = [];
        this.render(ponto);
        this.map.invalidateSize();
      });

      this.rootScope.$on('limpaSelecaoPontoReferencia', () => this.cancelarPonto());
    }

    setVariaveis() {
      this.map = null;
      this.countPontosProximos = 0;
      this.countPontosProximosBackup = 1;
      this.latlngsOriginal = [];
      this.alertaZoom = false;
      this.retangulo = null;
      this.markerPontoProximo = null;
      this.pontosProximosLayers = new L.FeatureGroup();
      this.pontosProximosLayersBackup = new L.FeatureGroup();
      this.pontosProximosPolyline = new L.FeatureGroup();
      this.logradouroLayers = new L.FeatureGroup();
      this.layer = null;
      this.editableLayers = new L.FeatureGroup();
      this.pontoHover = null;
      this.pontosReferencia = [];
      this.pontosRendered = [];

      if (this.pontosReferenciaService.roleVisualizar) {
        this.pontosReferenciaService.getCadastroPontoConfig().then(data => {
          this.cadastroPontoConfig = data;
          this.rootScope.$emit('init-menu-map');
        }).catch(() => {
          this.cadastroPontoConfig = {permissaoEdicao: true};
        });
      } else {
        this.cadastroPontoConfig = {permissaoEdicao: true};
      }
    }

    desabilitarZoom() {
      this.alertaZoom = false;
      this.map.off('zoomend', this.verificaZoom, this);
    }

    verificaZoom() {
      if (this.map.getZoom() >= 14) {
        this.alertaZoom = false;
        this.retangulo.enable();
      } else {
        this.alertaZoom = true;
        this.retangulo.disable();
      }
    }

    setVerificaZoom() {
      this.verificaZoom();
      this.map.on('zoomend', this.verificaZoom, this);
    }

    setLayerDefault() {
      this.latlngsOriginal = [];
      this.editableLayers.eachLayer(layer => this.setDefaultStyle(layer));
    }

    setDefaultStyle(layer) {
      if (angular.isUndefined(layer.options.id) && angular.isUndefined(layer.options.label)) {
        return layer.setStyle({weight: 0, fillOpacity: 0.4});
      }
    }

    setMap(map) {
      this.map = map;
      this.drawControl = new L.Control.Draw({
        draw: false,
        edit: {
          moveMarkers: true,
          featureGroup: this.editableLayers
        }
      });
      L.drawLocal.draw.handlers.simpleshape.tooltip.end = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.simpleshapeTooltipEnd');
      L.drawLocal.draw.handlers.rectangle.tooltip.start = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.rectangleTooltipStart');
      L.drawLocal.draw.handlers.marker.tooltip.start = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.markerTooltipStart');
      let element = L.DomUtil.get('map-control');
      L.DomEvent.on(element, 'mousewheel', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'dblclick', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'mousedown', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'contextmenu', L.DomEvent.stopPropagation);
      angular.element('tabela-pontos-proximos-directive').on('click', L.DomEvent.stopPropagation);
      angular.element('pesquisa-ponto-referencia-directive').on('click', L.DomEvent.stopPropagation);
      angular.element('#fechar-drawer').on('click', L.DomEvent.stopPropagation);
      this.moveMap();
      this.map.invalidateSize(true);
    }

    moveMap() {
      this.map.on('moveend', () => {
        this.mostrarPontosBoundsAtual();
        this.editableLayers.eachLayer(layer => {
          this.renderLabels(layer);
        });
      });
    }

    addLayer(layer) {
      this.editableLayers.addLayer(layer);
    }

    removeLayer(layer) {
      if (layer) {
        this.editableLayers.removeLayer(layer);
      }
    }

    addLayerMap(layer) {
      this.map.addLayer(layer);
    }

    removeLayerMap(layer) {
      if (layer) {
        this.map.removeLayer(layer);
      }
    }

    removeAllLayerMap() {
      this.editableLayers.eachLayer(layer => this.removeLayerMap(layer));
    }

    getIdLayer(layer) {
      return L.stamp(layer);
    }

    markerEditing(layer) {
      return !!layer.options.editing;
    }

    removeLogradouroMarker() {
      this.logradouroLayers.removeLayer(this.logradouroMarker);
    }

    focusLogradouro(logradouro, index) {
      if (this.logradouroMarker) {
        this.removeLogradouroMarker();
      }

      const zoom = this.map.getZoom();
      this.map.setView(new L.LatLng(logradouro.lat, logradouro.lon), zoom <= 15 ? 15 : zoom);
      if (index !== null && angular.isDefined(index)) {
        this.logradouroIndex = index;
      }
      this.logradouroMarker = L.marker([logradouro.lat, logradouro.lon], {
        icon: L.divIcon({
          iconSize: [0, 0],
          html: '<img src="images/icons-map/marker-pin-blue.png" class="img-icon"/>',
          iconAnchor: [15, 40],
          popupAnchor: [1, -35]
        })
      });
      this.logradouroLayers.addLayer(this.logradouroMarker);
      this.map.addLayer(this.logradouroLayers);
    }

    focusLayer(layer, zoomMap) {
      if (!this.hasEditableLayers()) {
        if (!this.markerEditing(layer)) {
          this.setLayerDefault();
          layer.setStyle({weight: 4, fillOpacity: 0.75});
          this.latlngsOriginal = layer.getLatLngs()[0];
          let latlngCenter = geolib.getCenter(this.latlngsOriginal);
          if (zoomMap) {
            const zoom = this.map.getZoom();
            this.map.setView(new L.LatLng(latlngCenter.latitude, latlngCenter.longitude), zoom <= 15 ? 15 : zoom);
          } else {
            this.map.panTo(new L.LatLng(latlngCenter.latitude, latlngCenter.longitude));
          }
          if (this.pontosReferenciaFiltroService.verificaLayer(layer, this.getIdLayer(layer))) {
            this.layer = layer;
            return true;
          }

          this.alertMessage.create({
            type: 'warning',
            message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.messagePontoOculto'),
            appendTo: '.alerta-aqui-ponto',
            timeout: 10000
          });
        }
      }
      return false;
    }

    hasRotogramas(layer) {
      return layer.entidade.rotogramas && layer.entidade.rotogramas.length > 0;
    }

    hasEditableLayers() {
      return _.some(this.editableLayers.getLayers(), item => {
        if (item instanceof L.Rectangle) {
          return item.options.editing;
        }
      });
    }

    markerOut(id) {
      let layer = this.getMarkerLimite(id, true);
      if (layer) {
        layer.setOpacity(0);
      }
    }

    markerIn(id, latlng, value) {
      let layer = this.getMarkerLimite(id, false, latlng, value);
      if (layer && this.map.getZoom() >= 15) {
        layer.setOpacity(1);
      }
    }

    getMarkerLimite(id, editing, latlng, value) {
      let retorno = null;
      this.editableLayers.eachLayer(layer => {
        if (angular.isDefined(layer.options.id) && layer.options.id === id) {
          retorno = layer;
          if (angular.isDefined(editing)) {
            layer.options.editing = editing;
          }
          if (angular.isDefined(latlng)) {
            if (angular.isDefined(value)) {
              this.removeLayer(layer);
              this.removeLayerMap(layer);

              let marker = this.buildMarkerLimite(latlng[0], latlng[1], value, id);
              this.addLayer(marker);
              this.addLayerMap(marker);
              retorno = marker;
            } else {
              layer.setLatLng(latlng);
            }
          }
        }
      });

      if (retorno === null && angular.isDefined(latlng) && angular.isDefined(value)) {
        let marker = this.buildMarkerLimite(latlng[0], latlng[1], value, id);
        this.addLayer(marker);
        this.addLayerMap(marker);
        retorno = marker;
      }

      return retorno;
    }

    getPontoReferencia(entidadePermissao) {
      let achouPonto = false;
      this.editableLayers.eachLayer(layer => {
        if (layer instanceof L.Rectangle && layer.entidadePermissao.entidade.id === entidadePermissao.entidade.id) {
          if (this.focusLayer(layer, true) && this.deveExibirIconeDeEdicao(layer)) {
            this.pontosReferenciaMenuService.setFABActive('editar');
          } else {
            this.pontosReferenciaMenuService.setFABDefault();
          }
          this.map.dragging.enable();
          achouPonto = true;
        }
      });
      if (!achouPonto) {
        if (!this.pontosReferenciaFiltroService.isPontoVisible(entidadePermissao)) {
          this.alertMessage.create({
            type: 'warning',
            message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.messagePontoOculto'),
            appendTo: '.alerta-aqui-ponto',
            timeout: 10000
          });
        } else {
          this.modalService.open({
            animation: true,
            controller: 'FocusPontoReferenciaModalCtrl',
            controllerAs: '$ctrl',
            templateUrl: 'directives/map-control/focus-ponto-referencia/focus-ponto-referencia-modal.tpl.html'
          });
        }
      }
    }

    deveExibirIconeDeEdicao(layer) {
      return layer.entidadePermissao.permissao.edicao &&
        this.pontosReferenciaService.roleCadastrar &&
        this.cadastroPontoConfig &&
        this.cadastroPontoConfig.permissaoDeEdicao;
    }

    buildMarkerLimite(
      latitude, longitude, velocidade = null, id = null, status = this.controleVelocidadeRotograma.CONFIGURADO.value,
      rotation = -1, idRotograma = null
    ) {
      let html, opacity = this.map.getZoom() >= 15 ? 1 : 0;
      if (status === this.controleVelocidadeRotograma.CONFIGURADO.value ||
          status === this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value) {
        html = `<strong>${velocidade}</strong><small>km/h</small>`;
      } else {
        html = '';
      }

      return L.marker([latitude, longitude], {
        icon: L.divIcon({
          className: `limite-velocidade-icone-${rotation} ${this.controleVelocidadeRotograma[status].classe}`,
          html
        }),
        opacity,
        id,
        idRotograma,
        velocidade,
        status
      });
    }

    buildMarkerLabel(infosPonto) {
      const larguraRotograma = 30;
      let html, label = true, width,
          pontosLargura = this.pontosReferenciaToolsService.geraLatLngsPontoLargura(infosPonto.raio, infosPonto.latitude, infosPonto.longitude);

      width = this.pontosReferenciaToolsService.geraWidth(
        this.map, pontosLargura.latlngEsquerda, pontosLargura.latlngDireta) - larguraRotograma;

      html = `<div class="label-box" style="width: ${width}px; margin-left: -${Math.round(width / 2 - 6)}px;">\
                <div style="max-width: ${width}px" class="label-text">\
                  <i class='fa fa-map-marker icon-label-ponto'></i>${infosPonto.nome}\
                </div>\
              </div>`;
      return L.marker([infosPonto.latitude, infosPonto.longitude], {
        icon: L.divIcon({
          html
        }),
        label,
        nome: infosPonto.nome,
        idPonto: infosPonto.id
      });
    }

    getMarkerRotograma(idLayer, angulo, velocidade, tipoRotograma, latitude, longitude, raio, idRotograma) {
      let marker;
      if (angular.isDefined(angulo) && angulo !== null) {
        let retorno = this.pontosReferenciaToolsService.geraLatLngsRotograma(angulo, raio, latitude, longitude);
        marker = this.buildMarkerLimite(retorno.latlng.latitude, retorno.latlng.longitude, velocidade, idLayer, tipoRotograma, retorno.sentido, idRotograma);
      } else {
        marker = this.buildMarkerLimite(latitude, longitude, velocidade, idLayer, tipoRotograma, -1, idRotograma);
      }
      return marker;
    }

    getIconPontoProximo(html, className) {
      return L.divIcon({
        html,
        iconSize: [30, 35],
        iconAnchor: [15, 32],
        index: this.countPontosProximosBackup,
        className: `icon-pontos-proximos ${className}`
      });
    }

    setPontoProximo() {
      const icon = this.getIconPontoProximo('<i class="fv trimble-push-pin"></i><span class="label label-danger">x</span>', `icon-pontos-proximos-${this.countPontosProximos}`);
      this.markerPontoProximo = new L.Draw.Marker(this.map, {icon});
      this.enablePontoProximo();
    }

    enablePontoProximo() {
      this.timeout(() => {
        if (this.markerPontoProximo) {
          this.markerPontoProximo.enable();
        }
        this.pontosReferenciaMenuService.menuOpen();
        this.countPontosProximos++;
      }, 100);
    }

    exibirVelocidadeVia(lat, lon) {
      if (this.map.getZoom() >= 15) {
        this.pontosReferenciaService.getInfoGeocode(lat, lon)
        .then(item => {
          const logradouroLabel = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.logradouro'),
              reportarVelocidadeIncorretaVia = this.reportWrongSpeedViaService.getLinkToFormHtml(this.user, lat, lon);
          let html = item.logradouro ? `<div><strong>${logradouroLabel}</strong> ` + item.logradouro + '</div>' : '',
              urbano = item.urbano;
          if (item.roadInfo && item.roadInfo.highwayType) {
            const fonteLabel = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.fonte');
            if (item.roadInfo.speedLimit) {
              const limiteLabel = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.limite');
              html += `<div><strong>${limiteLabel}</strong> ${item.roadInfo.speedLimit} km/h <i>(${fonteLabel} <a href="https://www.openstreetmap.org/way/${item.osmId}" target="_blank">OSM</a>)</i></div>`;
            } else {
              const i = this.retornaTipoVia.hasOwnProperty(item.roadInfo.highwayType) ? this.retornaTipoVia[item.roadInfo.highwayType](urbano) : this.retornaTipoVia.default(urbano),
                  limiteVeiculoLeveLabel = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.limiteVeiculoLeve'),
                  limiteVeiculoPesadoLabel = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.limiteVeiculoPesado'),
                  padraoPorTipoDe = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.padraoPorTipoDe');
              html += `<div><strong>${limiteVeiculoLeveLabel}</strong> ${i.veiculoLeve} km/h <i>(${fonteLabel} ${padraoPorTipoDe} ${i.tipoVia})</i></div>`;
              html += `<div><strong>${limiteVeiculoPesadoLabel}</strong> ${i.veiculoPesado} km/h <i>(${fonteLabel} ${padraoPorTipoDe} ${i.tipoVia})</i></div>`;
            }
          } else {
            html = `<strong>${this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.naoFoiPossivelCarregar')}</strong>`;
          }
          html += `<div>${this.popupService.getGoogleMapsLink(lat, lon)}</div>`;
          html += `<div>${this.popupService.getStreetViewLink(lat, lon)}</div>`;
          if (reportarVelocidadeIncorretaVia) {
            html += `<div>${reportarVelocidadeIncorretaVia}</div>`;
          }

          L.popup()
          .setLatLng(new L.LatLng(lat, lon))
          .setContent(html)
          .openOn(this.map);
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorCarregarLimiteVia'),
            appendTo: '.alerta-aqui-ponto'
          });
        });
      }
    }

    getTextoPopUp(textoPopup, data) {
      if (!data.permissao.edicao) {
        const textoSemPermissao = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.popupNaoTemPermissao');
        return textoPopup + `<br/><strong style="color: #f39c12;">${textoSemPermissao}</strong>`;
      }
      return textoPopup;
    }

    selecionarPonto(e) {
      if (this.layer && this.getIdLayer(this.layer) !== this.getIdLayer(e.layer)) {
        this.map.closePopup();
        if (this.map.getZoom() < 17) {
          e.layer.openPopup(e.latlng);
        }
      }
      let focus = this.focusLayer(e.layer, false);
      if (focus) {
        this.atualizaScopeMenuEditar();
      }
    }

    atualizaScopeMenuEditar() {
      angular.element('#map-control').scope().$apply(() => {
        if (this.cadastroPontoConfig.permissaoDeEdicao) {
          this.pontosReferenciaMenuService.setFABActive('editar');
        }
      });
    }

    atualizarLayer(data) {
      let item = data.entidade;
      if (item) {
        this.alertMessage.create({
          type: 'success',
          message: item.texto,
          appendTo: '.alerta-aqui-ponto'
        });
        this.map.fire('click');
        this.removeLayer(this.layer);
        this.removeLayerMap(this.layer);
        if (!data.isNew) {
          let ponto = _.find(this.pontosReferencia, (p) => p.entidade.id === data.oldId);
          this.unRender(ponto);
          ponto.layers = null;
          ponto.entidade = data.entidade;
          ponto.permissao = data.permissao;
          ponto.objCategoria = data.objCategoria;
        } else {
          data.isNew = false;
          this.pontosReferencia.push(data);
        }
        this.map.panTo(new L.LatLng(item.latitude, item.longitude));
      }

      if (this.layer) {
        this.latlngsOriginal = this.layer.getLatLngs()[0];
        this.layer.editing.disable();
      }
      this.mapControlService.isDrawingPontoRef = false;
      this.retangulo = null;

      this.pontosReferenciaMenuService.setFABDefault();
      this.setLayerDefault();
      this.setCategorias();
      this.setUos();
    }

    cancelarPonto() {
      this.pontosReferenciaMenuService.setFABDefault();
      this.setLayerDefault();
      this.removePontosProximos();
      if (this.layer && angular.isUndefined(this.layer.entidadePermissao)) {
        this.removeLayer(this.layer);
        this.removeLayerMap(this.layer);
      }
      if (this.retangulo) {
        this.drawCanceled = true;
        this.retangulo.disable();
        this.retangulo = null;
        this.desabilitarZoom();
      }
      this.mapControlService.isDrawingPontoRef = false;
    }

    cancelarAjuste() {
      let item = this.layer.entidadePermissao.entidade,
          pontos = this.pontosReferenciaToolsService.geraLatLngsExternos(item.latitude, item.longitude, item.raio);

      this.layer.setLatLngs([
        [pontos.latlngLeftBottom.latitude, pontos.latlngLeftBottom.longitude],
        [pontos.latlngRightTop.latitude, pontos.latlngLeftBottom.longitude],
        [pontos.latlngRightTop.latitude, pontos.latlngRightTop.longitude],
        [pontos.latlngLeftBottom.latitude, pontos.latlngRightTop.longitude]
      ]);
      this.layer.options.editing = false;
      this.mapControlService.isDrawingPontoRef = false;
      this.markerIn(this.getIdLayer(this.layer), [item.latitude, item.longitude]);
    }

    eventoDrawStart() {
      this.map.on(L.Draw.Event.DRAWSTART, () => {
        this.layer = null;
        this.drawCanceled = false;
      });
    }

    eventoDrawCanceled() {
      this.map.on('draw:canceled', () => {
        this.desabilitarZoom();
        this.pontosReferenciaMenuService.setFABDefault();
        this.setLayerDefault();
        this.drawCanceled = true;
        this.retangulo = null;
        this.removePontosProximos();
      });
    }

    eventoDrawStop() {
      this.map.on(L.Draw.Event.DRAWSTOP, () => {
        if (this.map.hasLayer(this.layer)) {
          this.desabilitarZoom();
        } else if (this.map.getZoom() >= 14 && !this.drawCanceled && this.retangulo !== null) {
          this.retangulo.enable();
        }
      });
    }

    eventoDrawMove() {
      this.map.on(L.Draw.Event.EDITMOVE, e => {
        this.unRenderLabels(e.layer);
        this.latlngsOriginal = e.layer.getLatLngs()[0];
      });
    }

    eventoDrawResize() {
      this.map.on(L.Draw.Event.EDITRESIZE, e => {
        this.unRenderLabels(e.layer);
        let layer = e.layer,
            latlngs = this.pontosReferenciaToolsService.geraLatlngsResize(layer.getLatLngs()[0], this.latlngsOriginal),
            latlngsOriginalCentro = this.pontosReferenciaToolsService.geraLatLngCentro(this.latlngsOriginal);

        layer.setLatLngs(latlngs);
/*eslint-disable */
        layer.editing._moveMarker.setLatLng({lat: latlngsOriginalCentro.latitude, lng: latlngsOriginalCentro.longitude});
/*eslint-enable */
        this.layer = layer;
      });
    }

    eventoDrawCreated() {
      this.map.on(L.Draw.Event.CREATED, e => {
        if (e.layerType === this.layerTypeEnum.RECTANGLE) {
          this.drawCreatedPontoReferencia(e);
        }

        if (e.layerType === this.layerTypeEnum.MARKER) {
          this.drawCreatedPontoProximo(e);
        }
      });
    }

    drawCreatedPontoProximo(e) {
      let layer = e.layer,
          buttonsPrincipal = {
            active: 'fa fa-thumb-tack',
            resting: 'fa fa-thumb-tack'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtConfirmar',
                icon: 'fa fa-check',
                color: 'success',
                tipo: 'default',
                funcao: this.confirmarPontosProximos.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.cancelarPonto.bind(this)
              }
          ];
      this.rootScope.$apply(() => this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal));
      this.pontosProximosLayers.addLayer(layer);
      this.addLayerMap(layer);

      layer.dragging.enable();

      layer.on('mouseover', () => this.markerPontoProximo && this.markerPontoProximo.disable());
      layer.on('mouseout', () => this.markerPontoProximo && this.markerPontoProximo.enable());

      angular.element(`.${layer.options.icon.options.className.split(' ')[1]} > span.label`)
          .on('click', () => this.removePontoProximo(layer));

      this.setPontoProximo();
    }

    excluiPontoReferencia(idPontoReferenciaDeletar) {
      this.pontosReferencia = this.pontosReferencia.filter(pontoRerefencia => pontoRerefencia.entidade.id !== idPontoReferenciaDeletar);
    }

    confirmarPontosProximos() {
      this.pontosReferenciaMenuService.setFABDefault();
      this.pontosProximosLayers.eachLayer((e) => {
        this.pontosProximosLayersBackup.addLayer(e);
        e.dragging.disable();
        e.setIcon(this.getIconPontoProximo(`<i class="fv trimble-push-pin"></i><span class="label">${this.countPontosProximosBackup}</span>`, 'fix'));
        this.countPontosProximosBackup++;
      });
      this.pontosProximosLayers = new L.FeatureGroup();
      this.markerPontoProximo.disable();
      this.markerPontoProximo = null;

      this.pontosProximosService.setPontosProximos(this.pontosProximosLayersBackup.getLayers());
    }

    removePontoProximo(layer) {
      layer.dragging.disable();
      this.pontosReferenciaMenuService.menuOpen();
      this.timeout(() => this.removeLayerPontoProximo(layer), 100);
      this.setPontoProximo();

      if (this.pontosProximosLayers.getLayers().length === 0) {
        this.pontosReferenciaMenuService.menuClose();
        this.cancelarPonto();
      }
    }

    removeLayerPontoProximo(layer) {
      this.pontosProximosLayers.removeLayer(layer);
      this.pontosProximosLayersBackup.removeLayer(layer);
      this.removeLayerMap(layer);
    }

    removePontosProximos() {
      if (this.markerPontoProximo) {
        this.pontosProximosLayers.eachLayer((layer) => {
          this.removeLayerMap(layer);
        });
        this.pontosProximosLayers = new L.FeatureGroup();
        this.markerPontoProximo.disable();
        this.markerPontoProximo = null;
      }
    }

    removeAllPontosProximos() {
      this.removeDemoPontoProximo();
      this.removePontosProximos();
      this.pontosProximosLayersBackup.eachLayer((layer) => {
        this.removeLayerMap(layer);
      });
      this.pontosProximosLayersBackup = new L.FeatureGroup();
      this.pontosReferenciaMenuService.setFABDefault();
      this.countPontosProximosBackup = 1;
    }

    removeDemoPontoProximo() {
      this.pontosProximosPolyline.eachLayer(layer => this.removeLayerMap(layer));
      this.pontosProximosPolyline = new L.FeatureGroup();
    }

    focusPontoProximo(item, layerEvento) {
      const linha = new L.Polyline(
          [
            [item.latitude, item.longitude],
            [item.latitudeMarker, item.longitudeMarker]
          ],
          {
            dashArray: '7, 7',
            opacity: 1,
            color: '#9775FB'
          }),
          marker = new L.Marker(new L.LatLng(item.latitude, item.longitude), {
            icon: L.divIcon({
              html: '<img src="images/icons-map/marker-pin-blue.png" class="img-icon"/>',
              iconSize: [0, 0],
              iconAnchor: [12, 37],
              popupAnchor: [0, -35]
            })
          }).bindPopup(this.popupService.build(item.evento));

      if (!layerEvento) {
        this.timeout(() => marker.openPopup(), 100);
      }
      this.focusMarker(item);
      this.removeDemoPontoProximo();

      this.pontosProximosPolyline.addLayer(linha);
      this.pontosProximosPolyline.addLayer(marker);
      this.pontosProximosPolyline.eachLayer(layer => this.addLayerMap(layer));
    }

    focusMarker(item) {
      const zoom = this.map.getZoom();
      this.map.setView(new L.LatLng(item.latitude, item.longitude), zoom <= 16 ? 16 : zoom);
    }

    drawCreatedPontoReferencia(e) {
      const DIAMETRO_MIN_EM_METROS = 20,
          DIAMETRO_MAX_EM_METROS = 400;
      this.desabilitarZoom();
      this.pontosReferenciaMenuService.menuOpen();

      let layer = e.layer,
          latlngs = layer.getLatLngs()[0],
          difLatMetros = geolib.getDistance({latitude: latlngs[0].lat, longitude: latlngs[0].lng}, {latitude: latlngs[1].lat, longitude: latlngs[1].lng}, 1, 3),
          buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtConfirmar',
                icon: 'fa fa-check',
                color: 'success',
                tipo: 'adicionar',
                funcao: this.confirmarNovoPonto.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.cancelarPonto.bind(this)
              }
          ];

      this.rootScope.$apply(() => this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal));
      layer.setLatLngs(this.pontosReferenciaToolsService.retornaPontosComDistancia(latlngs, Math.max(DIAMETRO_MIN_EM_METROS, Math.min(DIAMETRO_MAX_EM_METROS, difLatMetros))));

      layer.options.editing = true;
      this.mapControlService.isDrawingPontoRef = true;
      this.latlngsOriginal = latlngs;

      this.addLayer(layer);
      this.addLayerMap(layer);
      layer.editing.enable();

      this.layer = layer;
    }

    confirmarNovoPonto() {
      this.pontosReferenciaMenuService.drawerClose();
      const latlngCentro = this.pontosReferenciaToolsService.geraLatLngCentro(this.layer.getLatLngs()[0]),
          raio = this.pontosReferenciaToolsService.geraDiametro(this.layer.getLatLngs()[0]) / 2,
          posicao = {lat: latlngCentro.latitude, lng: latlngCentro.longitude, raio: raio};

      this.openModalCreatePontoReferencia(posicao);
    }

    openModalCreatePontoReferencia(posicao) {
      if (this.featureFlag.DIALOG_PONTO_REESTRUTURADO) {
        /* eslint-disable */
        const abrirModal = document.querySelector('#dialog-create-ponto'),
          modal = document.createElement('vfwc-open-dialog-create-ponto-referencia'),
          advisorSuggestion = posicao.advisorSuggestion;
        /* eslint-enable */
        modal.pontoReferencia = {
          latitude: Number.parseFloat(posicao.lat),
          longitude: Number.parseFloat(posicao.lng),
          raio: Math.round(posicao.raio),
          rotogramas: []
        };
        modal.uoFiltro = this.uoSelecionadaParaNovoPonto();
        modal.usuario = this.user;
        modal.advisorSuggestion = advisorSuggestion;
        abrirModal.appendChild(modal);
        this.onPontoCreated(modal, advisorSuggestion);
      } else {
        this.modalService.open({
          animation: true,
          component: 'modalEdicaoPontoReferencia',
          backdrop: 'static',
          openedClass: 'modal-edicao-ponto-referencia',
          resolve: {
            pontoReferencia: () => ({
              latitude: Number.parseFloat(posicao.lat),
              longitude: Number.parseFloat(posicao.lng),
              raio: Math.round(posicao.raio)
            }),
            rotogramas: () => [],
            uoSelecionada: () => {
              return this.uoSelecionadaParaNovoPonto();
            },
            advisorSuggestion: () => posicao.advisorSuggestion
          }
        }).result.then(data => this.atualizaPonto(data));
      }
    }

    onPontoCreated(element, advisorSuggestion) {
      element.addEventListener('pontoReferenciaEventEmitter', (event) => {
        if (event.detail && event.detail.result) {
          const data = event.detail.result;
          this.atualizaPonto(data);
          if (data.rotogramas && data.rotogramas.length) {
            this.rootScope.$broadcast('rotogramasCadastrados', {
              rotogramas: data.rotogramas,
              pontoReferenciaId: data.entidade.id
            });
            if (advisorSuggestion) {
              this.rootScope.$broadcast('advisorAtualizado');
            }
          }
        }
      });
    }

    focusLocal(item) {
      this.map.setView(new L.LatLng(item.lat, item.lng), 18);
    }

    renderAdvisorSuggestion(posicao, advisorSuggestion) {
      const slowerSpeed = this.getSlowestSpeed(advisorSuggestion),
          latLngsExternos = this.pontosReferenciaToolsService.geraLatLngsExternos(posicao.lat, posicao.lng, posicao.raio),
          advisorArea = L.rectangle([
            [latLngsExternos.latlngLeftBottom.latitude, latLngsExternos.latlngLeftBottom.longitude],
            [latLngsExternos.latlngRightTop.latitude, latLngsExternos.latlngRightTop.longitude]
          ], {
            color: '#000000',
            weight: 0,
            fillOpacity: 0.4,
            editing: false
          });

      this.lastLayerSuggestionClicked = L.layerGroup([advisorArea], {id: 'advisor-selected-group'});
      if (slowerSpeed) {
        const markerVelocidade = this.buildMarkerLimite(posicao.lat, posicao.lng, slowerSpeed, 'advisor-vel-selected', this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value);
        markerVelocidade.setOpacity(1);
        this.lastLayerSuggestionClicked.addLayer(markerVelocidade);
      }

      this.addLayerMap(this.lastLayerSuggestionClicked);
      this.map.on('zoomend', () => {
        const opacity = this.map.getZoom() >= 15 ? 1 : 0;
        this.lastLayerSuggestionClicked.getLayers()
          .filter(layer => layer instanceof L.Marker)
          .forEach(layer => layer.setOpacity(opacity));
      });
    }

    getSlowestSpeed(advisorSuggestion) {
      return advisorSuggestion.velocidadeInterna ? _.min(Object.values(advisorSuggestion.velocidadeInterna)) : null;
    }

    unrenderAdvisorSuggestion() {
      if (this.lastLayerSuggestionClicked) {
        this.removeLayerMap(this.lastLayerSuggestionClicked);
      }
    }

    uoSelecionadaParaNovoPonto() {
      let config = this.cadastroPontoConfig;
      if (config && config.uoDaRegra && config.permissaoDeEdicao) {
        return config.uoDaRegra.id;
      }
      return this.pontosReferenciaService.user.uo.id;
    }

    atualizaPonto(data) {
      this.removeLayer(this.layer);
      this.removeLayerMap(this.layer);
      this.atualizarLayer(data);
      if (data.entidade.limiteVelocidade) {
        let marker = this.buildMarkerLimite(data.entidade.latitude, data.entidade.longitude, data.entidade.limiteVelocidade, this.getIdLayer(this.layer));
        this.addLayer(marker);
        this.addLayerMap(marker);
        if (this.map.getZoom() >= 15) {
          marker.setOpacity(1);
        }
      }
    }

    mapearMarcador(posicao, pontoReferencia) {
      if (posicao.key === this.posicoesRotogramas.CENTRO.key) {
        return L.marker([pontoReferencia.latitude, pontoReferencia.longitude], {
          icon: L.divIcon({className: 'icon-rotograma'}),
          posicao: posicao
        });
      }
      const latLngRotograma = this.pontosReferenciaToolsService.geraLatLngsRotograma(
        posicao.anguloInicial,
        pontoReferencia.raio,
        pontoReferencia.latitude,
        pontoReferencia.longitude
      );
      return L.marker([latLngRotograma.latlng.latitude, latLngRotograma.latlng.longitude], {
        icon: L.divIcon({className: 'icon-rotograma'}),
        draggable: true,
        posicao: posicao
      });
    }

    iniciaEventos() {
      this.eventoDrawStart();
      this.eventoDrawCanceled();
      this.eventoDrawStop();
      this.eventoDrawMove();
      this.eventoDrawResize();
      this.eventoDrawCreated();
    }

    geraLabelPorZoom(layer) {
      let latLngLabel = this.pontosReferenciaToolsService.geraLatLngsLabel(
        layer.ponto.raio, layer.ponto.latitude, layer.ponto.longitude), label, labels = [],
          infosPonto = {
            latitude: latLngLabel.latitude,
            longitude: latLngLabel.longitude,
            nome: layer.ponto.nome,
            raio: layer.ponto.raio,
            id: layer.ponto.id
          };

      label = this.buildMarkerLabel(infosPonto);
      this.addLayer(label);
      this.addLayerMap(label);
      label.setZIndexOffset(-1001);

      if (angular.isDefined(layer.labels)) {
        layer.labels[this.map.getZoom()] = label;
      } else {
        labels[this.map.getZoom()] = label;
        layer.labels = labels;
      }
      label.bindPopup(this.getTextoPopUp(layer.ponto.nome, layer.entidadePermissao));
      return label;
    }

    renderLabels(layer) {
      if (this.map.getZoom() >= 17 && angular.isDefined(layer.ponto)) {
        if (this.shouldRenderLabel(layer.entidadePermissao) && angular.isDefined(layer.ponto.nome)) {
          if (angular.isUndefined(layer.labels) || angular.isUndefined(layer.labels[this.map.getZoom()])) {
            this.geraLabelPorZoom(layer).setOpacity(1);
          }
          if (angular.isDefined(layer.labels)) {
            layer.labels.forEach((label, index) => {
              if (index !== this.map.getZoom()) {
                label.setOpacity(0);
                this.removeLayer(label);
                this.removeLayerMap(label);
              } else {
                this.addLayer(label);
                this.addLayerMap(label);
                label.setOpacity(1);
              }
            });
          }
        }
      } else if (angular.isDefined(layer.labels)) {
        this.unRenderLabels(layer);
      }
    }

    eventoZoomEnd() {
      this.map.on('zoomend', () => {
        this.editableLayers.eachLayer(layer => {
          this.renderLabels(layer);
          this.popUpControl(layer);
          if (angular.isDefined(layer.options.id) && !this.markerEditing(layer)) {
            if (this.map.getZoom() >= 15) {
              layer.setOpacity(1);
            } else {
              layer.setOpacity(0);
            }
          }
        });
      });
    }

    eventoGeral() {
      this.eventoZoomEnd();

      this.editableLayers.on('contextmenu', e => this.exibirVelocidadeVia(e.latlng.lat, e.latlng.lng));
      this.map.on('contextmenu', e => this.exibirVelocidadeVia(e.latlng.lat, e.latlng.lng));

      this.eventoClickLayer();
    }

    popUpControl(layerPonto) {
      if (angular.isDefined(layerPonto.entidadePermissao)) {
        if (layerPonto.entidadePermissao.permissao.edicao && this.map.getZoom() >= 17) {
          layerPonto.closePopup();
        }
      }
    }

    eventoClickLayer() {
      this.editableLayers.on('click', e => {
        this.popUpControl(e.layer);

        if (this.pontosReferenciaService.roleVisualizarRotograma &&
            e.layer instanceof L.Marker) {
          this.exibirRotograma(e);
        }
        if (this.pontosReferenciaService.roleCadastrar &&
            e.layer instanceof L.Rectangle) {
          this.exibirPontoReferencia(e);
        }
        if (e.layer.options.label) {
          this.getPontoReferencia({entidade: {id: e.layer.options.idPonto}});
        }
      });
    }

    exibirRotograma(e) {
      if (e.layer.options.id && e.layer.options.idRotograma) {
        this.modalService.open({
          component: 'modalDetalhesRotograma',
          backdrop: 'static',
          resolve: {
            id: () => e.layer.options.idRotograma,
            velocidade: () => e.layer.options.velocidade,
            status: () => e.layer.options.status
          }
        });
      }
    }

    exibirPontoReferencia(e) {
      if (angular.isUndefined(e.layer.options.id) &&
          e.layer.entidadePermissao.permissao.edicao &&
          !this.hasEditableLayers() &&
          !this.mapControlService.isDrawingCercaEletronica) {
        this.rootScope.$broadcast('limpaSelecaoCercaEletronica');
        this.selecionarPonto(e);
      }
    }

    centerPontoId(pontoId) {
      this.pontosReferenciaMenuService.limpaClassePesquisa();
      this.removeDemoPontoProximo();
      this.removePontosProximos();

      const ponto = _.find(this.pontosReferencia, (p) => p.entidade.id === pontoId);

      if (angular.isDefined(ponto)) {
        this.map.setView(
          new L.LatLng(ponto.entidade.latitude, ponto.entidade.longitude),
          14,
          {animate: false}
        );
        this.mostrarPontosBoundsAtual();
        this.getPontoReferencia(ponto);
      } else {
        this.modalService.open({
          animation: true,
          controller: 'FocusPontoReferenciaModalCtrl',
          controllerAs: '$ctrl',
          templateUrl: 'directives/map-control/focus-ponto-referencia/focus-ponto-referencia-modal.tpl.html'
        });
      }
    }

    focusPonto(item) {
      this.rootScope.$broadcast('limpaSelecaoCercaEletronica');
      this.pontosReferenciaMenuService.limpaClassePesquisa();
      this.removeDemoPontoProximo();
      this.removePontosProximos();

      const zoom = this.map.getZoom();
      this.map.setView(new L.LatLng(item.entidade.latitude, item.entidade.longitude), zoom <= 15 ? 15 : zoom);
      this.mostrarPontosBoundsAtual();

      this.getPontoReferencia(item);
    }

    getEntidadePermissao() {
      return this.editableLayers.getLayers().filter(x => x.entidadePermissao);
    }

    setUos() {
      this.pontosReferenciaService.uos = this.getEntidadePermissao()
      .filter(item => item.entidadePermissao.permissao.uo)
          .filter((item, index, self) => index === self.findIndex((t) => t.entidadePermissao.permissao.uo.id === item.entidadePermissao.permissao.uo.id))
          .map(x => x.entidadePermissao.permissao.uo);
    }

    setCategorias() {
      this.pontosReferenciaService.categorias = this.getEntidadePermissao()
          .filter((item, index, self) => index === self.findIndex((t) => t.entidadePermissao.objCategoria.id === item.entidadePermissao.objCategoria.id))
          .map(x => x.entidadePermissao.objCategoria);
    }

    setPontosReferencia(pontosReferencia, categorias, pontoId) {
      this.pontosReferenciaService.uos = _.uniq(
            pontosReferencia,
            (t) => t.permissao.uo.id
          ).map(x => x.permissao.uo);
      this.pontosReferenciaFiltroService.loadFiltroFromLocalStorage();

      this.responseCategorias(categorias);
      this.responsePontos(pontosReferencia, pontoId);
    }

    recarregaPontos() {
      this.mostrarPontosBoundsAtual();
      this.pesquisa = '';
      this.pontos = [];
    }

    removerLayersRotogramas(rotogramaIds) {
      const ids = new Set(rotogramaIds);
      this.editableLayers.getLayers()
      .filter(layer => layer instanceof L.Marker && ids.has(layer.options.idRotograma))
      .forEach(layer => {
        this.editableLayers.removeLayer(layer);
        this.map.removeLayer(layer);
      });
    }

    responsePontos(pontosReferencia, idPonto) {
      this.pontosReferencia = pontosReferencia;
      this.mostrarPontosBoundsAtual();
      if (idPonto) {
        this.centerPontoId(parseInt(idPonto, 10));
      }
    }

    getLayersPontoReferencia(item) {
      let ponto = item.entidade,
          objCategoria = this.categoriasById[ponto.categoria] && this.categoriasById[ponto.categoria][0] ?
            this.categoriasById[ponto.categoria][0] :
            this.pontosCategoriaReferenciaService.categoriaDefault,
          retangulo,
          pontos = this.pontosReferenciaToolsService.geraLatLngsExternos(
            ponto.latitude,
            ponto.longitude,
            ponto.raio),
          layers = [];

      retangulo = new L.Rectangle([
        [pontos.latlngLeftBottom.latitude, pontos.latlngLeftBottom.longitude],
        [pontos.latlngRightTop.latitude, pontos.latlngRightTop.longitude]
      ], {color: objCategoria.cor, weight: 0, fillOpacity: 0.4, editing: false});

      item.objCategoria = objCategoria;

      retangulo.ponto = ponto;
      retangulo.entidadePermissao = item;
      retangulo.bindPopup(this.getTextoPopUp(ponto.nome, retangulo.entidadePermissao));
      layers.push(retangulo);

      if (ponto.limiteVelocidade) {
        layers.push(this.buildMarkerLimite(ponto.latitude, ponto.longitude, ponto.limiteVelocidade, this.getIdLayer(retangulo)));
      }

      if (this.pontosReferenciaService.roleVisualizarRotograma && this.hasRotogramas(retangulo.entidadePermissao)) {
        this.pontosReferenciaService.rotograma = true;
        ponto.rotogramas.forEach(rotograma => {
          layers.push(
            this.getMarkerRotograma(
              this.getIdLayer(retangulo),
              rotograma.grau,
              rotograma.grupoVelocidadePadrao && rotograma.grupoVelocidadePadrao.velocidade,
              rotograma.status,
              ponto.latitude,
              ponto.longitude,
              ponto.raio,
              rotograma.id
            )
          );
        });
      }
      return layers;
    }

    shouldRender(item) {
      return this.map.getBounds().contains(L.latLng(item.entidade.latitude, item.entidade.longitude)) &&
        this.pontosReferenciaFiltroService.isPontoVisible(item) &&
        !item.rendered;
    }

    shouldRenderLabel(item) {
      return this.map.getBounds().contains(L.latLng(item.entidade.latitude, item.entidade.longitude)) &&
        this.pontosReferenciaFiltroService.isPontoVisible(item);
    }

    shouldUnrender(item) {
      return (!this.map.getBounds().contains(L.latLng(item.entidade.latitude, item.entidade.longitude)) ||
        !this.pontosReferenciaFiltroService.isPontoVisible(item)) &&
        item.rendered;
    }

    render(item) {
      if (!item.layers) {
        item.layers = this.getLayersPontoReferencia(item);
      }
      item.layers.forEach(layer => {
        if (angular.isDefined(layer.options.id) && !this.markerEditing(layer)) {
          if (this.map.getZoom() >= 15) {
            layer.setOpacity(1);
          } else {
            layer.setOpacity(0);
          }
        }
        this.addLayer(layer);
        this.addLayerMap(layer);
        this.renderLabels(layer);
      });
      item.rendered = true;
    }

    unRender(item) {
      if (item && item.layers) {
        item.layers.forEach(layer => {
          this.unRenderLabels(layer);
          this.removeLayer(layer);
          this.removeLayerMap(layer);
        });
        item.rendered = false;
      }
    }

    unRenderLabels(layer) {
      if (angular.isDefined(layer.labels)) {
        layer.labels.map(label => {
          label.setOpacity(0);
          this.removeLayer(label);
          this.removeLayerMap(label);
        });
      }
    }

    mostrarPontosBoundsAtual() {
      if (this.map.getZoom() >= ZOOM_MINIMO_RENDER_MARKER) {
        this.pontosReferencia.forEach(item => {
          if (this.shouldRender(item)) {
            let rawName = item.entidade.nome,
                sanitizedName = this.$sanitize(rawName);
            item.entidade.nome = sanitizedName;
            this.render(item);
            this.pontosRendered.push(item);
          } else if (this.shouldUnrender(item)) {
            this.unRender(item);
            this.pontosRendered = this.pontosRendered.filter(i => item.entidade.id !== i.entidade.id);
          }
        });
      } else {
        this.pontosRendered.forEach(item => this.unRender(item));
        this.pontosRendered = [];
      }
    }

    responseCategorias(categorias) {
      this.pontosReferenciaService.categorias = [this.pontosCategoriaReferenciaService.categoriaDefault].concat(categorias);
      this.categoriasById = _.groupBy(categorias.plain(), cat => cat.id);
    }

    clearMarkers() {
      this.pontosRendered.forEach(item => this.unRender(item));
      this.pontosRendered = [];
      this.pontosReferencia = [];
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosReferenciaMap', PontosReferenciaMap);
}());
