(function () {
  'use strict';

  const HORA_EM_SEGUNDOS = 3600,
      KM_EM_METROS = 1000;

  class AlertaManutencaoCtrl {
    constructor($uibModalInstance, $timeout, Restangular, AlertMessage, veiculos, permissaoManutencaoProgramada, tipoRemocao, HttpServiceV3g, $translate) {
      this.modalInstance = $uibModalInstance;
      this.timeout = $timeout;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.veiculos = veiculos;
      this.permissaoManutencaoProgramada = permissaoManutencaoProgramada;
      this.httpService = HttpServiceV3g;
      this.tipoRemocao = tipoRemocao;

      this.intervalo = null;
      this.margem = null;
      this.intervaloBackend = null;
      this.margemBackend = null;
      this.intervaloHorimetro = null;
      this.margemHorimetro = null;
      this.intervaloHorimetroBackend = null;
      this.margemHorimetroBackend = null;
      this.translate = $translate;

      this.init();
    }

    init() {
      this.veiculos = this.veiculos.length === 1 ? this.veiculos[0] : this.veiculos;

      if (!angular.isArray(this.veiculos) && this.veiculos && this.veiculos.manutencaoProgramada) {
        this.intervalo = this.veiculos.manutencaoProgramada.intervaloManutencao || null;
        this.margem = this.veiculos.manutencaoProgramada.margemAlertaManutencao || null;
        this.intervaloHorimetro = this.veiculos.manutencaoProgramada.intervaloManutencaoHorimetro || null;
        this.margemHorimetro = this.veiculos.manutencaoProgramada.margemAlertaManutencaoHorimetro || null;
      }

      if (!this.permissaoManutencaoProgramada) {
        this.timeout(() => {
          this.alertMessage.create({
            type: 'warning',
            message: this.translate.instant('ce.cadastro.veiculos.modalManutencao.aviso'),
            appendTo: '.alerta-aqui-manutencao',
            timeout: 1000000
          });
        }, 100);
      }
    }

    buildProximoAlertaManutencao(hodometroOuHorimetro, intervalo, margem) {
      if (hodometroOuHorimetro > 0) {
        return Math.ceil(hodometroOuHorimetro / intervalo) * intervalo - (margem || 0);
      }

      return intervalo - (margem || 0);
    }

    buildVeiculo(id, hodometro, horimetro) {
      const veiculo = {
        veiculoId: id
      };

      if (this.intervaloBackend || this.intervaloBackend === 0) {
        veiculo.intervaloManutencao = this.intervaloBackend;
        veiculo.margemAlertaManutencao = this.margemBackend;
        veiculo.hodometroProximoAlertaManutencao = veiculo.intervaloManutencao === 0 ? 0 : this.buildProximoAlertaManutencao(
          Number(hodometro *= KM_EM_METROS),
          this.intervaloBackend,
          this.margemBackend
        );
      }

      if (this.intervaloHorimetroBackend || this.intervaloHorimetroBackend === 0) {
        veiculo.intervaloManutencaoHorimetro = this.intervaloHorimetroBackend;
        veiculo.margemAlertaManutencaoHorimetro = this.margemHorimetroBackend || 0;
        veiculo.horimetroProximoAlertaManutencao = veiculo.intervaloManutencaoHorimetro === 0 ? 0 : this.buildProximoAlertaManutencao(
          Number(horimetro *= HORA_EM_SEGUNDOS),
          this.intervaloHorimetroBackend,
          this.margemHorimetroBackend
        );
      }

      return veiculo;
    }

    buildListaVeiculos() {
      this.intervaloBackend = angular.copy(this.intervalo) * KM_EM_METROS;
      this.margemBackend = angular.copy(this.margem) * KM_EM_METROS;
      this.intervaloHorimetroBackend = angular.copy(this.intervaloHorimetro) * HORA_EM_SEGUNDOS;
      this.margemHorimetroBackend = angular.copy(this.margemHorimetro) * HORA_EM_SEGUNDOS;

      if (angular.isArray(this.veiculos)) {
        return this.veiculos.map(x => this.buildVeiculo(x.id, x.hodometro, x.horimetro));
      }
      return [
        this.buildVeiculo(
          this.veiculos.id,
          this.veiculos.hodometro,
          this.veiculos.horimetro
        )
      ];
    }

    ok() {
      this.restangular.all('veiculo/manutencoes-programadas/batch').post(this.buildListaVeiculos())
        .then((newValue) => {
          this.modalInstance.close(newValue.plain().map(v => {
            v.intervaloManutencao /= KM_EM_METROS;
            v.margemAlertaManutencao = v.margemAlertaManutencao ?
              v.margemAlertaManutencao / KM_EM_METROS :
              null;

            v.intervaloManutencaoHorimetro /= HORA_EM_SEGUNDOS;
            v.margemAlertaManutencaoHorimetro = v.margemAlertaManutencaoHorimetro ?
              v.margemAlertaManutencaoHorimetro / HORA_EM_SEGUNDOS :
              null;
            return v;
          }));
        })
        .catch((e) => {
          if (e.status === 406) {
            this.alertMessage.create({
              type: 'error',
              message: e.data.erro,
              appendTo: '.alerta-erro'
            });
          } else {
            this.modalInstance.dismiss({msg: this.translate.instant('ce.cadastro.veiculos.modalManutencao.erroConfigurar')});
          }
        });
    }

    delete() {
      this.restangular.all('veiculo/manutencoes-programadas/batch').post(this.buildListaVeiculos())
        .then(() => {
          const value = {veiculoId: this.veiculos.id},
              manutencaoProgramadaDoVeiculo = this.veiculos.manutencaoProgramada;

          if (this.tipoRemocao === 'horimetro') {
            value.intervaloManutencao = manutencaoProgramadaDoVeiculo.intervaloManutencao;
            value.margemAlertaManutencao = manutencaoProgramadaDoVeiculo.margemAlertaManutencao;
            value.hodometroProximoAlertaManutencao = manutencaoProgramadaDoVeiculo.hodometroProximoAlertaManutencao;
          } else {
            value.intervaloManutencaoHorimetro = manutencaoProgramadaDoVeiculo.intervaloManutencaoHorimetro;
            value.margemAlertaManutencaoHorimetro = manutencaoProgramadaDoVeiculo.margemAlertaManutencaoHorimetro;
            value.horimetroProximoAlertaManutencao = manutencaoProgramadaDoVeiculo.horimetroProximoAlertaManutencao;
          }

          this.modalInstance.close([value]);
        })
        .catch(() => {
          this.modalInstance.dismiss({msg: this.translate.instant('ce.cadastro.veiculos.modalManutencao.erroRemover')});
        }
      );
    }

    podeSalvar() {
      return Boolean(this.intervalo || this.intervaloHorimetro);
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('cadastros.veiculo')
    .controller('AlertaManutencaoCtrl', AlertaManutencaoCtrl);
}());
