(function () {
  'use strict';

  class MapaCtrl {
    constructor(latitude, longitude, posicao, MapasUtil, $modalInstance) {
      this.posicao = posicao;
      this.tiles = MapasUtil.tiles;
      this.modalInstance = $modalInstance;
      this.center = {
        lat: latitude,
        lng: longitude,
        zoom: 15
      };
      this.markers = {
        mainMarker: {
          lat: latitude,
          lng: longitude,
          focus: true,
          draggable: false
        }
      };
    }

    closeModal() {
      this.modalInstance.dismiss('cancel');
    }
  }

  angular
    .module('mapas')
    .controller('MapasCtrl', MapaCtrl);
}());
