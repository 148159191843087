(function () {
  'use strict';

  function TurnosEventos() {
    return {
      templateUrl: `configuracoes/eventos/subpaginas/turnos/turnos-eventos.tpl.html`,
      controller: 'TurnosEventosCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {}
    };
  }

  angular
    .module('SubpaginasEventos')
    .directive('turnosEventos', TurnosEventos);
}());
