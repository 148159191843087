(function () {
  'use strict';

  const getManyAdvisorResult = [
      {
        velocidades: {
          caminhao: [
              {angulo: 35, velocidade: 65}
          ],
          onibus: [
              {angulo: 35, velocidade: 50}
          ]
        },
        uuid: 'a80f20d4-c548-4287-96ac-f20d338698cb',
        velocidadeInformativa: {caminhao: 50, onibus: 40},
        velocidadeInterna: {caminhao: 50, onibus: 40},
        uoId: 151,
        lat: -23.2688573,
        lng: -51.1751573,
        raio: 300,
        eventos: {curva: 10, freada: 20},
        status: 'pendente',
        logradouro: 'Rua Joao Cunha, Londrina, Paraná'
      },
      {
        velocidades: {
          caminhao: [
              {angulo: 35, velocidade: 65}
          ],
          onibus: [
              {angulo: 35, velocidade: 50}
          ]
        },
        uuid: 'abd01840-36b9-44dd-9bef-d6e3d50844bd',
        velocidadeInformativa: {caminhao: 40, onibus: 30},
        velocidadeInterna: {caminhao: 40, onibus: 30},
        uoId: 151,
        lat: 52.5,
        lng: 66.7,
        raio: 300,
        eventos: {curva: 98, freada: 47},
        status: 'pendente',
        logradouro: 'Rua Jose Alves, Ibiporã, Paraná'
      },
      {
        velocidades: {
          caminhao: [
              {angulo: 35, velocidade: 65}
          ],
          onibus: [
              {angulo: 35, velocidade: 50}
          ]
        },
        uuid: 'abd01840-36b9-44dd-9bef-d6e3d50844bd',
        velocidadeInformativa: {caminhao: 40, onibus: 30},
        velocidadeInterna: {caminhao: 40, onibus: 30},
        uoId: 151,
        lat: 52.5,
        lng: 66.7,
        raio: 300,
        eventos: {curva: 98, freada: 47},
        status: 'pendente',
        logradouro: 'Rua Jose Alves, Ibiporã, Paraná'
      },
      {
        velocidades: {
          caminhao: [
              {angulo: 35, velocidade: 65}
          ],
          onibus: [
              {angulo: 35, velocidade: 50}
          ]
        },
        uuid: 'abd01840-36b9-44dd-9bef-d6e3d50844bd',
        velocidadeInformativa: {caminhao: 40, onibus: 30},
        velocidadeInterna: {caminhao: 40, onibus: 30},
        uoId: 151,
        lat: 52.5,
        lng: 66.7,
        raio: 300,
        eventos: {curva: 98, freada: 47},
        status: 'pendente',
        logradouro: 'Rua Jose Alves, Ibiporã, Paraná'
      }
  ];

  angular
    .module('enumModule')
    .constant('MockGetManyAdvisors', getManyAdvisorResult);
}());
