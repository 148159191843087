(function () {
  'use strict';
  class MenuAdicionarCercaEletronicaCtrl {
    constructor($timeout, PontosReferenciaMenu, PontosReferencia, CercaEletronicaService, CercaEletronicaMapService) {
      this.timeout = $timeout;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.cercaEletronicaService = CercaEletronicaService;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.adicionarNovaCercaEletronica();
    }

    adicionarNovaCercaEletronica() {
      let buttonsPrincipal = {
            active: 'trimble-control-area',
            resting: 'trimble-control-area'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.cercaEletronicaMapService.cancelarDesenhoCerca.bind(this.cercaEletronicaMapService)
              }
          ];
      this.pontosReferenciaMenuService.menuOpen();
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.cercaEletronicaMapService.iniciaDesenhoCercaEletronica();
    }
  }

  angular
    .module('mapControlModule')
    .component('menuAdicionarCercaEletronica', {
      controller: MenuAdicionarCercaEletronicaCtrl,
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controllerAs: 'ctrl'
    });
}());
