/* global document */
/* eslint-disable angular/log */
(function () {
  'use strict';

  class DocumentosCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, Keys, moment, $stateParams) {
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.moment = moment;
      this.podeEditar = $stateParams.podeEditar;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          name: 'motorista_id',
          key: this.keys.motoristas.name,
          size: 6
        },
        {
          name: 'documentos',
          key: this.keys.documentos.name,
          size: 6
        }
      ]))
        .then((filtro) => this.init(filtro));
    }

    init(filtro) {
      /*eslint-disable */
      const tableDocumentosElement = document.querySelector('#table-documentos'),
        documentosListElement = document.createElement('vfwc-documentos-list'),
        motoristasFiltered = filtro.find(f => f.key === 'motoristas'),
        motoristaIds = motoristasFiltered ? motoristasFiltered.values.map(motorista => motorista.id) : motoristasFiltered,
        tipoFiltered = filtro.find(f => f.key === 'documentos'),
        tipoDocumento = tipoFiltered ? tipoFiltered.value.id : tipoFiltered;

      documentosListElement.uoId = Number(filtro.find(f => f.key === 'uo').value.id);
      documentosListElement.tipoDocumento = tipoDocumento;
      documentosListElement.motoristasIds = motoristaIds;
      documentosListElement.temPermissaoEditar = this.podeEditar;

      tableDocumentosElement.appendChild(documentosListElement);
    }
  }

  angular
    .module('cadastros.motorista.documentos')
    .controller('DocumentosCtrl', DocumentosCtrl);
}());