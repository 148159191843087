(function () {
  'use strict';

  class BuscaRegiaoService {
    constructor(HttpServiceV3g, MockGetListBuscaRegiao, $q) {
      this.httpService = HttpServiceV3g;
      this.mockGetListBuscaRegiao = MockGetListBuscaRegiao;
      this.q = $q;
      this.isDemo = false;
    }

    getList(latlngCentro, params) {
      if (this.isDemo) {
        return this.q(resolve => resolve(this.mockGetListBuscaRegiao));
      }
      let url = this.getUrlRegiao(latlngCentro, params);

      return this.httpService.get(url);
    }

    getUrlRegiao(latlngCentro, params) {
      return `/sensor/regiao/${Number.parseFloat(latlngCentro.lat)}/${Number.parseFloat(latlngCentro.lng)}?${params}`;
    }
  }

  angular
    .module('mapControlModule')
    .service('BuscaRegiaoService', BuscaRegiaoService);
}());
