(function () {
  'use strict';

  /* global Highcharts */
  class DrilldownChart {
    constructor(yAxis, CommonChart, $timeout, $rootScope, $translate) {
      this.commonChartService = CommonChart;
      this.timeout = $timeout;
      this.scope = $rootScope;
      this.translate = $translate;
      this.title = this.commonChartService.getTitle();
      this.subtitle = this.commonChartService.getSubtitle();
      this.options = this.getOptions();
      this.series = null;
      this.yAxis = {
        title: {
          text: this.translate.instant('ce.tempoReal.statusFrota.charts.common.quantidadeDeEventos')
        }
      };
      this.xAxis = {
        type: 'category',
        categories: [],
        plotBands: []
      };

      this.animation = false;
      this.erroSemData = true;
      this.isDrillDown = false;
      Highcharts.Tick.prototype.drillable = function () {};
    }

    getOptions() {
      return {
        chart: {
          type: 'column',
          events: {
            load: () => {
              this.timeout(() => this.scope.$broadcast('highchartsng.reflow'), 100);
            },
            drilldown: (e) => {
              e.target.xAxis[0].categories = e.seriesOptions.data.map(x => x.name);
              e.target.xAxis[0].removePlotBand('turno-atual');
              this.isDrillDown = true;
            },
            drillup: (e) => {
              e.target.xAxis[0].categories = e.target.options.xAxis[0].categories;
              e.target.xAxis[0].addPlotBand(this.plotOptions);
              this.isDrillDown = false;
            }
          }
        },
        lang: {
          drillUpText: `< ${this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.voltar')}`
        },
        plotOptions: {
          series: {
            point: {events: {}},
            dataLabels: {
              enabled: false
            },
            stacking: 'normal',
            events: {
              legendItemClick: this.commonChartService.filterAllSeries
            }
          }
        },
        credits: {enabled: false},
        legend: {
          enabled: true,
          itemStyle: {
            font: '9pt "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: 'black'
          }
        },
        drilldown: {
          drillUpButton: {
            relativeTo: 'spacingBox',
            position: {
              y: 0,
              x: -30
            }
          },
          series: []
        }
      };
    }

    setAnimation() {
      if (this.animation) {
        this.options.plotOptions.series.animation = false;
      } else {
        this.options.plotOptions.series.animation = true;
        this.animation = true;
      }
    }

    setData(data) {
      this.xAxis.categories = [];
      this.xAxis.plotBands = [];
      this.series = [];
      this.options.drilldown.series = [];

      if (data && data.series.length > 0) {
        this.erroSemData = false;

        for (let i = 1; i <= data.series[0].data.length; i++) {
          if (data.turnoAtual === i) {
            let from = angular.copy(i),
                to = angular.copy(i);
            this.xAxis.categories.push(
              `<strong>
                ${this.translate.instant('ce.tempoReal.statusFrota.charts.common.resolvidosTotal')}
              </strong>
              <br />
              <strong>
                ${this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.turno')} ${i}
              </strong>`
            );
            this.plotOptions = {
              color: '#f0f0f0',
              from: from - 1.5,
              to: to - 0.5,
              id: 'turno-atual'
            };
            this.xAxis.plotBands.push(this.plotOptions);
          } else {
            this.xAxis.categories.push(
              `${this.translate.instant('ce.tempoReal.statusFrota.charts.common.resolvidosTotal')}
              <br />
              ${this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.turno')} ${i}`
            );
          }
        }
        this.series = data.series;
        this.options.drilldown.series = data.drilldown;

        this.setAnimation();
      } else {
        this.erroSemData = true;
      }
    }

    setClickEvent(onClickColumn) {
      this.options.plotOptions.series.point.events.click = onClickColumn;
      this.options.plotOptions.series.events.click = () => {};
    }
  }

  angular
    .module('chartModule')
    .service('DrilldownChart', (CommonChart, $timeout, $rootScope, $translate) => ({get: (yAxis) => new DrilldownChart(yAxis, CommonChart, $timeout, $rootScope, $translate)}));
}());
