(function () {
  'use strict';
  /* global _, L */
  class MapaCalorCtrl {
    constructor($stateParams, $window, MapasUtil, Restangular, moment, AlertMessage,
      ServiceVfiltro, FactoryVfiltro, Keys, leafletData, $scope, CONFIG, Authenticator,
      AlertasEnum, $translate) {
      this.stateParams = $stateParams;
      this.window = $window;
      this.scope = $scope;
      this.mapasUtil = MapasUtil;
      this.restangular = Restangular;
      this.moment = moment;
      this.alertMessage = AlertMessage;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.leafletData = leafletData;
      this.factoryVfiltro = FactoryVfiltro;
      this.authenticator = Authenticator;
      this.alertasEnum = AlertasEnum;
      this.translate = $translate;

      this.whichClicked = 'ABSOLUTO';
      this.DBCLICK_ZOOM = 15;
      this.MIN_LONGITUDE = -34;
      this.MIN_ZOOM_FOR_CIRCLES = 12;
      this.VISIBLE_REGIONS = null;
      this.COLORS = ['#07f', '#fa0', '#f02'];
      this.CONFIG = CONFIG;
      this.regionsArray = [];
      this.ranking = [];
      this.unidadeMedida = null;

      this.mainMarker = null;
      this.map = null;
      this.heatLayerGroup = L.featureGroup();
      this.heatMapContent = [];

      this.mesFocus = 0;
      this.listaMes = [];
      this.constDia = '-01';

      this.leafletData.unresolveMap('leafletCalor');
      this.setMapConfig();
      this.leafletData.getMap('leafletCalor').then(map => {
        this.map = map;
        this.baseLayer = L.tileLayer.colorFilter(this.mapasUtil.tiles.veltec.url, {filter: [], subdomains: this.mapasUtil.tiles.veltec.options.subdomains});
        this.map.addLayer(this.baseLayer);
        L.control.scale().addTo(this.map);
        this.mainMarker = L.marker([31.5965265, 130.5569595], {
          icon: L.divIcon({
            html: this.mapasUtil.getIconPosicao(),
            iconSize: [0, 0],
            shadowSize: [14, 20],
            shadowAnchor: [4, 19],
            iconAnchor: [15, 40],
            popupAnchor: [1, -35]
          })
        }).addTo(this.map);

        this.authenticator.getUser().then(u => {
          this.initFiltro(u);
        });
      });

      this.scope.$on('$destroy', () => {
        this.leafletData.unresolveMap('leafletCalor');
      });
    }

    getTiposAlertaHeatmap() {
      let tiposHeatmap = Object.keys(this.CONFIG);
      const tiposAlertas = this.alertasEnum.getTipos()
        .filter(tipoAlerta => _.include(tiposHeatmap, tipoAlerta.id))
        .map(tipoAlerta => {
          return {id: tipoAlerta.id, label: this.translate.instant(tipoAlerta.descricao)};
        });
      tiposAlertas.push({id: 'TREPIDACAO', label: this.translate.instant('ce.enum.common.trepidacao')});
      return tiposAlertas;
    }

    initFiltro(usuario) {
      this.serviceVfiltro.init(this.factoryVfiltro.get([
        {
          key: this.keys.uo.name,
          disabled: true,
          fixedId: usuario.uo.id,
          type: 'filtro-fixed',
          info: this.translate.instant('ce.mapa.mapaDeCalor.naoEhpermitidoTrocarUo')
        },
        {
          key: this.keys.tipo.name,
          required: true,
          hasNivel: false,
          defaultValues: this.getTiposAlertaHeatmap()
        }
      ]))
      .then(vfiltro => {
        this.tipoEvento = vfiltro.find(x => x.key === this.keys.tipo.name).value.id;
        this.unidadeMedida = this.CONFIG[this.tipoEvento].UNIDADE;
        this.carregaMeses(this.stateParams.mes, this.serviceVfiltro.factoryVfiltro.user);
      }).catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
        this.leafletData.unresolveMap('leafletCalor');
      });
    }

    carregaMeses(mesState, user) {
      this.user = user;
      this.restangular.one(`/analisedados/mapa-de-calor/${this.CONFIG[this.tipoEvento].ID}/${this.user.uo.id}`).get()
      .then(data => {
        if (data.meses.length > 0) {
          this.listaMes = data.meses;
          if (mesState && data.meses.filter(x => x === mesState)) {
            this.mesFocus = data.meses.filter(x => x === mesState)[0];
          } else {
            this.mesFocus = data.meses[data.meses.length - 1];
          }
          return this.carregaCards();
        }
        this.alertMessage.create({
          type: 'warning',
          message: this.translate.instant('ce.mapa.mapaDeCalor.dadosInexistentesParaUo'),
          appendTo: '.alerta-aqui'
        });
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.mapaDeCalor.erroAoCarregarMapaDeCalor'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    carregaCards() {
      return this.restangular.one(`/analisedados/mapa-de-calor/${this.CONFIG[this.tipoEvento].ID}/${this.user.uo.id}/${this.mesFocus}`).get()
      .then(data => {
        let finalData = this.dataProcessing(data);
        if (finalData.length > 0) {
          this.heatMapContent = finalData;
          this.createCircles();
          this.paintCircles();
          this.updateMapOnMove();
          this.updateHeatmap();
          this.map.on('moveend', this.updateMapOnMove.bind(this));
          this.map.on('click', () => this.mainMarker.setLatLng([31.5965265, 130.5569595]));
        }
      }).catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.mapaDeCalor.erroAoCarregarMapaDeCalor'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    dataProcessing(data) {
      let classifier = (value, flagValue) => {
        let cls = 2;
        if (value < this.CONFIG[this.tipoEvento][flagValue][1]) {
          cls = 1;
        }
        if (value < this.CONFIG[this.tipoEvento][flagValue][0]) {
          cls = 0;
        }
        return cls;
      };

      return data.map(cluster => {
        let mainValue = {PERCENTUAL: [], ABSOLUTO: []},
            granularClass = {PERCENTUAL: [], ABSOLUTO: []};

        for (let i = 0; i < cluster.vei.length; i++) {
          let percentual;
          if (cluster.lim[i] > 0) {
            percentual = Math.round(cluster.exc[i] * 100 / cluster.lim[i] - 100);
          } else {
            percentual = 0;
          }

          mainValue.PERCENTUAL.push(percentual);
          // Arredonda para no máximo 2 casas no valor absoluto.
          mainValue.ABSOLUTO.push(Math.round(cluster.exc[i] * 100) / 100);
          granularClass.PERCENTUAL.push(classifier(percentual, 'PERCENTUAL'));
          granularClass.ABSOLUTO.push(classifier(cluster.exc[i], 'ABSOLUTO'));
        }

        cluster.mainValue = mainValue;
        cluster.granularClass = granularClass;
        cluster.regionClass = {
          PERCENTUAL: classifier(Math.max(...cluster.mainValue.PERCENTUAL), 'PERCENTUAL'),
          ABSOLUTO: classifier(Math.max(...cluster.mainValue.ABSOLUTO), 'ABSOLUTO')
        };
        return cluster;
      }).filter(cluster => cluster.lon < this.MIN_LONGITUDE);
    }

    updateHeatmap() {
      if (this.map.hasLayer(this.heatLayerGroup)) {
        this.map.removeLayer(this.heatLayerGroup);
        this.heatLayerGroup = L.featureGroup();
      }

      let heats = [
        // blues
        {grad: {0.6: '#001AFF', 0.8: '#00B3FF', 1: '#AAFFFF'}, data: []},
        // oranges
        {grad: {0.2: '#ff4400', 0.6: '#FF8800', 1: '#FFFF00'}, data: []},
        // reds
        {grad: {0.2: '#FF0000', 0.9: '#FF0011', 1: '#FF3355'}, data: []}
      ];

      this.heatMapContent.forEach(reg => {
        heats[reg.regionClass[this.whichClicked]].data.push([
          reg.lat[0],
          reg.lon[0],
          1
        ]);
      });

      heats.forEach(heat => {
        this.heatLayerGroup.addLayer(
          L.heatLayer(heat.data, {
            radius: 3,
            blur: 3,
            maxZoom: 0,
            zIndex: 2,
            gradient: heat.grad
          }),
          {interactive: false}
        );
      });

      this.map.addLayer(this.heatLayerGroup);
    }

    updateMapOnMove() {
      this.updateVisibleRegions();
      this.showCirclesInsideBounds();
      this.updateCards();
      this.freeVisibleRegions();
    }

    updateVisibleRegions() {
      let mapBounds = this.map.getBounds();
      this.VISIBLE_REGIONS = this.regionsArray.filter(
        reg => mapBounds.contains(reg.getLatLng())
      );
    }

    freeVisibleRegions() {
      this.VISIBLE_REGIONS = null;
    }

    showCirclesInsideBounds() {
      if (this.map.getZoom() > this.MIN_ZOOM_FOR_CIRCLES) {
        this.VISIBLE_REGIONS.forEach(e => e.addTo(this.map));
      } else {
        this.VISIBLE_REGIONS.forEach(e => this.map.removeLayer(e));
      }
    }

    updateCards() {
      let newRanking = [];
      this.VISIBLE_REGIONS.forEach(e => {
        let incident = e.options.data;
        for (let i = 0; i < incident.vei.length; i++) {
          newRanking.push({
            lat: incident.lat[0],
            lon: incident.lon[0],
            dtt: incident.dtt[i],
            exc: incident.exc[i],
            lim: incident.lim[i],
            pfx: incident.pfx[i],
            plc: incident.plc[i],
            vei: incident.vei[i],
            mainValue: {
              PERCENTUAL: incident.mainValue.PERCENTUAL[i],
              ABSOLUTO: incident.mainValue.ABSOLUTO[i]
            },
            granularClass: {
              PERCENTUAL: incident.granularClass.PERCENTUAL[i],
              ABSOLUTO: incident.granularClass.ABSOLUTO[i]
            }
          });
        }
      });

      this.ranking = newRanking
        .sort(this.sortCards(this.whichClicked))
        .slice(0, 10);
    }

    setMapConfig() {
      this.defaults = {
        zoomControlPosition: 'topleft',
        minZoom: 3
      };
      this.center = this.mapasUtil.getCenter();
    }

    toggleValueFlag(newFlagValue) {
      if (newFlagValue !== this.whichClicked) {
        this.whichClicked = newFlagValue;

        this.paintCircles();
        this.updateHeatmap();
        this.updateMapOnMove();
      }
    }

    getMesExtenso(mes) {
      return this.moment(mes + this.constDia).locale(this.translate.use()).format('MMM');
    }

    sortCards(type) {
      return (a, b) => {
        if (a.mainValue[type] < b.mainValue[type]) {
          return 1;
        } else if (a.mainValue[type] > b.mainValue[type]) {
          return -1;
        }
        return 0;
      };
    }

    refresh(anoMes) {
      const filtroAtual = this.serviceVfiltro.filtroTopbar.map(filtro => {
        return `${filtro.key}:${filtro.value.id}`;
      }).join(';');
      this.window.location = `/mapa-de-calor/${anoMes}?vfiltro=${filtroAtual}`;
    }

    getVeiculoDescricao(item) {
      return `${item.pfx ? item.pfx + ' - ' : ''}${item.plc}`;
    }

    zoom() {
      this.map.setZoom(this.DBCLICK_ZOOM, {animate: false});
    }

    pan(item) {
      this.map.panTo([item.lat, item.lon]);
      this.mainMarker.setLatLng([item.lat, item.lon]);
    }

    getStyleCardContainer(item, tipo) {
      return {
        color: this.COLORS[item.granularClass[tipo]]
      };
    }

    getStyleLine(item) {
      let maxValue = this.CONFIG[this.tipoEvento].ABSOLUTO[2],
          lineSize = this.whichClicked === 'ABSOLUTO' ? item.mainValue.ABSOLUTO / maxValue * 100 : item.mainValue.PERCENTUAL;
      return {
        'border-bottom': `2px solid ${this.COLORS[item.granularClass[this.whichClicked]]}`,
        width: `${lineSize}%`,
        'min-width': '3%',
        'max-width': '100%'
      };
    }

    createCircles() {
      this.heatMapContent.forEach(region => {
        let circleStyle = {
          color: '#000',
          weight: 1,
          opacity: 0.9,
          fillOpacity: 0.6,
          data: region
        };

        this.regionsArray.push(L.circle(
          L.latLng(
            Number(region.lat),
            Number(region.lon)
          ),
          60,
          circleStyle
        ).on('click', this.regionClick.bind(this)));
      });
    }

    regionClick(event) {
      this.window.location = this.getLinkHistoricoDetalhado(event.target.options.data);
    }

    getLinkHistoricoDetalhado(item) {
      let dataInfrator = this.moment(item.dtt, 'YYYY-MM-DD HH:mm:ss.ZZ'),
          eventoIsoDate = new Date(item.dtt).toISOString(),
          dataInicio = this.moment(dataInfrator).startOf('day').format('YYYY.MM.DD.HH.mm.ss.ZZ'),
          dataFim = dataInfrator.format('YYYY.MM.DD.HH.mm.ss.ZZ');

      return `/historico-timeline?vfiltro=dateTime:${dataInicio},${dataFim};uo:${this.user.uo.id};veiculo:${item.vei}&date=${eventoIsoDate}&ultimo=true`;
    }

    paintCircles() {
      this.regionsArray.forEach(region => {
        region.setStyle({
          color: this.COLORS[region.options.data.regionClass[this.whichClicked]]
        });
      });
    }

    getUnidadeMedida() {
      return this.unidadeMedida;
    }

    onLayerSwitch(layerWrapper) {
      const newBaseLayer = layerWrapper.getLayerObject();
      this.map.addLayer(newBaseLayer);
      this.map.removeLayer(this.baseLayer);
      this.baseLayer = newBaseLayer;
    }
  }

  /**
   * @ngdoc object
   * @name mapaCalor.controller:MapaCalorCtrl
   *
   * @description
   *
   */
  angular
    .module('mapaCalor')
    .controller('MapaCalorCtrl', MapaCalorCtrl)
    .constant('CONFIG', {
      EXCESSO_VELOCIDADE: {
        ID: 'SPEED_LIMIT_V3',
        ABSOLUTO: [60, 90, 120],
        UNIDADE: 'km/h',
        PERCENTUAL: [33, 66]
      },
      EXCESSO_VELOCIDADE_POR_VIA: {
        ID: 'SPEED_LIMIT_VIA',
        ABSOLUTO: [60, 90, 120],
        UNIDADE: 'km/h',
        PERCENTUAL: [33, 66]
      },
      EXCESSO_RPM: {
        ID: 'EXCESSO_RPM',
        ABSOLUTO: [2500, 3000, 4500],
        UNIDADE: 'RPM',
        PERCENTUAL: [33, 66]
      },
      CURVA_BRUSCA: {
        ID: 'CURVA_BRUSCA',
        ABSOLUTO: [0.8, 1.2, 1.5],
        UNIDADE: 'G',
        PERCENTUAL: [33, 66]
      },
      TREPIDACAO: {
        ID: 'TREPIDACAO',
        ABSOLUTO: [1, 2, 3],
        UNIDADE: 'G',
        PERCENTUAL: []
      },
      ACELERACAO_BRUSCA: {
        ID: 'ACELERACAO_BRUSCA',
        ABSOLUTO: [20, 40, 50],
        UNIDADE: 'km/h/s',
        PERCENTUAL: [33, 66]
      },
      FREADA_BRUSCA: {
        ID: 'FREADA_BRUSCA',
        ABSOLUTO: [20, 40, 50],
        UNIDADE: 'km/h/s',
        PERCENTUAL: [33, 66]
      },
      BANGUELA: {
        ID: 'BANGUELA',
        ABSOLUTO: [15, 17, 120],
        UNIDADE: 's',
        PERCENTUAL: [0, 0]
      },
      POSSIVEL_COLISAO: {
        ID: 'POSSIVEL_COLISAO',
        ABSOLUTO: [40, 90, 120],
        UNIDADE: 'km/h',
        PERCENTUAL: [0, 0]
      },
      FADIGA_MOTORISTA: {
        ID: 'FADIGA',
        ABSOLUTO: [40, 90, 120],
        UNIDADE: 'km/h',
        PERCENTUAL: [0, 0]
      }
    });
}());
