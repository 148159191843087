(function () {
  'use strict';

  class GrupoVeicCtrl {
    constructor(GrupoVeic, $httpParamSerializer, ServiceVfiltro, ObjetosVfiltroService, ModalInfoConfirmService,
      FactoryVfiltro, Keys, VtabelaFactory, $uibModal, Restangular, AlertMessage, TipoPermissaoEnum, HttpServiceV3g, $sanitize) {
      this.$sanitize = $sanitize;
      this.grupoVeicService = GrupoVeic;
      this.serviceVfiltro = ServiceVfiltro;
      this.tipoPermissaoEnum = TipoPermissaoEnum;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.httpParamSerializer = $httpParamSerializer;
      this.modalService = $uibModal;
      this.httpService = HttpServiceV3g;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.modalInfoConfirmService = ModalInfoConfirmService;

      this.grupos = [];
      this.vtabela = null;
      this.FIELDS_GRUPO_VEIC = [
        'id',
        'nome',
        'observacoes',
        'quantidadeVeiculosTotal',
        'tipoPermissao',
        'uo.id',
        'uo.nome'
      ];

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.user = this.serviceVfiltro.factoryVfiltro.user;
        this.hasRoleEditarGrupo = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('GRUPO_VEICULO_EDITAR');
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'grupoVeic', 'v1');
        this.consultar();
      });
    }

    initVtabela() {
      let objVtabela = {
        appScopeProvider: this,
        enableRowSelection: false,
        enableGridMenu: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        enableSelectAll: false,
        exportsCsv: false,
        columnDefs: [
            {
              name: 'nome',
              displayName: 'ce.cadastro.grupoDeVeiculos.colunas.nome',
              type: 'string',
              pinnedLeft: true,
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'observacoes',
              displayName: 'ce.cadastro.grupoDeVeiculos.colunas.observacoes',
              enableHiding: false,
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\''
            },
            {
              name: 'veicTotal',
              displayName: 'ce.cadastro.grupoDeVeiculos.colunas.veicTotal',
              type: 'number',
              cellClass: 'text-center',
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'uoNome',
              displayName: 'ce.cadastro.grupoDeVeiculos.colunas.uoNome',
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'editar',
              displayName: '',
              enableFiltering: false,
              enableSorting: false,
              pinnedRight: true,
              enableColumnResizing: false,
              enableHiding: false,
              exporterSuppressExport: true,
              width: 70,
              visible: this.hasRoleEditarGrupo,
              cellTemplate: `
                <div class="ui-grid-cell-contents vtabela-icone-detalhado">
                  <a class='grupo-editar'
                  ng-if='grid.appScope.hasRoleEditarGrupo &&
                  grid.appScope.tipoPermissaoEnum.podeEditar(row.entity.item.tipoPermissao)'
                  ng-click='grid.appScope.modalForm(row.entity.item)'>
                    <span class="fa fa-pencil" aria-hidden='true'></span>
                  </a>
                </div>`
            }
        ]
      };
      return objVtabela;
    }

    modalForm(row) {
      this.modalService.open({
        animation: true,
        templateUrl: 'cadastros/grupo-veic/grupo-veic-formmodal/grupo-veic-formmodal.tpl.html',
        controller: 'GrupoVeicFormCtrl',
        controllerAs: '$ctrl',
        size: 'custom-size',
        resolve: {
          grupoRow: () => row
        }
      }).result
      .then(data => {
        const sanitizedMessage = this.$sanitize(data.message);
        this.alertMessage.create({
          type: 'success',
          message: sanitizedMessage,
          appendTo: '.alerta-aqui'
        });

        if (data.idDeleted) {
          this.removeGrupoVtabela(data.idDeleted);
        } else if (angular.isDefined(data.grupoSubmit)) {
          this.updateGridRow(data.grupoSubmit, row);
        } else {
          this.consultar();
        }
      });
    }

    removeGrupoVtabela(grupoId) {
      this.grupos = this.grupos.filter(grupo => grupo.id !== grupoId);
      this.setInfoTable();
    }

    updateGridRow(grupoEdited, row) {
      let grupoUpdate = this.grupos.find(grupo => grupo.id === row.id);
      grupoUpdate.nome = grupoEdited.nome;
      grupoUpdate.observacoes = grupoEdited.observacoes;
      this.setInfoTable();
    }

    getItemVtabela(item) {
      return {
        id: item.id,
        nome: item.nome,
        observacoes: item.observacoes,
        tipoPermissao: item.tipoPermissao,
        veicTotal: this.undefinedToZero(item.quantidadeVeiculosTotal),
        uoNome: item.uo && item.uo.nome,
        link: this.linkCadastroVeiculoPorGrupo(item.id),
        item: item
      };
    }

    undefinedToZero(value) {
      return angular.isUndefined(value) ? 0 : value;
    }

    linkCadastroVeiculoPorGrupo(idGrupo) {
      return `/veiculo/vfiltro=grupo:${idGrupo}`;
    }

    consultar() {
      this.grupoVeicService.one()
        .get(this.getParams())
        .then(data => {
          this.grupos = data;
          this.setInfoTable();
        })
        .catch(() => this.vtabela.setError());
    }

    setInfoTable() {
      this.vtabela.setData(this.grupos.map(i => this.getItemVtabela(i)));
    }

    getParams() {
      let params = this.serviceVfiltro.getFiltroBackendNovo(this.filtro);
      params.fields = this.FIELDS_GRUPO_VEIC.join();
      return params;
    }
  }

  angular
  .module('cadastros.grupoVeic')
  .controller('GrupoVeicCtrl', GrupoVeicCtrl);
}());
