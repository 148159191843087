(function () {
  'use strict';

  angular
    .module('mapaCalor')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.mapaCalor', {
        url: '/mapa-de-calor/:mes?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'mapa-calor/mapa-calor.tpl.html',
            controller: 'MapaCalorCtrl',
            controllerAs: 'mapaCalor'
          }
        },
        data: {
          titulo: 'ce.mapa.mapaDeCalor.titulo',
          hideTitulo: true,
          role: 'MAPA_DE_CALOR_VISUALIZAR'
        }
      });
  }
}());
