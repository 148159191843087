(function () {
  'use strict';

  function SafedriveVdrivers() {
    return {
      templateUrl: `configuracoes/vdrivers/subpaginas/safedrive/safedrive-vdrivers.tpl.html`,
      controller(ConfigVdriversService) {
        this.service = ConfigVdriversService;
      },
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {}
    };
  }

  angular
    .module('SubpaginasVdrivers')
    .directive('safedriveVdrivers', SafedriveVdrivers);
}());
