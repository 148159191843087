(function () {
  'use strict';

  class SituacaoVeiculoCtrl {
    constructor($uibModalInstance, Restangular, veiculos, moment, $translate) {
      this.modalInstance = $uibModalInstance;
      this.restangular = Restangular;
      this.veiculos = veiculos;
      this.moment = moment;

      this.tomorrow = this.moment().startOf('day').add(1, 'days');
      this.situacoesComDataRetorno = [
        'Em manutenção',
        'Fora de operação',
        'Manutenção mecânica',
        'En manutención',
        'Fuera de operación',
        'Mantenimiento mecánico',
        'Under maintenance',
        'Out of service',
        'Mechanical maintenance'
      ];
      this.situacoesOption = [];
      this.situacaoSelected = null;
      this.translate = $translate;
      this.dataRetorno = null;
      this.dateOptions = {
        formatYear: 'yy',
        minDate: this.tomorrow,
        startingDay: 0,
        showWeeks: false
      };
      this.translate = $translate;

      this.init();
    }

    init() {
      this.restangular.all('veiculo/veiculos/situacoes').getList()
      .then((situacoes) => this.situacoesOption = situacoes.plain())
      .catch(() => this.modalInstance.dismiss({msg: this.translate.instant('ce.cadastro.veiculos.modalSituacao.falha')}));
    }

    registrar() {
      this.restangular.all('veiculo/veiculos/permitidos/situacao')
      .post(this.buildSituacoes())
      .then(() => {
        return this.modalInstance.close({situacao: this.situacaoSelected, dataRetorno: this.dataRetorno});
      })
      .catch((err) => this.modalInstance.dismiss({msg: err.msg}));
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    buildSituacoes() {
      return this.veiculos.map(veiculo => {
        return {
          veiculoId: veiculo.id,
          situacaoId: this.situacaoSelected.id,
          dataRetorno: this.dataRetorno ? this.moment(this.dataRetorno).format('YYYY-MM-DD').toString() : this.dataRetorno
        };
      });
    }

    getMsgError(inputScope) {
      if (angular.isDefined(inputScope)) {
        if (inputScope.$invalid && inputScope.$touched) {
          return this.translate.instant('ce.cadastro.veiculos.modalSituacao.dataObrigatoria');
        }
        if (this.dataRetorno && this.moment(this.dataRetorno).isBefore(this.tomorrow)) {
          return this.translate.instant('ce.cadastro.veiculos.modalSituacao.dataPeriodoPermitido');
        }
      }
      return null;
    }

    isSituacaoWithDataRetorno() {
      return this.situacaoSelected && this.situacaoSelected.nome ? this.situacoesComDataRetorno.includes(this.situacaoSelected.nome) : false;
    }

  }
  angular
    .module('cadastros.veiculo')
    .controller('SituacaoVeiculoCtrl', SituacaoVeiculoCtrl);
}());
