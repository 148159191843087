(function () {
  'use strict';

  class ExcluirCategoriaPontoReferencia {
    constructor($uibModalInstance, categoriaId, CategoriaPontosReferencia, AlertMessage, Restangular, $translate) {
      this.modalInstance = $uibModalInstance;
      this.model = {};
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.categoriaPontoReferenciaService = CategoriaPontosReferencia;
      this.categoriaId = categoriaId;
      this.translate = $translate;

      this.carregarCategoria();
    }

    carregarCategoria() {
      this.categoriaPontoReferenciaService.getCategoriaPontoReferencia(this.categoriaId)
      .then(data => {
        this.model = data.plain();
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.errorCarregarCategoria'),
          appendTo: '.alerta-aqui-categoria-ponto-excluir'
        });
      });
    }

    ok() {
      this.categoriaPontoReferenciaService.deleteCategoriaPontoReferencia(this.model.id)
      .then(() => {
        this.modalInstance.close();
      })
      .catch((e) => {
        this.alertMessage.create({
          type: 'error',
          message: angular.isDefined(e) && e.status === 409 ?
          this.translate.instant('ce.mapa.common.modal.excluirCategoriaPonto.errorCategoriaEmUso')
          : this.translate.instant('ce.mapa.common.modal.excluirCategoriaPonto.errorExcluirCategoria'),
          appendTo: '.alerta-aqui-categoria-ponto-excluir'
        });
      });
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('mapControlModule')
    .controller('ExcluirCategoriaPontoReferenciaCtrl', ExcluirCategoriaPontoReferencia);
}());
