(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.quadroOcorrencias', {
      url: '/tempo-real/ocorrencias?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'quadro-ocorrencias/quadro-ocorrencias.tpl.html',
          controller: 'QuadroOcorrenciasCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'ce.tempoReal.quadroDeOcorrencias.titulo',
        role: ['OCORRENCIA_VISUALIZAR']
      }
    });
  }

  angular
    .module('quadroOcorrencias')
    .config(pageConfig);
}());
