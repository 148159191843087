(function () {
  'use strict';

  class ModalSelectCtrl {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }
  }

  angular
    .module('mapasGrid')
    .controller('ModalSelectCtrl', ModalSelectCtrl);
}());
