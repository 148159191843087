(function () {
  'use strict';

  /* global _, L */
  class BdsDetalhesCtrl {
    constructor($stateParams, Bds, MapasUtil, $filter, $scope, leafletData, Eventos, VFlash,
      SpiderfierService, MarkerService, moment, ServiceVfiltro, FactoryVfiltro, $translate) {
      this.stateParams = $stateParams;
      this.bsService = Bds;
      this.mapasUtilService = MapasUtil;
      this.spiderfierService = SpiderfierService;
      this.markerService = MarkerService;
      this.filter = $filter;
      this.scope = $scope;
      this.moment = moment;
      this.todosSegmentos = false;
      this.distancia = 0;
      this.motoristas = [];
      this.veiculos = [];
      this.segmentos = [];
      this.segmentoSelecionado = null;
      this.leafletData = leafletData;
      this.eventos = Eventos;
      this.flash = VFlash;
      this.titulo = null;
      this.logradouroInicio = null;
      this.logradouroFim = null;
      this.indexSegmento = 0;
      this.duracao = 0;
      this.translate = $translate;

      this.tiles = MapasUtil.tiles.veltec;
      this.defaults = MapasUtil.defaults;
      this.geoJson = {};
      this.mapCenter = MapasUtil.getCenter();
      this.markersGroup = new L.FeatureGroup();
      this.pathGroup = new L.FeatureGroup();
      this.eventoSelecionado = '';

      this.onClickTipoEvento = this.filtraEvento();
      this.onClickPosicao = this.destacarMarcador();
      this.markersPorPosicao = new Map();

      this.setMapEvents();
      ServiceVfiltro.init(FactoryVfiltro.get([]))
      .then(() => {
        this.loadServerData();
      })
      .catch(() => {
        this.leafletData.unresolveMap('bds-map');
      });

      this.leafletData.getMap('bds-map').then(map =>
        this.scope.$broadcast('initPontoReferencia', map)
      );
    }

    filtraEvento() {
      return (index) => {
        this.eventoSelecionado = index;
        if (this.segmentos.length === 0) {
          this.flash.filterWarn();
        } else if (this.todosSegmentos) {
          this.selecionarTodosSegmentos();
        } else {
          this.limparVariaveisLayers();
          this.popularDadosDoSegmento(this.segmentoSelecionado);
          this.inserirSegmentoNoMapa(this.segmentoSelecionado);
        }
      };
    }

    destacarMarcador() {
      return (posicao) =>
        this.leafletData.getMap('bds-map')
          .then(map => {
            const marker = this.markersPorPosicao.get(posicao);
            map.panTo(marker.getLatLng());
            marker.openPopup(marker.getLatLng());
          });
    }

    loadServerData() {
      if (angular.isUndefined(this.stateParams.execucaoServico)) {
        return;
      }

      this.bsService.getDetalhes(this.stateParams.execucaoServico).then(data => {
        const movimento = this.translate.instant('ce.enum.eventos.movimento');
        this.segmentos = data.map(segmento => {
          // Icone parado 1 esta sobrecarregando. Removido até proximo sprint.
          segmento.posicoes.forEach(posicao => {
            if (posicao.evento === this.translate.instant(this.eventos.PARADO.tipo) && posicao.duracao <= 300) {
              posicao.evento = movimento;
            }
            if (posicao.evento === this.translate.instant('ce.enum.eventos.pontoDeReferencia')) {
              posicao.evento = movimento;
            }
          });

          segmento.posicoes = this.mapasUtilService.ajustarEventos(segmento.posicoes);
          this.mapasUtilService.marcarPosicoesInvalidasNoInicio(segmento.posicoes);

          segmento.inicio = this.moment(segmento.inicio);
          segmento.fim = this.moment(segmento.fim);
          segmento.duracao = segmento.fim.diff(segmento.inicio, 'seconds');
          segmento.horario = segmento.inicio.format('HH:mm:ss') + ' - ' + segmento.fim.format('HH:mm:ss');
          segmento.posicoes = segmento.posicoes;
          segmento.ativo = false;
          segmento.intervalos.forEach(intervalo => intervalo.ativo = false);
          return segmento;
        });

        if (this.segmentos.length === 0) {
          this.flash.filterWarn();
        } else {
          this.selecionarTodosSegmentos();
        }
      });
    }

    selecionarTodosSegmentos() {
      this.limparVariaveisLayers();
      this.resumoPeriodo = this.segmentos;
      const primeiroSegmento = this.segmentos[0], ultimoSegmento = this.segmentos[this.segmentos.length - 1];
      this.segmentos.forEach(segmento => {
        this.distancia += segmento.distancia;
        this.titulo = segmento.servico.nome + ' - ' + this.moment(segmento.inicioPrevisto).format('DD/MM/YYYY');
        this.motoristas.push(segmento.motorista);
        this.veiculos.push(segmento.veiculo);
        segmento.ativo = false;
        let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(segmento.posicoes), 'data'),
            posicoesValidas = posicoes.filter(p => p.valida);
        this.setPath(posicoesValidas);
        this.setPathEventos(posicoesValidas, this.eventoSelecionado);
      });

      this.logradouroInicio = primeiroSegmento.logradouroInicio;
      this.logradouroFim = ultimoSegmento.logradouroFim;
      this.motoristas = _.uniq(this.motoristas);
      this.veiculos = _.uniq(this.veiculos);
      this.duracao = this.moment(ultimoSegmento.fim).diff(this.moment(primeiroSegmento.inicio), 'seconds');
      this.todosSegmentos = true;
      let posicoes = [];
      this.segmentos.map(segmento => {
        segmento.posicoes.map(posicao => {
          posicoes.push(posicao);
        });
      });
      this.insertMarkers(this.filtraMarkers(posicoes));
    }

    limparVariaveisLayers() {
      this.pathGroup.clearLayers();
      this.markersGroup.clearLayers();
      this.veiculos = [];
      this.motoristas = [];
      this.distancia = 0;
      this.markersPorPosicao.clear();
    }

    selecionarSegmento(segmento, index) {
      this.indexSegmento = index;
      this.limparVariaveisLayers();
      this.resumoPeriodo = [segmento];
      this.popularDadosDoSegmento(segmento, index);
      this.inserirSegmentoNoMapa(segmento);
      this.collapseSegmentos(index);
    }

    popularDadosDoSegmento(segmento, index) {
      this.titulo = segmento.servico.nome + ' - ' + this.moment(segmento.inicioPrevisto).format('DD/MM/YYYY');
      this.todosSegmentos = false;
      this.indexSegmento = index;
      this.segmentoSelecionado = segmento;
      this.logradouroInicio = this.segmentoSelecionado.logradouroInicio;
      this.logradouroFim = this.segmentoSelecionado.logradouroFim;
      this.motoristas = [segmento.motorista];
      this.veiculos = [segmento.veiculo];
      this.distancia = segmento.distancia;
      this.duracao = segmento.duracao;
    }

    inserirSegmentoNoMapa(segmento) {
      let posicoes = _.sortBy(this.mapasUtilService.flatPosicoes(segmento.posicoes), 'data');
      this.setPath(posicoes);
      this.setPathEventos(posicoes, this.eventoSelecionado);
      this.insertMarkers(this.filtraMarkers(segmento.posicoes));
    }

    collapseSegmentos(indexSegmentoClicado) {
      this.segmentos.forEach((s, index) => {
        if (index === indexSegmentoClicado) {
          s.ativo = !s.ativo;
          s.intervalos.forEach(i => i.ativo = true);
        } else {
          s.ativo = false;
        }
      });
    }

    setMapEvents() {
      angular.extend(this.scope, {
        events: {
          map: {
            enable: ['click'],
            logic: 'emit'
          },
          markers: {
            enable: ['click'],
            logic: 'emit'
          }
        }
      });
    }

    insertMarkers(trechos, fit = true) {
      this.leafletData.getMap('bds-map').then(
        map => {
          let oms = this.spiderfierService.createSpiderfier(map);
          this.markersGroup.clearLayers();

          this.markersPorPosicao = this.markerService
            .buildMarkersAssociadosAsPosicoes(_.flatten(trechos), this.eventoSelecionado, 'bds-');

          this.markersPorPosicao.forEach(marker => {
            this.markersGroup.addLayer(marker);
            if (!this.eventos.isLocalizacao(marker.options.evento)) {
              oms.addMarker(marker);
            }
          });

          if (!map.hasLayer(this.markersGroup)) {
            map.addLayer(this.markersGroup);
          }
          if (fit) {
            map.fitBounds(this.markersGroup.getBounds(), {padding: [100, 100]});
          }
        }
      );
    }

    setPath(posicoes) {
      this.leafletData.getMap('bds-map').then(
        map => {
          let routeLines = L.polyline(posicoes.map(p => [p.latitude, p.longitude]), {
            color: '#3388FF',
            opacity: 0.75,
            weight: 3
          });
          L.polylineDecorator(routeLines, {
            patterns: [{
              offset: 0,
              repeat: 300,
              symbol: L.Symbol.arrowHead({
                pixelSize: 12,
                pathOptions: {fillOpacity: 0.75, color: '#0033FF', weight: 0}
              })
            }]
          }).addTo(this.pathGroup);
          this.pathGroup.addLayer(routeLines);

          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    setPathEventos(posicoes, e) {
      this.leafletData.getMap('bds-map').then(
        map => {
          posicoes
            .filter(p => this.isEvento(p, e))
            .map(p=> {
              let coord = [[p.latitude, p.longitude]],
                  routeEventsLines = null;
              if (angular.isDefined(p.fim)) {
                coord = coord.concat(this.getPosicoesDentroDoPeriodoDoEvento(p, posicoes));
                coord.push([p.fim.latitude, p.fim.longitude]);
              }
              routeEventsLines = L.polyline(coord, {
                color: 'red',
                weight: 3
              });
              this.pathGroup.addLayer(routeEventsLines);
            });

          if (!map.hasLayer(this.pathGroup)) {
            map.addLayer(this.pathGroup);
          }
        }
      );
    }

    getPosicoesDentroDoPeriodoDoEvento(posicao, posicoes) {
      return posicoes
        .filter(p => this.isDentroDoPeriodo(p, posicao))
        .map(p=> [p.latitude, p.longitude]);
    }

    isDentroDoPeriodo(posicao, posicaoPeriodo) {
      return new Date(posicaoPeriodo.data) < new Date(posicao.data) &&
        new Date(posicaoPeriodo.fim.data) > new Date(posicao.data);
    }

    isEvento(p, e) {
      if (e === this.translate.instant(this.eventos.EXCESSO_VELOCIDADE.tipo)) {
        return this.eventos.isExcessoVelocidade(p.evento);
      } else if (e === this.translate.instant(this.eventos.CURVA_BRUSCA.tipo)) {
        return this.eventos.isCurvaBrusca(p.evento);
      }
      return e === '' || p.evento === e;
    }

    selecionarIntervalo(segmento, intervalo) {
      const posicoesNoPeriodo = segmento.posicoes.filter(p =>
        this.moment(p.data).isBetween(intervalo.pontos[0].horario, intervalo.pontos[1].horario));

      this.insertMarkers(this.filtraMarkers(posicoesNoPeriodo), false);

      this.logradouroInicio = intervalo.pontos[0].nome;
      this.logradouroFim = intervalo.pontos[1].nome;
      this.duracao = intervalo.duracao;
      this.distancia = intervalo.distancia;
      this.excessoVelocidade = intervalo.excessosVelocidade;
      this.aceleracoesBruscas = intervalo.aceleracoes;
      this.frenagensBruscas = intervalo.frenagens;
      this.curvasBruscas = intervalo.curvas;
      this.banguela = intervalo.banguelas;
      this.rpmExcessivo = intervalo.rpmExcessivos;
      this.motorOcioso = intervalo.motorOcioso;
      this.todosSegmentos = false;

      this.ativarIntervalo(segmento, intervalo);

      this.leafletData.getMap('bds-map')
        .then(map => {
          const primeiroPonto = new L.LatLng(intervalo.pontos[0].latitude, intervalo.pontos[0].longitude),
              segundoPonto = new L.LatLng(intervalo.pontos[1].latitude, intervalo.pontos[1].longitude);

          map.fitBounds(L.latLngBounds(primeiroPonto, segundoPonto), {padding: [100, 100]});
        });
    }

    ativarIntervalo(segmento, intervalo) {
      this.segmentos.forEach(s => {
        const isSelecionado = segmento === s;
        s.ativo = isSelecionado;
        if (isSelecionado) {
          s.intervalos.forEach(i => i.ativo = intervalo === i);
        }
      });
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }

    filtraMarkers(posicoes) {
      return posicoes.filter(p =>
        p.valida && p.descricao !== this.translate.instant('ce.enum.eventos.analiseOperacional')
      );
    }
  }

  angular
    .module('relatorios.bds')
    .controller('BdsDetalhesCtrl', BdsDetalhesCtrl);
}());

