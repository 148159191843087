(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.ocorrencia', {
      url: '/relatorios/historico-ocorrencia?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/historico-ocorrencia/historico-ocorrencia.tpl.html',
          controller: 'HistoricoOcorrenciaCtrl',
          controllerAs: 'historicoOcorrencia'
        }
      },
      data: {
        titulo: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.titulo',
        role: 'OCORRENCIA_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.ocorrencia')
    .config(pageConfig);
}());
