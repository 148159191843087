(function () {
  'use strict';

  angular
    .module('mapasGrid')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.mapasGrid', {
        url: '/mapas-grid?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'mapas-grid/mapas-grid.tpl.html',
            controller: 'MapasGridCtrl',
            controllerAs: 'mapasGrid'
          }
        },
        data: {
          titulo: 'ce.mapa.localizacaoDaFrota.titulo',
          hideTitulo: true,
          role: 'LOCALIZACAO_ATUAL_VISUALIZAR'
        }
      });
  }
}());
