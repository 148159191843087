/* global document */
/* eslint-disable angular/log */
(function () {
  'use strict';

  class SaudeFrotaCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, Keys) {
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          name: 'veiculosId',
          key: this.keys.veiculos.name,
          size: 6
        },
        {
          name: 'diagnosticosId',
          key: this.keys.diagnosticos.name,
          size: 6
        }
      ]))
        .then((filtro) => this.init(filtro));
    }

    init(filtro) {
      /*eslint-disable */
      const tableDiagnosticosElement = document.querySelector('#table-diagnosticos'),
          saudeFrotaListElement = document.createElement('vfwc-saude-frota-list'), 
          vehiclesFiltered = filtro.find(f => f.key === 'veiculos'),
          diagnosticsFiltered = filtro.find(f => f.key === 'diagnosticos'),
          vehiclesId = vehiclesFiltered ? vehiclesFiltered.values.map(vehicle => vehicle.id) : vehiclesFiltered,
          diagnosticsId = diagnosticsFiltered ? diagnosticsFiltered.values.map(diagnostic => diagnostic.id) : diagnosticsFiltered;

      saudeFrotaListElement.uoId = Number(filtro.find(f => f.key === 'uo').value.id);
      saudeFrotaListElement.vehiclesIds = vehiclesId;
      saudeFrotaListElement.diagnosticsTypes = diagnosticsId;

      tableDiagnosticosElement.appendChild(saudeFrotaListElement);
    }
  }

  angular
    .module('relatorios.saudeFrota')
    .controller('SaudeFrotaCtrl', SaudeFrotaCtrl);
}());
