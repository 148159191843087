(function () {
  'use strict';

  /* global _ */
  class ColumnChart {
    constructor(yAxis, colors, CommonChart, moment, $filter) {
      this.commonChartService = CommonChart;
      this.moment = moment;
      this.filter = $filter;
      this.title = this.commonChartService.getTitle();
      this.subtitle = this.commonChartService.getSubtitle();
      this.xAxis = this.commonChartService.getXAxis();
      this.yAxis = this.commonChartService.getYAxis(yAxis, false);
      this.colors = colors || [
        'rgb(49,139,255)',
        'rgb(204,38,54)',
        'rgb(139,188,33)',
        'rgb(136,84,196)',
        'rgb(255,203,63)',
        'rgb(255,73,121)',
        'rgb(255,108,58)',
        'rgb(8,204,180)',
        'rgb(83,119,0)',
        'rgb(255,63,0)',
        'rgb(79,73,255)',
        'rgb(209,153,12)'
      ];
      this.gradientColors = this.commonChartService.getGradientColors(this.colors);
      this.options = this.getOptions();
      this.series = null;
      this.columnSeries = null;
    }

    getOptions() {
      return {
        chart: {type: 'column'},
        colors: this.colors,
        plotOptions: {
          column: {
            stacking: 'normal',
            grouping: false
          },
          series: {
            point: {events: {}},
            events: {
              click: this.commonChartService.filterAllSeries,
              legendItemClick: this.commonChartService.filterAllSeries
            },
            tooltip: {
              pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>'
            }
          }
        },
        credits: {enabled: false},
        legend: {
          itemStyle: {
            font: '9pt "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: 'black'
          }
        }
      };
    }

    setDateTooltip() {
      let self = this;
      this.options.plotOptions.series.tooltip.pointFormatter = function () {
        let valor = this.y;
        if (this.series.options.dataDefault) {
          const data = this.series.options.dataDefault[this.index];
          valor = self.filter('humanizedDuration')(angular.isNumber(data) ? data : data.y, true);
        }
        return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${valor}</b><br/>`;
      };
    }

    setData(data, tipoDate) {
      this.xAxis.categories = data.categorias;
      this.columnSeries = data;

      if (data.series) {
        if (data.series[0].compareData) {
          this.series = this.mapComparativeSeries(data.series, tipoDate);
        } else {
          this.series = this.mapSingleSeries(data.series, tipoDate);
        }
        this.options.tooltip = {shared: false};
      }
    }

    mapSeries(data) {
      return data.map(item => {
        if (angular.isNumber(item)) {
          return item / 3600;
        }
        let i = item.y;
        item.y = i / 3600;
        return item;
      });
    }

    mapSingleSeries(series, tipoDate) {
      return _.chain(series)
        .map((serie, index) => {
          let ret;
          if (serie.type === 'VEICULOS_QTD') {
            ret = {
              data: serie.data,
              name: serie.name,
              color: 'rgb(100, 100, 100)',
              type: 'spline',
              yAxis: 1,
              zIndex: -1,
              lineWidth: 0.6,
              marker: {
                enabled: true,
                radius: 2.5,
                symbol: 'circle'
              }
            };
          } else {
            if (this.comparativeGradient) {
              let dataDefault = serie.data;
              if (tipoDate) {
                serie.data = this.mapSeries(serie.data);
              }
              serie.data[0] = {y: angular.isNumber(serie.data[0]) ? serie.data[0] : serie.data[0].y, color: this.gradientColors[index]};
              serie.data[1] = {y: angular.isNumber(serie.data[1]) ? serie.data[1] : serie.data[1].y, color: this.options.colors[index]};
              return {
                name: serie.name,
                data: serie.data,
                dataDefault
              };
            }
            ret = {
              name: serie.name,
              data: tipoDate ? this.mapSeries(serie.data) : serie.data,
              color: this.options.colors[index],
              dataDefault: serie.data
            };
          }
          return ret;
        })
        .flatten()
        .value();
    }

    mapComparativeSeries(series, tipoDate) {
      return _.chain(series)
        .map((serie, index) => {
          let ret;
          if (serie.type === 'VEICULOS_QTD') {
            ret = [
              {
                linkedTo: serie.name,
                name: serie.name,
                data: serie.compareData,
                stack: 'anteriorz',
                color: 'rgb(180,180,180)',
                pointPadding: 0.2,
                pointPlacement: -0.1,
                type: 'spline',
                yAxis: 1,
                zIndex: -1,
                lineWidth: 0.6,
                marker: {
                  enabled: true,
                  radius: 2.5,
                  symbol: 'circle'
                }
              },
              {
                linkedTo: null,
                name: serie.name,
                data: serie.data,
                stack: 'atualz',
                color: 'rgb(100, 100, 100)',
                pointPadding: 0.2,
                pointPlacement: 0.1,
                lineWidth: 0.6,
                type: 'spline',
                zIndex: -1,
                yAxis: 1,
                marker: {
                  enabled: true,
                  radius: 2.5,
                  symbol: 'circle'
                }
              }
            ];
          } else {
            ret = [
              {
                linkedTo: serie.name,
                name: serie.name,
                data: tipoDate ? this.mapSeries(serie.compareData) : serie.compareData,
                stack: 'anterior',
                color: this.gradientColors[index],
                pointPadding: 0.2,
                pointPlacement: -0.1,
                dataDefault: serie.compareData
              },
              {
                linkedTo: null,
                name: serie.name,
                data: tipoDate ? this.mapSeries(serie.data) : serie.data,
                stack: 'atual',
                color: this.options.colors[index],
                pointPadding: 0.2,
                pointPlacement: 0.1,
                dataDefault: serie.data
              }
            ];
          }
          return ret;
        })
        .flatten()
        .sortBy('stack')
        .value();
    }

    setClickEvent(onClickColumn) {
      this.options.plotOptions.series.point.events.click = onClickColumn;
      this.options.plotOptions.series.events.click = () => {};
    }

    withComparativeGradient() {
      this.comparativeGradient = true;
    }

    disableLegend() {
      this.options.legend.enabled = false;
    }

    setTitleText(chartTitle) {
      this.title.text = chartTitle;
    }

    setYAxisTitle(yAxisTitle, axisIndex) {
      if (angular.isArray(this.yAxis)) {
        if (axisIndex) {
          this.yAxis[axisIndex].title.text = yAxisTitle;
        } else {
          this.yAxis[0].title.text = yAxisTitle;
        }
      } else {
        this.yAxis.title.text = yAxisTitle;
      }
    }
  }

  angular
    .module('chartModule')
    .service('ColumnChart', (CommonChart, moment, $filter) => ({get: (yAxis, colors) => new ColumnChart(yAxis, colors, CommonChart, moment, $filter)}));
}());
