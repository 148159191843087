(function () {
  'use strict';

  function pageConfig($stateProvider, FeatureFlag) {
    return FeatureFlag.DOCUMENTOS_MOTORISTA && $stateProvider.state('root.documentos', {
      url: '/documentos?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'cadastros/motorista/documentos/documentos.tpl.html',
          controller: 'DocumentosCtrl',
          controllerAs: 'documentos'
        }
      },
      data: {
        titulo: 'ce.cadastro.motoristas.documentos.titulo',
        role: 'MOTORISTA_EDITAR'
      },
      params: {
        podeEditar: 'podeEditar'
      }
    });
  }

  angular
    .module('cadastros.motorista.documentos')
    .config(pageConfig);
}());
