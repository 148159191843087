(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.rankingInfracoes', {
      url: '/ranking/infracoes?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'ranking/infracoes/infracoes.tpl.html',
          controller: 'RankingInfracoesCtrl',
          controllerAs: 'rankingInfracoes'
        }
      },
      data: {
        titulo: 'ce.tempoReal.infracoes.titulo',
        role: 'RANKING_INFRACOES_VISUALIZAR'
      }
    });
  }

  angular
    .module('ranking.Infracoes')
    .config(pageConfig);
}());
