(function () {
  'use strict';

  function AplicativoVdrivers() {
    return {
      templateUrl: `configuracoes/vdrivers/subpaginas/aplicativo/aplicativo-vdrivers.tpl.html`,
      controller(ConfigVdriversService) {
        this.service = ConfigVdriversService;
      },
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {}
    };
  }

  angular
    .module('SubpaginasVdrivers')
    .directive('aplicativoVdrivers', AplicativoVdrivers);
}());
