(function () {
  'use strict';

  class EditarCategoriaPontoReferencia {
    constructor($uibModalInstance, $q, categoriaId, CategoriaPontosReferencia, AlertMessage, uoSelecionada, TIPOS_CATEGORIA, $translate) {
      this.modalInstance = $uibModalInstance;
      this.model = {audioHabilitado: false};
      this.hasVtalks = false;
      this.q = $q;
      this.alertMessage = AlertMessage;
      this.uoSelecionada = uoSelecionada;
      this.categoriaPontoReferenciaService = CategoriaPontosReferencia;
      this.categoriaId = categoriaId;
      this.AREA_RISCO = 'AREA_RISCO';
      this.GARAGEM = 'GARAGEM';
      this.TIPOS_CATEGORIA = TIPOS_CATEGORIA;
      this.temRegra = false;
      this.translate = $translate;

      this.carregarCategoria();
    }

    carregarCategoria() {
      this.q.all([
        this.categoriaPontoReferenciaService.getCategoriaPontoReferencia(this.categoriaId),
        this.categoriaPontoReferenciaService.getCategoriaAudios(this.uoSelecionada.id),
        this.categoriaPontoReferenciaService.hasVtalks()
      ]).then(([data, audios, hasVtalks]) => {
        this.hasVtalks = hasVtalks;
        this.audios = this.ordenarAudios(audios.plain());
        // API retorna uma lista vazia quando a uo não possui regra cadastrada
        this.temRegra = this.audios.length > 0;
        this.model = data.plain();
        if (this.audios) {
          this.model.audio = this.audios.find(audio => audio.id === this.model.audioId);
        }

        this.model.audioHabilitado = Boolean(this.model.audioId);

        if (this.model.areaRisco || this.model.garagem) {
          this.model.tipo = this.model.areaRisco ?
            this.TIPOS_CATEGORIA[this.AREA_RISCO] :
            this.TIPOS_CATEGORIA[this.GARAGEM];
        }
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.errorCarregarCategoria'),
          appendTo: '.alerta-aqui-categoria-ponto'
        });
      });
    }

    ordenarAudios(audios) {
      return audios.sort((a, b) => a.nome.localeCompare(b.nome));
    }

    changeAudioHabilitado() {
      this.model.audioHabilitado = !this.model.audioHabilitado;
    }

    ok() {
      let categoria = {
        id: this.model.id,
        uo: this.uoSelecionada.id,
        nome: this.model.nome,
        cor: this.model.cor,
        areaRisco: angular.isDefined(this.model.tipo) && this.model.tipo !== null && this.model.tipo.id === this.AREA_RISCO,
        garagem: angular.isDefined(this.model.tipo) && this.model.tipo !== null && this.model.tipo.id === this.GARAGEM,
        audioId: this.model.audioHabilitado && angular.isDefined(this.model.audio) ? this.model.audio.id : null
      };
      this.categoriaPontoReferenciaService.putCategoriaPontoReferencia(categoria)
      .then(data => {
        this.modalInstance.close(data.plain());
      })
      .catch((e) => {
        this.alertMessage.create({
          type: 'error',
          message: angular.isDefined(e) && e.status === 409 ?
          this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.categoriaJaCadastrada') + ' "' + e.data.nome + '"'
          : this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorEditarCategoria'),
          appendTo: '.alerta-aqui-categoria-ponto'
        });
      });
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('mapControlModule')
    .controller('EditarCategoriaPontoReferenciaCtrl', EditarCategoriaPontoReferencia);
}());
