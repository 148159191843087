(function () {
  'use strict';

  angular
    .module('relatorios.rankingSafety')
    .constant('MockRankingSafety', [
      {
        motorista: 'André Castanheira',
        motoristaId: 1,
        departamento: 'Coletas',
        totalEventos: 35,
        eventosPorTempo: 12,
        tempoEmDirecao: 3600,
        eventosPorEspaco: 21,
        distanciaPercorrida: 5035,
        aceleracoesBruscas: 8,
        frenagensBruscas: 2,
        curvasBruscas: 10,
        banguelas: 0,
        exVelocidadeFaixa1: 2,
        exVelocidadeFaixa2: 8,
        exVelocidadeFaixa3: 5,
        veiculos: 'VW81502 - AOI7238',
        veiculosIds: [1]
      },
      {
        motorista: 'Gil Larangeira',
        motoristaId: 2,
        departamento: 'Coletas',
        totalEventos: 22,
        eventosPorTempo: 10,
        tempoEmDirecao: 2043,
        eventosPorEspaco: 4,
        distanciaPercorrida: 5840,
        aceleracoesBruscas: 0,
        frenagensBruscas: 0,
        curvasBruscas: 0,
        banguelas: 0,
        exVelocidadeFaixa1: 0,
        exVelocidadeFaixa2: 8,
        exVelocidadeFaixa3: 14,
        veiculos: 'FSADF45 - VXZ9045',
        veiculosIds: [2]
      },
      {
        motorista: 'Iberê Lages',
        motoristaId: 3,
        departamento: 'Entregas',
        totalEventos: 18,
        eventosPorTempo: 6,
        tempoEmDirecao: 1068,
        eventosPorEspaco: 10,
        distanciaPercorrida: 7345,
        aceleracoesBruscas: 0,
        frenagensBruscas: 2,
        curvasBruscas: 9,
        banguelas: 1,
        exVelocidadeFaixa1: 2,
        exVelocidadeFaixa2: 4,
        exVelocidadeFaixa3: 0,
        veiculos: 'VCBZ45 - UKT3556',
        veiculosIds: [3]
      },
      {
        motorista: 'Silvério Mansilla',
        motoristaId: 4,
        departamento: 'Coletas',
        totalEventos: 11,
        eventosPorTempo: 2,
        tempoEmDirecao: 3024,
        eventosPorEspaco: 0,
        distanciaPercorrida: 15040,
        aceleracoesBruscas: 0,
        frenagensBruscas: 0,
        curvasBruscas: 1,
        banguelas: 3,
        exVelocidadeFaixa1: 2,
        exVelocidadeFaixa2: 4,
        exVelocidadeFaixa3: 1,
        veiculos: 'ANGA01 - ANR1131',
        veiculosIds: [4]
      }
    ]);
}());
