(function () {
  'use strict';

  angular
    .module('configEventos', [
      'ui.router',
      'ModuleMapasUtil',
      'ModalEventos',
      'SubpaginasEventos',
      'enumModule',
      'v3gAuthenticatorModule',
      'SolucoesModule',
      'FeatureFlagModule'
    ]);
}());
