(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.conducaoEconomica', {
      url: '/conducao-economica?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'indicadores/conducao-economica/conducao-economica.tpl.html',
          controller: 'ConducaoEconomicaCtrl',
          controllerAs: 'conducaoEconomica'
        }
      },
      data: {
        titulo: 'ce.indicadores.conducaoEconomica.titulo',
        role: 'CONDUCAO_ECONOMICA_VISUALIZAR'
      }
    });
  }

  angular
    .module('indicadores.ConducaoEconomica')
    .config(pageConfig);
}());
