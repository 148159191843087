(function () {
  'use strict';

  angular
    .module('SubpaginasVdrivers', [
      'InputCheckboxModule',
      'InputNumberModule',
      'dndLists'
    ]);
}());
