(function () {
  'use strict';

  /* @ngdoc object
  * @name mapasGrid
  * @description
  *
  */
  angular
    .module('informativoModule', [
      'informativoKeysModule',
      'ui.router',
      'restangular',
      'ui.router',
      'v3gAuthenticatorModule'
    ]);
}());
