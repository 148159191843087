(function () {
  'use strict';

  angular
    .module('relatorios.analiseOp', [
      'ui.router',
      'restangular',
      'uoModule',
      'v3gVeiculoModule',
      'motoristaModule',
      'angularMoment',
      'vflashModule',
      'sidebarMenuToggleModule',
      'smart-table',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'urlConfigModule'
    ])
    .factory('AnaliseOperacional', Restangular => Restangular.service('/analiseoperacional/relatorio/analise-operacional'));
}());
