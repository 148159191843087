/* global document */
(function () {
  'use strict';

  class VideoLibraryCtrl {
    constructor($state) {
      if ($state.current.name === 'root.videoLibrary') {
        $state.go('root.videoLibrary.solicitacaoVideos');
        return;
      }

      /*eslint-disable */
      const elementContainer = document.querySelector('#video-library-tabs');
      if (elementContainer) {
        const vfwcVideoLibraryTabsElement = document.createElement('vfwc-video-library-tabs');
        vfwcVideoLibraryTabsElement.tabs = [
          {
              name: 'playlist',
              label: 'ce.analise.videoLibrary.tabs.playlist',
          },
        ];

        switch ($state.current.name) {
          default: {
            vfwcVideoLibraryTabsElement.initialTabIndex = 0;
          }
        }

        vfwcVideoLibraryTabsElement.addEventListener('tabChanged', (event) => {
          switch(event.detail) {
            default: $state.go('root.videoLibrary.solicitacaoVideos');
          }
        });

        elementContainer.appendChild(vfwcVideoLibraryTabsElement);
      }
      /*eslint-enable */
    }
  }

  angular
    .module('relatorios.videoLibrary')
    .controller('VideoLibraryCtrl', VideoLibraryCtrl);
}());
