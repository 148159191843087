(function () {
  'use strict';

  class OnePageService {
    constructor($timeout, StatusVeiculo, StatusVeiculoPassivelManutencao, Restangular) {
      this.timeout = $timeout;
      this.statusVeiculoService = StatusVeiculo;
      this.statusVeiculoPassivelManutencaoService = StatusVeiculoPassivelManutencao;
      this.restangular = Restangular;
      this.callbacksStatusVeiculo = [];
      this.callbacksStatusVeiculoPassivelManutencao = [];
      this.callbacksAlertaTurno = [];
      this.alertaTurnoErrorHandlers = [];
      this.paramStatusVeiculo = null;
      this.paramAlertaTurno = null;
      this.alertaTurno = null;
      this.alertaTipo = '';
      this.alertaSelecionado = {};
      this.exibirAlertaTurnos = true;
    }

    onUpdateStatusVeiculoPassivelManutencao(callback, name) {
      if (this.callbacksStatusVeiculoPassivelManutencao.filter(e => e.name === name).length === 0) {
        this.callbacksStatusVeiculoPassivelManutencao.push({callback, name});
      }
    }

    checkStatusVeiculoPassivelManutencao(param) {
      this.paramStatusVeiculo = param;
      return this.statusVeiculoPassivelManutencaoService.getStatusVeiculos(param.uoId)
      .then(data => {
        this.updateStatusVeiculoPassivelManutencao(data.plain());
      }, () => {
        this.updateStatusVeiculoPassivelManutencao(0);
      });
    }

    updateStatusVeiculoPassivelManutencao(data) {
      if (data !== 0) {
        this.listaCacheadaPassivelManutencao = data;
      }
      this.callbacksStatusVeiculoPassivelManutencao.forEach(cb => {
        cb.callback(this.listaCacheadaPassivelManutencao);
      });
      this.timerPassivelManutencao = this.timeout(() => this.checkStatusVeiculoPassivelManutencao(this.paramStatusVeiculo), 60000);
    }

    onUpdateStatusVeiculo(callback, name) {
      if (this.callbacksStatusVeiculo.filter(e => e.name === name).length === 0) {
        this.callbacksStatusVeiculo.push({callback, name});
      }
    }

    checkStatusVeiculo(param) {
      this.paramStatusVeiculo = param;
      return this.statusVeiculoService.one().get(this.paramStatusVeiculo)
      .then(data => {
        this.updateStatusVeiculo(data.plain());
      }, () => {
        this.updateStatusVeiculo(0);
      });
    }

    updateStatusVeiculo(data) {
      if (data !== 0) {
        this.listaCacheada = data;
      }
      this.callbacksStatusVeiculo.forEach(cb => {
        cb.callback(this.listaCacheada);
      });
      this.timer = this.timeout(() => this.checkStatusVeiculo(this.paramStatusVeiculo), 60000);
    }

    removeTimer() {
      this.timeout.cancel(this.timer);
      this.timeout.cancel(this.timerAlertaTurno);
      this.timeout.cancel(this.timerPassivelManutencao);
    }

    removeUpdateStatusVeiculo() {
      this.callbacksStatusVeiculo = [];
    }

    removeUpdateAlertaTurno() {
      this.callbacksAlertaTurno = [];
      this.alertaTurnoErrorHandlers = [];
    }

    removeUpdateStatusVeiculoPassivelManutencao() {
      this.callbacksStatusVeiculoPassivelManutencao = [];
    }

    onUpdateAlertaTurno(callback, name) {
      if (this.callbacksAlertaTurno.filter(e => e.name === name).length === 0) {
        this.callbacksAlertaTurno.push({callback, name});
      }
    }

    onUpdateAlertaTurnoError(callback, name) {
      if (this.alertaTurnoErrorHandlers.filter(e => e.name === name).length === 0) {
        this.alertaTurnoErrorHandlers.push({callback, name});
        this.timeout.cancel(this.timerAlertaTurno);
        this.checkAlertaTurno(this.paramAlertaTurno);
      }
    }

    checkAlertaTurno(param) {
      this.paramAlertaTurno = param;
      return this.restangular.one(`alerta/one-page/${this.paramAlertaTurno.uoId}`).get()
      .then(data => {
        const dataPlain = data.plain();
        if (dataPlain && dataPlain.turnos.length > 0) {
          this.exibirAlertaTurnos = true;
        } else {
          this.exibirAlertaTurnos = false;
        }
        this.updateAlertaTurno(dataPlain);
      }, error => {
        this.alertaTurnoErrorHandlers.forEach(cb => cb.callback(error));
        this.exibirAlertaTurnos = true;
        this.updateAlertaTurno(0);
      });
    }

    updateAlertaTurno(data) {
      if (data !== 0) {
        this.listaCacheadaAlertaTurno = data;
      }
      this.callbacksAlertaTurno.forEach(cb => {
        cb.callback(this.listaCacheadaAlertaTurno);
      });
      this.timerAlertaTurno = this.timeout(() => this.checkAlertaTurno(this.paramAlertaTurno), 60000);
    }

    getAlertaTurno(tipo) {
      if (this.alertaTipo !== tipo || this.alertaTurno.length === 1) {
        const alerta = this.alertaTurno.filter(x => x.key === tipo)[0];

        this.alertaTipo = tipo;
        this.alertaSelecionado = alerta;
      }
      return this.alertaSelecionado;
    }
  }

  angular
    .module('onePageModule')
    .service('OnePageService', OnePageService);
}());
