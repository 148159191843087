/* global document */
/* eslint-disable no-underscore-dangle */
(function () {
  'use strict';

  class Onboarding {
    constructor($q, HttpServiceV3g, $window, $location, $document, $rootScope, Restangular, ngIntroService, IDBuscaPorRegiao, $timeout) {
      this.q = $q;
      this.http = HttpServiceV3g;
      this.window = $window;
      this.location = $location;
      this.document = $document;
      this.rootScope = $rootScope;
      this.restangular = Restangular;
      this.isTutorialBuscaRegiao = false;
      this.ngIntroService = ngIntroService;
      this.timeout = $timeout;
      this.idBuscaPorRegiao = IDBuscaPorRegiao;
      this.steps = null;
      this.stepsLengthTotal = 0;
    }

    getListOnboarding(id) {
      return this.restangular.one(`/wiki/${id}/child?expand=page.body.view,page.metadata.labels`).get();
    }

    buildObjectOnboarding(lista) {
      return {
        steps: lista,
        exitOnOverlayClick: false,
        nextLabel: '<button type="button" style="background: #005F9E; width: 92px; height: 28px; color: white; font-weight: bold; border: 0;">PRÓXIMO</button>',
        prevLabel: '<i class="fa fa-chevron-left"></i>',
        skipLabel: 'Sair',
        doneLabel: 'Sair',
        showStepNumbers: false,
        hidePrev: this.isTutorialBuscaRegiao,
        showBullets: !this.isTutorialBuscaRegiao,
        disableInteraction: true,
        keyboardNavigation: false
      };
    }

    startOnboarding(id) {
      this.isTutorialBuscaRegiao = id === this.idBuscaPorRegiao;
      if (this.location.search().demo) {
        this.getListOnboarding(id)
        .then(data => {
          this.ngIntroService.clear();
          this.steps = data.page.results.map(x => {
            return {
              intro: x.body.view.value,
              element: isNaN(Number(x.title)) ? x.title : undefined,
              position: x.metadata.labels.results[0] ? x.metadata.labels.results[0].name : undefined,
              stepIndex: data.page.results.indexOf(x) + 1
            };
          });
          this.stepsLengthTotal = this.steps.length;
          this.ngIntroService.clear();
          this.ngIntroService.setOptions(this.buildObjectOnboarding(this.steps));

          this.ngIntroService.onExit(() => {
            if (!this.isTutorialBuscaRegiao) {
              this.window.location.href = '/direcao-segura?vfiltro=limpar-vfiltro:true';
            }
          });

          this.ngIntroService.onChange(element => {
            this.controlarFiltroHeader(element);
          });

          this.ngIntroService.onAfterChange(element => {
            if (this.isTutorialBuscaRegiao && this.ngIntroService.intro._currentStep !== 0) {
              this.emitEvent();
            }

            const ele = angular.element(element);
            this.controlarSortTable(ele);
            this.controlarColunaVeiculo(ele);
          });

          this.timeout(() => {
            this.ngIntroService.start();
            if (this.isTutorialBuscaRegiao) {
              angular.element('.introjs-fixParent').removeClass('introjs-fixParent');
              this.adjustButtonsForBuscaRegiao();
            }
          }, 1000);
        });
        return true;
      }
      return false;
    }

    adjustButtonsForBuscaRegiao() {
      /*eslint-disable*/
      const introModal = document.querySelector('.introjs-tooltip'),
          prevButton = document.querySelector('.introjs-prevbutton'),
          nextButton = document.querySelector('.introjs-nextbutton'),
          exitButton = document.querySelector('.introjs-skipbutton'),
          doneButton = document.querySelector('.introjs-donebutton'),
          tooltipbuttons = document.querySelector('.introjs-tooltipbuttons'),
          introTexto = document.querySelector('.introjs-tooltiptext'),
          cookiesBar = document.querySelector('#cookies-bar'),
          progresso = document.createElement('p'),
          progressoTexto = document.createTextNode(`${this.steps[0].stepIndex}/${this.stepsLengthTotal}`),
          closeButton = document.createElement('button'),
          closeTexto = document.createTextNode('×');
        /*eslint-enable*/

      if (prevButton && nextButton && exitButton && tooltipbuttons && introTexto) {
        prevButton.style.display = 'none';
        exitButton.style.display = 'none';
        nextButton.style.padding = '0px';
      }

      if (introModal && introTexto) {
        introTexto.style.display = 'inline-block';
        introTexto.style.width = '95%';
        introTexto.firstChild.style.marginTop = '0';
        closeButton.className = 'btn-close';
        closeButton.onclick = () => {
          this.ngIntroService.onExit(() => {
            this.window.close();
          });
          this.ngIntroService.exit();
        };
        closeButton.appendChild(closeTexto);
        introModal.insertBefore(closeButton, introModal.firstChild.nextSibling);
      }

      if (doneButton) {
        doneButton.style.padding = '0px';
        doneButton.innerHTML = '<button type="button" class="finalizar-button">FINALIZAR</button>';
      }

      if (tooltipbuttons) {
        progresso.style.display = 'inline-block';
        progresso.style.marginRight = '60%';
        progresso.appendChild(progressoTexto);
        tooltipbuttons.insertBefore(progresso, tooltipbuttons.firstChild);
      }

      if (cookiesBar) {
        cookiesBar.style.display = 'none';
      }
    }

    emitEvent() {
      switch (this.steps[0].stepIndex) {
        case 1:
          this.rootScope.$broadcast('toggle-drawer');
          this.resetOnboarding();
          break;
        case 2:
          this.rootScope.$broadcast('clica-busca-regiao');
          this.resetOnboarding();
          break;
        case 3:
          this.rootScope.$broadcast('desenha-area');
          this.resetOnboarding();
          break;
        case 4:
          this.rootScope.$broadcast('data-busca-regiao');
          this.resetOnboarding();
          break;
        case 5:
          this.rootScope.$broadcast('busca-por-regiao');
          this.resetOnboarding();
          break;
        case 6:
          this.rootScope.$broadcast('buscar-veiculo');
          this.resetOnboarding();
          this.ngIntroService.onExit(() => {
            this.window.localStorage.setItem('tutorial-busca-regiao', false);
            this.window.close();
          });
          break;
        default:
          break;
      }
    }

    resetOnboarding() {
      this.ngIntroService.exit();
      this.timeout(() => {
        this.steps.shift();
        const remainingSteps = this.steps;
        this.ngIntroService.setOptions(this.buildObjectOnboarding(remainingSteps));
        this.ngIntroService.start();
        angular.element('.introjs-fixParent').removeClass('introjs-fixParent');
        this.adjustButtonsForBuscaRegiao();
      }, 500);
    }

    controlarFiltroHeader(element) {
      if (element.id === 'vfiltro') {
        angular.element('body').css('overflow-y', 'hidden');
      } else {
        angular.element('body').css('overflow-y', 'auto');
      }
    }

    controlarSortTable(element) {
      if (element.hasClass('st-sort-descent') || element.hasClass('td-detalhes')) {
        const elementTable = angular.element('.table-responsive'),
            offsetElement = element.offset().left,
            paddingIntro = element.hasClass('last-item') ? -8 : 15,
            paddingElemento = 8,
            distanciaScroll = offsetElement - elementTable.width() + paddingIntro;
        let distanciaIntro = offsetElement - paddingElemento;

        if (distanciaScroll > 0) {
          distanciaIntro = offsetElement - distanciaScroll - paddingElemento;
          elementTable.animate({scrollLeft: distanciaScroll});
        }

        angular.element('.introjs-helperLayer').css('left', distanciaIntro + 'px');
        angular.element('.introjs-tooltipReferenceLayer').css('left', distanciaIntro + 'px');
      }
    }

    controlarColunaVeiculo(element) {
      if (element.hasClass('column-veiculos')) {
        angular.element('.table-responsive').animate({scrollLeft: 0});
      }
    }
  }

  angular
    .module('onboarding', [
      'ui.router',
      'angular-intro',
      'httpServiceV3gModule',
      'restangular',
      'confluenceID'
    ])
    .service('Onboarding', Onboarding);
}());
