(function () {
  'use strict';

  angular
    .module('ranking.Infracoes', [
      'ui.router',
      'videoPlayer',
      'AlertasModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'uoModule',
      'ui.bootstrap.modal',
      'angularMoment',
      'ConstantModule',
      'pascalprecht.translate',
      'usuarioModule',
      'httpServiceV3gModule',
      'v3gTrimbleFilters',
      'historicoAlerta'
    ])
    .factory('RankingInfracoes', Restangular => Restangular.service('alerta/ranking-infracoes'))
    .factory('AlertasRanking', Restangular => Restangular.service('alerta/ranking-infracoes/alertas'));
}());
