(function () {
  'use strict';

  /* @ngdoc object
   * @name cadastros.veiculo
   * @description
   *
   */
  angular
    .module('cadastros.veiculo', [
      'ui.router',
      'restangular',
      'angularMoment',
      'alert-message',
      'httpServiceV3gModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'enumModule',
      'pascalprecht.translate',
      'modalInfoConfirmModule',
      'FeatureFlagModule',
      'relatorios',
      'FeatureOuModule'
    ]);
}());
