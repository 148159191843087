(function () {
  'use strict';

  function OnePageSortDirective($window) {
    return {
      require: '^stTable',
      link: function (scope, element, attr, ctrl) {
        const orderBy = angular.fromJson(attr.orderby);
        if (orderBy && orderBy.predicate) {
          ctrl.sortBy(orderBy.predicate, orderBy.reverse);
        }

        scope.$watch(function () {
          return ctrl.tableState();
        }, function (newValue, oldValue) {
          if (newValue !== oldValue) {
            let local = angular.fromJson($window.localStorage.getItem(attr.nameLocalstorage));
            local.alertas.map(x => {
              if (x.tipo === attr.onePageSortDirective) {
                x.sort = newValue.sort;
              }
              return x;
            });
            $window.localStorage.setItem(attr.nameLocalstorage, angular.toJson(local));
          }
        }, true);
      }
    };
  }

  angular
    .module('onePageModule')
    .directive('onePageSortDirective', OnePageSortDirective);
}());
