(function () {
  'use strict';

  const ADVISOR_ENDPOINT = '/advisor/reducao_velocidade';

  class AdvisorRotogramasService {
    constructor(HttpServiceV3g, ServiceVfiltro, $window, MockGetManyAdvisors, $q) {
      this.httpService = HttpServiceV3g;
      this.serviceVfiltro = ServiceVfiltro;
      this.window = $window;
      this.mockGetManyAdvisors = MockGetManyAdvisors;
      this.q = $q;
      this.isDemo = false;
    }

    getMany() {
      if (this.isDemo) {
        return this.q(resolve => resolve(this.mockGetManyAdvisors));
      }
      return this.httpService.get(`${ADVISOR_ENDPOINT}/${this.getVfiltroUo()}`);
    }

    updateStatus(uoId, uuid, status) {
      return this.httpService.put(`${ADVISOR_ENDPOINT}/${uoId}/status`, null, {params: {uuid, status}});
    }

    getVfiltroUo() {
      return angular.fromJson(this.window.localStorage.getItem(`vfiltro-${this.serviceVfiltro.factoryVfiltro.user.id}`)).uo;
    }
  }

  angular
    .module('mapControlModule')
    .service('AdvisorRotogramasService', AdvisorRotogramasService);
}());
