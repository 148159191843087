(function () {
  'use strict';

  angular
    .module('rotogramaModule')
    .constant('RotogramaComponentStates', {
      INICIAL: 'rotograma-inicial',
      DESTAQUE: 'rotograma-inicial rotograma-destaque',
      ATIVO: 'rotograma-ativo',
      EXCLUSAO: 'rotograma-exclusao'
    })
    .constant('ControleVelocidadeRotograma', {
      CONFIGURADO: {
        label: 'ce.mapa.common.modal.modalDetalhesRotograma.descricaoControlarVelocidade',
        classe: 'placa-ponto-referencia limite-velocidade-placa',
        value: 'CONFIGURADO',
        visible: true
      },
      UTILIZA_VELOCIDADE_ANTERIOR: {
        label: 'ce.mapa.common.modal.modalDetalhesRotograma.descricaoUtilizarVelAnterior',
        classe: 'placa-ponto-referencia manter-velocidade-placa',
        value: 'UTILIZA_VELOCIDADE_ANTERIOR',
        visible: true
      },
      CONTROLA_VELOCIDADE_INTERNA: {
        label: 'ce.mapa.common.modal.modalDetalhesRotograma.descricaoRotogramaInterno',
        classe: 'placa-ponto-referencia limite-velocidade-placa',
        value: 'CONTROLA_VELOCIDADE_INTERNA',
        visible: false
      },
      NAO_CONTROLA_VELOCIDADE: {
        label: 'ce.mapa.common.modal.modalDetalhesRotograma.descricaoNaoControlarVelocidade',
        classe: 'placa-ponto-referencia nao-controlar-velocidade-placa',
        value: 'NAO_CONTROLA_VELOCIDADE',
        visible: false
      }
    })
    .constant('PosicoesRotogramas', (() => {
      const TOPO = {
            key: 'TOPO',
            anguloInicial: 0,
            contemAngulo: (angulo) => angulo >= 315 && angulo <= 360 || angulo >= 0 && angulo < 45
          },
          DIREITA = {
            key: 'DIREITA',
            anguloInicial: 90,
            contemAngulo: (angulo) => angulo >= 45 && angulo < 135
          },
          BASE = {
            key: 'BASE',
            anguloInicial: 180,
            contemAngulo: (angulo) => angulo >= 135 && angulo < 225
          },
          ESQUERDA = {
            key: 'ESQUERDA',
            anguloInicial: 270,
            contemAngulo: (angulo) => angulo >= 225 && angulo < 325
          },
          CENTRO = {
            key: 'CENTRO'
          };

      TOPO.ladosAdjacentes = [DIREITA, ESQUERDA];
      DIREITA.ladosAdjacentes = [TOPO, BASE];
      BASE.ladosAdjacentes = [DIREITA, ESQUERDA];
      ESQUERDA.ladosAdjacentes = [TOPO, BASE];

      return {TOPO, DIREITA, BASE, ESQUERDA, CENTRO};
    })());
}());
