(function () {
  'use strict';

  const status = {
        MOVIMENTO: {
          id: 'MOVIMENTO',
          descricao: 'ce.enum.veiculoStatus.movimento'
        },
        PARADO: {
          id: 'PARADO',
          descricao: 'ce.enum.veiculoStatus.parado'
        }
      },
      funcoes = {
        getStatusById: (id) => status[id]
      };

  angular
    .module('enumModule')
    .constant('VeiculoStatusEnum', Object.assign({}, status, funcoes));
}());
