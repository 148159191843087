(function () {
  'use strict';

  class MultiVideoService {
    constructor($rootScope) {
      this.sharedScope = $rootScope.$new();
    }

    updateVideo(video) {
      this.sharedScope.$emit('update-video', video);
    }
  }

  angular
    .module('videoPlayer')
    .service('MultiVideoService', MultiVideoService);
}());
