(function () {
  'use strict';

  angular
    .module('relatorios.bdv', [
      'ui.router',
      'restangular',
      'angularMoment',
      'uoModule',
      'v3gVeiculoModule',
      'onboarding',
      'ChecklistEnum',
      'VtabelaModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate',
      'v3gAuthenticatorModule',
      'cadastros.veiculo',
      'urlConfigModule',
      'mapas',
      'ModuleEventos',
      'vflashModule',
      'alert-message',
      'ConfigEventosModule'
    ])
    .factory('Bdv', Restangular => {
      const url = 'boletim/bv',
          fieldsChecklist = [
            'itemPreenchido.opcao',
            'itemPreenchido.observacao',
            'item.titulo',
            'checklist.id',
            'motorista.nome',
            'veiculo.placa',
            'veiculo.prefixo',
            'dataPreenchimento'
          ];

      let service = Restangular.service(url);

      service.getDetalhes = (veiculoId, dia) => {
        return Restangular.one(`${url}/detalhes?veiculoId=${veiculoId}&dia=${dia}`).get();
      };

      service.getChecklist = (veiculoId, inicio, fim) => {
        return Restangular.one(`/veiculo/checklist/preenchimentos?preenchimentoInicio=${inicio}&preenchimentoFim=${fim}&veiculoId=${veiculoId}&fields=${fieldsChecklist.toString()}`).get();
      };

      return service;
    });
}());
