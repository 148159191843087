/* global document */
/* eslint-disable */
(function () {
  'use strict';

  const INFORMATIVO_KEY = 'informativo-key',
      PAGE_INFORMATIVO_DEFAULT = 'INDICADORES_DIRECAO_SEGURA';

  class InformativoCtrl {
    constructor($stateParams, Authenticator, $rootScope, $window) {
      this.window = $window;
      this.stateParams = $stateParams;
      this.rootScope = $rootScope;
      Authenticator.getUser().then(() => {
        this.init();
      });
    }

    init() {
      const informativoElement = this.getElement();
      if (!this.stateParams.informativoKey) {
        informativoElement.informativoKey = this.getPageKeyInformativo();
        informativoElement.uoId = this.rootScope.usuario.uo.id;
      } else {
        informativoElement.informativoKey = this.stateParams.informativoKey;
        informativoElement.uoId = this.stateParams.uoId;
      }
      informativoElement.noPadding = false;

      this.setPageKeyInformativo(informativoElement.informativoKey);
    }

    getElement() {
      return angular.element(document.getElementById('informativo-box'))[0];
    }

    getPageKeyInformativo() {
      let keyInformativo = this.window.localStorage.getItem(`${INFORMATIVO_KEY}-${this.rootScope.usuario.id}`);
      return keyInformativo || PAGE_INFORMATIVO_DEFAULT;
    }

    setPageKeyInformativo(pageKey) {
      this.window.localStorage.setItem(`${INFORMATIVO_KEY}-${this.rootScope.usuario.id}`, pageKey);
    }
  }

  angular
    .module('informativoModule')
    .controller('InformativoCtrl', InformativoCtrl);
}());
/* eslint-enable */
