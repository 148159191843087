/* global document */
(function () {
  'use strict';

  function menuEditarPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuEditarPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuEditarPontoReferenciaCtrl {
    constructor($scope, $uibModal, PontosReferenciaTools, PontosReferenciaMap,
      PontosReferenciaMenu, PontosReferencia, FeatureFlag, Authenticator, $rootScope, ServiceVfiltro) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.featureFlag = FeatureFlag;
      this.authenticator = Authenticator;
      this.authenticator.getUser().then(user => {
        this.user = user;
      });
      this.rootScope = $rootScope;
      this.serviceVfiltro = ServiceVfiltro;
      this.init();
    }

    init() {
      this.pontosReferenciaMapService.layer.editing.disable();
      let buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.menuEditarPonto.tituloBtAjustarNoMapa',
                icon: 'fa fa-arrows-alt',
                color: 'default',
                tipo: 'ajustar'
              },
              {
                titulo: 'ce.mapa.common.tituloBtEditarCadastro',
                icon: 'fa fa-pencil',
                color: 'default',
                tipo: 'editar',
                funcao: this.editarPonto.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtExcluirPonto',
                icon: 'fa fa-trash-o',
                color: 'default',
                tipo: 'excluir'
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.cancelarPonto.bind(this)
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMenuService.menuOpen();
    }

    editarPonto() {
      this.pontosReferenciaMenuService.drawerClose();
      if (this.featureFlag.DIALOG_PONTO_REESTRUTURADO) {
        /* eslint-disable */
        const abrirModal = document.querySelector('#dialog-create-ponto'),
          modal = document.createElement('vfwc-open-dialog-create-ponto-referencia'); /* eslint-enable */
        modal.pontoReferencia = {
          id: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.id,
          latitude: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.latitude,
          longitude: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.longitude,
          raio: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.raio,
          uo: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.uo,
          rotogramas: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas || []
        };
        modal.uoFiltro = this.uoSelecionadaParaEdicao();
        modal.usuario = this.user;
        abrirModal.appendChild(modal);
        this.onPontoEdited(modal);
      } else {
        this.modalService.open({
          animation: true,
          component: 'modalEdicaoPontoReferencia',
          backdrop: 'static',
          size: 'lg',
          openedClass: 'modal-edicao-ponto-referencia',
          resolve: {
            pontoReferencia: () => ({
              id: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.id,
              latitude: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.latitude,
              longitude: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.longitude,
              raio: this.pontosReferenciaMapService.layer.entidadePermissao.entidade.raio
            }),
            rotogramas: () => this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas || [],
            uoSelecionada: () => {
              return this.uoSelecionadaParaEditarPonto();
            }
          }
        }).result.then(data => this.atualizarPonto(data));
      }
    }

    onPontoEdited(element) {
      element.addEventListener('pontoReferenciaEventEmitter', (event) => {
        if (event.detail && event.detail.result) {
          const data = event.detail.result,
              rotogramaIds = this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas ?
                  this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas.map(rotograma => rotograma.id) : [];
          this.atualizarPonto(data);
          if (data.rotogramas.length) {
            this.rootScope.$broadcast('rotogramasCadastrados', {
              rotogramas: data.rotogramas,
              pontoReferenciaId: data.entidade.id
            });
          } else if (rotogramaIds.length) {
            this.rootScope.$broadcast('rotogramasDeletados', {
              rotogramaIds: rotogramaIds,
              pontoReferenciaId: data.entidade.id
            });
          }
        }
      });
    }

    uoSelecionadaParaEdicao() {
      if (this.serviceVfiltro.filtroTopbar.find(x => x.key === 'uo')) {
        return this.serviceVfiltro.filtroTopbar.find(x => x.key === 'uo').value.id;
      }
      return this.user.uo.id;
    }

    uoSelecionadaParaEditarPonto() {
      let config = this.pontosReferenciaMapService.cadastroPontoConfig;
      if (config !== null && config.uoDaRegra) {
        return config.uoDaRegra.id;
      }
      return this.pontosReferenciaMapService.layer.entidadePermissao.entidade.uo;
    }

    atualizarPonto(data) {
      this.pontosReferenciaMapService.pesquisa = '';
      this.pontosReferenciaMapService.pontos = [];
      this.pontosReferenciaMapService.atualizarLayer(data);
      if (angular.isDefined(data.entidade.limiteVelocidade)) {
        this.pontosReferenciaMapService.markerIn(this.pontosReferenciaMapService.getIdLayer(this.pontosReferenciaMapService.layer), [data.entidade.latitude, data.entidade.longitude], data.entidade.limiteVelocidade);
      } else {
        let layer = this.pontosReferenciaMapService.getMarkerLimite(this.pontosReferenciaMapService.getIdLayer(this.pontosReferenciaMapService.layer));
        if (layer !== null) {
          this.pontosReferenciaMapService.removeLayer(layer);
          this.pontosReferenciaMapService.removeLayerMap(layer);
        }
      }
    }

    cancelarPonto() {
      this.pontosReferenciaMenuService.setFABDefault();
      this.pontosReferenciaMapService.setLayerDefault();
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuEditarPontoReferenciaCtrl', MenuEditarPontoReferenciaCtrl)
    .directive('menuEditarPontoReferenciaDirective', menuEditarPontoReferenciaDirective);
}());
