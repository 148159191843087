/* eslint-disable quote-props */
(function () {
  'use strict';

  angular
    .module('indicadores')
    .constant('URLDirecaoSegura', '/relatorios/ranking/safety')
    .constant('URLConducaoEconomica', '/relatorios/ranking/economy')
    .constant('SortAlertas', {
      'Acelerações Bruscas': 'aceleracoesBruscas',
      'Banguelas': 'banguelas',
      'Trepidações': '',
      'Curvas Bruscas': 'curvasBruscas',
      'Excessos Velocidade': '',
      'Excessos de Velocidade Até 20%': 'exVelocidadeFaixa1',
      'Excessos de Velocidade de 21% até 30%': 'exVelocidadeFaixa2',
      'Excessos de Velocidade Acima de 30%': 'exVelocidadeFaixa3',
      'Frenagens Bruscas': 'frenagensBruscas',
      'Parado Ligado': 'motorOcioso',
      'RPM Excessivo': 'rpmExcessivo',
      'Sensor Acionado': 'tempoSensorDesengate'
    });
}());
