(function () {
  'use strict';

  class FocusPontoReferenciaModal {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }
  }

  angular
    .module('mapControlModule')
    .controller('FocusPontoReferenciaModalCtrl', FocusPontoReferenciaModal);
}());
