(function () {
  'use strict';

  class QrcodeVeiculoCtrl {
    constructor(CadastroVeiculoService, $window) {
      this.veiculoService = CadastroVeiculoService;
      this.window = $window;

      this.localStorageQrCode = 'qrcode-veiculos-params';
      this.veiculosQrCode = null;
      this.consultarQrCode();
    }

    consultarQrCode() {
      const params = angular.fromJson(this.window.localStorage.getItem(this.localStorageQrCode));
      this.veiculoService.getQrCode(params)
        .then((response) => {
          this.veiculosQrCode = response;
          this.window.localStorage.removeItem(this.localStorageQrCode);
        });
    }
  }

  angular
    .module('cadastros.veiculo')
    .controller('QrcodeVeiculoCtrl', QrcodeVeiculoCtrl);
}());
