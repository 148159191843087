(function () {
  'use strict';

  /* @ngdoc object
   * @name cadastros.motorista
   * @description
   *
   */
  angular
    .module('cadastros.motorista', [
      'ui.router',
      'ng-mfb',
      'restangular',
      'alert-message',
      'httpServiceV3gModule',
      'motoristaModule',
      'middlewareModule',
      'angularMoment',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'enumModule',
      'VtabelaModule',
      'v3gAuthenticatorModule',
      'pascalprecht.translate',
      'FeatureFlagModule',
      'urlConfigModule',
      'cadastros.motorista.filters'
    ]);
}());
