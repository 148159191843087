(function () {
  'use strict';

  function OnePageHighlightDirective($timeout, OnePageService) {
    return {
      scope: {
        tipoAlerta: '=',
        newValue: '=',
        oldValue: '='
      },
      link: function (scope, element, attr) {
        if (scope.newValue !== scope.oldValue) {
          const obj = OnePageService.alertaTurno.filter(x => x.key === attr.onePageHighlightDirective);
          if (obj.length > 0) {
            obj[0][scope.tipoAlerta] = scope.newValue;
          }
          angular.element(element[0]).addClass('highlight-turno');
          $timeout(() => angular.element(element[0]).removeClass('highlight-turno'), 1500);
        }
      }
    };
  }

  angular
    .module('onePageModule')
    .directive('onePageHighlightDirective', OnePageHighlightDirective);
}());
