(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.prontuario', {
      url: '/prontuario-antigo?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'prontuario/prontuario.tpl.html',
          controller: 'ProntuarioCtrl',
          controllerAs: 'prontuario'
        }
      },
      data: {
        titulo: 'ce.ranking.motoristas.prontuario.titulo',
        hideTitulo: true,
        roles: [['RANKING_GERAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }

  angular
    .module('ProntuarioModule')
    .config(pageConfig);
}());
