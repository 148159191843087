(function () {
  'use strict';

  angular
    .module('relatorios.bdm', [
      'ui.router',
      'restangular',
      'pascalprecht.translate',
      'ModuleMapasUtil',
      'leaflet-directive',
      'ModuleEventos',
      'vflashModule',
      'mapas',
      'angularMoment',
      'onboarding',
      'confluenceID',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'urlConfigModule',
      'smart-table',
      'motoristaModule',
      'uoModule',
      'relatorios',
      'cadastros.veiculo',
      'httpServiceV3gModule',
      'ModuleMeasurementUnitConverter',
      'ConfigEventosModule'
    ])
    .factory('Bdm', Restangular => {
      const url = 'boletim/bdm';

      let service = Restangular.service(url);

      service.getDetalhes = (motoristaId, dia) => {
        return Restangular.one(`${url}/detalhes?motoristaId=${motoristaId}&dia=${dia}`).get();
      };

      return service;
    });
}());
