(function () {
  'use strict';
  const now = new Date(),
      lastTenMinutes = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() - 10, now.getSeconds()),
      lastOneHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() - 1, now.getMinutes(), now.getSeconds()),
      lastTwoDays = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2, now.getHours(), now.getMinutes(), now.getSeconds()),
      getStatusVeiculoMock = [
          {
            prefixo: 'V1',
            placa: 'QBO5620',
            situacaoVeiculo: 'LIBERADO',
            status: 'Movimento',
            statusGps: true,
            gsm: false,
            meio: 'GSM',
            vei: 1,
            uoId: 957,
            uoNome: 'VELTEC S/A',
            grupoNome: 'Veículos leves',
            lng: -55.431427,
            lat: -10.819852,
            data: '2018-09-18T17:34:42Z',
            logra: 'MT-320',
            local: 'Colíder - Mato Grosso',
            vel: 57,
            hodo: 69677015,
            dir: 121,
            motNome: 'Antônio Nunes',
            motoristaId: 132,
            motApel: 'Tônin',
            motTel: '+55 (43) 9898-5050',
            motCpf: '01669107167',
            bloqueioPartida: true,
            videoVitalSigns: {
              cameras: 13,
              lastConnection: lastOneHour.toISOString(),
              lastBehaviorEvent: lastTenMinutes.toISOString(),
              lastVideo: lastTwoDays.toISOString(),
              lastPhoto: lastTenMinutes.toISOString(),
              statusConnection: 'RELEASED',
              statusBehaviorEvent: 'RELEASED',
              statusVideo: 'RELEASED',
              statusPhoto: 'RELEASED',
              videoSemaphoreStatus: 'RELEASED',
              storageHealth: 'HEALTHY'
            }
          },
          {
            data: '2021-04-20T20:14:25Z',
            dir: 0,
            fontAliment: 'PRINCIPAL',
            grupoNome: 'DEOVAN',
            gsm: false,
            hodo: 213555625,
            lat: -23.322643,
            lng: -51.192665,
            local: 'Londrina - Paraná',
            logra: 'Rodovia Celso Garcia Cid',
            meio: 'VMOV',
            motApel: 'Bart',
            motCpf: '60428016944',
            motNome: 'Bartholomeu',
            motTel: '',
            motoristaId: 77990,
            obs: '',
            placa: 'VVV1010',
            prefixo: 'VSAFE 4.0',
            situacaoVeiculo: 'LIBERADO',
            status: 'Parado',
            statusGps: false,
            uoId: 2351,
            uoNome: 'GRUPO NAKAMURA',
            vei: 90561,
            vel: 0,
            videoVitalSigns: {
              cameras: 2,
              lastConnection: lastTwoDays.toISOString(),
              lastBehaviorEvent: lastTenMinutes.toISOString(),
              lastVideo: lastTenMinutes.toISOString(),
              lastPhoto: lastTenMinutes.toISOString(),
              statusConnection: 'REQUIRES_ATTENTION',
              statusBehaviorEvent: 'RELEASED',
              statusVideo: 'RELEASED',
              statusPhoto: 'RELEASED',
              videoSemaphoreStatus: 'REQUIRES_ATTENTION',
              storageHealth: 'HEALTHY'
            }
          },
          {
            data: '2021-04-20T20:14:25Z',
            dir: 0,
            fontAliment: 'PRINCIPAL',
            grupoNome: 'DEOVAN',
            gsm: false,
            hodo: 213555625,
            lat: -23.322643,
            lng: -51.192665,
            local: 'Londrina - Paraná',
            logra: 'Rodovia Celso Garcia Cid',
            meio: 'VMOV',
            motApel: 'Bart',
            motCpf: '60428016944',
            motNome: 'Bartholomeu',
            motTel: '',
            motoristaId: 77990,
            obs: '',
            placa: 'VVV2020',
            prefixo: 'VSAFE',
            situacaoVeiculo: 'LIBERADO',
            status: 'Parado',
            statusGps: false,
            uoId: 2351,
            uoNome: 'GRUPO NAKAMURA',
            vei: 90561,
            vel: 0,
            videoVitalSigns: {
              cameras: 8,
              lastConnection: lastOneHour.toISOString(),
              lastBehaviorEvent: lastTenMinutes.toISOString(),
              lastVideo: lastTwoDays.toISOString(),
              lastPhoto: lastTenMinutes.toISOString(),
              statusConnection: 'REQUIRES_ATTENTION',
              statusBehaviorEvent: 'RELEASED',
              statusVideo: 'EVALUATE_URGENTLY',
              statusPhoto: 'RELEASED',
              videoSemaphoreStatus: 'EVALUATE_URGENTLY',
              storageHealth: 'HEALTHY'
            }
          },
          {
            data: '2021-04-20T21:14:25Z',
            dir: 0,
            fontAliment: 'PRINCIPAL',
            grupoNome: 'TESTE-PAULO',
            gsm: false,
            hodo: 213555625,
            lat: -23.322643,
            lng: -51.192665,
            local: 'Londrina - Paraná',
            logra: 'Rodovia Celso Garcia Cid',
            meio: 'VMOV',
            motApel: 'Bart',
            motCpf: '60428016944',
            motNome: 'Bartholomeu',
            motTel: '',
            motoristaId: 77990,
            obs: '',
            placa: 'PFR0001',
            prefixo: 'VSAFE',
            situacaoVeiculo: 'LIBERADO',
            status: 'Parado',
            statusGps: false,
            uoId: 2351,
            uoNome: 'GRUPO NAKAMURA',
            vei: 90561,
            vel: 0,
            videoVitalSigns: {
              cameras: 8,
              lastVideo: lastTwoDays.toISOString(),
              lastPhoto: lastTenMinutes.toISOString(),
              statusVideo: 'EVALUATE_URGENTLY',
              statusPhoto: 'RELEASED',
              videoSemaphoreStatus: 'EVALUATE_URGENTLY',
              storageHealth: 'HEALTHY'
            }
          },
          {
            data: '2021-04-20T20:14:25Z',
            dir: 0,
            fontAliment: 'PRINCIPAL',
            grupoNome: 'DEOVAN',
            gsm: false,
            hodo: 213555625,
            lat: -23.322643,
            lng: -51.192665,
            local: 'Londrina - Paraná',
            logra: 'Rodovia Celso Garcia Cid',
            meio: 'VMOV',
            motApel: 'Bart',
            motCpf: '60428016944',
            motNome: 'Bartholomeu',
            motTel: '',
            motoristaId: 77990,
            obs: '',
            placa: 'VVV3020',
            prefixo: 'VSAFE',
            situacaoVeiculo: 'LIBERADO',
            status: 'Parado',
            statusGps: false,
            uoId: 2351,
            uoNome: 'GRUPO NAKAMURA',
            vei: 90561,
            vel: 0
          }
      ];

  angular
    .module('enumModule')
    .constant('MockGetStatusVeiculo', getStatusVeiculoMock);
}());
