(function () {
  'use strict';

  const getListBuscaRegiaoResult = {
    data: [
        {
          veiculoId: 999,
          placa: 'ABC1234',
          prefixo: 'ABC 1234',
          datahora: new Date().toISOString(),
          latitude: -23.31531166,
          longitude: -51.15512166
        },
        {
          veiculoId: 888,
          placa: 'DEF5678',
          prefixo: 'DEF 5678',
          datahora: new Date().toISOString(),
          latitude: -23.31531166,
          longitude: -51.15512166
        }
    ]
  };

  angular
    .module('enumModule')
    .constant('MockGetListBuscaRegiao', getListBuscaRegiaoResult);
}());
