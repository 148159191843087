(function () {
  'use strict';

  angular
    .module('cadastros.grupoVeic', [
      'ui.router',
      'ui.bootstrap',
      'restangular',
      'ServiceVfiltroModule',
      'ObjetosVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'modalInfoConfirmModule',
      'VtabelaModule',
      'alert-message',
      'enumModule',
      'pascalprecht.translate',
      'httpServiceV3gModule',
      'ngSanitize'
    ])
    .factory('GrupoVeic', Restangular => Restangular.service('/veiculo/veiculos/grupos'));
}());
