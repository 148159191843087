(function () {
  'use strict';
  class ToggleLayerMarkersCtrl {
    constructor(MapLayerVisibility, Authenticator, MapasUtil, $scope) {
      this.mapLayerConstant = MapLayerVisibility;
      this.authenticator = Authenticator;
      this.mapasUtil = MapasUtil;
      this.scope = $scope;

      this.user = null;
      this.options = [
        {
          name: this.mapLayerConstant.PONTO_REFERENCIA,
          label: 'ce.common.permanencia.pontoReferencia',
          shouldShow: true
        },
        {
          name: this.mapLayerConstant.CERCA_ELETRONICA,
          label: 'ce.common.permanencia.cercaEletronica',
          shouldShow: true
        }
      ];
      this.scope.$on('layerVisibilityHasChanged', (event, entityType, shouldShow) => {
        this.updateCheckboxValue(entityType, shouldShow);
      });
    }

    $onInit() {
      this.authenticator.getUser().then(user => {
        this.user = user;
        this.populateOptionsWithUserPreferences();
      });
    }

    onSelectOption(optionName, optionvalue) {
      this.savePreferencesInLocalStorage();
      this.mapasUtil.notifyLayersVisibilityChange(optionName, optionvalue);
    }

    populateOptionsWithUserPreferences() {
      const preferencesLocalStorage = this.loadPreferencesFromLocalStorage();
      if (preferencesLocalStorage) {
        preferencesLocalStorage.forEach((preference) => {
          this.updateCheckboxValue(preference.name, preference.shouldShow);
        });
      }
    }

    updateCheckboxValue(entityType, newValue) {
      let option = this.options.find((checkbox) => checkbox.name === entityType);
      option.shouldShow = newValue;
    }

    loadPreferencesFromLocalStorage() {
      return this.mapasUtil.getLayersVisibilityLocalStorage(this.user.id);
    }

    savePreferencesInLocalStorage() {
      const toPreference = this.options.map(({name, shouldShow}) => ({name, shouldShow}));
      this.mapasUtil.setLayersVisibilityLocalStorage(this.user.id, toPreference);
    }
  }

  angular
    .module('mapas')
    .component('toggleLayerMarkers', {
      templateUrl: 'mapas/toggle-layer-markers/toggle-layer-markers-component.tpl.html',
      controller: ToggleLayerMarkersCtrl
    });
}());
