(function () {
  'use strict';

  const NAO_CADASTRADO = -1;

  class VUiSelectCtrl {

    checkEscapeKey(evento) {
      if (evento.keyCode === 27) {
        this.model = '';
      }
    }

    onSelectedElement(select) {
      this.onSelection({elemento: select});
    }

    itemCadastrado(element) {
      return element.id !== NAO_CADASTRADO;
    }

    refreshResults($select) {
      var search = $select.search, list = angular.copy($select.items), userInputItem = {};
      list = list.filter((item) =>item.id !== NAO_CADASTRADO);

      userInputItem = {
        id: NAO_CADASTRADO,
        nome: search
      };

      if (!search) {
        userInputItem.nome = userInputItem.nome;
        $select.items = [userInputItem].concat(list);
      }
    }
  }

  angular
    .module('vUiSelectModule', [])
    .component('vUiSelect', {
      controller: VUiSelectCtrl,
      templateUrl: 'directives/v-ui-select/v-ui-select.tpl.html',
      bindings: {
        name: '@',
        placeholder: '@',
        info: '@',
        model: '=',
        elements: '=',
        onSelection: '&'
      }
    });
}());
