(function () {
  'use strict';

  class MenuExcluirCercaEletronicaCtrl {
    constructor(CercaEletronicaMapService, PontosReferenciaMenu, CercaEletronicaService, AlertMessage, $translate) {
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.cercaEletronicaService = CercaEletronicaService;
      this.alertMessage = AlertMessage;
      this.translate = $translate;
      this.init();
    }

    init() {
      const buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtExcluirCerca',
                icon: 'fa fa-check',
                color: 'success',
                tipo: 'default',
                funcao: this.excluirCercaEletronica.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'editar-cerca-eletronica'
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMenuService.drawerClose();
      this.pontosReferenciaMenuService.menuOpen();
    }

    excluirCercaEletronica() {
      const idCercaEletronica = this.cercaEletronicaMapService.cercaEletronicaSelecionadaLayer.cercaEletronica.id;
      this.cercaEletronicaService.deleteCercaEletronica(idCercaEletronica)
        .then(() => {
          this.alertMessage.create({
            type: 'success',
            message: this.translate.instant('ce.mapa.common.menuExcluirCerca.excluirCercaEletronicaSucess'),
            appendTo: '.alerta-aqui-ponto'
          });
          this.cercaEletronicaMapService.excluirCercaEletronica(idCercaEletronica);
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.common.menuExcluirCerca.excluirCercaEletronicaError'),
            appendTo: '.alerta-aqui-ponto'
          });
        });

      this.pontosReferenciaMenuService.setFABDefault();
      this.pontosReferenciaMenuService.menuClose();
      this.cercaEletronicaMapService.setCercaEletronicaSelecionada(null);
    }
  }

  angular
    .module('mapControlModule')
    .component('menuExcluirCercaEletronica', {
      controller: MenuExcluirCercaEletronicaCtrl,
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controllerAs: 'ctrl'
    });
}());
