(function () {
  'use strict';

  /* global _ */
  function alertaDiaOnePageCardDirective() {
    return {
      templateUrl: 'one-page/directives/alerta-dia/card/alerta-dia-one-page-card.tpl.html',
      controller: 'AlertaDiaOnePageCardCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class AlertaDiaOnePageCardCtrl {
    constructor($scope, $window, AlertaVeiculoService, Authenticator, FiltroAlertaService, Keys, Restangular, $translate) {
      this.scope = $scope;
      this.window = $window;
      this.alertaVeiculoService = AlertaVeiculoService;
      this.authenticator = Authenticator;
      this.filtroAlertaService = FiltroAlertaService;
      this.keys = Keys;
      this.restangular = Restangular;
      this.translate = $translate;

      this.alertas = {data: null, erroSemData: false};
      this.onePageCtrl = this.scope.$parent.$parent.onePage;
      this.visualizarHistoricoAlerta = false;

      this.scope.$on('$destroy', () => {
        this.alertaVeiculoService.removeCallbackPorTipo('onepage');
        this.alertaVeiculoService.stop();
      });

      this.authenticator.getUser().then(user => {
        if (this.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.visualizarHistoricoAlerta = true;
        }
        this.uoId = user.uo.id;
        this.init();
      });

      this.alertasDesativados = [
        'VEICULO_SEM_SINAL'
      ];
    }

    init() {
      this.alertaVeiculoService.setUoId(this.uoId);
      this.alertaVeiculoService.checkAlertaPorTipo();
      if (this.alertaVeiculoService.listaCacheada) {
        this.alertas.data = this.buildCardAlerta(this.alertaVeiculoService.listaCacheada);
      } else {
        this.verificarAlertasInativos();
      }

      this.alertaVeiculoService.onUpdateAlertaPorTipo(data => this.alertas.data = this.buildCardAlerta(data), 'onepage');
      this.alertaVeiculoService.onUpdateAlertaPorTipoError((error) => {
        if (error) {
          this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
        }
      }, 'onepageError');
    }

    verificarAlertasInativos() {
      this.restangular.one(`configuracao/configuracoes/${this.uoId}/alerta`).get()
      .then(alertas => {
        this.erroAlertasInativos = _.values(alertas.configuracoes).filter(item => item.ativo).length === 0;
        if (this.erroAlertasInativos) {
          this.mensagemDeErro = 'ce.tempoReal.statusFrota.charts.alertaSeguranca.nenhumAlertaAtivo';
          this.linkConfigAlertas = this.getUrlConfigAlertas();
        } else if (!this.erroAlertasInativos) {
          this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.mensagemSucesso');
        }
      }, () => {
        this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
      });
    }

    buildCardAlerta(data) {
      let retorno = [];
      this.erroSemData = true;

      if (data && data.length > 0 && data.filter(x => x.quantidade).length > 0) {
        data.filter(x => x.quantidade > 0 && this.alertasDesativados.indexOf(x.key) === -1)
          .forEach((item) => {
            if (this.erroSemData) {
              this.erroSemData = false;
            }

            retorno.push({
              name: item.texto,
              data: item.quantidade,
              color: item.chartColor,
              key: item.key
            });
          }
        );
      } else {
        this.verificarAlertasInativos();
      }

      return retorno;
    }

    goToHistoricoAlerta(item) {
      return this.filtroAlertaService.filtroLink(this.onePageCtrl.getParamValue(this.keys.uo.name), item);
    }

    getUrlConfigAlertas() {
      return `/configuracoes-alertas?vfiltro=uo:${this.uoId}`;
    }
  }

  angular
    .module('onePageModule')
    .controller('AlertaDiaOnePageCardCtrl', AlertaDiaOnePageCardCtrl)
    .directive('alertaDiaOnePageCardDirective', alertaDiaOnePageCardDirective);
}());
