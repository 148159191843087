(function () {
  'use strict';

  angular
    .module('indicadores.powerBi', [
      'ui.router',
      'powerbi',
      'restangular',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule'
    ]);
}());
