(function () {
  'use strict';

  function InputCheckbox() {
    return {
      templateUrl: `configuracoes/components/checkbox/input-checkbox.tpl.html`,
      controllerAs: 'ctrl',
      restrict: 'E',
      replace: true,
      scope: {
        model: '=',
        disabled: '=',
        name: '@',
        change: '='
      }
    };
  }

  angular
    .module('InputCheckboxModule', [])
    .directive('inputCheckbox', InputCheckbox);
}());
