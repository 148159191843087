(function () {
  'use strict';

  class ExcluirUploadProntuarioCtrl {
    constructor($uibModalInstance, Restangular, AlertMessage,
      motoristaId, arquivo, $translate) {
      this.modalInstance = $uibModalInstance;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.translate = $translate;

      this.motoristaId = motoristaId;
      this.arquivo = arquivo;
    }

    excluir() {
      this.restangular.all(this.getURLDelete())
      .remove()
      .then(() => {
        this.modalInstance.close();
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.ranking.motoristas.prontuario.modal.erroSalvar'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    getURLDelete() {
      return `/motorista/motoristas/${this.motoristaId}/infracoes-transito/${this.arquivo.id}`;
    }

    isMulta() {
      return this.arquivo && this.arquivo.anexoDocumento && this.arquivo.anexoDocumento.tipo === 'INFRACAO';
    }

    dismiss() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('ProntuarioModule')
    .controller('ExcluirUploadProntuarioCtrl', ExcluirUploadProntuarioCtrl);
}());
