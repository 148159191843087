(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.rankingPontos', {
      url: '/ranking/motoristas?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'ranking/pontos/pontos.tpl.html',
          controller: 'RankingPontosCtrl',
          controllerAs: 'rankingPontos'
        }
      },
      data: {
        titulo: 'ce.ranking.pontos.titulo',
        role: [['RANKING_GERAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }

  angular
    .module('ranking.Pontos')
    .config(pageConfig);
}());
