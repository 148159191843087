/* global document */
/* eslint-disable angular/log */
(function () {
  'use strict';

  class IdentificacoesCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, Keys, moment) {
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.moment = moment;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          key: this.keys.dateTime.name,
          dateLimit: 1,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          name: 'veiculosId',
          key: this.keys.veiculos.name,
          size: 6
        },
        {
          name: 'motorista_id',
          key: this.keys.motoristas.name,
          size: 6
        },
        {
          name: 'identificacao',
          key: this.keys.identificacao.name,
          size: 6
        }
      ]))
        .then((filtro) => {
          this.roleMotoristaEditar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('MOTORISTA_EDITAR');
          this.roleVideoVisualizar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VIDEO_VISUALIZAR');
          this.init(filtro);
        });
    }

    init(filtro) {
      /*eslint-disable */
      const tableIdentificacoesElement = document.querySelector('#table-identificacoes'),
        identificacaoesListElement = document.createElement('vfwc-identificacoes-list'),
        dataFiltered = filtro.find(f => f.key === 'dateTime'),
        veiculosFiltered = filtro.find(f => f.key === 'veiculos'),
        motoristasFiltered = filtro.find(f => f.key === 'motoristas'),
        identificacaoFiltered = filtro.find(f => f.key === 'identificacao'),
        veiculoIds = veiculosFiltered ? veiculosFiltered.values.map(veiculo => veiculo.id) : veiculosFiltered,
        motoristaIds = motoristasFiltered ? motoristasFiltered.values.map(motorista => motorista.id) : motoristasFiltered,
        isIdentificacao = identificacaoFiltered ? identificacaoFiltered.value.isIdentificado : null;

      identificacaoesListElement.uoId = Number(filtro.find(f => f.key === 'uo').value.id);
      identificacaoesListElement.dataInicio = dataFiltered.value.startDate;
      identificacaoesListElement.dataFim = dataFiltered.value.endDate;
      identificacaoesListElement.veiculoIds = veiculoIds;
      identificacaoesListElement.motoristaIds = motoristaIds;
      identificacaoesListElement.isIdentificacao = isIdentificacao;
      identificacaoesListElement.hasPermissionEditMotorista = this.roleMotoristaEditar;
      identificacaoesListElement.hasPermissionViewVideo = this.roleVideoVisualizar;
      identificacaoesListElement.user = this.serviceVfiltro.factoryVfiltro.user;

      tableIdentificacoesElement.appendChild(identificacaoesListElement);
    }
  }

  angular
    .module('relatorios.identificacoes')
    .controller('IdentificacoesCtrl', IdentificacoesCtrl);
}());
