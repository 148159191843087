(function () {
  'use strict';

  function pageConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/relatorios/solicitacao-videos', ['$state', ($state) => {
      $state.go('root.videoLibrary.solicitacaoVideos');
    }]);

    return $stateProvider.state('root.videoLibrary.solicitacaoVideos', {
      url: 'solicitacao-videos?vfiltro=',
      views: {
        '': {
          templateUrl: 'relatorios/video-library/solicitacao-videos/solicitacao-videos.tpl.html',
          controller: 'SolicitacaoVideosCtrl',
          controllerAs: 'solicitacaoVideos'
        }
      },
      data: {
        roles: [['VPLAYER_REQMANUAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }
  angular
    .module('relatorios.videoLibrary.solicitacaoVideos')
    .config(pageConfig);
}());
