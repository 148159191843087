(function () {
  'use strict';

  /* global _ */
  const comVideoStatus = ['CONCLUIDO', 'ASSISTIDO'],
      processandoStatus = ['CADASTRADO', 'BAIXANDO', 'CONVERTENDO', 'PENDENTE'],
      semVideoStatus = ['INDISPONIVEL', 'ERRO'],
      COM_VIDEO_ID = 'COM_VIDEO',
      PROCESSANDO_ID = 'PROCESSANDO',
      SEM_VIDEO_ID = 'SEM_VIDEO',
      statusVideo = {
        getAgrupadores: () => [COM_VIDEO_ID, PROCESSANDO_ID, SEM_VIDEO_ID],
        getByAgrupador: (agrupador) => {
          switch (agrupador) {
            case COM_VIDEO_ID:
              return comVideoStatus;
            case PROCESSANDO_ID:
              return processandoStatus;
            case SEM_VIDEO_ID:
              return semVideoStatus;
            default:
              return undefined;
          }
        },
        getAll: () => _.flatten([comVideoStatus, processandoStatus, semVideoStatus])
      };

  angular
    .module('statusVideo', [])
    .constant('StatusVideo', statusVideo);
}());
