(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.videoLibrary', {
      url: '/relatorios/biblioteca-videos/',
      views: {
        'content@': {
          templateUrl: 'relatorios/video-library/video-library.tpl.html',
          controller: 'VideoLibraryCtrl'
        }
      },
      data: {
        titulo: 'ce.analise.videoLibrary.menuLabel',
        roles: [['VPLAYER_REQMANUAL_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }

  angular
    .module('relatorios.videoLibrary')
    .config(pageConfig);
}());
