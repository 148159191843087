(function () {
  'use strict';

  class ModalResetVdrivers {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }

    ok() {
      this.modalInstance.close();
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('ModalVdrivers')
    .controller('ModalResetVdriversCtrl', ModalResetVdrivers);
}());
