(function () {
  'use strict';

  class PesosEventosCtrl {
    constructor($scope, $uibModal, Restangular, AlertMessage, AlertasEnum,
      Keys, ServiceVfiltro, FactoryVfiltro, $translate, ConfigEventosService, $q) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.alertasEnum = AlertasEnum;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;
      this.configEventosService = ConfigEventosService;
      this.q = $q;

      this.uoId = 0;
      this.roleCadastrar = true;
      this.model = {configuracoes: []};

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.uoId = filtro.find(x => x.key === this.keys.uo.name).value.id;
        this.roleCadastrar = this.temPermissaoParaConfigurar();
        this.init();
      });
    }

    temPermissaoParaConfigurar() {
      return this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_PESO_EVENTO_EDITAR') &&
      this.serviceVfiltro.factoryVfiltro.user.uo.uoPai.id === 1 &&
      this.serviceVfiltro.factoryVfiltro.user.uo.id === this.uoId;
    }

    init() {
      this.q.all([
        this.restangular.one(`configuracao/configuracoes/${this.uoId}/pontuacao`).get(),
        this.configEventosService.getConfigEventos()
      ])
      .then(([alertas, configEventos]) => this.setConfiguracoes(alertas, configEventos))
      .catch(() => this.alertMessage.create({
        type: 'error',
        message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.msgErroCarregarConfiguracoes'),
        appendTo: '.alerta-aqui-geral'
      }));
    }

    setConfiguracoes(alertas, configEventos) {
      let excVel = [],
          excVelVia = [],
          curvaBrusca = [],
          cameraObstruida = [];

      this.model.uoId = alertas.uoId;
      this.model.heranca = alertas.heranca;
      this.model.configuracoes = alertas.configuracoes
      .filter(alerta =>
        alerta.tipoOcorrencia !== this.alertasEnum.DIAGNOSTICO_OBD.id &&
        alerta.tipoOcorrencia !== this.alertasEnum.MANUTENCAO_PROGRAMADA.id &&
        alerta.tipoOcorrencia !== 'TREPIDACAO' &&
        alerta.tipoOcorrencia !== this.alertasEnum.VEICULO_SEM_SINAL.id &&
        alerta.tipoOcorrencia !== this.alertasEnum.PREVISAO_COLISAO.id &&
        alerta.tipoOcorrencia !== this.alertasEnum.RFID_NAO_CADASTRADO.id &&
        alerta.tipoOcorrencia !== this.alertasEnum.MANUTENCAO_PROGRAMADA_HORIMETRO.id &&
        alerta.tipoOcorrencia !== this.alertasEnum.PANICO.id
      )
      .map(alerta => {
        const alertaEnum = this.alertasEnum.getAlerta(alerta.tipoOcorrencia);
        if (alertaEnum) {
          if (this.alertasEnum.isExcessoVelocidade(alertaEnum.id)) {
            excVel.push(alerta);
            return null;
          } else if (this.alertasEnum.isExcessoVelocidadeVia(alertaEnum.id)) {
            excVelVia.push(alerta);
            return null;
          } else if (alertaEnum && this.alertasEnum.CURVA_BRUSCA.id === alertaEnum.id) {
            curvaBrusca.push(alerta);
            return null;
          } else if (alertaEnum && this.alertasEnum.CAMERA_OBSTRUIDA.id === alertaEnum.id) {
            cameraObstruida.push(alerta);
            return null;
          }
          return angular.extend(alerta, this.extendDefault(alerta, alertaEnum, null, []));
        }
        return null;
      })
      .filter(x => !!x && x.alertaEnum.isEnabled(configEventos));

      this.buildExcessoVelocidade(
        excVel,
        this.alertasEnum.EXCESSO_VELOCIDADE
      );
      this.buildExcessoVelocidade(
        excVelVia,
        this.alertasEnum.EXCESSO_VELOCIDADE_POR_VIA
      );
      this.buildExcessoVelocidade(
        curvaBrusca,
        this.alertasEnum.CURVA_BRUSCA
      );
      this.buildExcessoVelocidade(
        cameraObstruida,
        this.alertasEnum.CAMERA_OBSTRUIDA
      );
    }

    buildExcessoVelocidade(
      lista,
      alertaEnum
    ) {
      if (lista.length > 0) {
        lista = lista.map(alerta =>
          angular.extend(
            alerta,
            this.extendDefault(alerta, this.alertasEnum.getAlerta(alerta.tipoOcorrencia), null, [])
          )
        );
        this.model.configuracoes.push(angular.extend({
          lista
        }, this.extendDefault({}, alertaEnum, lista)));
      }
    }

    extendDefault(alerta, alertaEnum, lista) {
      return {
        alertaEnum,
        backupMultiplicadorUnitario: alerta.multiplicadorUnitario >= 0 ? angular.copy(alerta.multiplicadorUnitario) : null,
        backupMultiplicadorTempo: alerta.multiplicadorTempo >= 0 ? angular.copy(alerta.multiplicadorTempo) : null,
        backupLista: angular.copy(lista) || []
      };
    }

    checkError(error, alertaEnum, alertaLista) {
      return error && !!Object.keys(error)
      .find(tipo => !!error[tipo]
        .find(item => {
          if (alertaLista && alertaLista.length > 0) {
            return !!alertaLista.find(alerta => alerta.alertaEnum.id === item.$name.split('.')[1]);
          }
          return item.$name.split('.')[1] === alertaEnum.id;
        })
      );
    }

    descartarAlteracoes() {
      this.model.configuracoes = this.model.configuracoes.map(item => {
        if (item.backupMultiplicadorUnitario >= 0) {
          item.multiplicadorUnitario = angular.copy(item.backupMultiplicadorUnitario);
        }
        if (item.backupMultiplicadorTempo >= 0) {
          item.multiplicadorTempo = angular.copy(item.backupMultiplicadorTempo);
        }
        if (item.backupLista.length > 0) {
          item.lista = angular.copy(item.backupLista);
        }
        return item;
      });
    }

    abrirModal() {
      this.modalService.open({
        animation: true,
        templateUrl: 'configuracoes/eventos/modal/reset/reset-eventos.tpl.html',
        controller: 'ModalResetEventosCtrl',
        controllerAs: '$ctrl'
      })
      .result
      .then(() => {
        this.salvar(true);
      });
    }

    buildConfiguracoes(item) {
      let itemFinal = {tipoOcorrencia: item.tipoOcorrencia};

      if (item.multiplicadorUnitario >= 0) {
        itemFinal.multiplicadorUnitario = item.multiplicadorUnitario;
      }
      if (item.multiplicadorTempo >= 0) {
        itemFinal.multiplicadorTempo = item.multiplicadorTempo;
      }

      return itemFinal;
    }

    getConfiguracoes() {
      let lista = [];

      this.model.configuracoes.forEach(item => {
        if (item.backupLista.length > 0) {
          item.lista.forEach(alerta => lista.push(this.buildConfiguracoes(alerta)));
        } else {
          lista.push(this.buildConfiguracoes(item));
        }
      });

      return lista;
    }

    salvar(heranca) {
      const config = this.getConfiguracoes(),
          pontuacao = {
            uoId: this.uoId,
            heranca,
            configuracoes: config
          };

      Promise.all([
        this.restangular.all(`configuracao/configuracoes/${this.uoId}/pontuacao`).post(pontuacao)
      ])
      .then(([pesos]) => {
        if (pesos) {
          this.rebuildPesos(pesos);
        }
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.botaoRestaurarConfiguracoes.modal.erroSalvarConfiguracoes'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    rebuildPesos(pesos) {
      this.scope.formPesos.$setPristine();
      this.setConfiguracoes(pesos);
      this.alertMessage.create({
        type: 'success',
        message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.botaoRestaurarConfiguracoes.modal.salvoComSucesso'),
        appendTo: '.alerta-aqui'
      });
      this.scope.$apply();
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('PesosEventosCtrl', PesosEventosCtrl);
}());
