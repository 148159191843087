(function () {
  'use strict';

  class CategoriaPontosReferencia {

    constructor(Restangular, HttpServiceV3g, AGRUPAMENTO_EQUIPAMENTO) {
      this.restangular = Restangular;
      this.httpService = HttpServiceV3g;
      this.agrupamentoDeEquipamentos = AGRUPAMENTO_EQUIPAMENTO;
      this.categoriaDefault = {id: 0, nome: 'Sem categoria', cor: '#000000'};
    }

    getListCategoriasPontoReferencia() {
      return this.restangular.all('ponto-referencia/categorias-ponto-referencia').getList();
    }

    getCategoriaPontoReferencia(categoriaId) {
      return this.restangular.one(`ponto-referencia/categorias-ponto-referencia/${categoriaId}`).get();
    }

    postCategoriaPontoReferencia(item) {
      return this.restangular.all('ponto-referencia/categorias-ponto-referencia').post(item);
    }

    putCategoriaPontoReferencia(item) {
      return this.restangular.all(`ponto-referencia/categorias-ponto-referencia/${item.id}`).customPUT(item);
    }

    deleteCategoriaPontoReferencia(categoriaId) {
      return this.restangular.all(`ponto-referencia/categorias-ponto-referencia/${categoriaId}`).remove();
    }

    getCategoriaAudios(uoId) {
      return this.restangular.all('ponto-referencia/v2/categoria-audio').getList({uoId});
    }

    hasVtalks() {
      return this.restangular
        .one('veiculo/v2/veiculos')
        .get({
          agrupamentosEquipamentos: this.agrupamentoDeEquipamentos.VTALKS,
          size: 1
        }).then(responseVeiculos => Boolean(responseVeiculos.numberOfElements));
    }
  }

  angular
    .module('mapControlModule')
    .service('CategoriaPontosReferencia', CategoriaPontosReferencia);
}());
