(function () {
  'use strict';

  angular
    .module('relatorios.videoLibrary.solicitacaoVideos', [
      'ui.router',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'angularMoment',
      'v3gAuthenticatorModule',
      'pascalprecht.translate'
    ]);
}());
