(function () {
  'use strict';

  angular
    .module('mapControlModule')
    .directive('focusPontoReferenciaDirective', (PontosReferenciaMap) => {
      return {
        restrict: 'A',
        link: function (scope, element, attr) {
          element.on('click', () => PontosReferenciaMap.centerPontoId(Number(attr.focusPontoReferenciaDirective)));
        }
      };
    });
}());
