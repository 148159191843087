(function () {
  'use strict';

  function botaoUploadDirective() {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.bind('click', function (e) {
          angular.element(e.target).siblings('#upload').trigger('click');
        });
      }
    };
  }

  angular
    .module('conducaoEconomica')
    .directive('botaoUpload', botaoUploadDirective);
}());
