(function () {
  'use strict';

  angular
    .module('relatorios.bdv')
    .constant('MockBDV', [
      {
        id: 1,
        data: {
          total: 2,
          list: [
            {
              motoristas: 'André Castanheira',
              veiculo: {id: 1, prefixo: 'VW81502', placa: 'AOI7238'},
              dia: '2016-01-15',
              excessosVelocidade: 15,
              aceleracoes: 8,
              frenagens: 2,
              curvas: 10,
              trepidacoes: 0,
              banguelas: 0,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 5035,
              distanciaPercorridaConvertida: 5.04,
              tempoDirecao: 3600,
              hodometroFim: 5078102
            },
            {
              motoristas: 'Silvério Mansilla',
              veiculo: {id: 1, prefixo: 'VW81502', placa: 'AOI7238'},
              dia: '2016-01-15',
              excessosVelocidade: 7,
              aceleracoes: 0,
              frenagens: 0,
              curvas: 1,
              trepidacoes: 0,
              banguelas: 3,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 15040,
              distanciaPercorridaConvertida: 15.04,
              tempoDirecao: 3024,
              hodometroFim: 9078101
            }
          ]
        }
      },
      {
        id: 2,
        data: {
          total: 2,
          list: [
            {
              motoristas: 'Gil Larangeira',
              veiculo: {id: 2, prefixo: 'FSADF45', placa: 'VXZ9045'},
              dia: '2016-01-15',
              excessosVelocidade: 22,
              aceleracoes: 0,
              frenagens: 0,
              curvas: 0,
              trepidacoes: 0,
              banguelas: 0,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 5840,
              distanciaPercorridaConvertida: 5.84,
              tempoDirecao: 2043,
              hodometroFim: 2372810
            },
            {
              motoristas: 'Iberê Lages',
              veiculo: {id: 2, prefixo: 'FSADF45', placa: 'VXZ9045'},
              dia: '2016-01-15',
              excessosVelocidade: 6,
              aceleracoes: 0,
              frenagens: 2,
              curvas: 9,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 7345,
              distanciaPercorridaConvertida: 7.35,
              tempoDirecao: 1068,
              hodometroFim: 1273810
            }
          ]
        }
      },
      {
        id: 3,
        data: {
          total: 2,
          list: [
            {
              motoristas: 'André Castanheira',
              veiculo: {id: 3, prefixo: 'VCBZ45', placa: 'UKT3556'},
              dia: '2016-01-15',
              excessosVelocidade: 15,
              aceleracoes: 8,
              frenagens: 2,
              curvas: 10,
              trepidacoes: 0,
              banguelas: 0,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 5035,
              distanciaPercorridaConvertida: 5.04,
              tempoDirecao: 3600,
              hodometroFim: 5072810
            },
            {
              motoristas: 'Iberê Lages',
              veiculo: {id: 3, prefixo: 'VCBZ45', placa: 'UKT3556'},
              dia: '2016-01-15',
              excessosVelocidade: 6,
              aceleracoes: 0,
              frenagens: 2,
              curvas: 9,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 7345,
              distanciaPercorridaConvertida: 7.35,
              tempoDirecao: 1068,
              hodometroFim: 1237810
            }
          ]
        }
      },
      {
        id: 4,
        data: {
          total: 2,
          list: [
            {
              motoristas: 'Iberê Lages',
              veiculo: {id: 4, prefixo: 'ANGA01', placa: 'ANR1131'},
              dia: '2016-01-15',
              excessosVelocidade: 6,
              aceleracoes: 0,
              frenagens: 2,
              curvas: 9,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorridaConvertida: 7.35,
              tempoDirecao: 1068,
              hodometroFim: 1271810
            },
            {
              motoristas: 'Silvério Mansilla',
              veiculo: {id: 4, prefixo: 'ANGA01', placa: 'ANR1131'},
              dia: '2016-01-15',
              excessosVelocidade: 7,
              aceleracoes: 0,
              frenagens: 0,
              curvas: 1,
              trepidacoes: 0,
              banguelas: 3,
              rpmExcessivos: 0,
              motorOcioso: 0,
              logradouroInicio: 'Rua Paraná',
              logradouroFim: 'Av. Higienópolis',
              distanciaPercorrida: 15040,
              distanciaPercorridaConvertida: 15.04,
              tempoDirecao: 3024,
              hodometroFim: 9037810
            }
          ]
        }
      }
    ])
    .constant('MockBDVDetalhes', {
      success: true,
      message: 'Success',
      data: [
        {
          veiculo: 'ANGA01 - ANR1131',
          motoristas: 'Sem identificação',
          logradouroInicio: '-',
          logradouroFim: 'Avenida Santos Dumont',
          inicio: '2016-01-15T09:18:41Z',
          fim: '2016-01-15T09:59:24Z',
          posicoes: [
            {
              latitude: 0,
              longitude: 0,
              direcao: 0,
              hodometro: 3598000,
              hodometroAjustado: -655100,
              velocidade: 0,
              gsm: true,
              evento: 'Ignição ligada',
              limiar: '-',
              intensidade: '-',
              gps: 'Inválido',
              duracao: 0,
              data: '2016-01-15T09:18:41.000Z'
            },
            {
              latitude: -23.412614822387695,
              longitude: -51.435218811035156,
              direcao: 36,
              hodometro: 0,
              hodometroAjustado: -4253100,
              velocidade: 39,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Inválido',
              duracao: 0,
              data: '2016-01-15T09:21:34.000Z'
            },
            {
              latitude: -23.411392211914062,
              longitude: -51.434043884277344,
              direcao: 74,
              hodometro: 3598000,
              hodometroAjustado: -655100,
              velocidade: 27,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:21:52.000Z'
            },
            {
              latitude: -23.410415649414062,
              longitude: -51.43329620361328,
              direcao: 38,
              hodometro: 3598000,
              hodometroAjustado: -655100,
              velocidade: 41,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:22:03.000Z'
            },
            {
              latitude: -23.4052734375,
              longitude: -51.429561614990234,
              direcao: 37,
              hodometro: 3599000,
              hodometroAjustado: -654100,
              velocidade: 49,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:23:15.000Z'
            },
            {
              latitude: -23.401506423950195,
              longitude: -51.427978515625,
              direcao: 79,
              hodometro: 3600000,
              hodometroAjustado: -653100,
              velocidade: 0,
              gsm: true,
              evento: 'Parado',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:24:26.000Z'
            },
            {
              latitude: -23.401302337646484,
              longitude: -51.427650451660156,
              direcao: 42,
              hodometro: 3600000,
              hodometroAjustado: -653100,
              velocidade: 33,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:24:32.000Z'
            },
            {
              latitude: -23.39692497253418,
              longitude: -51.425453186035156,
              direcao: 25,
              hodometro: 3600000,
              hodometroAjustado: -653100,
              velocidade: 77,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:25:00.000Z'
            },
            {
              latitude: -23.381549835205078,
              longitude: -51.417694091796875,
              direcao: 25,
              hodometro: 3602000,
              hodometroAjustado: -651100,
              velocidade: 72,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:26:45.000Z'
            },
            {
              latitude: -23.36176109313965,
              longitude: -51.39988327026367,
              direcao: 52,
              hodometro: 3605000,
              hodometroAjustado: -648100,
              velocidade: 74,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:28:32.000Z'
            },
            {
              latitude: -23.349027633666992,
              longitude: -51.38587951660156,
              direcao: 64,
              hodometro: 3607000,
              hodometroAjustado: -646100,
              velocidade: 83,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:29:59.000Z'
            },
            {
              latitude: -23.34894371032715,
              longitude: -51.384525299072266,
              direcao: 104,
              hodometro: 3607000,
              hodometroAjustado: -646100,
              velocidade: 86,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:30:05.000Z'
            },
            {
              latitude: -23.34955596923828,
              longitude: -51.381622314453125,
              direcao: 81,
              hodometro: 3607000,
              hodometroAjustado: -646100,
              velocidade: 91,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:30:17.000Z'
            },
            {
              latitude: -23.34955596923828,
              longitude: -51.381622314453125,
              direcao: 81,
              hodometro: 3607000,
              hodometroAjustado: -646100,
              velocidade: 91,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:30:17.000Z'
            },
            {
              latitude: -23.34859848022461,
              longitude: -51.37976837158203,
              direcao: 43,
              hodometro: 3607000,
              hodometroAjustado: -646100,
              velocidade: 84,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:30:26.000Z'
            },
            {
              latitude: -23.341352462768555,
              longitude: -51.372371673583984,
              direcao: 69,
              hodometro: 3608000,
              hodometroAjustado: -645100,
              velocidade: 101,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:31:17.000Z'
            },
            {
              latitude: -23.33977508544922,
              longitude: -51.35969161987305,
              direcao: 99,
              hodometro: 3610000,
              hodometroAjustado: -643100,
              velocidade: 109,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:32:02.000Z'
            },
            {
              latitude: -23.33977508544922,
              longitude: -51.35969161987305,
              direcao: 99,
              hodometro: 3610000,
              hodometroAjustado: -643100,
              velocidade: 109,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:32:02.000Z'
            },
            {
              latitude: -23.34037208557129,
              longitude: -51.35377883911133,
              direcao: 68,
              hodometro: 3610000,
              hodometroAjustado: -643100,
              velocidade: 107,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:32:23.000Z'
            },
            {
              latitude: -23.33758544921875,
              longitude: -51.34952163696289,
              direcao: 25,
              hodometro: 3611000,
              hodometroAjustado: -642100,
              velocidade: 104,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:32:41.000Z'
            },
            {
              latitude: -23.334304809570312,
              longitude: -51.34843444824219,
              direcao: 15,
              hodometro: 3611000,
              hodometroAjustado: -642100,
              velocidade: 125,
              gsm: true,
              evento: 'Excesso de Velocidade',
              limiar: 'Limite: 110 Km/h',
              intensidade: 'ponto de interesse: 65535',
              fim: {
                latitude: -23.32306480407715,
                longitude: -51.34444046020508,
                direcao: 42,
                hodometro: 3613000,
                hodometroAjustado: -640100,
                velocidade: 104,
                gsm: true,
                evento: 'Movimento',
                gps: 'Precisão alta',
                duracao: 0,
                data: '2016-01-15T09:33:32.000Z'
              },
              gps: 'Precisão alta',
              miscInfo: {
                sensorChuva: 0
              },
              duracao: 39,
              data: '2016-01-15T09:32:53.000Z'
            },
            {
              latitude: -23.32254981994629,
              longitude: -51.343753814697266,
              direcao: 56,
              hodometro: 3613000,
              hodometroAjustado: -640100,
              velocidade: 109,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:33:35.000Z'
            },
            {
              latitude: -23.321361541748047,
              longitude: -51.34042739868164,
              direcao: 69,
              hodometro: 3613000,
              hodometroAjustado: -640100,
              velocidade: 109,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:33:47.000Z'
            },
            {
              latitude: -23.318899154663086,
              longitude: -51.33598327636719,
              direcao: 32,
              hodometro: 3614000,
              hodometroAjustado: -639100,
              velocidade: 108,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:34:05.000Z'
            },
            {
              latitude: -23.30337905883789,
              longitude: -51.328060150146484,
              direcao: 48,
              hodometro: 3616000,
              hodometroAjustado: -637100,
              velocidade: 122,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:35:06.000Z'
            },
            {
              latitude: -23.29849624633789,
              longitude: -51.321048736572266,
              direcao: 58,
              hodometro: 3617000,
              hodometroAjustado: -636100,
              velocidade: 114,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:35:33.000Z'
            },
            {
              latitude: -23.311674118041992,
              longitude: -51.331703186035156,
              direcao: 21,
              hodometro: 3615000,
              hodometroAjustado: -638100,
              velocidade: 117,
              gsm: true,
              evento: 'Excesso de Velocidade',
              limiar: 'Limite: 110 Km/h',
              intensidade: 'ponto de interesse: 65535',
              fim: {
                latitude: -23.297332763671875,
                longitude: -51.318851470947266,
                direcao: 65,
                hodometro: 3617000,
                hodometroAjustado: -636100,
                velocidade: 92,
                gsm: true,
                evento: 'Movimento',
                gps: 'Precisão alta',
                duracao: 0,
                data: '2016-01-15T09:35:42.000Z'
              },
              gps: 'Precisão alta',
              miscInfo: {
                sensorChuva: 0
              },
              duracao: 66,
              data: '2016-01-15T09:34:36.000Z'
            },
            {
              latitude: -23.295419692993164,
              longitude: -51.311763763427734,
              direcao: 81,
              hodometro: 3617000,
              hodometroAjustado: -636100,
              velocidade: 91,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:36:12.000Z'
            },
            {
              latitude: -23.295310974121094,
              longitude: -51.29420471191406,
              direcao: 89,
              hodometro: 3619000,
              hodometroAjustado: -634100,
              velocidade: 105,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:37:20.000Z'
            },
            {
              latitude: -23.295787811279297,
              longitude: -51.27145767211914,
              direcao: 60,
              hodometro: 3621000,
              hodometroAjustado: -632100,
              velocidade: 82,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:38:57.000Z'
            },
            {
              latitude: -23.29474449157715,
              longitude: -51.26995086669922,
              direcao: 50,
              hodometro: 3622000,
              hodometroAjustado: -631100,
              velocidade: 72,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:39:06.000Z'
            },
            {
              latitude: -23.288820266723633,
              longitude: -51.2620849609375,
              direcao: 50,
              hodometro: 3623000,
              hodometroAjustado: -630100,
              velocidade: 32,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:41:00.000Z'
            },
            {
              latitude: -23.286216735839844,
              longitude: -51.25962829589844,
              direcao: 29,
              hodometro: 3623000,
              hodometroAjustado: -630100,
              velocidade: 79,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:41:24.000Z'
            },
            {
              latitude: -23.281957626342773,
              longitude: -51.25615692138672,
              direcao: 65,
              hodometro: 3624000,
              hodometroAjustado: -629100,
              velocidade: 64,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:41:50.000Z'
            },
            {
              latitude: -23.281789779663086,
              longitude: -51.25402069091797,
              direcao: 102,
              hodometro: 3624000,
              hodometroAjustado: -629100,
              velocidade: 67,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:42:02.000Z'
            },
            {
              latitude: -23.283645629882812,
              longitude: -51.24597930908203,
              direcao: 104,
              hodometro: 3625000,
              hodometroAjustado: -628100,
              velocidade: 57,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:42:47.000Z'
            },
            {
              latitude: -23.286731719970703,
              longitude: -51.228233337402344,
              direcao: 115,
              hodometro: 3627000,
              hodometroAjustado: -626100,
              velocidade: 67,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:44:32.000Z'
            },
            {
              latitude: -23.29473876953125,
              longitude: -51.21070098876953,
              direcao: 114,
              hodometro: 3629000,
              hodometroAjustado: -624100,
              velocidade: 0,
              gsm: true,
              evento: 'Parado',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:46:17.000Z'
            },
            {
              latitude: -23.29727554321289,
              longitude: -51.20194625854492,
              direcao: 99,
              hodometro: 3630000,
              hodometroAjustado: -623100,
              velocidade: 25,
              gsm: true,
              evento: 'Aceleração Brusca',
              limiar: 'Limite: 18,5 Km/h/s',
              intensidade: '',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:48:14.000Z'
            },
            {
              latitude: -23.297372817993164,
              longitude: -51.20128631591797,
              direcao: 97,
              hodometro: 3630000,
              hodometroAjustado: -623100,
              velocidade: 50,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:48:20.000Z'
            },
            {
              latitude: -23.298067092895508,
              longitude: -51.19388961791992,
              direcao: 54,
              hodometro: 3631000,
              hodometroAjustado: -622100,
              velocidade: 57,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:49:08.000Z'
            },
            {
              latitude: -23.297208786010742,
              longitude: -51.1922721862793,
              direcao: 88,
              hodometro: 3631000,
              hodometroAjustado: -622100,
              velocidade: 46,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:49:20.000Z'
            },
            {
              latitude: -23.29731559753418,
              longitude: -51.18859100341797,
              direcao: 92,
              hodometro: 3631000,
              hodometroAjustado: -622100,
              velocidade: 58,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:50:05.000Z'
            },
            {
              latitude: -23.298839569091797,
              longitude: -51.1772346496582,
              direcao: 98,
              hodometro: 3632000,
              hodometroAjustado: -621100,
              velocidade: 75,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:51:50.000Z'
            },
            {
              latitude: -23.299352645874023,
              longitude: -51.173744201660156,
              direcao: 121,
              hodometro: 3633000,
              hodometroAjustado: -620100,
              velocidade: 26,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:52:42.000Z'
            },
            {
              latitude: -23.299467086791992,
              longitude: -51.1733512878418,
              direcao: 73,
              hodometro: 3633000,
              hodometroAjustado: -620100,
              velocidade: 30,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:52:48.000Z'
            },
            {
              latitude: -23.299457550048828,
              longitude: -51.173065185546875,
              direcao: 104,
              hodometro: 3633000,
              hodometroAjustado: -620100,
              velocidade: 39,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:52:51.000Z'
            },
            {
              latitude: -23.30176544189453,
              longitude: -51.16803741455078,
              direcao: 108,
              hodometro: 3634000,
              hodometroAjustado: -619100,
              velocidade: 14,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:53:35.000Z'
            },
            {
              latitude: -23.302995681762695,
              longitude: -51.165863037109375,
              direcao: 134,
              hodometro: 3634000,
              hodometroAjustado: -619100,
              velocidade: 44,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:54:02.000Z'
            },
            {
              latitude: -23.30779457092285,
              longitude: -51.162593841552734,
              direcao: 100,
              hodometro: 3634000,
              hodometroAjustado: -619100,
              velocidade: 34,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:55:20.000Z'
            },
            {
              latitude: -23.307819366455078,
              longitude: -51.1614875793457,
              direcao: 90,
              hodometro: 3635000,
              hodometroAjustado: -618100,
              velocidade: 36,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:55:36.000Z'
            },
            {
              latitude: -23.30811882019043,
              longitude: -51.15535354614258,
              direcao: 120,
              hodometro: 3635000,
              hodometroAjustado: -618100,
              velocidade: 52,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:56:21.000Z'
            },
            {
              latitude: -23.310178756713867,
              longitude: -51.15266799926758,
              direcao: 175,
              hodometro: 3636000,
              hodometroAjustado: -617100,
              velocidade: 38,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:56:57.000Z'
            },
            {
              latitude: -23.31351661682129,
              longitude: -51.15262222290039,
              direcao: 179,
              hodometro: 3636000,
              hodometroAjustado: -617100,
              velocidade: 46,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:57:21.000Z'
            },
            {
              latitude: -23.31540870666504,
              longitude: -51.152496337890625,
              direcao: 100,
              hodometro: 3636000,
              hodometroAjustado: -617100,
              velocidade: 24,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:58:09.000Z'
            },
            {
              latitude: -23.315780639648438,
              longitude: -51.1511116027832,
              direcao: 126,
              hodometro: 3636000,
              hodometroAjustado: -617100,
              velocidade: 54,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:58:21.000Z'
            },
            {
              latitude: -23.317087173461914,
              longitude: -51.14973831176758,
              direcao: 358,
              hodometro: 3637000,
              hodometroAjustado: -616100,
              velocidade: 0,
              gsm: true,
              evento: 'Ignição desligada',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T09:59:24.000Z'
            }
          ],
          distanciaPercorrida: 39000,
          distanciaPercorridaConvertida: 39.0,
          curvasBruscas: 0,
          aceleracoesBruscas: 1,
          banguela: 0,
          freadasBruscas: 0,
          excessoVelocidade: 105,
          trepidacoes: 0,
          motorOcioso: 0,
          rpmExcessivo: 0
        },
        {
          veiculo: 'ANGA01 - ANR1131',
          motoristas: 'Sem identificação',
          logradouroInicio: 'Avenida Santos Dumont',
          logradouroFim: 'Rua Aturia',
          inicio: '2016-01-15T21:08:14Z',
          fim: '2016-01-15T21:47:06Z',
          posicoes: [
            {
              latitude: -23.317005157470703,
              longitude: -51.14962387084961,
              direcao: 358,
              hodometro: 3638000,
              hodometroAjustado: -615100,
              velocidade: 0,
              gsm: true,
              evento: 'Ignição ligada',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:08:14.000Z'
            },
            {
              latitude: -23.307958602905273,
              longitude: -51.151302337646484,
              direcao: 1,
              hodometro: 3639000,
              hodometroAjustado: -614100,
              velocidade: 60,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:10:18.000Z'
            },
            {
              latitude: -23.300966262817383,
              longitude: -51.15116500854492,
              direcao: 348,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 16,
              gsm: true,
              evento: 'Curva brusca',
              limiar: 'Limite: 0.6097 G',
              intensidade: '',
              gps: 'Inválido',
              duracao: 0,
              data: '2016-01-15T21:11:24.000Z'
            },
            {
              latitude: -23.300966262817383,
              longitude: -51.15116500854492,
              direcao: 348,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 16,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:11:24.000Z'
            },
            {
              latitude: -23.300905227661133,
              longitude: -51.15131759643555,
              direcao: 293,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 23,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:11:27.000Z'
            },
            {
              latitude: -23.29990577697754,
              longitude: -51.15290451049805,
              direcao: 299,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 35,
              gsm: true,
              evento: 'Aceleração Brusca',
              limiar: 'Limite: 18,5 Km/h/s',
              intensidade: '',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:12:00.000Z'
            },
            {
              latitude: -23.299755096435547,
              longitude: -51.15317916870117,
              direcao: 302,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 40,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:12:03.000Z'
            },
            {
              latitude: -23.299476623535156,
              longitude: -51.15353775024414,
              direcao: 344,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 20,
              gsm: true,
              evento: 'Curva brusca',
              limiar: 'Limite: 0.6097 G',
              intensidade: '',
              gps: 'Inválido',
              duracao: 0,
              data: '2016-01-15T21:12:09.000Z'
            },
            {
              latitude: -23.299476623535156,
              longitude: -51.15353775024414,
              direcao: 344,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 20,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:12:09.000Z'
            },
            {
              latitude: -23.29928207397461,
              longitude: -51.1534538269043,
              direcao: 32,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 35,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:12:12.000Z'
            },
            {
              latitude: -23.297704696655273,
              longitude: -51.15262222290039,
              direcao: 342,
              hodometro: 3640000,
              hodometroAjustado: -613100,
              velocidade: 51,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:12:27.000Z'
            },
            {
              latitude: -23.292884826660156,
              longitude: -51.15398025512695,
              direcao: 303,
              hodometro: 3641000,
              hodometroAjustado: -612100,
              velocidade: 16,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:13:36.000Z'
            },
            {
              latitude: -23.29289436340332,
              longitude: -51.15409851074219,
              direcao: 267,
              hodometro: 3641000,
              hodometroAjustado: -612100,
              velocidade: 13,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:13:39.000Z'
            },
            {
              latitude: -23.29292869567871,
              longitude: -51.15593719482422,
              direcao: 271,
              hodometro: 3641000,
              hodometroAjustado: -612100,
              velocidade: 54,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:14:27.000Z'
            },
            {
              latitude: -23.292827606201172,
              longitude: -51.16886901855469,
              direcao: 270,
              hodometro: 3642000,
              hodometroAjustado: -611100,
              velocidade: 76,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:16:12.000Z'
            },
            {
              latitude: -23.293333053588867,
              longitude: -51.18681716918945,
              direcao: 240,
              hodometro: 3644000,
              hodometroAjustado: -609100,
              velocidade: 34,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:17:37.000Z'
            },
            {
              latitude: -23.29342269897461,
              longitude: -51.18700408935547,
              direcao: 236,
              hodometro: 3644000,
              hodometroAjustado: -609100,
              velocidade: 0,
              gsm: true,
              evento: 'Parado',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:17:58.000Z'
            },
            {
              latitude: -23.293481826782227,
              longitude: -51.1871337890625,
              direcao: 235,
              hodometro: 3644000,
              hodometroAjustado: -609100,
              velocidade: 25,
              gsm: true,
              evento: 'Aceleração Brusca',
              limiar: 'Limite: 18,5 Km/h/s',
              intensidade: '',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:18:01.000Z'
            },
            {
              latitude: -23.298179626464844,
              longitude: -51.194881439208984,
              direcao: 273,
              hodometro: 3645000,
              hodometroAjustado: -608100,
              velocidade: 67,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:18:55.000Z'
            },
            {
              latitude: -23.296768188476562,
              longitude: -51.2044563293457,
              direcao: 281,
              hodometro: 3646000,
              hodometroAjustado: -607100,
              velocidade: 86,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:19:43.000Z'
            },
            {
              latitude: -23.295597076416016,
              longitude: -51.208194732666016,
              direcao: 296,
              hodometro: 3647000,
              hodometroAjustado: -606100,
              velocidade: 27,
              gsm: true,
              evento: 'Aceleração Brusca',
              limiar: 'Limite: 18,5 Km/h/s',
              intensidade: '',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:20:21.000Z'
            },
            {
              latitude: -23.29008674621582,
              longitude: -51.220359802246094,
              direcao: 295,
              hodometro: 3647000,
              hodometroAjustado: -606100,
              velocidade: 88,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:21:30.000Z'
            },
            {
              latitude: -23.284664154052734,
              longitude: -51.24129104614258,
              direcao: 284,
              hodometro: 3650000,
              hodometroAjustado: -603100,
              velocidade: 84,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:23:15.000Z'
            },
            {
              latitude: -23.281734466552734,
              longitude: -51.25582504272461,
              direcao: 252,
              hodometro: 3651000,
              hodometroAjustado: -602100,
              velocidade: 82,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:24:27.000Z'
            },
            {
              latitude: -23.282827377319336,
              longitude: -51.257545471191406,
              direcao: 218,
              hodometro: 3651000,
              hodometroAjustado: -602100,
              velocidade: 90,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:24:36.000Z'
            },
            {
              latitude: -23.28696632385254,
              longitude: -51.26018142700195,
              direcao: 211,
              hodometro: 3652000,
              hodometroAjustado: -601100,
              velocidade: 66,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:25:00.000Z'
            },
            {
              latitude: -23.295740127563477,
              longitude: -51.271484375,
              direcao: 239,
              hodometro: 3653000,
              hodometroAjustado: -600100,
              velocidade: 96,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:26:36.000Z'
            },
            {
              latitude: -23.29644775390625,
              longitude: -51.27361297607422,
              direcao: 259,
              hodometro: 3654000,
              hodometroAjustado: -599100,
              velocidade: 86,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:26:45.000Z'
            },
            {
              latitude: -23.29705238342285,
              longitude: -51.27812576293945,
              direcao: 269,
              hodometro: 3654000,
              hodometroAjustado: -599100,
              velocidade: 78,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:27:06.000Z'
            },
            {
              latitude: -23.295166015625,
              longitude: -51.29820251464844,
              direcao: 270,
              hodometro: 3656000,
              hodometroAjustado: -597100,
              velocidade: 85,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:28:31.000Z'
            },
            {
              latitude: -23.295705795288086,
              longitude: -51.31336975097656,
              direcao: 253,
              hodometro: 3658000,
              hodometroAjustado: -595100,
              velocidade: 93,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:29:37.000Z'
            },
            {
              latitude: -23.298912048339844,
              longitude: -51.32269287109375,
              direcao: 229,
              hodometro: 3659000,
              hodometroAjustado: -594100,
              velocidade: 101,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:30:16.000Z'
            },
            {
              latitude: -23.300006866455078,
              longitude: -51.32387924194336,
              direcao: 223,
              hodometro: 3659000,
              hodometroAjustado: -594100,
              velocidade: 107,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:30:22.000Z'
            },
            {
              latitude: -23.305410385131836,
              longitude: -51.32963180541992,
              direcao: 198,
              hodometro: 3660000,
              hodometroAjustado: -593100,
              velocidade: 100,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:30:51.000Z'
            },
            {
              latitude: -23.319936752319336,
              longitude: -51.337135314941406,
              direcao: 231,
              hodometro: 3662000,
              hodometroAjustado: -591100,
              velocidade: 112,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:31:51.000Z'
            },
            {
              latitude: -23.321327209472656,
              longitude: -51.340572357177734,
              direcao: 249,
              hodometro: 3662000,
              hodometroAjustado: -591100,
              velocidade: 116,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:32:03.000Z'
            },
            {
              latitude: -23.32319450378418,
              longitude: -51.34458541870117,
              direcao: 219,
              hodometro: 3663000,
              hodometroAjustado: -590100,
              velocidade: 110,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:32:18.000Z'
            },
            {
              latitude: -23.3389892578125,
              longitude: -51.35099411010742,
              direcao: 238,
              hodometro: 3665000,
              hodometroAjustado: -588100,
              velocidade: 112,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:33:15.000Z'
            },
            {
              latitude: -23.34046745300293,
              longitude: -51.3558464050293,
              direcao: 278,
              hodometro: 3665000,
              hodometroAjustado: -588100,
              velocidade: 103,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:33:33.000Z'
            },
            {
              latitude: -23.339582443237305,
              longitude: -51.36007308959961,
              direcao: 278,
              hodometro: 3666000,
              hodometroAjustado: -587100,
              velocidade: 106,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:33:48.000Z'
            },
            {
              latitude: -23.339981079101562,
              longitude: -51.368377685546875,
              direcao: 251,
              hodometro: 3666000,
              hodometroAjustado: -587100,
              velocidade: 120,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:34:15.000Z'
            },
            {
              latitude: -23.342458724975586,
              longitude: -51.37419128417969,
              direcao: 220,
              hodometro: 3666000,
              hodometroAjustado: -587100,
              velocidade: 111,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:34:36.000Z'
            },
            {
              latitude: -23.3393611907959,
              longitude: -51.36459732055664,
              direcao: 271,
              hodometro: 3666000,
              hodometroAjustado: -587100,
              velocidade: 117,
              gsm: true,
              evento: 'Excesso de Velocidade',
              limiar: 'Limite: 110 Km/h',
              intensidade: 'ponto de interesse: 65535',
              fim: {
                latitude: -23.344873428344727,
                longitude: -51.376319885253906,
                direcao: 218,
                hodometro: 3667000,
                hodometroAjustado: -586100,
                velocidade: 88,
                gsm: true,
                evento: 'Movimento',
                gps: 'Precisão alta',
                duracao: 0,
                data: '2016-01-15T21:34:48.000Z'
              },
              gps: 'Precisão alta',
              miscInfo: {
                sensorChuva: 0
              },
              duracao: 45,
              data: '2016-01-15T21:34:03.000Z'
            },
            {
              latitude: -23.349424362182617,
              longitude: -51.38144302368164,
              direcao: 255,
              hodometro: 3668000,
              hodometroAjustado: -585100,
              velocidade: 99,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:35:27.000Z'
            },
            {
              latitude: -23.349340438842773,
              longitude: -51.38311004638672,
              direcao: 288,
              hodometro: 3668000,
              hodometroAjustado: -585100,
              velocidade: 107,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:35:33.000Z'
            },
            {
              latitude: -23.349340438842773,
              longitude: -51.38311004638672,
              direcao: 288,
              hodometro: 3668000,
              hodometroAjustado: -585100,
              velocidade: 107,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:35:33.000Z'
            },
            {
              latitude: -23.348913192749023,
              longitude: -51.3855094909668,
              direcao: 256,
              hodometro: 3669000,
              hodometroAjustado: -584100,
              velocidade: 92,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:35:43.000Z'
            },
            {
              latitude: -23.349781036376953,
              longitude: -51.38667678833008,
              direcao: 210,
              hodometro: 3669000,
              hodometroAjustado: -584100,
              velocidade: 97,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:35:49.000Z'
            },
            {
              latitude: -23.36110496520996,
              longitude: -51.39976501464844,
              direcao: 217,
              hodometro: 3671000,
              hodometroAjustado: -582100,
              velocidade: 52,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:37:19.000Z'
            },
            {
              latitude: -23.368526458740234,
              longitude: -51.4073486328125,
              direcao: 224,
              hodometro: 3671000,
              hodometroAjustado: -582100,
              velocidade: 116,
              gsm: true,
              evento: 'Excesso de Velocidade',
              limiar: 'Limite: 110 Km/h',
              intensidade: 'ponto de interesse: 65535',
              fim: {
                latitude: -23.38232421875,
                longitude: -51.4181022644043,
                direcao: 205,
                hodometro: 3673000,
                hodometroAjustado: -580100,
                velocidade: 78,
                gsm: true,
                evento: 'Movimento',
                gps: 'Precisão alta',
                duracao: 0,
                data: '2016-01-15T21:39:03.000Z'
              },
              gps: 'Precisão alta',
              miscInfo: {
                sensorChuva: 0
              },
              duracao: 59,
              data: '2016-01-15T21:38:04.000Z'
            },
            {
              latitude: -23.382720947265625,
              longitude: -51.418312072753906,
              direcao: 206,
              hodometro: 3673000,
              hodometroAjustado: -580100,
              velocidade: 42,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:39:06.000Z'
            },
            {
              latitude: -23.400056838989258,
              longitude: -51.427024841308594,
              direcao: 204,
              hodometro: 3676000,
              hodometroAjustado: -577100,
              velocidade: 63,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:40:52.000Z'
            },
            {
              latitude: -23.405380249023438,
              longitude: -51.42843246459961,
              direcao: 173,
              hodometro: 3676000,
              hodometroAjustado: -577100,
              velocidade: 76,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:41:28.000Z'
            },
            {
              latitude: -23.407846450805664,
              longitude: -51.42839050292969,
              direcao: 209,
              hodometro: 3677000,
              hodometroAjustado: -576100,
              velocidade: 53,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:41:43.000Z'
            },
            {
              latitude: -23.408794403076172,
              longitude: -51.42921829223633,
              direcao: 275,
              hodometro: 3677000,
              hodometroAjustado: -576100,
              velocidade: 28,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:41:55.000Z'
            },
            {
              latitude: -23.408788681030273,
              longitude: -51.42984390258789,
              direcao: 203,
              hodometro: 3677000,
              hodometroAjustado: -576100,
              velocidade: 22,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:42:10.000Z'
            },
            {
              latitude: -23.409847259521484,
              longitude: -51.4300422668457,
              direcao: 190,
              hodometro: 3677000,
              hodometroAjustado: -576100,
              velocidade: 30,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:42:37.000Z'
            },
            {
              latitude: -23.41341781616211,
              longitude: -51.43244934082031,
              direcao: 138,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 25,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:43:54.000Z'
            },
            {
              latitude: -23.413957595825195,
              longitude: -51.431488037109375,
              direcao: 57,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 24,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:44:06.000Z'
            },
            {
              latitude: -23.413127899169922,
              longitude: -51.43092346191406,
              direcao: 338,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 20,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:44:18.000Z'
            },
            {
              latitude: -23.41299057006836,
              longitude: -51.43111038208008,
              direcao: 305,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 35,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:44:21.000Z'
            },
            {
              latitude: -23.41254234313965,
              longitude: -51.43180465698242,
              direcao: 265,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 17,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:44:42.000Z'
            },
            {
              latitude: -23.41269874572754,
              longitude: -51.431968688964844,
              direcao: 221,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 35,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:44:45.000Z'
            },
            {
              latitude: -23.413938522338867,
              longitude: -51.4329833984375,
              direcao: 213,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 25,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:45:09.000Z'
            },
            {
              latitude: -23.415103912353516,
              longitude: -51.43407440185547,
              direcao: 254,
              hodometro: 3678000,
              hodometroAjustado: -575100,
              velocidade: 20,
              gsm: true,
              evento: 'Movimento',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:46:15.000Z'
            },
            {
              latitude: -23.415443420410156,
              longitude: -51.43421936035156,
              direcao: 163,
              hodometro: 3679000,
              hodometroAjustado: -574100,
              velocidade: 0,
              gsm: true,
              evento: 'Parado',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:46:50.000Z'
            },
            {
              latitude: -23.415517807006836,
              longitude: -51.43422317504883,
              direcao: 167,
              hodometro: 3679000,
              hodometroAjustado: -574100,
              velocidade: 0,
              gsm: true,
              evento: 'Ignição desligada',
              limiar: '-',
              intensidade: '-',
              gps: 'Precisão alta',
              duracao: 0,
              data: '2016-01-15T21:47:06.000Z'
            }
          ],
          distanciaPercorrida: 41000,
          distanciaPercorridaConvertida: 41.0,
          curvasBruscas: 2,
          aceleracoesBruscas: 3,
          banguela: 0,
          freadasBruscas: 0,
          excessoVelocidade: 104,
          trepidacoes: 0,
          motorOcioso: 0,
          rpmExcessivo: 0
        }
      ]
    });
}());
