(function () {
  'use strict';

  class CardSnapshotCtrl {
    constructor($uibModal, UrlConfigService, $translate, AuditingEventsEnum) {
      this.uibModal = $uibModal;
      this.urlConfigService = UrlConfigService;
      this.translate = $translate;
      this.auditingEventsEnum = AuditingEventsEnum;
    }

    $onInit() {
      this.qtdCameras = this.indiceDisponiveis.length;
      this.cameraLabels = this.snapshot.cameraLabels;
    }

    getUrl() {
      const currentCamera = this.snapshot.camerasPhotoUrl.find(camera => camera.indice === this.indiceDisponiveis[this.indice]);
      if (currentCamera) {
        return currentCamera.urlDriverPhoto;
      }
      return 'https://veltec3g-o-que-ha-de-novo.s3-sa-east-1.amazonaws.com/Veltec+Trimble/veltec-background.png';
    }

    visualizarPonto() {
      this.verNoMapa({posicao: {
        latitude: this.snapshot.latitude,
        longitude: this.snapshot.longitude,
        horario: this.getTime()
      }});
    }

    visualizarFoto() {
      const snapshotSlide = {
        id: this.snapshot.id,
        dateTime: this.snapshot.dateTime,
        cameras: this.indiceDisponiveis.map(indice => {
          const currentCamera = this.snapshot.camerasPhotoUrl.find(camera => camera.indice === indice);
          return {
            indice: indice,
            possuiImagem: !!currentCamera,
            s3Url: currentCamera ? currentCamera.urlDriverPhoto : undefined
          };
        })
      };

      this.uibModal.open({
        component: 'carouselSnapshot',
        size: 'carousel',
        resolve: {
          snapshot: () => snapshotSlide,
          indice: () => this.indice,
          cameraLabels: () => this.cameraLabels,
          isFeatSnapshotLabelActived: () => this.isFeatSnapshotLabelActived
        }
      });
    }

    getTime() {
      return new Date(this.snapshot.dateTime).toLocaleTimeString();
    }

    getLabels() {
      if (!this.cameraLabels) {
        return this.translate.instant(this.auditingEventsEnum.getDescriptionById('NO_LABEL'));
      }

      const labelsShow = this.cameraLabels
        .filter(cameraLabel => cameraLabel.index === this.cameraSelected)
        .map(cameraLabel => {
          const translatedLabels = cameraLabel.labels.map(label =>
            this.translate.instant(this.auditingEventsEnum.getDescriptionById(label))
          );

          if (translatedLabels.length > 2) {
            const firstTwoLabels = translatedLabels.slice(0, 2).join(', '),
                remainingCount = translatedLabels.length - 2,
                remainingText = remainingCount > 1
                ? ` + ${remainingCount} ${this.translate.instant('ce.ranking.motoristas.prontuarioTassi.score.eventos')}`
                : ` + ${remainingCount} ${this.translate.instant('ce.ranking.motoristas.prontuarioTassi.score.evento')}`;
            return `${firstTwoLabels}${remainingText}`;
          }

          return translatedLabels.join(', ');
        }).join(', ');

      return labelsShow || this.translate.instant(this.auditingEventsEnum.getDescriptionById('NO_LABEL'));
    }
  }

  angular
    .module('historicoTimeline')
    .component('cardSnapshot', {
      controller: CardSnapshotCtrl,
      templateUrl: 'historico-timeline/visao-cameras/card-snapshot/card-snapshot.tpl.html',
      bindings: {
        snapshot: '<',
        indice: '<',
        cameraSelected: '<',
        indiceDisponiveis: '<',
        verNoMapa: '&',
        isFeatSnapshotLabelActived: '<'
      }
    });
}());
