(function () {
  'use strict';

  class EditarGrupoCtrl {
    constructor($uibModalInstance, veiculos, HttpServiceV3g, $scope, $translate) {
      this.modalInstance = $uibModalInstance;
      this.translate = $translate;
      this.selectInput = {text: this.translate.instant('ce.cadastro.veiculos.modalAlterarGrupo.optPadrao'), disable: false};
      this.veiculos = veiculos;
      this.httpService = HttpServiceV3g;
      this.scope = $scope;
      this.limitItensSelect = 10;
      this.fields = ['id', 'nome', 'uo.nome'];

      this.getGrupos();
    }

    getGrupos() {
      this.httpService.get(`/veiculo/veiculos/grupos?fields=${this.fields.join()}`)
        .success(data => {
          this.gruposDestino = data;
        })
        .error(() => {
          this.selectInput = {
            text: this.translate.instant('ce.cadastro.veiculos.modalAlterarGrupo.msgErro'),
            disable: true
          };
        });
    }

    loadMoreItensSelect() {
      this.limitItensSelect += 10;
    }

    confirmaEdicao() {
      if (this.scope.editarGrupoForm.grupoDestino.$valid) {
        const veiculosIds = this.veiculos.map(veic => veic.id),
            edicao = {
              veiculoIds: veiculosIds,
              patch: {
                grupoId: this.grupoDestinoSelected.id
              }
            };
        this.httpService.post(`/veiculo/v2/veiculos/batch`, edicao)
          .success(() => {
            this.modalInstance.close({
              message: this.translate.instant('ce.cadastro.veiculos.modalAlterarGrupo.msgSucesso'),
              edition: {grupo: this.grupoDestinoSelected, veiculosIds}
            });
          })
          .error(() => {
            this.modalInstance.dismiss({message: this.translate.instant('ce.cadastro.veiculos.modalAlterarGrupo.falha')});
          });
      } else {
        this.scope.editarGrupoForm.grupoDestino.$setTouched();
      }
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('cadastros.veiculo')
    .controller('EditarGrupoCtrl', EditarGrupoCtrl);
}());
