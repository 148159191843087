(function () {
  'use strict';

  /* global FormData */
  class FotoMotoristaCtrl {
    constructor($uibModalInstance, $scope, AlertMessage, Restangular, motorista) {
      this.scope = $scope;
      this.alertMessage = AlertMessage;
      this.modal = $uibModalInstance;
      this.restangular = Restangular;
      this.motorista = motorista;
      this.MAX_SIZE_UPLOAD = 5000000;

      this.fotoFinal = null;
      this.fileName = null;
    }

    onChangeFile(file) {
      this.$parent.$ctrl.fileName = file.name;
      this.$parent.formFotoMotorista.$setDirty();
    }

    onCrop(fotoFinal) {
      this.$parent.$ctrl.fotoFinal = fotoFinal;
    }

    salvar() {
      let formData = new FormData();
      formData.append('file', this.fotoFinal, this.fileName);

      if (this.isValidUpload(this.fotoFinal)) {
        this.restangular.all(this.getUrlFotoMotorista())
          .withHttpConfig({transformRequest: angular.identity})
          .customPOST(formData, undefined, undefined, {'Content-Type': undefined})
          .then((motoristaSalvo) => {
            this.modal.close(motoristaSalvo);
          })
          .catch(() => {
            this.alertMessage.create({
              type: 'error',
              message: 'Ocorreu um erro ao importar a foto, por favor, tente novamente',
              appendTo: '.alerta-aqui'
            });
          });
      }
    }

    isValidUpload(file) {
      if (file.size > this.MAX_SIZE_UPLOAD) {
        this.alertMessage.create({
          type: 'error',
          message: `Arquivo excedeu o limite máximo de 5MB.`,
          appendTo: '.alerta-aqui'
        });
        angular.element('#fileFotoMotorista').val('');
        return false;
      }
      return true;
    }

    getUrlFotoMotorista() {
      return `/motorista/v2/motoristas/${this.motorista.id}/foto`;
    }

    dismiss() {
      this.modal.dismiss();
    }
  }

  angular
    .module('ProntuarioModule')
    .controller('FotoMotoristaCtrl', FotoMotoristaCtrl);
}());
