(function () {
  'use strict';

  class BdsCtrl {
    constructor($httpParamSerializer, Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService,
                Bds, v3gTableLimitService, moment, sortAlertasIndicadores, VtabelaFactory, uiGridConstants,
                UrlConfigService) {
      this.httpParamSerializer = $httpParamSerializer;
      this.bsService = Bds;
      this.moment = moment;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.v3gTableLimitService = v3gTableLimitService;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.urlConfigService = UrlConfigService;

      this.vtabela = null;
      this.relatorio = null;
      this.uoSelecionada = null;
      this.currentPage = 0;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.tableState.sort.predicate = sortAlertasIndicadores[this.tableState.sort.predicate];

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 30,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo',
          key: this.keys.uo.name
        },
        {
          name: 'uoParceira',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'veiculo',
          key: this.keys.veiculo.name,
          size: 6
        },
        {
          name: 'motorista',
          key: this.keys.motorista.name,
          size: 6
        },
        {
          name: 'linha',
          key: this.keys.linha.name,
          size: 6
        },
        {
          name: 'servico',
          key: this.keys.servico.name,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.consultar(ServiceVfiltro.getFiltroBackendNovo(filtro));
      });
    }

    initVtabela() {
      let objVtabela = {
        exporterPdfFilename: 'Boletim Diário de Serviço PDF.pdf',
        exporterCsvFilename: 'Boletim Diário de Serviço CSV.csv',
        exporterExcelFilename: 'Boletim Diário de Serviço XLSX.xlsx',
        appScopeProvider: this,
        enableFiltering: false,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        showColumnFooter: true,
        columnDefs: [
            {
              name: 'servico',
              displayName: 'ce.boletim.servicos.colunas.servico',
              enableHiding: false,
              pinnedLeft: true,
              width: 150,
              aggregationTotalTitle: 'Total'
            },
            {
              name: 'linha',
              displayName: 'ce.boletim.servicos.colunas.linha'
            },
            {
              name: 'prefixoPlaca',
              displayName: 'ce.boletim.servicos.colunas.veiculos',
              enableHiding: false
            },
            {
              name: 'motoristasNome',
              displayName: 'ce.boletim.servicos.colunas.motoristas',
              enableHiding: false
            },
            {
              name: 'dia',
              displayName: 'ce.boletim.servicos.colunas.dia',
              type: 'date',
              cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\''
            },
            {
              name: 'distancia',
              displayName: 'ce.boletim.servicos.colunas.distanciaPercorrida',
              cellClass: 'text-center',
              headerCellClass: 'text-center',
              cellFilter: 'decimalWithLabel:\'Km\'',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'tempoIgnicaoLigada',
              displayName: 'ce.boletim.servicos.colunas.tempoIgnicaoLigada',
              cellFilter: 'humanizedDuration',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'aceleracoesBruscas',
              displayName: 'ce.boletim.servicos.colunas.aceleracoesBruscas',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'frenagensBruscas',
              displayName: 'ce.boletim.servicos.colunas.frenagensBruscas',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'curvasBruscas',
              displayName: 'ce.boletim.servicos.colunas.curvasBruscas',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'banguelas',
              displayName: 'ce.boletim.servicos.colunas.banguela',
              cellFilter: 'humanizedDuration',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'rpmExcessivos',
              displayName: 'ce.boletim.servicos.colunas.rpmExcessivo',
              cellFilter: 'humanizedOrUndefinedWithLabel:\'-\'',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'excessosVelocidade',
              displayName: 'ce.boletim.servicos.colunas.excessoVelocidade',
              cellFilter: 'humanizedDuration',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'motorOcioso',
              displayName: 'ce.boletim.servicos.colunas.paradoLigado',
              cellFilter: 'humanizedOrUndefinedWithLabel:\'-\'',
              headerCellClass: 'text-center',
              cellClass: 'text-center',
              aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
            },
            {
              name: 'logradouroInicial',
              displayName: 'ce.boletim.servicos.colunas.logradouroInicial'
            },
            {
              name: 'logradouroFinal',
              displayName: 'ce.boletim.servicos.colunas.logradouroFinal'
            },
            {
              name: 'detalhe',
              displayName: '',
              enableFiltering: false,
              enableSorting: false,
              pinnedRight: true,
              enableColumnResizing: false,
              enableHiding: false,
              exporterSuppressExport: true,
              width: 70,
              cellTemplate: `
                <div class="ui-grid-cell-contents vtabela-icone-detalhado">
                  <a
                    id='btn-detalhes-{{$index}}'
                    ui-sref="root.bdsDetalhes({execucaoServico: row.entity.execucaoServico})">
                    <span class="glyphicon glyphicon-zoom-in" aria-hidden='true'></span>
                  </a>
                </div>`
            }
        ]
      };

      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        servico: item.servico,
        linha: item.linha,
        prefixoPlaca: item.prefixoPlaca,
        motoristasNome: item.motoristasNome,
        dia: item.inicioPrevisto,
        distancia: item.distancia >= 0 ? item.distancia : 0,
        tempoIgnicaoLigada: item.tempoDirecao,
        aceleracoesBruscas: item.aceleracoes,
        frenagensBruscas: item.frenagens,
        curvasBruscas: item.curvas,
        banguelas: item.banguelas,
        rpmExcessivos: item.rpmExcessivos,
        excessosVelocidade: item.excessosVelocidade,
        motorOcioso: item.motorOcioso,
        logradouroInicial: item.logradouroInicial,
        logradouroFinal: item.logradouroFinal,
        execucaoServico: item.execucaoServico
      };
    }

    consultar(filtro) {
      this.bsService.one().get(filtro).then(data => {
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'relatorioBDS', 'v1');
        this.vtabela.setData(data.list.map(i => this.getItemVtabela(i)));
      });
    }

    getUrlCsv() {
      return `/boletim/bs/csv?${this.httpParamSerializer(this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar))}`;
    }
  }

  /**
   * @ngdoc object
   * @name bds.controller:BdsCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.bds')
    .controller('BdsCtrl', BdsCtrl);
}());
