/* global L*/
(function () {
  'use strict';

  const OPACIDADE_NOME_AREA_CONTROLE = 0.75,
      ZOOM_MINIMO_RENDER_MARKER = 13;

  class CercaEletronicaMapService {
    constructor($rootScope, PontosReferenciaMenu, $uibModal, LayerTypeEnum, CercaEletronicaService,
      MapControlService, PontosReferenciaFiltro, TipoPermissaoEnum, $translate, $sanitize) {
      this.cercaEletronicaDrawer = null;
      this.mapControlService = MapControlService;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.tipoPermissao = TipoPermissaoEnum;
      this.map = null;
      this.cercaEletronicaService = CercaEletronicaService;
      this.drawControl = null;
      this.todasCercasEletronicasFeatureGroup = new L.FeatureGroup();
      this.rootScope = $rootScope;
      this.$sanitize = $sanitize;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.modalService = $uibModal;
      this.layerTypeEnum = LayerTypeEnum;
      this.cercasEletronicas = [];
      this.cercaEletronicaSelecionadaLayer = null;
      this.polygonEditable = null;
      this.cercaEletronicaCriandoEditable = null;
      this.translate = $translate;
      this.cercasRendered = [];

      this.rootScope.$on('limpaSelecaoCercaEletronica', () => this.setCercaEletronicaSelecionada(null));
    }

    setCercasEletronicas(cercasEletronicas) {
      this.cercasEletronicas = cercasEletronicas;
      this.mostrarCercasBoundsAtual();
    }

    mostrarCercasBoundsAtual() {
      if (this.map.getZoom() >= ZOOM_MINIMO_RENDER_MARKER) {
        this.cercasEletronicas.forEach(cercaEletronica => {
          if (this.shouldRender(cercaEletronica)) {
            this.render(cercaEletronica);
            this.cercasRendered.push(cercaEletronica);
          } else if (cercaEletronica.rendered) {
            this.unRender(cercaEletronica);
            this.cercasRendered = this.cercasRendered.filter(i => cercaEletronica.id !== i.id);
          }
        });
      } else {
        this.cercasRendered.forEach(item => this.unRender(item));
        this.cercasRendered = [];
      }
    }

    render(cercaEletronica) {
      if (!cercaEletronica.layer) {
        cercaEletronica.layer = this.getMarkerFromCercaEletronica(cercaEletronica);
      }
      this.addToMap(cercaEletronica.layer);
      this.addToFeatureGroup(cercaEletronica.layer);
      this.renderTooltip(cercaEletronica.layer);
      cercaEletronica.rendered = true;
    }

    renderTooltip(layer) {
      layer
        .getTooltip()
        .setOpacity(this.map.getZoom() >= 17 ? OPACIDADE_NOME_AREA_CONTROLE : 0);
    }

    unRender(cercaEletronica) {
      if (cercaEletronica.layer) {
        this.removeFromMap(cercaEletronica.layer);
        this.removeFromFeatureGroup(cercaEletronica.layer);
        cercaEletronica.rendered = false;
      }
    }

    /* Renderiza somente areas de controle que possuem ao menos um ponto em comum com view do mapa */
    shouldRender(cercaEletronica) {
      return this.pontosReferenciaFiltroService.isCercaVisible(cercaEletronica) &&
        this.map.getBounds().intersects(this.getLatLngsBoundsCercaEletronica(cercaEletronica));
    }

    getLatLngsBoundsCercaEletronica(cercaEletronica) {
      return L.latLngBounds(
        L.GeoJSON.coordsToLatLngs(
          cercaEletronica.geometria.coordinates[0]
        )
      );
    }

    getHtmlLabel(nome, showMsgPermissao = false) {
      const msgPermissao = this.translate.instant('ce.mapa.common.cercaMapService.msgPermissao');
      if (showMsgPermissao) {
        return `
          <div class='label-nome-area'>
            <div>
              <i class='trimble-control-area'></i>
              ${nome}
            </div>
            <div class='label-msg-permissao'>
              ${msgPermissao}
            </div>
          </div>`;
      }
      return `
        <div class='label-nome-area'>
          <i class='trimble-control-area'></i>
          ${nome}
        </div>`;
    }

    getMarkerFromCercaEletronica(cercaEletronica) {
      cercaEletronica.nome = this.$sanitize(cercaEletronica.nome);
      const layerCercaEletronica = L.GeoJSON.geometryToLayer({
        type: 'Feature',
        properties: {},
        geometry: cercaEletronica.geometria
      });

      layerCercaEletronica.bindPopup(
        this.getHtmlLabel(cercaEletronica.nome, !this.podeEditarCerca(cercaEletronica)),
        {className: 'popup-nome-cerca-eletronica'}
      );

      layerCercaEletronica.bindTooltip(this.getHtmlLabel(cercaEletronica.nome), {
        permanent: true,
        className: 'label-nome-cerca-eletronica',
        direction: 'center',
        opacity: 0
      });

      layerCercaEletronica.setStyle(this.getStyleDefault(cercaEletronica.categoria ? cercaEletronica.categoria.cor : null));
      layerCercaEletronica.cercaEletronica = cercaEletronica;

      return layerCercaEletronica;
    }

    getOptionsPolygon() {
      return {
        showArea: false,
        showLength: false,
        shapeOptions: this.getStyleSelecionada(),
        icon: new L.DivIcon({
          iconSize: new L.Point(15, 15),
          className: 'cerca-eletronica-icon-draw'
        }),
        touchIcon: new L.DivIcon({
          iconSize: new L.Point(15, 15),
          className: 'cerca-eletronica-touch-icon-draw'
        })
      };
    }

    iniciaDesenhoCercaEletronica() {
      this.cercaEletronicaDrawer = new L.Draw.Polygon(this.map, this.getOptionsPolygon());
      this.onZoomEndMap();
      this.mapControlService.isDrawingCercaEletronica = true;
    }

    onZoomEndMap() {
      this.verificaZoom();
      this.map.on('zoomend', this.verificaZoom, this);
    }

    offZoomEndMap() {
      this.map.off('zoomend', this.verificaZoom, this);
    }

    verificaZoom() {
      if (this.map.getZoom() >= ZOOM_MINIMO_RENDER_MARKER) {
        this.alertaZoom = false;
        this.cercaEletronicaDrawer.enable();
      } else {
        this.alertaZoom = true;
        this.cercaEletronicaDrawer.disable();
      }
    }

    setMap(map) {
      this.map = map;
      this.drawControl = new L.Control.Draw({
        draw: false,
        edit: {
          moveMarkers: true,
          featureGroup: this.todasCercasEletronicasFeatureGroup
        }
      });

      L.drawLocal.draw.handlers.polygon.tooltip = {
        start: this.translate.instant('ce.mapa.common.cercaMapService.tooltipStart'),
        cont: this.translate.instant('ce.mapa.common.cercaMapService.tooltipCont'),
        end: this.translate.instant('ce.mapa.common.cercaMapService.tooltipEnd')
      };

      let element = L.DomUtil.get('map-control');
      L.DomEvent.on(element, 'mousewheel', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'dblclick', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'mousedown', L.DomEvent.stopPropagation);
      L.DomEvent.on(element, 'contextmenu', L.DomEvent.stopPropagation);
      this.map.invalidateSize(true);
    }

    // Exibe layer no mapa
    addToMap(layer) {
      this.map.addLayer(layer);
    }

    removeFromMap(layer) {
      this.map.removeLayer(layer);
    }

    addToFeatureGroup(cercaEletronicaLayer) {
      this.todasCercasEletronicasFeatureGroup.addLayer(cercaEletronicaLayer);
    }

    removeFromFeatureGroup(layer) {
      this.todasCercasEletronicasFeatureGroup.removeLayer(layer);
    }

    excluirCercaEletronica(idCercaEletronica) {
      this.cercasEletronicas = this.cercasEletronicas.filter(cercaEletronica => {
        if (cercaEletronica.id === idCercaEletronica) {
          this.unRender(cercaEletronica);
          return false;
        }
        return true;
      });
    }

    getCustomEditablePolygon(polygonLayer) {
      /**
       * Adiciona options em modo de edição dos polygons do Leaflet.draw
      **/
      return new L.Edit.PolyVerticesEdit(
        polygonLayer,
        polygonLayer.getLatLngs(),
        this.getOptionsPolygon()
      );
    }

    setCercaEletronicaLayerEditable() {
      this.editablePolygon = this.getCustomEditablePolygon(this.cercaEletronicaSelecionadaLayer);
      this.editablePolygon.enable();
      this.mapControlService.isDrawingCercaEletronica = true;
    }

    iniciaEventosEdicao() {
      this.eventoDrawStart();
      this.eventoDrawCreated();
    }

    iniciaEventosVisualizacao() {
      this.onMoveEndMap();
      this.onClickAreaControle();
    }

    onMoveEndMap() {
      this.map.on('moveend', () => this.mostrarCercasBoundsAtual());
    }

    onClickAreaControle() {
      this.todasCercasEletronicasFeatureGroup.on('click', e => {
        if (this.map.getZoom() >= 17 && this.podeEditarCerca(e.layer.cercaEletronica)) {
          e.layer.closePopup();
        }

        if (!this.mapControlService.isDrawingCercaEletronica &&
            !this.mapControlService.isDrawingPontoRef &&
            this.podeEditarCerca(e.layer.cercaEletronica)) {
          this.rootScope.$broadcast('limpaSelecaoPontoReferencia');
          this.setCercaEletronicaSelecionada(e.layer, e.latlng);
          this.setMenuEditar();
        }
      });
    }

    podeEditarCerca(cercaEletronica) {
      return this.tipoPermissao.podeEditar(cercaEletronica.tipoPermissao) && this.cercaEletronicaService.roleCadastrar;
    }

    focusCercaEletronica(cercaEletronicaId) {
      const cercaEletronica = this.cercasEletronicas.find(cerca => cerca.id === cercaEletronicaId);
      if (!cercaEletronica.layer) {
        cercaEletronica.layer = this.getMarkerFromCercaEletronica(cercaEletronica);
      }
      this.map.fitBounds(cercaEletronica.layer.getBounds());
      this.setCercaEletronicaSelecionada(cercaEletronica.layer);
      if (this.podeEditarCerca(cercaEletronica)) {
        this.pontosReferenciaMenuService.setFABActive('editar-cerca-eletronica');
        this.pontosReferenciaMenuService.menuOpen();
      }
    }

    setCercaEletronicaSelecionada(cercaEletronicaLayer, latLngClick = null) {
      if (this.cercaEletronicaSelecionadaLayer) {
        this.setStyleDefault(this.cercaEletronicaSelecionadaLayer);
      }

      if (cercaEletronicaLayer) {
        this.setStyleSelecionada(cercaEletronicaLayer);
        if (latLngClick && !this.map.getBounds().contains(cercaEletronicaLayer.getBounds())) {
          this.map.panTo(latLngClick);
        } else {
          this.map.panTo(cercaEletronicaLayer.getBounds().getCenter());
        }
      }
      this.cercaEletronicaSelecionadaLayer = cercaEletronicaLayer;
    }

    setStyleDefault(layer) {
      layer.setStyle(this.getStyleDefault(layer.cercaEletronica.categoria ? layer.cercaEletronica.categoria.cor : null));
    }

    setStyleSelecionada(layer) {
      layer.setStyle(this.getStyleSelecionada(layer.cercaEletronica.categoria ? layer.cercaEletronica.categoria.cor : null));
    }

    getStyleDefault(color = '#000') {
      return {
        stroke: true,
        color,
        opacity: 0.5,
        weight: 2,
        fill: true,
        fillColor: color,
        fillOpacity: 0.38,
        clickable: true
      };
    }

    getStyleSelecionada(color = '#000') {
      return {
        stroke: true,
        color,
        opacity: 0.5,
        weight: 4,
        fill: true,
        fillColor: color,
        fillOpacity: 0.75,
        clickable: true
      };
    }

    eventoDrawStart() {
      this.map.on(L.Draw.Event.DRAWSTART, () => {
        this.setCercaEletronicaSelecionada(null);
      });
    }

    setMenuEditar() {
      angular.element('#map-control').scope().$apply(() => {
        this.pontosReferenciaMenuService.setFABActive('editar-cerca-eletronica');
        this.pontosReferenciaMenuService.menuOpen();
      });
    }

    eventoDrawCreated() {
      this.map.on(L.Draw.Event.CREATED, e => {
        if (e.layerType === this.layerTypeEnum.POLYGON) {
          this.offZoomEndMap();
          this.cercaEletronicaCriandoLayer = e.layer;
          this.drawCreatedAreaControle();
        }
      });
    }

    drawCreatedAreaControle() {
      const buttonsPrincipal = {
            active: 'trimble-control-area',
            resting: 'trimble-control-area'
          }, buttonsCercaEletronica = [{
            titulo: 'ce.mapa.common.tituloBtConfirmar',
            icon: 'fa fa-check',
            color: 'success',
            tipo: 'adicionar-cerca-eletronica',
            funcao: this.confirmaCercaEletronica.bind(this)
          }, {
            titulo: 'ce.mapa.common.tituloBtCancelar',
            icon: 'fa fa-times',
            color: 'danger',
            tipo: 'default',
            funcao: this.cancelarDesenhoCerca.bind(this)
          }];

      this.addToMap(this.cercaEletronicaCriandoLayer);
      this.cercaEletronicaCriandoEditable = this.getCustomEditablePolygon(this.cercaEletronicaCriandoLayer);
      this.cercaEletronicaCriandoEditable.enable();

      this.mapControlService.isDrawingCercaEletronica = true;

      this.rootScope.$apply(() => {
        this.pontosReferenciaMenuService.setMenu(buttonsCercaEletronica, buttonsPrincipal);
        this.pontosReferenciaMenuService.menuOpen();
      });
    }

    cancelarDesenhoCerca() {
      if (this.cercaEletronicaDrawer) {
        this.offZoomEndMap();
        this.cercaEletronicaDrawer.disable();
      }

      if (this.cercaEletronicaCriandoLayer) {
        this.cercaEletronicaCriandoEditable.disable();
        this.map.removeLayer(this.cercaEletronicaCriandoLayer);
        this.cercaEletronicaCriandoLayer = null;
      }

      this.cercaEletronicaDrawer = null;
      this.alertaZoom = false;
      this.mapControlService.isDrawingCercaEletronica = false;
      this.pontosReferenciaMenuService.setFABDefault();
    }

    confirmaCercaEletronica() {
      const cercaEletronica = {
        geometria: this.cercaEletronicaCriandoLayer.toGeoJSON().geometry
      };
      this.openModalCadastro(cercaEletronica);
    }

    openModalCadastro(cercaEletronica) {
      if (cercaEletronica) {
        this.unRender(cercaEletronica);
      }
      this.modalService.open({
        animation: true,
        component: 'modalCercaEletronica',
        backdrop: 'static',
        openedClass: 'modal-cerca-eletronica',
        size: 'cerca-eletronica-dimension',
        resolve: {
          cercaEletronica: () => cercaEletronica
        }
      }).result
      .then((result) => {
        if (result.isEdicao) {
          this.confirmaEdicaoCercaEletronica(result.cercaEletronica);
        } else {
          this.confirmaCadastroCercaEletronica(result.cercaEletronica);
        }
        this.pontosReferenciaMenuService.setFABDefault();
        this.pontosReferenciaMenuService.menuClose();
        this.mostrarCercasBoundsAtual();
      })
      .catch(() => {
        this.mostrarCercasBoundsAtual();
        this.setCercaEletronicaSelecionada(cercaEletronica.layer);
      });
    }

    confirmaCadastroCercaEletronica(cercaEletronica) {
      this.cercaEletronicaCriandoEditable.disable();
      this.removeFromMap(this.cercaEletronicaCriandoLayer);
      this.cercasEletronicas.push(cercaEletronica);
      this.mapControlService.isDrawingCercaEletronica = false;
    }

    editarCadastroCercaEletronica() {
      const cercaEletronica = this.cercaEletronicaSelecionadaLayer.cercaEletronica;
      this.openModalCadastro(cercaEletronica);
    }

    confirmaEdicaoCercaEletronica(cercaEletronicaAtualizada) {
      this.excluirCercaEletronica(this.cercaEletronicaSelecionadaLayer.cercaEletronica.id);
      cercaEletronicaAtualizada.layer = null;
      this.cercasEletronicas.push(cercaEletronicaAtualizada);
      this.setCercaEletronicaSelecionada(null);
    }

    clearMarkers() {
      this.cercasRendered.forEach(cercaEletronica => this.unRender(cercaEletronica));
      this.cercasRendered = [];
      this.cercasEletronicas = [];
    }
  }

  angular
    .module('mapControlModule')
    .service('CercaEletronicaMapService', CercaEletronicaMapService);
}());
