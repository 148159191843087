(function () {
  'use strict';

  /* @ngdoc object
  * @name onepage
  * @description
  *
  */
  angular
    .module('onePageModule', [
      'ui.router',
      'chartModule',
      'customGridsterModule',
      'mapasGrid',
      'restangular',
      'httpServiceV3gModule',
      'angularMoment',
      'AlertasModule',
      'pascalprecht.translate',
      'customGridsterModule',
      'v3gAuthenticatorModule',
      'ui.bootstrap.modal',
      'enumModule',
      'filtroAlertaModule',
      'uoModule',
      'uoArvoreNivelModule',
      'UrlModule'
    ]);
}());
