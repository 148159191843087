(function () {
  'use strict';
  angular.module('ModuleMapasUtil', ['ModuleEventos', 'pascalprecht.translate', 'mapas']);

  const SOUTH_AMERICA_MAP_CENTER_DEFAULT = {lat: -23.3045, lng: -51.1696, zoom: 4},
      NORTH_AMERICA_MAP_CENTER_DEFAULT = {lat: 39.8283, lng: -98.5795, zoom: 4},
      NORTH_AMERICA_TIMEZONES = [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'America/Toronto',
        'America/Vancouver',
        'America/Winnipeg',
        'America/Halifax',
        'America/St_Johns',
        'America/Phoenix',
        'America/Anchorage',
        'America/Adak',
        'America/Juneau',
        'America/Indiana/Indianapolis',
        'America/Detroit',
        'America/Boise',
        'America/Sitka',
        'America/Metlakatla'
      ];

  /* global _ , Intl */
  class MapasUtil {

    constructor(Eventos, $translate, $window, $rootScope, MapLayerVisibility) {
      this.translate = $translate;
      this.eventos = Eventos;
      this.window = $window;
      this.rootScope = $rootScope;
      this.mapLayerVisibility = MapLayerVisibility;
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.tiles = {
        veltec: {
          name: 'Mapa',
          url: 'https://tiles-{s}.vfleets.com.br/tile/{z}/{x}/{y}.png',
          type: 'xyz',
          options: {
            subdomains: ['1', '2', '3', '4'],
            attribution: '&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors'
          }
        },
        trimbleMaps: {
          name: 'Satellite',
          url: 'https://contentsvc-sat-{s}.alk.com/ContentService.svc/xml/SatImageTile?authToken=300E18BCE5EEA04A8BA48B936949B413|2023-05-23T00:00:00.000Z&proj=EPSG:900913&provider=DigitalGlobe&width=256&z={z}&x={x}&y={y}',
          type: 'xyz',
          options: {
            subdomains: ['a', 'b', 'c', 'd'],
            attribution:
              '© Trimble Inc. | © OpenMapTiles © OpenStreetMap contributors | <a href="https://maps.alk.com/Account/MapDataDisclaimer">Imagery © Digital Globe</a> contributors'
          }
        }
      };
      this.defaults = {
        zoomControlPosition: 'topright',
        minZoom: 3
      };
      this.eventosOperacionaisPermiteExibicao = this.translate.instant(this.eventos.PARADO_LIGADO.descricao);
      this.layersVisibilityLocalStorage = 'map-layers-visibility';
    }

    getIconByZoom(zoom, posicao) {
      const qtdOcorrenciasNovasVeiculo = posicao.ocorrenciasNovasDoVeiculo ? posicao.ocorrenciasNovasDoVeiculo.length : 0;
      if (zoom > 13) {
        return this.getIcon(posicao.dir, 'v3g-icon-25x', [27, 35], [5, -20], posicao.placa, posicao.prefixo, posicao.status, qtdOcorrenciasNovasVeiculo);
      }
      if (zoom > 12) {
        return this.getIcon(posicao.dir, 'fa-2x', [25, 25], [0, -10], posicao.placa, posicao.prefixo, posicao.status, qtdOcorrenciasNovasVeiculo);
      }
      if (zoom > 10) {
        return this.getIcon(posicao.dir, 'fa-lg', [15, 15], [0, -10], posicao.placa, posicao.prefixo, posicao.status, qtdOcorrenciasNovasVeiculo);
      }
      return this.getIconLonge();
    }

    getVeiculoLabel(placa, prefixo, emMovimento) {
      let statusVeiculoCssClass;

      statusVeiculoCssClass = emMovimento ? 'div-text-placa-movimento' : 'div-text-placa-parado';

      if (Boolean(prefixo)) {
        return `<div class='${statusVeiculoCssClass}'>
            <span>${prefixo}-</span>
            <span>${placa}</span>
          </div>`;
      } else if (Boolean(placa)) {
        return `<div class='${statusVeiculoCssClass}'>
            <span>${placa}</span>
          </div>`;
      }
      return '';
    }

    getIcon(direcao, size, iconAnchor, popupAnchor, placa, prefixo, status, qtdOcorrenciasNovasVeiculo) {
      let emMovimento = status !== this.translate.instant('ce.enum.eventos.parado');
      return {
        type: 'div',
        html: `<div class='icon-container'>
                <span class="v3g-icon fa-stack ${size}">
                  <div class="fa-stack-2x
                  ${emMovimento ? 'trimble-vehicle-directional' : 'trimble-vehicle-directional stopped'}"
                  style="${this.getCssRotate(direcao, emMovimento)};"></div>
                  <i class="v3g-icon trimble-vehicle fa-stack-2x ${emMovimento ? 'text-primary' : ''}"'></i>
                  ${this.getLabelQtdOcorrenciasNovasDoVeiculo(qtdOcorrenciasNovasVeiculo)}
                </span>
                ${this.getVeiculoLabel(placa, prefixo, emMovimento)}
              </div>`,
        iconAnchor: iconAnchor,
        popupAnchor: popupAnchor
      };
    }

    getLabelQtdOcorrenciasNovasDoVeiculo(qtdOcorrenciasNovasVeiculo) {
      return `<div class="quantity-container ${qtdOcorrenciasNovasVeiculo > 0 ? '' : 'no-display'}" >
                <span>${qtdOcorrenciasNovasVeiculo}</span>
              </div>`;
    }

    getCssRotate(direcao, emMovimento) {
      if (!emMovimento) {
        return '';
      }
      return `
        -webkit-transform: rotate(${direcao}deg);
        -ms-transform: rotate(${direcao}deg);
        transform: rotate(${direcao}deg);
      `;
    }

    getIconPosicao() {
      return `<img src='images/icons-map/marker-pin-blue.png' class="img-icon"/>`;
    }

    setVelocidadeExcessiva(e) {
      if (e.evento === this.translate.instant(this.eventos.EXCESSO_VELOCIDADE.tipo)) {
        let limiar = this.getLimiarComoNumero(e.limiar),
            velocidade = Number(e.velocidade),
            excesso = limiar === 0 ? 0 : Math.round(velocidade / limiar * 100 - 100);
        if (excesso <= 20 && excesso !== 0) {
          e.evento = this.translate.instant(this.eventos.EXCESSO_VELOCIDADE1.tipo);
        } else if (excesso > 30) {
          e.evento = this.translate.instant(this.eventos.EXCESSO_VELOCIDADE3.tipo);
        } else {
          e.evento = this.translate.instant(this.eventos.EXCESSO_VELOCIDADE2.tipo);
        }
      }

      return e;
    }

    mapParadoExcessivamente(e) {
      if (e.evento === this.translate.instant(this.eventos.PARADO.tipo)) {
        if (e.duracao > 3600) {
          e.evento = this.translate.instant(this.eventos.PARADO_EX_4.tipo);
        } else if (e.duracao > 1800) {
          e.evento = this.translate.instant(this.eventos.PARADO_EX_3.tipo);
        } else if (e.duracao > 300) {
          e.evento = this.translate.instant(this.eventos.PARADO_EX_2.tipo);
        } else {
          e.evento = this.translate.instant(this.eventos.PARADO_EX_1.tipo);
        }
      }

      return e;
    }

    handleUndefinedEvent(event) {
      if (angular.isUndefined(event.evento)) {
        if (event.tipoPosicao == 'STATUS_PARADO_MOTOR_LIGADO') {
          event.evento = this.translate.instant(this.eventos.RPM.tipo);
        } else {
          event.evento = this.translate.instant('ce.enum.common.desconhecido');
        }
      }

      return event;
    }

    mapCurvaBrusca(e) {
      if (angular.isUndefined(e.evento)) {
        return e;
      }

      const curvaBrusca = this.translate.instant(this.eventos.CURVA_BRUSCA.tipo);
      if (e.evento === curvaBrusca) {
        e.evento = curvaBrusca;
      } else if (e.evento.indexOf(this.translate.instant('ce.enum.eventos.curva')) !== -1 &&
          e.evento.indexOf(this.translate.instant('ce.enum.common.esquerda')) !== -1) {
        e.evento = this.translate.instant(this.eventos.CURVA_BRUSCA_ESQUERDA.tipo);
        e.intensidade = angular.isDefined(e.intensidade) ? e.intensidade.replace('-', '') : null;
      } else if (e.evento.indexOf(this.translate.instant('ce.enum.eventos.curva')) !== -1 &&
          e.evento.indexOf(this.translate.instant('ce.enum.common.direita')) !== -1) {
        e.evento = this.translate.instant(this.eventos.CURVA_BRUSCA_DIREITA.tipo);
      }

      return e;
    }

    setDescida(e, faixasDescida) {
      const eventoDescida = this.translate.instant(this.eventos.DESCIDA.tipo);
      if (e.evento === eventoDescida && angular.isDefined(faixasDescida)) {
        const segundosFreioPressionado = e.miscInfo.segundosFreioPressionado;
        if (segundosFreioPressionado <= faixasDescida.segundosFaixa1) {
          e.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo);
        } else if (segundosFreioPressionado <= faixasDescida.segundosFaixa2) {
          e.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo);
        } else {
          e.evento = this.translate.instant(this.eventos.DESCIDA_FAIXA3.tipo);
        }
      }
      return e;
    }

    getCenter(eventos = []) {
      if (eventos.length > 0) {
        return {
          lat: eventos[0].latitude,
          lng: eventos[0].longitude,
          zoom: 12
        };
      }
      return this.getMapCenterByTimezone(this.currentTimezone);
    }

    getMapCenterByTimezone(timezone) {
      if (NORTH_AMERICA_TIMEZONES.includes(timezone)) {
        return NORTH_AMERICA_MAP_CENTER_DEFAULT;
      }
      return SOUTH_AMERICA_MAP_CENTER_DEFAULT;
    }

    getLimiarComoNumero(limiar) {
      let arrayPalavras = limiar.split(' ');
      return Number(arrayPalavras[1]);
    }

    flatPosicoes(posicoes) {
      return posicoes.reduce(
        (acc, posicao) => {
          acc.push(posicao);

          if (angular.isDefined(posicao.posicoes)) {
            posicao.posicoes.forEach(p => acc.push(p));
          }

          if (angular.isDefined(posicao.fim)) {
            acc.push(posicao.fim);
          }

          return acc;
        }, []);
    }

    ajustaEvento(p, index, ultimaValida, ultimaInvalida, faixasDescida) {
      this.handleUndefinedEvent(p);
      this.setVelocidadeExcessiva(p);
      this.mapParadoExcessivamente(p);
      this.mapCurvaBrusca(p);
      this.setDescida(p, faixasDescida);

      let evento = this.eventos.getEvento(p.evento);
      p.descricao = angular.isDefined(evento) ? evento.descricao : p.evento;
      p.index = index;

      if (p.gps !== 'Inválido') {
        if (angular.isArray(p.posicoes) && p.posicoes.length > 0) {
          ultimaValida = p.posicoes[p.posicoes.length - 1];
        } else {
          ultimaValida = p;
        }
        p.temCoordenada = true;
        if (ultimaInvalida && !ultimaInvalida.temCoordenada) {
          ultimaInvalida.temCoordenada = true;
          ultimaInvalida.latitude = p.latitude;
          ultimaInvalida.longitude = p.longitude;
          ultimaInvalida.logradouro = p.logradouro;
          ultimaInvalida.cidade = p.cidade;
          ultimaInvalida.uf = p.uf;
        }
      } else if (ultimaValida) {
        p.latitude = ultimaValida.latitude;
        p.longitude = ultimaValida.longitude;
        p.logradouro = ultimaValida.logradouro;
        p.cidade = ultimaValida.cidade;
        p.uf = ultimaValida.uf;
        p.temCoordenada = true;
      } else {
        ultimaInvalida = p;
        p.temCoordenada = false;
      }

      return {
        posicao: p,
        ultimaValida: ultimaValida,
        ultimaInvalida: ultimaInvalida
      };
    }

    ajustarEventos(posicoes, faixasDescida) {
      let ultimaValida, ultimaInvalida;
      return _.chain(posicoes)
        .map((p, index) => {
          var ajustado = this.ajustaEvento(p, index, ultimaValida, ultimaInvalida, faixasDescida);

          ultimaInvalida = ajustado.ultimaInvalida;
          ultimaValida = ajustado.ultimaValida;
          return ajustado.posicao;
        }).value();
    }

    marcarPosicoesInvalidasNoInicio(posicoes) {
      let jaTeveValida = false;
      posicoes.forEach(p => {
        if (p.temCoordenada || jaTeveValida) {
          jaTeveValida = true;
          p.valida = true;
        } else {
          p.valida = false;
        }
      });
    }

    posicoesFiltradas(posicoes) {
      return posicoes.filter(p => {
        let eventoAnaliseOperacionalExibivel = _.contains(this.eventosOperacionaisPermiteExibicao, p.evento) && p.valida;
        return p.valida && p.tipoPosicao !== 'ANAL_OPERACIONAL' || eventoAnaliseOperacionalExibivel;
      });
    }

    getLayersVisibilityLocalStorage(userId) {
      const preferences = this.window.localStorage.getItem(`${this.layersVisibilityLocalStorage}-${userId}`);
      return preferences && angular.fromJson(preferences);
    }

    setLayersVisibilityLocalStorage(userId, preferences) {
      const preferencesJson = angular.toJson(preferences);
      this.window.localStorage.setItem(`${this.layersVisibilityLocalStorage}-${userId}`, preferencesJson);
    }

    activateAllMapLayers(userId) {
      let visibilityPreferences = this.getLayersVisibilityLocalStorage(userId);
      if (visibilityPreferences) {
        visibilityPreferences.forEach((layer) => {
          if (!layer.shouldShow) {
            layer.shouldShow = true;
            this.notifyLayersVisibilityChange(layer.name, true);
          }
        });
        this.setLayersVisibilityLocalStorage(userId, visibilityPreferences);
      }
    }

    notifyLayersVisibilityChange(entity, shouldShow) {
      this.rootScope.$broadcast('layerVisibilityHasChanged', entity, shouldShow);
    }
  }

  angular
    .module('ModuleMapasUtil')
    .service('MapasUtil', MapasUtil);
}());
