(function () {
  'use strict';

  class CarouselSnapshotCtrl {
    constructor($translate, UrlConfigService, AuditingEventsEnum) {
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
      this.auditingEventsEnum = AuditingEventsEnum;
    }

    $onInit() {
      this.snapshot = this.resolve.snapshot;
      this.indiceCard = this.resolve.indice;
      this.cameraLabels = this.resolve.cameraLabels;
      this.isFeatSnapshotLabelActived = this.resolve.isFeatSnapshotLabelActived;
    }

    getUrl(indice) {
      const currentCamera = this.snapshot.cameras.find(cam => cam.indice === indice);
      return currentCamera.possuiImagem ?
        currentCamera.s3Url :
        `https://veltec3g-o-que-ha-de-novo.s3-sa-east-1.amazonaws.com/Veltec+Trimble/veltec-background.png`;
    }

    getCaption(indice) {
      return `${this.translate.instant('ce.mapa.common.visaoCameras.camera')} ${indice + 1}`;
    }

    getLabels(indice) {
      const noLabelTranslation = this.translate.instant(this.auditingEventsEnum.getDescriptionById('NO_LABEL'));
      let cameraLabel = null;

      if (!this.cameraLabels) {
        return noLabelTranslation;
      }

      cameraLabel = this.cameraLabels.find(cam => cam.index === indice);

      if (!cameraLabel) {
        return noLabelTranslation;
      }

      return cameraLabel.labels
        .map(label => this.translate.instant(this.auditingEventsEnum.getDescriptionById(label)))
        .join(', ');
    }
  }

  angular
    .module('historicoTimeline')
    .component('carouselSnapshot', {
      controller: CarouselSnapshotCtrl,
      controllerAs: '$ctrl',
      templateUrl: 'historico-timeline/visao-cameras/carousel-snapshot/carousel-snapshot.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
