(function () {
  'use strict';

  class ModalOcorrenciasInfoCtrl {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('ModalOcorrenciasInfoCtrl', ModalOcorrenciasInfoCtrl);
}());
