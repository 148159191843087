(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.rankingSafety', {
      url: '/relatorios/ranking/safety?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/ranking-safety/ranking-safety.tpl.html',
          controller: 'RankingSafetyCtrl',
          controllerAs: 'rankingSafety'
        }
      },
      data: {
        titulo: 'ce.ranking.direcaoSegura.titulo',
        role: 'RANKING_SEGURANCA_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.rankingSafety')
    .config(pageConfig);
}());
