(function () {
  'use strict';
  const VEICULO_URL = '/veiculo/veiculos/grupos';

  class GrupoVeicFormCtrl {
    constructor($uibModalInstance, HttpServiceV3g, grupoRow, TipoPermissaoEnum, $scope, AlertMessage, ModalInfoConfirmService, $translate, $sanitize) {
      this.tipoPermissaoEnum = TipoPermissaoEnum;
      this.modalInstance = $uibModalInstance;
      this.scope = $scope;
      this.httpService = HttpServiceV3g;
      this.grupo = grupoRow;
      this.alertMessage = AlertMessage;
      this.isGrupoCarregado = Boolean(this.grupo);
      this.modalInfoConfirmService = ModalInfoConfirmService;
      this.translate = $translate;
      this.$sanitize = $sanitize;

      this.initModal();
    }

    initModal() {
      if (this.isGrupoCarregado) {
        this.title = this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.edicao.titulo');
        this.carregaGrupo();
      } else {
        this.title = this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.cadastro.titulo');
      }
    }

    carregaGrupo() {
      this.model = {
        nome: this.grupo.nome,
        observacoes: this.grupo.observacoes,
        uoSelected: this.grupo.uo
      };
    }

    confirmar() {
      if (this.scope.grupoVeicForm.$valid) {
        this.salvar();
      } else {
        this.scope.grupoVeicForm.nome.$setTouched();
        this.scope.grupoVeicForm.uo.$setTouched();
      }
    }

    salvar() {
      let grupoSubmit = {
        nome: this.model.nome,
        observacoes: this.model.observacoes || null
      };
      if (this.isGrupoCarregado) {
        this.httpService.patch(VEICULO_URL + '/' + this.grupo.id, grupoSubmit)
        .then(() => {
          this.modalInstance.close({message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.edicao.sucesso'), grupoSubmit});
        })
        .catch(data => {
          this.appendAlert('editar', data);
        });
      } else {
        grupoSubmit.uo = {id: this.model.uoSelected.id};

        this.httpService.post(VEICULO_URL, grupoSubmit)
        .then(() => {
          this.modalInstance.close({message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.cadastro.sucesso')});
        })
        .catch((response) => {
          this.appendAlert('cadastrar', response);
        });
      }
    }

    removerGrupo() {
      if (!this.grupo.quantidadeVeiculosTotal) {
        const sanitizedMessage = this.$sanitize(this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.confirmacao') + `${this.grupo.nome}?`);
        this.modalInfoConfirmService.confirmModal({
          title: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.titulo'),
          conteudo: sanitizedMessage,
          btnSecundario: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.cancelar'),
          btnPrimario: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.excluir'),
          headerColor: 'bg-light-blue',
          typeButton: 'btn-primary'
        }).then(() => this.removerBackend());
      }
    }

    removerBackend() {
      this.httpService.delete(`/veiculo/veiculos/grupos/${this.grupo.id}`)
      .then(() => {
        this.modalInstance.close({message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.sucesso'), idDeleted: this.grupo.id});
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluir.error'),
          appendTo: '.alerta-aqui-modal'
        });
      });
    }

    appendAlert(type, response) {
      let typeString;
      if (type === 'editar') {
        typeString = this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.editar');
      } else if (type === 'excluir') {
        typeString = this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.excluirString');
      } else if (type === 'cadastrar') {
        typeString = this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.cadastrar');
      }
      if (response.status === 409 &&
          response.data.statusCode === 'PERMISSAO_VEICULOS_GRUPO' &&
          type === 'editar') {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.permissaoNecessaria'),
          appendTo: '.alerta-aqui-modal'
        });
      } else {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.erroPt1') + typeString + this.translate.instant('ce.cadastro.grupoDeVeiculos.modal.erroPt2'),
          appendTo: '.alerta-aqui-modal'
        });
      }
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
  .module('cadastros.grupoVeic')
  .controller('GrupoVeicFormCtrl', GrupoVeicFormCtrl);
}());
