(function () {
  'use strict';

  function historicoTimelineDirective() {
    return {
      scope: {
        item: '=',
        historicoTimeline: '='
      },
      restrict: 'E',
      templateUrl: 'historico-timeline/historico-timeline-directive.tpl.html'
    };
  }

  angular
    .module('historicoTimeline')
    .directive('historicoTimelineDirective', historicoTimelineDirective);
}());
