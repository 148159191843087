(function () {
  'use strict';

  /* global Highcharts */
  class VelocidadeRpmCtrl {

    constructor(Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService,
                $timeout, $scope, VelocidadeRpm, moment, AlertMessage, $uibModal, $window, $filter, LIMIAR, $translate, GoogleTagManagerUtils) {
      this.velocidadeRpmService = VelocidadeRpm;
      this.moment = moment;
      this.alertMessage = AlertMessage;
      this.dadosDoGrafico = {};
      this.modalService = $uibModal;
      this.window = $window;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.timeout = $timeout;
      this.scope = $scope;
      this.filter = $filter;
      this.translate = $translate;
      this.googleTagManagerUtils = GoogleTagManagerUtils;

      this.limiares = angular.fromJson(this.window.localStorage.getItem('limiar-rpm-velocidade'));
      this.limiarInferior = this.limiares === null ? LIMIAR.INFERIOR : this.limiares.limiarInferior;
      this.limiarSuperior = this.limiares === null ? LIMIAR.SUPERIOR : this.limiares.limiarSuperior;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 1,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          name: 'uo_id',
          key: this.keys.uo.name,
          size: 6
        },
        {
          name: 'uo_parceira_id',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'veiculo_id',
          key: this.keys.veiculo.name,
          required: true,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.consultar(ServiceVfiltro.getFiltroBackendAntigo(filtro));
      }).catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
      });
    }

    consultar(filtro) {
      this.velocidadeRpmService.one().get({filtros: filtro})
        .then(data => {
          const dadosDoGrafico = data.plain();
          if (!Object.keys(dadosDoGrafico).some((chave) => dadosDoGrafico[chave].length > 0)) {
            this.alertMessage.create({
              type: 'warning',
              message: this.translate.instant('ce.analise.velocidadeXrpm.common.nenhumResultadoEncontrado'),
              appendTo: '.alerta-aqui'
            });
          }
          this.dadosDoGrafico = dadosDoGrafico;
          this.renderizarGrafico(dadosDoGrafico);
        })
        .catch(() => {
          this.serviceVfiltro.openFiltroDetalhado = true;
        });
    }

    getPlotLines() {
      return [
        {
          color: '#333333',
          value: this.limiarInferior,
          width: 1,
          zIndex: 3,
          label: {
            text: this.limiarInferior,
            x: -28,
            y: 5,
            style: {
              color: '#333333',
              fontWeight: ''
            }
          }
        },
        {
          color: '#333333',
          value: this.limiarSuperior,
          width: 1,
          zIndex: 3,
          label: {
            text: this.limiarSuperior,
            x: -28,
            y: 5,
            style: {
              color: '#333333',
              fontWeight: ''
            }
          }
        }
      ];
    }

    redrawPlotLines() {
      this.chart.yAxis[0].update({
        plotLines: this.getPlotLines()
      });
    }

    renderizarGrafico(data) {
      this.chart = new Highcharts.Chart(this.getChartOptions(data));
      this.chart.yAxis[0].setExtremes(this.chart.axes[2].min, this.chart.axes[2].max);
      this.chart.yAxis[1].setExtremes(this.chart.axes[3].min, this.chart.axes[3].max);
    }

    getChartOptions(data) {
      const chartSeries = this.mapChartSeries(data);
      return {
        title: {
          text: this.filter('prefixoPlaca')(data.veiculo),
          style: {
            fontSize: '16px'
          }
        },
        chart: {
          renderTo: 'grafico-velocidade-rpm'
        },
        credits: false,
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                if (this.visible) {
                  this.chart.series
                    .filter(s => s.name !== 'Navigator' && s.name !== this.name)
                    .forEach(s => s.visible ? s.hide() : s.show());
                } else {
                  this.chart.series
                    .filter(s => s.name !== 'Navigator' && s.name !== this.name)
                    .forEach(s => s.hide());
                  this.show();
                }
                return false;
              }
            }
          }
        },
        exporting: {
          enabled: false
        },
        legend: {
          padding: 15
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              y: 15
            }
          }
        },
        tooltip: {
          crosshairs: true,
          formatter: (() => {
            const ctrl = this;
            return function () {
              return `${ctrl.moment.utc(this.x).format('DD/MM/YYYY HH:mm:ss')}<br/><b>${this.series.name}</b>: ${this.y}`;
            };
          })()
        },
        xAxis: [{
          type: 'datetime',
          minTickInterval: 1000,
          minRange: 10 * 1000
        }],
        yAxis: [
          {
            title: {
              text: `${this.translate.instant('ce.analise.velocidadeXrpm.common.chart.velocidade')} (Km/h)`,
              offset: 40
            },
            height: '75%',
            gridLineWidth: 0,
            gridLineColor: 'transparent',
            labels: {
              formatter: function () {
                return this.value;
              }
            },
            plotLines: this.gerarPlotLinesVelocidade(data)
          },
          {
            title: {
              text: this.translate.instant('ce.analise.velocidadeXrpm.common.chart.rpmUpperCase')
            },
            labels: {
              formatter: function () {
                return this.value;
              }
            },
            opposite: true,
            height: '75%'
          },
          {
            title: {
              text: this.translate.instant('ce.analise.velocidadeXrpm.common.chart.sensorDeChuva'),
              margin: 0,
              offset: -14
            },
            top: '75%',
            height: '25%',
            min: 0,
            max: 1000,
            gridLineWidth: 0,
            labels: {
              enabled: false
            }
          }
        ],
        series: [
          {
            name: this.translate.instant('ce.analise.velocidadeXrpm.common.chart.velocidade'),
            color: '#5BC0DE',
            data: chartSeries.velocidade,
            marker: {enabled: true, radius: 0},
            yAxis: 0
          },
          {
            name: this.translate.instant('ce.analise.velocidadeXrpm.common.chart.rpmUpperCase'),
            color: '#367fa9',
            data: chartSeries.rpm,
            marker: {enabled: true, radius: 0},
            yAxis: 1
          },
          {
            name: this.translate.instant('ce.analise.velocidadeXrpm.common.chart.sensorDeChuva'),
            type: 'polygon',
            color: 'rgba(124, 181, 236, 0.6)',
            data: chartSeries.sensorDeChuva,
            yAxis: 2,
            enableMouseTracking: false
          }
        ]
      };
    }

    mapChartSeries(data) {
      return {
        velocidade: data.historicoVelocidade.map(v => [this.getMillisWithOffset(v.dataHora), v.velocidade]),
        rpm: data.historicoRpm.map(r => [this.getMillisWithOffset(r.dataHora), r.rpm]),
        sensorDeChuva: ((yBase = 250, altura = 500) =>
          data.historicoLimpadorParabrisa.map(periodo => {
            const inicio = this.getMillisWithOffset(periodo.inicio), fim = this.getMillisWithOffset(periodo.fim);
            return [
              [inicio, yBase],
              [fim, yBase],
              [fim, yBase + altura],
              [inicio, yBase + altura],
              [fim, null]
            ];
          }).reduce((ant, atual) => ant.concat(atual), [])
        )()
      };
    }

    getMillisWithOffset(dateTimeStr) {
      const instante = this.moment(dateTimeStr);
      return this.moment(instante.valueOf() + instante.utcOffset() * 60 * 1000).valueOf();
    }

    gerarPlotLinesVelocidade(data) {
      if (!data.historicoVelocidade || data.historicoVelocidade.length === 0) {
        return [];
      }
      return this.getPlotLines();
    }

    modalConfigurar() {
      this.sendEventToGTM();
      this.modalService.open({
        animation: true,
        templateUrl: 'relatorios/velocidade-rpm/velocidade-rpm-config.tpl.html',
        controller: 'VelocidadeRpmConfigCtrl',
        controllerAs: '$ctrl',
        size: 'sm',
        resolve: {
          limiarInferior: () => this.limiarInferior,
          limiarSuperior: () => this.limiarSuperior
        }
      }).result.then(data => {
        this.limiarInferior = data.limiarInferior;
        this.limiarSuperior = data.limiarSuperior;
        this.window.localStorage.setItem('limiar-rpm-velocidade', angular.toJson(data));
        this.redrawPlotLines();
      });
    }

    sendEventToGTM() {
      this.googleTagManagerUtils.sendEventClickGTM('velocidade_x_rpm_configurar_velocidades', this.serviceVfiltro.factoryVfiltro.user);
    }
  }

  class VelocidadeRpmConfigCtrl {
    constructor(limiarInferior, limiarSuperior, $uibModalInstance, LIMIAR) {
      this.limiarInferior = limiarInferior;
      this.limiarSuperior = limiarSuperior;
      this.modalInstance = $uibModalInstance;
      this.LIMIAR = LIMIAR;
    }

    validar() {
      if (this.limiarInferior < 0) {
        this.limiarInferior = this.limiarInferior * -1;
      }
      if (this.limiarSuperior < 0) {
        this.limiarSuperior = this.limiarSuperior * -1;
      }
      if (this.limiarInferior > 200 || this.limiarInferior <= 0) {
        this.limiarInferior = this.LIMIAR.INFERIOR;
      }
      if (this.limiarSuperior > 200 || this.limiarSuperior <= 0) {
        this.limiarSuperior = this.LIMIAR.SUPERIOR;
      }
      if (this.limiarInferior >= this.limiarSuperior) {
        this.limiarInferior = this.limiarSuperior;
      }
      this.modalInstance.close({
        limiarInferior: this.limiarInferior,
        limiarSuperior: this.limiarSuperior
      });
    }
  }

  /**
   * @ngdoc object
   * @name relatorios.velocidadeRpm.controller:VelocidadeRpmCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.velocidadeRpm')
    .constant('LIMIAR', {INFERIOR: 60, SUPERIOR: 80})
    .controller('VelocidadeRpmConfigCtrl', VelocidadeRpmConfigCtrl)
    .controller('VelocidadeRpmCtrl', VelocidadeRpmCtrl);
}());
