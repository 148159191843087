/* global document */
(function () {
  'use strict';
  class TutorialBuscaRegiaoCtrl {
    constructor($window) {
      this.window = $window;
    }

    start() {
      this.window.open('mapas-grid?demo&vfiltro=dateTime:ontem;uo:1&vtabela=currentPage:1;pageSize:50&camada=ESTRADAS', '_blank');
      this.closeContainer();
    }

    closeContainer() {
      /*eslint-disable */
      const dataAtual = new Date();
      this.window.localStorage.setItem('tutorial-busca-regiao', dataAtual.setDate(dataAtual.getDate() + 3));
      document.getElementById('container-busca-regiao').style.display = 'none'; /*eslint-enable */
    }
  }

  angular
    .module('conducaoEconomica')
    .controller('TutorialBuscaRegiaoCtrl', TutorialBuscaRegiaoCtrl)
    .directive('tutorialBuscaRegiao', function () {
      return {
        restrict: 'E',
        templateUrl: 'onboarding/tutorial-busca-regiao/tutorial-busca-regiao-directive.tpl.html',
        controller: TutorialBuscaRegiaoCtrl,
        controllerAs: 'ctrl'
      };
    });
}());
