(function () {
  'use strict';

  class RotogramaController {
    constructor(CadastroRotogramaService, RotogramaMapService, RotogramaComponentStates, $scope) {
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.rotogramaMapService = RotogramaMapService;
      this.componentStates = RotogramaComponentStates;
      this.scope = $scope;
    }

    $onInit() {
      this.configurarInteracoesMarker();
      this.rotogramaMapService.adicionarMarker(this.marker, this.posicao);
      if (this.marker.options.rotogramaCadastrado) {
        this.rotogramaId = this.cadastroRotogramaService.adicionarRotograma(
          this.marker,
          this.marker.options.rotogramaCadastrado
        );
      }
    }

    configurarInteracoesMarker() {
      let latLngInicial;
      this.marker.on('dragstart', () => {
        latLngInicial = this.handleDragStart();
      });
      this.marker.on('drag', e => this.handleDrag(e));
      this.marker.on('dragend', () => this.handleDragEnd(latLngInicial));
    }

    handleDragStart() {
      this.dragging = true;
      return this.marker.getLatLng();
    }

    handleDrag(dragEvent) {
      this.rotogramaMapService.forcarLatLngValida(dragEvent, this.posicao);
    }

    handleDragEnd(latLngInicial) {
      this.rotogramaMapService.forcarAnguloValido(this.posicao, latLngInicial);
      this.scope.$applyAsync(() => {
        this.dragging = false;
      });
    }

    click() {
      if (this.dragging) {
        return;
      }
      if (this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.INICIAL ||
        this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.DESTAQUE) {
        this.rotogramaId = this.cadastroRotogramaService.adicionarRotograma(this.marker);
      } else if (this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.EXCLUSAO) {
        this.cadastroRotogramaService.removerRotograma(this.rotogramaId);
        this.rotogramaId = null;
      }
    }

    mouseEnter() {
      if (this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.ATIVO) {
        this.cadastroRotogramaService.getRotograma(this.rotogramaId).state = this.componentStates.EXCLUSAO;
      }
    }

    mouseLeave() {
      if (this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.EXCLUSAO) {
        this.cadastroRotogramaService.getRotograma(this.rotogramaId).state = this.componentStates.ATIVO;
      }
    }

    getContent() {
      return this.cadastroRotogramaService.getState(this.rotogramaId) === this.componentStates.ATIVO ?
        this.cadastroRotogramaService.getRotograma(this.rotogramaId).indice : '+';
    }
  }

  angular
    .module('rotogramaModule')
    .component('rotograma', {
      template: `
        <span class="botao-rotograma rotograma-animated"
          ng-class="rotogramaCtrl.cadastroRotogramaService.getState(rotogramaCtrl.rotogramaId)"
          ng-click="rotogramaCtrl.click()"
          ng-mouseover="rotogramaCtrl.mouseEnter()"
          ng-mouseleave="rotogramaCtrl.mouseLeave()">
          {{ rotogramaCtrl.getContent() }}
        </span>
      `,
      controller: RotogramaController,
      controllerAs: 'rotogramaCtrl',
      bindings: {
        marker: '<',
        posicao: '<'
      }
    });
}());
