(function () {
  'use strict';

  const PROPORCAO_MAPA_BOUNDS_AUMENTO = 0.5, MINUTO_EM_SEGUNDOS = 60;
  /* global L, _ */
  class CadastroCercaEletronicaCtrl {
    constructor(CercaEletronicaService, CercaEletronicaMapService, PontosReferenciaMap, PontosReferenciaTools,
      MapasUtil, leafletData, $scope, CategoriaPontosReferencia, Authenticator, AlertMessage, Uos, UoArvoreNivel,
      $rootScope, $translate) {
      this.cercaEletronicaService = CercaEletronicaService;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.pontoReferenciaMapService = PontosReferenciaMap;
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.alertMessage = AlertMessage;
      this.categoriaPontosReferenciaService = CategoriaPontosReferencia;
      this.mapasUtil = MapasUtil;
      this.map = null;
      this.rootScope = $rootScope;
      this.leafletData = leafletData;
      this.authenticatorService = Authenticator;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.model = {
        uo: null,
        nome: null,
        tempoPermanenciaMinimo: null,
        observacao: null,
        categoria: null
      };
      this.uos = [];
      this.scope = $scope;
      this.maxBounds = null;
      this.rotogramas = new L.FeatureGroup();
      this.pontosRefencia = new L.FeatureGroup();
      this.isEdicao = false;
      this.cercaEletronica = null;
      this.errorCarregar = false;
      this.categorias = null;
      this.isPermanenciaVisivel = true;
      this.translate = $translate;
    }

    $onInit() {
      // remove referencia para não precisar clonar layer
      this.resolve.cercaEletronica.layer = null;
      this.cercaEletronica = _.cloneDeep(this.resolve.cercaEletronica);
      const idCercaEletronica = this.cercaEletronica.id;

      this.loadUos();
      this.loadCategorias(this.cercaEletronica.uoId);
      if (!idCercaEletronica) {
        this.initMap();
      } else {
        this.loadFields(idCercaEletronica);
      }
      this.isEdicao = !!this.cercaEletronica.id;
      this.setMapConfig();
    }

    toMinutes(seconds) {
      return seconds ? seconds / MINUTO_EM_SEGUNDOS : null;
    }

    toSeconds(minutes) {
      return minutes ? minutes * MINUTO_EM_SEGUNDOS : null;
    }

    loadFields(idCercaEletronica) {
      this.cercaEletronicaService.getCercaEletronica(idCercaEletronica)
        .then((cercaEletronica) => {
          this.model.nome = cercaEletronica.nome;
          this.model.tempoPermanenciaMinimo = this.toMinutes(cercaEletronica.tempoPermanenciaMinimo);
          this.model.configurarTempo = !!cercaEletronica.tempoPermanenciaMinimo;
          this.model.observacao = cercaEletronica.observacao;

          this.cercaEletronica = cercaEletronica;
          this.setCategoria();
          this.initMap();
        })
        .catch(() => {
          this.errorCarregar = true;
        });
    }

    loadUos() {
      this.uoService.getFilhas()
      .then(data => {
        const uoId = this.cercaEletronica.id ?
          this.cercaEletronica.uoId :
          this.rootScope.usuario.uo.id;

        this.uos = this.uoArvoreNivel.converterEmLista(data.plain());
        this.model.uo = this.uos.find(uo => uo.id === uoId);
      });
    }

    setCategoria() {
      if (this.categorias && this.cercaEletronica.categoriaId) {
        this.model.categoria = this.categorias.find(categoria => categoria.id === this.cercaEletronica.categoriaId);
      } else {
        this.model.categoria = null;
      }
      this.onChangeCategoria();
    }

    loadCategorias(uoId) {
      this.categoriaPontosReferenciaService.getListCategoriasPontoReferencia()
      .then(categorias => {
        if (uoId) {
          this.categorias = categorias.filter(categoria => categoria.uo === uoId);
        } else {
          this.categorias = categorias;
        }
        this.categorias = this.categorias.sort(
          (a, b) => a.nome.localeCompare(b.nome)
        );
        this.setCategoria();
      })
      .catch(() => {
        this.errorCarregar = true;
      });
    }

    setMapConfig() {
      const center = this.getCenterCercaEletronica();
      this.tiles = this.mapasUtil.tiles.veltec;
      this.defaults = this.mapasUtil.defaults;
      this.center = {
        zoom: 15,
        lat: center.lat,
        lng: center.lng
      };
    }

    getCenterCercaEletronica() {
      return this.cercaEletronicaMapService.getLatLngsBoundsCercaEletronica(this.cercaEletronica).getCenter();
    }

    initMap() {
      this.leafletData.getMap('mapaModalCercaEletronica')
        .then(map => {
          this.map = map;
          this.addCercaEletronicaLayer();
        });
    }

    addCercaEletronicaLayer() {
      this.cercaEletronicaLayer = this.cercaEletronicaMapService.getMarkerFromCercaEletronica(this.cercaEletronica);

      const boundsCercaEletronica = this.cercaEletronicaLayer.getBounds();

      this.cercaEletronicaLayer.setStyle(
        this.cercaEletronicaMapService.getStyleSelecionada(
          this.cercaEletronica.categoria ? this.cercaEletronica.categoria.cor : null
        )
      );
      this.map.addLayer(this.cercaEletronicaLayer);
      this.map.fitBounds(boundsCercaEletronica);

      // faz com o mapa possua um limite de navegação proporcional ao tamanho da area em edição
      this.maxBounds = boundsCercaEletronica.pad(PROPORCAO_MAPA_BOUNDS_AUMENTO);
      this.map.setMaxBounds(this.maxBounds);
      this.map.setMinZoom(this.map.getBoundsZoom(boundsCercaEletronica));

      this.cercaEletronicaMapService.getCustomEditablePolygon(this.cercaEletronicaLayer).enable();

      this.importCercasEletronicas();
      this.importPontosRef();
    }

    importCercasEletronicas() {
      this.getCercasFiltradas().forEach(cercaEletronica => {
        this.map.addLayer(this.cercaEletronicaMapService.getMarkerFromCercaEletronica(cercaEletronica));
      });
    }

    /**
     * Filtra somente cercas na região de visualizacao do mapa
     *
     * @return {Array} Cercas que se encontram nas delimitações do mapa visualizado pelo usuário
     */
    getCercasFiltradas() {
      return this.cercaEletronicaMapService.cercasEletronicas
        .filter(cercaEletronica =>
          cercaEletronica.id !== this.cercaEletronica.id &&
          this.maxBounds.intersects(this.cercaEletronicaMapService.getLatLngsBoundsCercaEletronica(cercaEletronica))
        );
    }

    importPontosRef() {
      this.getPontosFiltrados().forEach(ponto => {
        this.pontoReferenciaMapService
          .getLayersPontoReferencia(ponto)
          .forEach((layer, index) => {
            if (index !== 0) {
              layer.setOpacity(1);
              this.rotogramas.addLayer(layer);
            } else {
              this.pontosRefencia.addLayer(layer);
            }
          });
      });

      this.map.addLayer(this.pontosRefencia);
      this.controlRotogramas();
    }

    /**
     * Filtra somente pontos na região de visualizacao do mapa
     *
     * @return {Array} Pontos que se encontram nas delimitações do mapa visualizado pelo usuário
     */
    getPontosFiltrados() {
      return this.pontoReferenciaMapService
        .pontosReferencia
        .filter(pontoRef =>
          this.maxBounds.contains(
            L.latLng(
              pontoRef.entidade.latitude,
              pontoRef.entidade.longitude
            )
          )
        );
    }

    controlRotogramas() {
      this.map.on('zoomend', () => {
        if (this.map.getZoom() >= 15) {
          this.rotogramas.addTo(this.map);
        } else {
          this.rotogramas.removeFrom(this.map);
        }
      });
    }

    onChangeCategoria() {
      if (this.cercaEletronicaLayer) {
        this.cercaEletronicaLayer.setStyle(
          this.cercaEletronicaMapService.getStyleSelecionada(this.model.categoria ? this.model.categoria.cor : null)
        );
      }
    }

    salvar() {
      this.cercaEletronica = {
        id: this.cercaEletronica.id,
        geometria: this.cercaEletronicaLayer.toGeoJSON().geometry,
        nome: this.model.nome,
        uoId: this.model.uo.id,
        observacao: this.model.observacao,
        tempoPermanenciaMinimo: this.toSeconds(this.model.tempoPermanenciaMinimo),
        categoriaId: this.model.categoria ? this.model.categoria.id : null
      };

      if (this.cercaEletronica.id) {
        this.cercaEletronicaService.updateCercaEletronica(this.cercaEletronica)
          .then(res => {
            const cercaEletronicaEditada = Object.assign({},
                res.data,
                {
                  categoria: this.model.categoria,
                  tipoPermissao: 'E'
                }
            );
            this.alertMessage.create({
              type: 'success',
              message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.sucessoEditarCerca'),
              appendTo: '.alerta-aqui-ponto'
            });
            this.closeModal(cercaEletronicaEditada);
          })
          .catch((err) => {
            if (err && err.status === 409) {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.errorEditarCercaSobreposta'),
                appendTo: '.alerta-aqui-modal-area'
              });
            } else {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.errorEditarCerca'),
                appendTo: '.alerta-aqui-modal-area'
              });
            }
          });
      } else {
        this.cercaEletronicaService.createCercaEletronica(this.cercaEletronica)
          .then(res => {
            const cercaEletronicaNova = Object.assign({},
                res.data,
                {
                  categoria: this.model.categoria,
                  tipoPermissao: 'E'
                }
            );
            this.alertMessage.create({
              type: 'success',
              message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.sucessoCadastrarCerca'),
              appendTo: '.alerta-aqui-ponto'
            });
            this.closeModal(cercaEletronicaNova);
          })
          .catch((err) => {
            if (err && err.status === 409) {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.errorCadastrarCercaSobreposta'),
                appendTo: '.alerta-aqui-modal-area'
              });
            } else {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.cadastroCercaEletronica.errorCadastrarCerca'),
                appendTo: '.alerta-aqui-modal-area'
              });
            }
          });
      }
    }

    closeModal(cercaEletronica) {
      this.close({
        $value: {
          isEdicao: this.isEdicao,
          cercaEletronica
        }
      });
    }

    cancel() {
      this.dismiss();
    }

    $onDestroy() {
      this.leafletData.unresolveMap('mapaModalCercaEletronica');
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }
  }

  angular
  .module('mapControlModule')
  .component('modalCercaEletronica', {
    controller: CadastroCercaEletronicaCtrl,
    templateUrl: 'directives/map-control/cerca-eletronica/cadastro-cerca-eletronica-modal/cadastro-cerca-eletronica-modal.tpl.html',
    bindings: {
      close: '&',
      dismiss: '&',
      resolve: '<'
    }
  });
}());
