(function () {
  'use strict';

  /* global L, geolib, _ */
  class PontosProximos {
    constructor($filter, PontosReferenciaMenu) {
      this.filter = $filter;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;

      this.historicos = [];
      this.listaPontosProximos = [];

      this.RAIO_EM_METROS = 500;
    }

    init(historicos) {
      this.historicos = historicos;
    }

    setPontosProximos(pontos) {
      this.listaPontosProximos = pontos
      .map((layer) => {
        const latlng = layer.getLatLng(),
            idLayer = L.stamp(layer),
            index = layer.options.icon.options.index,
            obj = this.findHistorico(latlng, idLayer, index);

        if (obj.length > 0) {
          return _.sortBy(obj, 'raio')[0];
        }
        return {
          index,
          idLayer,
          latitude: latlng.lat,
          longitude: latlng.lng
        };
      });

      if (this.listaPontosProximos.length > 0) {
        this.pontosReferenciaMenuService.tabelaPontosProximosOpen();
      }
    }

    removePontoProximo(idLayer) {
      this.listaPontosProximos = this.listaPontosProximos.filter(item => item.idLayer !== idLayer);
    }

    findHistorico(latlng, idLayer, index) {
      let listaEvento = [];
      this.historicos.forEach(item => {
        listaEvento.push(this.getPontosProximos(item.historicos, latlng, idLayer, index));
      });
      return _.flatten(listaEvento)
        .filter(x => x && !!x.raio);
    }

    getPontosProximos(item, latlng, idLayer, index) {
      return _.flatten(item.map(i => this.getPontoProximo(i, latlng, idLayer, index)));
    }

    getPontoProximo(item, latlng, idLayer, index) {
      let lista = [];
      if (this.hasHistoricoInicio(item, latlng)) {
        lista.push(this.buildObjPontoProximo(item, item.latitude, item.longitude, latlng, idLayer, index));
      }
      if (this.hasHistoricoFim(item, latlng)) {
        lista.push(this.buildObjPontoProximo(item.fim, item.fim.latitude, item.fim.longitude, latlng, idLayer, index));
      }
      return lista;
    }

    buildObjPontoProximo(item, latitude, longitude, latlng, idLayer, index) {
      return {
        evento: item,
        raio: geolib.getDistanceSimple(
          {latitude: latlng.lat, longitude: latlng.lng},
          {latitude, longitude}
        ),
        latitudeMarker: latlng.lat,
        longitudeMarker: latlng.lng,
        latitude,
        longitude,
        idLayer,
        index
      };
    }

    hasHistoricoInicio(item, latlng) {
      return item.gps !== 'Inválido' &&
        item.latitude &&
        item.longitude &&
        geolib.isPointInCircle(
          {latitude: item.latitude, longitude: item.longitude},
          {latitude: latlng.lat, longitude: latlng.lng},
          this.RAIO_EM_METROS
        );
    }

    hasHistoricoFim(item, latlng) {
      return item.fim &&
        item.fim.gps !== 'Inválido' &&
        item.fim.latitude &&
        item.fim.longitude &&
        geolib.isPointInCircle(
          {latitude: item.fim.latitude, longitude: item.fim.longitude},
          {latitude: latlng.lat, longitude: latlng.lng},
          this.RAIO_EM_METROS
        );
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosProximos', PontosProximos);
}());
