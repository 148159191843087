(function () {
  'use strict';

  function menuExcluirPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuExcluirPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuExcluirPontoReferenciaCtrl {
    constructor($scope, $uibModal, AlertMessage, PontosReferenciaTools, PontosReferenciaMap, PontosReferenciaMenu, PontosReferencia, $translate) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.alertMessage = AlertMessage;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.translate = $translate;
      this.init();
    }

    init() {
      let buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtExcluirPonto',
                icon: 'fa fa-check',
                color: 'success',
                tipo: 'default',
                funcao: this.excluirPontoBanco.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'editar'
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMenuService.drawerClose();
      this.pontosReferenciaMenuService.menuOpen();
    }

    excluirPontoBanco() {
      const pontoReferencia = this.pontosReferenciaMapService.layer.entidadePermissao.entidade;
      if (angular.isDefined(pontoReferencia.rotogramas) && pontoReferencia.rotogramas.length > 0) {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.menuExcluirPonto.erroRotograma'),
          appendTo: '.alerta-aqui-ponto'
        });
        this.pontosReferenciaMapService.setLayerDefault();
        return;
      }
      this.pontosReferenciaService.deletePontoReferencia(pontoReferencia.id)
      .then(() => {
        this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.mapa.common.menuExcluirPonto.excluirSucesso'),
          appendTo: '.alerta-aqui-ponto'
        });

        if (this.pontosReferenciaMapService.layer) {
          let layer = this.pontosReferenciaMapService.getMarkerLimite(this.pontosReferenciaMapService.getIdLayer(this.pontosReferenciaMapService.layer));
          if (layer) {
            this.pontosReferenciaMapService.removeLayer(layer);
            this.pontosReferenciaMapService.removeLayerMap(layer);
          }
          if (angular.isDefined(this.pontosReferenciaMapService.layer.labels)) {
            this.pontosReferenciaMapService.layer.labels.forEach(label => {
              this.pontosReferenciaMapService.removeLayer(label);
              this.pontosReferenciaMapService.removeLayerMap(label);
            });
          }
          this.pontosReferenciaMapService.removeLayer(this.pontosReferenciaMapService.layer);
          this.pontosReferenciaMapService.removeLayerMap(this.pontosReferenciaMapService.layer);
          this.pontosReferenciaMapService.excluiPontoReferencia(pontoReferencia.id);
          this.pontosReferenciaMapService.pesquisa = '';
          this.pontosReferenciaMapService.pontos = [];
        }
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.menuExcluirPonto.excluirErro'),
          appendTo: '.alerta-aqui-ponto'
        });
      });

      this.pontosReferenciaMenuService.setFABDefault();
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuExcluirPontoReferenciaCtrl', MenuExcluirPontoReferenciaCtrl)
    .directive('menuExcluirPontoReferenciaDirective', menuExcluirPontoReferenciaDirective);
}());
