(function () {
  'use strict';
  class OcorrenciaDetalhesCtrl {
    constructor(Ocorrencias, OcorrenciasDetalhes, $q, $scope, $stateParams) {
      this.serviceQuadroOcorr = Ocorrencias;
      this.serviceOcorrDetalhes = OcorrenciasDetalhes;
      this.q = $q;
      this.stateParams = $stateParams;
      this.scope = $scope;

      this.nenhumUsuario = {id: null, login: 'Nenhum'};
      this.requestError = false;
      this.model = {
        responsavel: null,
        status: null,
        obs: null
      };
      this.limitScroll = 10;
      this.ocorrencia = null;

      this.initRequests();

      this.isDrawerActive = false;
      this.isDetailsReloaded = false;
      this.itemsReloaded = new Set();
      this.limit = 100;
    }

    toggleDrawer() {
      this.isDrawerActive = !this.isDrawerActive;
    }

    loadMore() {
      this.limitScroll += 10;
    }

    initRequests() {
      this.serviceOcorrDetalhes.getOcorrenciaById(this.stateParams.ocorrenciaId, this.stateParams.dataHora)
      .then((data) => {
        this.ocorrencia = data.data[0];
        this.lateRequest();
      })
      .catch(() => {
        this.requestError = true;
      });
    }

    lateRequest() {
      let requests = [
        this.serviceQuadroOcorr.getUsers(this.ocorrencia.veiculoId),
        this.serviceQuadroOcorr.getHistorico(this.ocorrencia)
      ];
      this.q.all(requests)
        .then(([userRes, histRes]) => {
          this.usuarios = userRes.data;
          this.model.responsavel = this.usuarios
            .find(user => user.id === this.ocorrencia.usuarioIdAtribuido) || this.nenhumUsuario;
          this.usuarios.unshift(this.nenhumUsuario);
          if (histRes) {
            this.ocorrencia.hist = histRes.data;
          }
        })
        .catch(() => {
          this.requestError = true;
        });
    }
  }

  angular
    .module('relatorios.ocorrenciaDetalhes')
    .controller('OcorrenciaDetalhesCtrl', OcorrenciaDetalhesCtrl);
}());
