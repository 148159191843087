(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.abastecimento', {
      url: '/relatorios/abastecimento?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/abastecimento/abastecimento.tpl.html',
          controller: 'AbastecimentoCtrl',
          controllerAs: 'abastecimento'
        }
      },
      data: {
        titulo: 'ce.boletim.abastecimento.titulo',
        tituloDescricao: 'ce.boletim.abastecimento.tituloDescricao',
        role: 'HISTORICO_ABASTECIMENTO_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.abastecimento')
    .config(pageConfig);
}());
