(function () {
  'use strict';

  class AbastecimentoCtrl {
    constructor(Abastecimento, $httpParamSerializer, moment, ServiceVfiltro, ObjetosVfiltroService, FactoryVfiltro, Keys, VtabelaFactory, uiGridConstants, UrlConfigService) {
      this.abastecimentoService = Abastecimento;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.moment = moment;
      this.httpParamSerializer = $httpParamSerializer;
      this.urlConfigService = UrlConfigService;

      this.vtabela = null;
      this.exportsPdf = false;
      this.uoSelecionada = null;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          time: true,
          dateLimit: 180,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo',
          key: this.keys.uo.name,
          size: 12
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.user = this.serviceVfiltro.factoryVfiltro.user;
        this.roleVisualizarMapa = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('LOCALIZACAO_ATUAL_VISUALIZAR');
        this.roleVisualizarPonto = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('PONTO_REFERENCIA_VISUALIZAR');
        this.consultar();
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'relatorioAbastecimento', 'v1');
      })
      .catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
      });
    }

    initVtabela() {
      let drilDownSorting, drilDownCol, objVtabela = {
            exporterPdfFilename: 'Relatório de Abastecimento PDF.pdf',
            exporterCsvFilename: 'Relatório de Abastecimento CSV.csv',
            exporterExcelFilename: 'Relatório de Abastecimento XLSX.xlsx',
            appScopeProvider: this,
            enableRowSelection: false,
            enableRowHeaderSelection: false,
            multiSelect: false,
            enableSelectAll: false,
            showColumnFooter: true,
            isRowSelectable: () => {
              return false;
            },
            columnDefs: [
                {
                  name: 'placa',
                  displayName: 'ce.boletim.abastecimento.colunas.placa',
                  type: 'string',
                  pinnedLeft: true,
                  filterCellFiltered: true,
                  enableHiding: false,
                  aggregationTotalTitle: 'Total'
                },
                {
                  name: 'motorista',
                  displayName: 'ce.boletim.abastecimento.colunas.motorista',
                  enableHiding: false,
                  pinnedLeft: true,
                  filterCellFiltered: true,
                  cellFilter: 'withLabelToUndefined:\'ce.common.motorista.semIdentificacao\' | translate | splitComposedLabels:true'
                },
                {name: 'dataAbastecimento', displayName: 'ce.boletim.abastecimento.colunas.dataHora', type: 'date', filterCellFiltered: true,
                cellFilter: 'date:\'dd/MM/yyyy HH:mm\'', cellClass: 'text-center', enableHiding: false},
                {name: 'hodometroInformado', displayName: 'ce.boletim.abastecimento.colunas.hodometroInformado', filterCellFiltered: true,
                cellFilter: 'decimalWithLabel:\'Km\'', cellClass: 'text-center'},
                {name: 'hodometroVeltec', displayName: 'ce.boletim.abastecimento.colunas.hodometroVeltec', filterCellFiltered: true,
                cellFilter: 'decimalWithLabel:\'Km\'', cellClass: 'text-center'},
                {name: 'logradouro', displayName: 'ce.boletim.abastecimento.colunas.logradouro', filterCellFiltered: true},
                {
                  name: 'distancia',
                  displayName: 'ce.boletim.abastecimento.colunas.distancia',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: 'ce.boletim.abastecimento.colunas.distanciaTooltip',
                  cellFilter: 'decimalWithLabel:\'Km\'',
                  cellClass: 'text-center'
                },
                {name: 'rendimento', displayName: 'ce.boletim.abastecimento.colunas.rendimento', type: 'number', cellClass: 'text-center',
                filterCellFiltered: true, cellFilter: 'withLabel:\'Km/L\''},
                {name: 'produto', displayName: 'ce.boletim.abastecimento.colunas.produto', filterCellFiltered: true},
                {
                  name: 'quantidade',
                  displayName: 'ce.boletim.abastecimento.colunas.quantidade',
                  filterCellFiltered: true,
                  cellClass: 'text-center',
                  cellFilter: 'decimalWithLabel:\'L\'',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
                },
                {
                  name: 'valorTotal',
                  displayName: 'ce.boletim.abastecimento.colunas.valorTotal',
                  filterCellFiltered: true,
                  cellFilter: 'currency:\'R$ \'',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum
                },
                {
                  name: 'pontoReferencia',
                  displayName: 'ce.boletim.abastecimento.colunas.pontoReferencia',
                  type: 'string',
                  cellTemplate: `
                  <div class='ui-grid-cell-contents' title="{{ row.entity.pontoReferencia }}">
                    <a ng-href='{{ row.entity.linkLocalizacaoFrota }}'
                      target='_blank'
                      ng-if='grid.appScope.roleVisualizarMapa && grid.appScope.roleVisualizarPonto'>
                      {{ row.entity.pontoReferencia }}
                    </a>
                    <span ng-if='!grid.appScope.roleVisualizarMapa || !grid.appScope.roleVisualizarPonto'>
                      {{ row.entity.pontoReferencia }}
                    </span>
                  </div>
                  `,
                  filterCellFiltered: true,
                  enableHiding: true
                }
            ]
          };
      drilDownSorting = this.getDrilDownSortingDirection(this.tableState);
      drilDownCol = objVtabela.columnDefs.find(column => column.name === this.tableState.sort.predicate);

      if (drilDownSorting && drilDownCol) {
        angular.merge(drilDownCol, drilDownSorting);
      }
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        placa: item.placa,
        motorista: item.motorista,
        dataAbastecimento: item.dataAbastecimento,
        hodometroInformado: item.hodometroIntegracao,
        hodometroVeltec: item.hodometroV3g,
        logradouro: item.logradouro,
        distancia: item.distancia,
        rendimento: item.rendimento,
        produto: item.produto,
        quantidade: item.quantidade,
        valorTotal: item.valorTotal,
        pontoReferencia: item.pontoReferencia && item.pontoReferencia.nome,
        linkLocalizacaoFrota: item.pontoReferencia &&
          `/mapas-grid?ponto=${item.pontoReferencia.id}&forceRenderLayer=true`
      };
    }

    consultar() {
      this.abastecimentoService.one().get(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))
        .then(data => this.setInfoTable(data))
        .catch(() => this.vtabela.setError());
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    getDrilDownSortingDirection(tableState) {
      if (tableState && tableState.sort.reverse) {
        return {
          sort: {
            direction: this.uiGridConstants.DESC
          }
        };
      }

      return {
        sort: {
          direction: this.uiGridConstants.ASC
        }
      };
    }

    getParams() {
      return this.serviceVfiltro.getFiltroBackendNovo(this.filtro);
    }

    getUrlCsv() {
      return `/abastecimento/historico-abastecimento/csv?${this.httpParamSerializer(this.getParams())}`;
    }

    getUrlPdf() {
      return `/abastecimento/historico-abastecimento/pdf?${this.httpParamSerializer(this.getParams())}`;
    }

  }

  angular
  .module('relatorios.abastecimento')
  .controller('AbastecimentoCtrl', AbastecimentoCtrl);
}());
