(function () {
  'use strict';

  angular
    .module('relatorios.rankingEconomy', [
      'ui.router',
      'restangular',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'pascalprecht.translate',
      'v3gAuthenticatorModule',
      'cadastros.veiculo',
      'urlConfigModule'
    ])
    .factory('RankingEconomy', Restangular => Restangular.service('boletim/ranking/economy'));
}());
