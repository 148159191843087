(function () {
  'use strict';

  angular
    .module('indicadores.DirecaoSegura', [
      'restangular',
      'angularMoment',
      'chartModule',
      'confluenceID',
      'indicadores',
      'uoModule',
      'onboarding',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate'
    ])
    .factory('DirecaoSegura', Restangular => Restangular.service('boletim/painel-direcao-segura'));
}());
