(function () {
  'use strict';

  class ReprodRotasService {
    constructor(EventosEnum, AlertMessage, $translate) {
      this.createdReprodRotas = false;
      this.showReprodRotas = false;
      this.agrupamentos = null;
      this.eventosEnum = EventosEnum;
      this.translate = $translate;
      this.alertMessage = AlertMessage;
    }

    criaPosicoesUnicas(posicoes) {
      return posicoes.reduce((acc, posicao) => {
        if (acc.length === 0 ||
          posicao.latitude !== acc[acc.length - 1].latitude ||
          posicao.longitude !== acc[acc.length - 1].longitude) {
          acc.push(posicao);
        }
        return acc;
      }, []);
    }

    setAgrupamentos(agrupamentos) {
      this.agrupamentos = agrupamentos;
    }

    hasSomeEventoMovimento() {
      if (this.agrupamentos) {
        return this.agrupamentos.some(agrupamento => agrupamento.evento === this.translate.instant(this.eventosEnum.MOVIMENTO));
      }
      return false;
    }

    createReprodRotas() {
      if (!this.hasSomeEventoMovimento()) {
        this.alertMessage.create({
          type: 'warning',
          message: this.translate.instant('ce.mapa.common.modal.reprodRotas.rotaNaoPossuiMovimento'),
          appendTo: '.alerta-aqui'
        });
      } else {
        this.createdReprodRotas = true;
        this.showReprodRotas = true;
      }
    }

    toggleReprodRotas() {
      this.showReprodRotas = !this.showReprodRotas;
    }
  }

  angular
    .module('reprodRotasModule')
    .service('ReprodRotasService', ReprodRotasService);
}());
