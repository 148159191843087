(function () {
  'use strict';

  class IndicadoresService {
    constructor(moment, UrlService, ObjetosVfiltroService, SortAlertas) {
      this.moment = moment;
      this.urlService = UrlService;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.SortAlertas = SortAlertas;
    }

    getFiltrosParaRanking(periodo, uoPai, uoParceira, evento, reverse = true) {
      let filtro = {
            dateTime: [
              this.moment(periodo[0]).format('YYYY.MM.DD.HH.mm.00.ZZ'),
              this.moment(periodo[1]).format('YYYY.MM.DD.HH.mm.59.ZZ')
            ],
            uo: uoPai,
            uoParceira: uoParceira
          }, tableState = {
            sort: {
              predicate: this.SortAlertas[evento] || evento,
              reverse: reverse
            },
            search: '',
            pagination: {
              start: 0,
              totalItemCount: 0,
              number: 10,
              numberOfPages: 1
            }
          };

      return `vfiltro=${this.urlService.getObjectVfiltroUrl(filtro)}&tablestate=${this.objetosVfiltroService.getTableState(tableState)}`;
    }
  }

  angular
    .module('indicadores')
    .service('IndicadoresService', IndicadoresService);
}());
