(function () {
  'use strict';

  /* global _ */
  class OnePageCtrl {
    constructor($scope, $window, $uibModal, OnePageService, CustomGridster,
                Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, $translate) {
      this.scope = $scope;
      this.window = $window;
      this.translate = $translate;
      this.modalService = $uibModal;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.onePageService = OnePageService;
      this.customGridster = CustomGridster;

      this.edicaoGridster = false;
      this.versaoLocalstorage = 'v5';
      this.colors = [
        'rgb(244, 150, 59)',
        'rgb(72, 60, 70)',
        'rgb(60, 110, 113)',
        'rgb(136,84,196)',
        'rgb(112,174,110)',
        'rgb(139,188,33)',
        'rgb(255,108,58)',
        'rgb(49,139,255)',
        'rgb(204,38,54)',
        '#F2994A',
        '#92a323',
        'rgb(255, 203, 63)',
        'rgb(255, 73, 121)',
        '#6790AB',
        '#FF94C2',
        '#880E4F',
        '#F80C0C',
        '#133c7c',
        '#5f680b',
        '#0b685a',
        '#9183ad',
        '#d7f704',
        '#627a5f',
        '#510e0b',
        '#bf1ea1',
        '#7b5b91',
        '#ea52c4',
        '#5eea52',
        '#dff704'
      ];

      this.scope.$on('$destroy', () => {
        this.onePageService.removeTimer();
      });

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then(() => {
        this.init();
      });
    }

    init() {
      this.user = this.serviceVfiltro.factoryVfiltro.user;
      let graficoCard = angular.fromJson(this.window.localStorage.getItem(`grafico-card-${this.user.id}`)) || true,
          edicaoGridster = angular.fromJson(this.window.localStorage.getItem(`edicao-gridster-${this.user.id}`));
      if (graficoCard !== null) {
        this.grafico = graficoCard;
      } else {
        this.window.localStorage.setItem(`grafico-card-${this.user.id}`, this.grafico);
      }
      if (edicaoGridster !== null) {
        this.edicaoGridster = edicaoGridster;
        this.customGridster.setStatusGridster(edicaoGridster);
      } else {
        this.window.localStorage.setItem(`edicao-gridster-${this.user.id}`, this.edicaoGridster);
      }
      this.loadOnePageService();
    }

    loadOnePageService() {
      this.onePageService.removeTimer();
      this.onePageService.checkStatusVeiculo(this.getParams());
      this.onePageService.checkStatusVeiculoPassivelManutencao(this.getParams());
      this.onePageService.checkAlertaTurno(this.getParams());
    }

    getParams() {
      return {
        uoId: this.getParamValue(this.keys.uo.name)
      };
    }

    getParamValue(name) {
      let param = this.serviceVfiltro.filtroTopbar.find(p => p.key === name);
      if (param) {
        return param.value.id;
      }
      return undefined;
    }

    graficoCard(item) {
      this.grafico = item;
      this.window.localStorage.setItem(`grafico-card-${this.user.id}`, this.grafico);
    }

    statusGridster() {
      this.edicaoGridster = !this.edicaoGridster;
      this.customGridster.setStatusGridster(this.edicaoGridster);
      this.window.localStorage.setItem(`edicao-gridster-${this.user.id}`, this.edicaoGridster);
    }

    getPosicaoGrafico(name, defaultValue) {
      let item = this.customGridster.getLocalStorage(name);
      if (item && item.versao && item.versao === this.versaoLocalstorage) {
        return item;
      }
      this.customGridster.setLocalStorage(name, defaultValue);
      return defaultValue;
    }

    setPosicaoLocalStorage(obj) {
      this.scope.$watch(() => obj, (i) => {
        let item = this.customGridster.getLocalStorage(i.name);
        if (item) {
          item.sizeX = i.sizeX;
          item.sizeY = i.sizeY;
          item.minSizeX = i.minSizeX;
          item.maxSizeX = i.maxSizeX;
          item.minSizeY = i.minSizeY;
          item.maxSizeY = i.maxSizeY;
          item.row = i.row;
          item.col = i.col;
          item.versao = i.versao;
          this.customGridster.setLocalStorage(i.name, item);
        }
      }, true);
    }

    setPosicaoLocalStorageAlertas(obj, name) {
      return this.scope.$watch(() => obj, (i) => {
        let item = this.customGridster.getLocalStorage(name);
        if (item) {
          let alertas = item.alertas.map(x => {
            if (x.tipo === i.tipo) {
              return {
                row: i.row,
                col: i.col,
                sizeX: i.sizeX,
                sizeY: i.sizeY,
                minSizeY: i.minSizeY,
                maxSizeY: i.maxSizeY,
                minSizeX: i.minSizeX,
                maxSizeX: i.maxSizeX,
                tipo: i.tipo,
                sort: i.sort
              };
            }
            return x;
          });
          this.customGridster.setLocalStorage(name, {name: name, alertas, versao: this.versaoLocalstorage});
        }
      }, true);
    }

    voltarPadrao() {
      this.modalService.open({
        animation: true,
        templateUrl: 'one-page/one-page-padrao.tpl.html',
        controller: 'ModalOnePageCtrl',
        controllerAs: '$ctrl'
      }).result.then(() => {
        _.forIn(this.window.localStorage, (value, key) => {
          if (key.indexOf(`c-alertaTurno-${this.user.id}`) !== -1) {
            this.customGridster.removeLocalStorage(key);
          }
        });
        this.customGridster.removeLocalStorage(`c-paradoMovimento-${this.user.id}`);
        this.customGridster.removeLocalStorage(`c-semsinal-${this.user.id}`);
        this.customGridster.removeLocalStorage(`g-alerta-${this.user.id}`);
        this.customGridster.removeLocalStorage(`g-alertaTurno-${this.user.id}`);
        this.customGridster.removeLocalStorage(`g-paradoMovimento-${this.user.id}`);
        this.customGridster.removeLocalStorage(`g-semsinal-${this.user.id}`);
        this.customGridster.removeLocalStorage(`edicao-gridster-${this.user.id}`);
        this.customGridster.removeLocalStorage(`grafico-card-${this.user.id}`);
        this.customGridster.removeLocalStorage(`collapse-alertas-${this.user.id}`);
        this.window.location.href = '/tempo-real';
      });
    }

    isEdicaoGridster() {
      return this.edicaoGridster ?
        this.translate.instant('ce.tempoReal.statusFrota.common.desabilitarEdicao') :
        this.translate.instant('ce.tempoReal.statusFrota.common.habilitarEdicao');
    }
  }

  class ModalOnePage {
    constructor($uibModalInstance) {
      this.modalInstance = $uibModalInstance;
    }
  }

  /**
   * @ngdoc object
   * @name onePage.controller:OnePageCtrl
   *
   * @description
   *
   */
  angular
    .module('onePageModule')
    .controller('ModalOnePageCtrl', ModalOnePage)
    .controller('OnePageCtrl', OnePageCtrl);
}());
