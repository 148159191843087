/* global L */
(function () {
  'use strict';

  class ReprodRotasCtrl {
    constructor($interval, ReprodRotasService, EventosEnum, $translate) {
      this.pathGroup = new L.FeatureGroup();
      this.intervalService = $interval;
      this.reprodRotasService = ReprodRotasService;
      this.eventosEnum = EventosEnum;
      this.translate = $translate;

      this.checkedPos = [];
      this.lastCheckPoint = 0;
      this.lastPosTime = null;
      this.routeFinished = false;
      this.showSpeed = false;

      this.pos = [];
      this.playbackRoute = null;
      this.isPlaying = false;
      this.model = {
        speed: 1,
        pulaParada: false
      };
      this.speedInput = {
        min: 0.1,
        max: 10,
        step: 0.1
      };

      this.intervalReproducao = null;
      this.dataTimeReproduction = 0;
    }

    $onInit() {
      this.pos = this.reprodRotasService.criaPosicoesUnicas(this.posicoes);
      this.initEvents();
      this.initReprodRoute();
      this.initControls();
    }

    $onDestroy() {
      this.intervalService.cancel(this.intervalReproducao);
    }

    initEvents() {
      const elementReprodRotas = L.DomUtil.get('reprod-rotas');
      L.DomEvent.disableClickPropagation(elementReprodRotas);
      L.DomEvent.disableScrollPropagation(elementReprodRotas);
    }

    initReprodRoute() {
      let playbackObj = {
            type: 'Feature',
            geometry: {
              type: 'MultiPoint',
              coordinates: this.pos.map(p => [p.longitude, p.latitude])
            },
            properties: {
              time: this.pos.map(p => new Date(p.data).getTime())
            }
          },

          icon = L.icon({
            iconUrl: '/images/veiculo-reprod-rotas.png',
            iconSize: [20, 20],
            iconAnchor: [10, 10]
          }),

          playbackOptions = {
            maxInterpolationTime: 1440 * 60 * 1000,
            tickLen: 15000,
            speed: this.model.speed * 50,
            orientIcons: false,
            marker: function () {
              return {
                icon: icon,
                zIndexOffset: 99999
              };
            }
          };

      this.playbackRoute = new L.Playback(this.map, playbackObj, null, playbackOptions);
      this.geraOffsetEventoParado();
    }

    geraOffsetEventoParado() {
      this.offsets = this.agrupamentos
        .filter(agrupamento => agrupamento.evento === this.translate.instant(this.eventosEnum.PARADO))
        .map(agrupamento => ({
          inicio: new Date(agrupamento.inicio).getTime(),
          fim: new Date(agrupamento.fim).getTime()
        }));
    }

    getIntervalReproducao() {
      let interval = this.intervalService(() => {
        let currentTime = this.playbackRoute.getTime();
        if (this.model.pulaParada) {
          this.pulaEventoParado(currentTime);
        }
        this.model.slider = currentTime;
        this.setCurrentTime(currentTime);
        this.centralizaNavegacao();
        this.verificaFinalRota();
      }, 250);
      return interval;
    }

    setCurrentTime(currentTime) {
      let endTime = this.playbackRoute.getEndTime();
      this.dataTimeReproduction = currentTime > endTime ? endTime : currentTime;
    }

    verificaFinalRota() {
      if (this.isAtEndRoute()) {
        this.pause();
        this.routeFinished = true;
      } else {
        this.lastPosTime = this.playbackRoute.getTime();
      }
    }

    pulaEventoParado(currentTime) {
      let offsetCurrentPos = this.offsets.find(offset =>
        currentTime >= offset.inicio &&
        currentTime <= offset.fim);
      if (offsetCurrentPos) {
        this.playbackRoute.setCursor(offsetCurrentPos.fim);
      }
    }

    getCurrentLatLng() {
      return this.playbackRoute._trackController._tracks[0].tick(this.playbackRoute.getTime()); //eslint-disable-line
    }

    centralizaNavegacao() {
      let latLng = this.getCurrentLatLng();
      latLng = L.latLng(latLng[1], latLng[0]);
      if (!this.map.getBounds().contains(latLng)) {
        this.map.setView(latLng, this.map.getZoom());
      }
    }

    initControls() {
      this.sliderInput = {
        min: this.playbackRoute.getStartTime(),
        max: this.playbackRoute.getEndTime(),
        step: this.playbackRoute.getTickLen(),
        value: this.playbackRoute.getTime()
      };
      this.dataTimeReproduction = this.playbackRoute.getStartTime();
    }

    playPause() {
      if (this.playbackRoute.isPlaying()) {
        this.pause();
      } else {
        this.play();
      }
    }

    play() {
      if (this.routeFinished) {
        this.playbackRoute.setCursor(this.playbackRoute.getStartTime());
        this.routeFinished = false;
      }
      this.intervalReproducao = this.getIntervalReproducao();
      this.playbackRoute.start();
      this.isPlaying = true;
    }

    pause() {
      this.intervalService.cancel(this.intervalReproducao);
      this.playbackRoute.stop();
      this.isPlaying = false;
    }

    setSpeed() {
      let velocidade = Number(this.model.speed) * 50;
      this.playbackRoute.setSpeed(velocidade);
    }

    isAtEndRoute() {
      let currentTime = this.playbackRoute.getTime();
      return this.lastPosTime === currentTime &&
        currentTime >= this.playbackRoute.getEndTime() - this.playbackRoute.getTickLen();
    }

    setSlider() {
      this.playbackRoute.setCursor(this.model.slider);
      this.dataTimeReproduction = this.playbackRoute.getTime();
      if (!this.isAtEndRoute()) {
        this.routeFinished = false;
      }
    }

    toggleSpeed() {
      this.showSpeed = !this.showSpeed;
    }

    closeSpeed() {
      this.showSpeed = false;
    }
  }

  angular
    .module('mapControlModule')
    .component('reprodRotas', {
      controller: ReprodRotasCtrl,
      templateUrl: 'directives/reprod-rotas/reprod-rotas.tpl.html',
      bindings: {
        posicoes: '=',
        agrupamentos: '=',
        map: '='
      }
    });
}());
