(function () {
  'use strict';

  function semSinalOnePageCardDirective() {
    return {
      templateUrl: 'one-page/directives/sem-sinal/card/sem-sinal-one-page-card.tpl.html',
      controller: 'SemSinalOnePageCardCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class SemSinalOnePageCardCtrl {
    constructor($scope, $window, moment, $httpParamSerializer, OnePageService, $uibModal, Keys, UrlService, $translate) {
      this.scope = $scope;
      this.window = $window;
      this.moment = moment;
      this.httpParamSerializer = $httpParamSerializer;
      this.onePageService = OnePageService;
      this.modalService = $uibModal;
      this.keys = Keys;
      this.urlService = UrlService;
      this.translate = $translate;

      this.excedidoQuantidadeMaximaVeiculos = {comSinal: false, semSinal: false};

      this.semSinal = {data: [], erroSemData: false};
      this.onePageCtrl = this.scope.$parent.$parent.onePage;
      this.comSinalEnum = this.translate.instant('ce.enum.common.comSinal');
      this.semSinalEnum = this.translate.instant('ce.enum.common.semSinal');

      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateStatusVeiculoPassivelManutencao();
      });

      this.init();
    }

    init() {
      if (this.onePageService.listaCacheadaPassivelManutencao) {
        this.buildGraficoSemSinal(this.onePageService.listaCacheadaPassivelManutencao);
      }
      this.onePageService.onUpdateStatusVeiculoPassivelManutencao((data) => this.buildGraficoSemSinal(data), 'semSinal');
    }

    buildGraficoSemSinal(data) {
      this.semSinal.data = [];
      this.semSinal.erroSemData = false;

      if (data && data.length > 0) {
        this.semSinal.erroSemData = false;
        let comSinal = data.filter(x => x.diasSemSinal === 0),
            semSinal = data.filter(x => x.diasSemSinal > 0),
            qntComSinal = comSinal.length,
            qntSemSinal = data.length - qntComSinal;
        const idsComSinal = comSinal.map(x => x.veiculoId),
            idsSemSinal = semSinal.map(x => x.veiculoId),
            qntMaxVeiculosId = 300;
        this.excedidoQuantidadeMaximaVeiculos.comSinal = idsComSinal.length > qntMaxVeiculosId;
        this.excedidoQuantidadeMaximaVeiculos.semSinal = idsSemSinal.length > qntMaxVeiculosId;

        this.semSinal.data.push({
          name: this.comSinalEnum,
          total: qntComSinal,
          porcentagem: qntComSinal > 0 ? Math.round(qntComSinal / data.length * 100 * 1e2) / 1e2 : 0,
          ids: idsComSinal,
          url: idsComSinal.length > 0 && idsComSinal.length <= qntMaxVeiculosId ? this.getUrlLocalizacaoFrota(idsComSinal) : null
        });
        this.semSinal.data.push({
          name: this.semSinalEnum,
          total: qntSemSinal,
          porcentagem: qntSemSinal > 0 ? Math.round(qntSemSinal / data.length * 100 * 1e2) / 1e2 : 0,
          ids: idsSemSinal,
          url: idsSemSinal.length > 0 && idsSemSinal.length <= qntMaxVeiculosId ? this.getUrlHistoricoAlerta() : null
        });
      } else {
        this.semSinal.erroSemData = true;
      }
    }

    getUrlLocalizacaoFrota(ids) {
      return this.filtroLinkMapGrid(this.onePageCtrl.getParams(this.keys.uo.name), ids);
    }

    getUrlHistoricoAlerta() {
      return this.filtroLinkHistoricoAlerta(this.onePageCtrl.getParams(this.keys.uo.name), 'VEICULO_SEM_SINAL');
    }

    openModal(excedidoQuantidadeVeiculos) {
      if (excedidoQuantidadeVeiculos) {
        this.modalService.open({
          animation: true,
          templateUrl: 'one-page/directives/sem-sinal/modal/sem-sinal-one-page-modal.tpl.html',
          controller: 'SemSinalModalCtrl',
          controllerAs: '$ctrl'
        });
      }
    }

    filtroLinkMapGrid(uoId, veiculos) {
      return '/mapas-grid?forceRenderLayer=true&vfiltro=' + this.urlService.getObjectVfiltro([
        {key: this.keys.uo.name, value: {id: uoId.uoId}},
        {key: this.keys.veiculos.name, values: veiculos.map(x => {
          return {id: x};
        })}
      ]);
    }

    filtroLinkHistoricoAlerta(uoId, tipo) {
      let filtro = [
          {
            key: this.keys.dateTime.name,
            value: {
              startDate: this.moment().startOf('day'),
              endDate: this.moment().endOf('day'),
              tipo: 'personalizado'
            }
          },
          {key: this.keys.uo.name, value: {id: uoId.uoId}},
          {key: this.keys.tipos.name, value: {id: tipo}}
      ];
      return '/historico-alerta?vfiltro=' + this.urlService.getObjectVfiltro(filtro);
    }
  }

  angular
    .module('onePageModule')
    .controller('SemSinalOnePageCardCtrl', SemSinalOnePageCardCtrl)
    .directive('semSinalOnePageCardDirective', semSinalOnePageCardDirective);
}());
