(function () {
  'use strict';

  class PlanosSolucoesNovoUsuarioCtrl {
    constructor() {
      this.init();
    }

    init() {
      const contratacaoPlanos = angular.element('vfwc-contratacao-planos')[0];
      if (contratacaoPlanos) {
        contratacaoPlanos.solucaoIds = [];
        contratacaoPlanos.uoId = 1;
        contratacaoPlanos.linkContatoFixo = 'https://veltec.atlassian.net/servicedesk/customer/portal/6/group/20/create/362';
      }
    }
  }

  angular
    .module('planosSolucoesNovoUsuarioModule', ['ui.router'])
    .controller('PlanosSolucoesNovoUsuarioCtrl', PlanosSolucoesNovoUsuarioCtrl);
}());
