(function () {
  'use strict';

  angular
    .module('indicadores.DirecaoSegura')
    .constant('MockDirecaoSegura', {
      comparativoChart: {
        categorias: ['Período Anterior: 24/04/2016 a 31/04/2016', 'Período Atual: 01/05/2016 a 07/05/2016'],
        series: [{
          name: 'Acelerações Bruscas',
          data: [2, 3],
          type: 'EVENTOS'
        }, {
          name: 'Banguelas',
          data: [9, 11],
          type: 'EVENTOS'
        }, {
          name: 'Curvas Bruscas',
          data: [8, 8],
          type: 'EVENTOS'
        }, {
          name: 'Excessos Velocidade',
          data: [9, 11],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Até 20%',
          data: [8, 5],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Acima de 30%',
          data: [9, 11],
          type: 'EVENTOS'
        }, {
          name: 'Freadas Bruscas',
          data: [8, 5],
          type: 'EVENTOS'
        }, {
          name: 'Média Quantidade Veiculos',
          data: [5, 6],
          type: 'VEICULOS_QTD'
        }],
        opcoes: {
          periodos: [
            ['2016-04-24', '2016-04-31'],
            ['2016-05-01', '2016-05-07']
          ]
        }
      },
      periodoChart: {
        categorias: ['01/05/2016', '02/05/2016', '03/05/2016', '04/05/2016', '05/05/2016', '06/05/2016', '07/05/2016'],
        series: [{
          name: 'Acelerações Bruscas',
          data: [6, 0, 2, 1, 3, 3, 5],
          type: 'EVENTOS'
        }, {
          name: 'Banguelas',
          data: [1, 2, 1, 1, 3, 2, 4],
          type: 'EVENTOS'
        }, {
          name: 'Curvas Bruscas',
          data: [11, 8, 3, 5, 1, 4, 3],
          type: 'EVENTOS'
        }, {
          name: 'Excessos Velocidade',
          data: [2, 9, 3, 3, 2, 1, 4],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Até 20%',
          data: [5, 3, 4, 7, 2, 5, 4],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Acima de 30%',
          data: [2, 2, 3, 2, 1, 5, 4],
          type: 'EVENTOS'
        }, {
          name: 'Freadas Bruscas',
          data: [16, 8, 9, 9, 10, 15, 9],
          type: 'EVENTOS'
        }, {
          name: 'Média Quantidade Veiculos',
          data: [1, 2, 3, 10, 9, 6, 5],
          type: 'VEICULOS_QTD'
        }],
        opcoes: {
          periodos: [
            ['2016-05-01T00:00:00-03:00', '2016-05-01T23:59:59-03:00'],
            ['2016-05-02T00:00:00-03:00', '2016-05-02T23:59:59-03:00'],
            ['2016-05-03T00:00:00-03:00', '2016-05-03T23:59:59-03:00'],
            ['2016-05-04T00:00:00-03:00', '2016-05-04T23:59:59-03:00'],
            ['2016-05-05T00:00:00-03:00', '2016-05-05T23:59:59-03:00'],
            ['2016-05-06T00:00:00-03:00', '2016-05-06T23:59:59-03:00'],
            ['2016-05-07T00:00:00-03:00', '2016-05-07T23:59:59-03:00']
          ]
        }
      },
      footer: [
        {
          descricao: 'Acelerações Bruscas',
          percentualEntrePeriodos: -12,
          mediaVeiculoPeriodoAnterior: 3.9,
          mediaVeiculoPeriodoAtual: 8.2
        },
        {
          descricao: 'Freadas Bruscas',
          percentualEntrePeriodos: 19,
          mediaVeiculoPeriodoAnterior: 3,
          mediaVeiculoPeriodoAtual: 5
        },
        {
          descricao: 'Curvas Bruscas',
          percentualEntrePeriodos: 0,
          mediaVeiculoPeriodoAnterior: 1,
          mediaVeiculoPeriodoAtual: 4
        },
        {
          descricao: 'Excessos Velocidade',
          percentualEntrePeriodos: 15,
          mediaVeiculoPeriodoAnterior: 6,
          mediaVeiculoPeriodoAtual: 2
        },
        {
          descricao: 'Banguelas',
          percentualEntrePeriodos: -25,
          mediaVeiculoPeriodoAnterior: 2,
          mediaVeiculoPeriodoAtual: 3
        }
      ],
      uoChart: {
        categorias: ['PR', 'CO', 'MGES', 'NE', 'NO', 'PRSPI', 'RJ', 'RSSC', 'SPC'],
        series: [{
          name: 'Acelerações Bruscas',
          atual: [5, 3, 4, 7, 2, 5, 4, 8, 9],
          anterior: [6, 3, 4, 7, 2, 5, 4, 8, 3],
          type: 'EVENTOS'
        }, {
          name: 'Banguelas',
          atual: [2, 2, 3, 2, 1, 5, 4, 8, 2],
          anterior: [2, 2, 3, 2, 1, 5, 4, 8, 2],
          type: 'EVENTOS'
        }, {
          name: 'Curvas Bruscas',
          atual: [3, 4, 4, 2, 5, 5, 4, 8, 2],
          anterior: [5, 3, 4, 7, 2, 5, 4, 8, 9],
          type: 'EVENTOS'
        }, {
          name: 'Excessos Velocidade',
          atual: [2, 9, 3, 3, 2, 1, 4, 6, 10],
          anterior: [5, 3, 4, 7, 2, 5, 4, 8, 9],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Até 20%',
          atual: [1, 0, 2, 1, 3, 3, 5, 4, 3],
          anterior: [2, 2, 3, 2, 1, 5, 4, 8, 2],
          type: 'EVENTOS'
        }, {
          name: 'Excessos de Velocidade Acima de 30%',
          atual: [1, 2, 1, 1, 3, 2, 4, 2, 3],
          anterior: [5, 3, 4, 7, 2, 5, 4, 8, 9],
          type: 'EVENTOS'
        }, {
          name: 'Freadas Bruscas',
          atual: [11, 8, 3, 5, 1, 4, 3, 7, 9],
          anterior: [5, 3, 4, 7, 2, 5, 4, 8, 9],
          type: 'EVENTOS'
        }, {
          name: 'Média Quantidade Veiculos',
          atual: [18, 8, 9, 9, 12, 15, 9, 10, 8],
          anterior: [16, 8, 9, 9, 10, 15, 9, 9, 8],
          type: 'VEICULOS_QTD'
        }],
        opcoes: {
          periodos: [
            ['2016-05-01T00:00:00-03:00', '2016-05-01T23:59:59-03:00'],
            ['2016-05-02T00:00:00-03:00', '2016-05-02T23:59:59-03:00'],
            ['2016-05-03T00:00:00-03:00', '2016-05-03T23:59:59-03:00'],
            ['2016-05-04T00:00:00-03:00', '2016-05-04T23:59:59-03:00'],
            ['2016-05-05T00:00:00-03:00', '2016-05-05T23:59:59-03:00'],
            ['2016-05-06T00:00:00-03:00', '2016-05-06T23:59:59-03:00'],
            ['2016-05-06T00:00:00-03:00', '2016-05-06T23:59:59-03:00'],
            ['2016-05-06T00:00:00-03:00', '2016-05-06T23:59:59-03:00'],
            ['2016-05-07T00:00:00-03:00', '2016-05-07T23:59:59-03:00']
          ],
          uos: [
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1},
            {uoId: 1}
          ]
        }
      }
    });
}());
