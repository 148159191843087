(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.historicoTimeline', {
      url: '/historico-timeline?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'historico-timeline/historico-timeline.tpl.html',
          controller: 'HistoricoTimelineCtrl',
          controllerAs: 'historicoTimeline'
        }
      },
      data: {
        titulo: 'ce.mapa.historicoDetalhado.titulo',
        hideTitulo: true,
        role: 'HISTORICO_DETALHADO_VISUALIZAR'
      }
    });
  }

  angular
    .module('historicoTimeline')
    .config(pageConfig);
}());
