(function () {
  'use strict';

  /* global Intl */
  class ModalCadastrarSolicitacaoCtrl {
    constructor(Restangular, AlertMessage, ServiceVfiltro, RelatorioAgendadoService, $timeout, Authenticator,
       $translate, $httpParamSerializer, $scope, Keys) {
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.serviceVfiltro = ServiceVfiltro;
      this.relatorioAgendadoService = RelatorioAgendadoService;
      this.timeout = $timeout;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.httpParamSerializer = $httpParamSerializer;
      this.scope = $scope;
      this.keys = Keys;

      this.filtro = null;
      this.exibirForm = true;
      this.isUoSemVeiculos = false;
    }

    $onInit() {
      this.authenticator.getUser().then((user) => {
        this.opcoesRelatorios = Object.values(this.relatorioAgendadoService.getTiposRelatoriosAgendaveis())
          .filter(opcao => user.permissoesSolucao.includes(opcao.permissao));

        this.somenteVisualizacao = this.resolve.somenteVisualizacao;
        this.filtroValues = this.resolve.filtroValues;
        this.tipoRelatorio = this.opcoesRelatorios.length === 1 ? this.opcoesRelatorios[0].tipo : this.resolve.tipoRelatorio;

        if (this.tipoRelatorio) {
          this.relatorioSelecionado = this.opcoesRelatorios.find(opcao => opcao.tipo === this.tipoRelatorio);
          this.onRelatorioSelecionado();
        }
      });
    }

    onRelatorioSelecionado() {
      this.expandindo = true;
      this.relatorioSelecionado.getFiltros(this.somenteVisualizacao, this.filtroValues)
        .then((filtro) => {
          this.filtro = filtro;
          this.bindChangeVeiculos();
        })
        .catch(() => {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.naoFoiPossivelCarregarFiltro'),
            appendTo: '.alerta-aqui-cadastro-solicitacao'
          });
        })
        .finally(() => {
          this.timeout(() => this.expandindo = false, 1000);
        });
    }

    save() {
      if (this.exibirForm) {
        this.exibirForm = false;
      } else {
        const payload = Object.assign(
          {type: this.relatorioSelecionado.tipo},
          this.serviceVfiltro.getFiltroBackendNovo(this.filtro.getFiltroTopbar(), false)
        ),
            zoneId = this.httpParamSerializer({zoneId: new Intl.DateTimeFormat().resolvedOptions().timeZone});
        this.restangular.all(`/relatorios-agendado/historico-requisicoes?${zoneId}`)
          .post(payload)
          .then((response) => {
            this.close({$value: response.plain()});
          })
          .catch(error => {
            if (error && error.data && error.data.message === 'Nada para ser exportado') {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.naoExisteRegistroParaExportacao'),
                appendTo: '.alerta-aqui-cadastro-solicitacao'
              });
            } else {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.naoFoiPossivelConcluirSolicitacao'),
                appendTo: '.alerta-aqui-cadastro-solicitacao'
              });
            }
          });
      }
    }

    cancelar() {
      if (!this.exibirForm) {
        this.exibirForm = true;
      } else {
        this.dismiss();
      }
    }

    getTitle() {
      return this.somenteVisualizacao ?
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.titulo.dadosDaSolicitacao') :
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.titulo.novaSolicitacao');
    }

    getLabelBtnCancelar() {
      return this.exibirForm ?
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.botao.cancelar') :
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.botao.voltar');
    }

    getLabelBtnSalvar() {
      return this.exibirForm ?
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.botao.salvar') :
        this.translate.instant('ce.analise.solicitacoesAgendadas.modal.botao.simCriarSolicitacao');
    }

    bindChangeVeiculos() {
      this.scope.$watch(
        () => this.filtro.filtroCompleto.find(item => item.key === this.keys.veiculos.name).defaultValues.length,
        (newValue) => this.verifyHasVeiculos(newValue));
    }

    verifyHasVeiculos(veiculosSize) {
      this.isUoSemVeiculos = veiculosSize === 0;
    }

    isSaveInvalid() {
      return !this.filtro || this.loading || this.scope.formVfiltroModal.$invalid || this.isUoSemVeiculos;
    }
  }

  angular
    .module('relatorios.historicoSolicitacao')
    .component('modalCadastrarSolicitacao', {
      controller: ModalCadastrarSolicitacaoCtrl,
      templateUrl: 'relatorios/historico-solicitacao/cadastrar/cadastrar-solicitacao.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
