(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.informativo', {
      url: '/informativo',
      params: {
        informativoKey: null,
        uoId: null
      },
      views: {
        'content@': {
          templateUrl: 'informativo/informativo.tpl.html',
          controller: 'InformativoCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        hideTitulo: true
      }
    });
  }

  angular
    .module('informativoModule')
    .config(pageConfig);
}());
