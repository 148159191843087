(function () {
  'use strict';

  class VehicleEditCtrl {
    constructor($scope, $log, Authenticator, safetyService) {
      this.log = $log;
      this.scope = $scope;
      this.window = window;
      this.document = angular.element(document)[0];
      this.safetyService = safetyService;
      this.safetyApp = 'safetyApp';
      this.authenticator = Authenticator;
      this.roots = {};
      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
    }

    handleEditVehicleButtonClick(veiculo, idioma) {
      this.loadReactComponent(
        './VehicleEditModal',
        'editVehicleDiv',
        {
          onEditSuccess: () => {
            this.scope.$apply(() => this.scope.$emit('vehicleEdited'));
            this.unmountReactComponent('editVehicleDiv');
          },
          onClose: () => {
            this.unmountReactComponent('editVehicleDiv');
          },
          vehicle: veiculo,
          idioma
        }
      );
    }

    loadReactComponent(componentPath, componentId, props = {}) {
      this.unmountReactComponent(componentId);

      return this.safetyService.initialize()
        .then(() => this.safetyService.getComponent(this.safetyApp, componentPath))
        .then((component) => {
          const container = this.document.getElementById(componentId);
          if (container) {
            let root = this.roots[componentId];
            if (!root) {
              root = this.window.ReactDOM.createRoot(container);
              this.roots[componentId] = root;
            }
            root.render(
              this.window.React.createElement(component().default, props)
            );
          }
        })
        .catch((error) => {
          this.log.error(`Error loading component at ${componentPath}: `, error);
        });
    }

    unmountReactComponent(componentId = this.safetyElement) {
      const container = this.document.getElementById(componentId),
        root = this.roots[componentId];
      if (container && root) {
        root.unmount();
        delete this.roots[componentId];
      }
    }
  }

  angular
    .module('safetyModule')
    .controller('VehicleEditCtrl', VehicleEditCtrl);
}());
