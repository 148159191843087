(function () {
  'use strict';

  class UtilizacaoVeiculoCtrl {
    constructor(UtilizacaoVeiculo, moment, ColumnChart, $window, IndicadoresService,
               PieChart, ServiceVfiltro, FactoryVfiltro, Keys, URLConducaoEconomica, $translate) {
      this.service = UtilizacaoVeiculo;
      this.moment = moment;
      this.window = $window;
      this.indicadoresService = IndicadoresService;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.URLConducaoEconomica = URLConducaoEconomica;
      this.translate = $translate;

      this.desligadosCard = null;
      this.ate10KmCard = null;
      this.maisDe10KmCard = null;

      this.pieChart = PieChart.get();
      this.pieChart.setClickEvent(this.clickBinding().onClickPie);
      this.columnChart = ColumnChart.get();
      this.columnChart.setClickEvent(this.clickBinding().onClickColumn);
      this.columnChart.options.colors = ['rgb(204,38,54)', 'rgb(255,154,0)', 'rgb(139,188,33)'];

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'fat_conducao.dia',
          key: this.keys.dateTime.name,
          dateLimit: 180,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          key: this.keys.uo.name,
          ignoreQuery: true
        },
        {
          key: this.keys.uoParceira.name,
          name: 'fat_conducao.uo_veiculo_id'
        }
      ]))
      .then((filtro) => {
        this.loadData(ServiceVfiltro.getFiltroBackendAntigo(filtro));
      });
    }

    loadData(filtro) {
      this.service.one().get({filtros: filtro}).then(data => {
        this.columnChart.setData(data.columnChart);
        this.listaUos = data.columnChart.opcoes.uos;
        this.setInvisibleSerie(this.columnChart.series[2]);
        this.pieChart.setData(data.pieChart);
        this.desligadosCard = data.desligadosCard;
        this.ate10KmCard = data.ate10KmCard;
        this.maisDe10KmCard = data.maisDe10KmCard;
      });
    }

    setInvisibleSerie(serie) {
      serie.visible = false;
    }

    clickBinding() {
      let self = this,
          nameTranslated = this.translate.instant('ce.indicadores.ociosidadeFrota.charts.mais10kmPercorridos');

      return {
        onClickColumn: function () {
          let uoId = self.listaUos.find(c => c.nome === this.category).id,
              uos = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).defaultValues,
              uoParceira = null;

          if (!uos.find(f => f.id === uoId)) {
            uoParceira = uoId;
            uoId = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id;
          }

          self.goToRanking(this.series.name.includes(nameTranslated), uoId, uoParceira);
        },
        onClickPie: function () {
          let uoId = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id,
              uoParceira = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uoParceira.name);

          uoParceira = uoParceira.value ? uoParceira.value.id : null;

          self.goToRanking(this.name.includes(nameTranslated), uoId, uoParceira);
        }
      };
    }

    goToRanking(sort, uoId, uoParceira) {
      let periodo = this.serviceVfiltro.filtroCompleto.find(item => item.key === this.keys.dateTime.name).value,
          filtros = this.indicadoresService.getFiltrosParaRanking([periodo.startDate, periodo.endDate], uoId, uoParceira, 'distanciaPercorrida', sort);

      this.window.location.href = `${this.URLConducaoEconomica}?${filtros}`;
    }
  }

  angular
    .module('indicadores.UtilizacaoVeiculo')
    .controller('UtilizacaoVeiculoCtrl', UtilizacaoVeiculoCtrl);
}());
