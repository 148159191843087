
const AdminProfile = {
  TRIMBLE_ADMIN: 1,
  ADMIN: 2,
  BI_ADMIN: 1216
};

class DevicesCtrl {
  constructor($document, $safetyService, $window, $scope, $log, Authenticator) {
    this.log = $log;
    this.scope = $scope;
    this.window = $window;
    this.document = $document[0];
    this.safetyService = $safetyService;
    this.safetyApp = 'safetyApp';
    this.deviceRegistration = './DevicePageRoute';
    this.safetyElement = 'safety-app';
    this.container = this.document.getElementById(this.safetyElement);
    this.authenticator = Authenticator;
    this.initializePage();
    this.scope.$on('$destroy', () => {
      this.unmountReactComponent();
    });
  }

  initializePage() {
    this.safetyService.initialize()
      .then(() => this.loadPage())
      .catch(error => this.log.error('Initialization failed:', error));
  }

  loadPage() {
    let isAdmin = false,
        userProps = [];
    this.authenticator.getUser()
      .then(user => {
        if (user.perfil && user.perfil.id) {
          isAdmin = [AdminProfile.TRIMBLE_ADMIN, AdminProfile.ADMIN, AdminProfile.BI_ADMIN].includes(user.perfil.id);
        }
        userProps = user;
        return this.safetyService.getComponent(this.safetyApp, this.deviceRegistration);
      })
      .then(page => {
        this.window.ReactDOM.render(
          this.window.React.createElement(page().default, {isAdmin: isAdmin, user: userProps}),
          this.container
        );
      })
      .catch(error => {
        this.log.error('Error occurred:', error);
      });
  }

  unmountReactComponent() {
    if (this.window.ReactDOM && this.container) {
      if (this.window.ReactDOM.unmountComponentAtNode(this.container)) {
        this.log.log('React component unmounted');
      } else {
        this.log.log('React component was not mounted.');
      }
    } else {
      this.log.log('Container not found or React component was not mounted.');
    }
  }
}
DevicesCtrl.$inject = ['$document', 'safetyService', '$window', '$scope', '$log', 'Authenticator'];
angular.module('cadastros.devices').controller('DevicesCtrl', DevicesCtrl);
