(function () {
  'use strict';
  class HistItemCtrl {
    constructor(OcorrenciasEnum, $filter, $translate, HttpServiceV3g, AlertMessage) {
      this.ocorrenciasEnum = OcorrenciasEnum;
      this.filter = $filter;
      this.teste = null;
      this.translate = $translate;
      this.statusI18N = '<strong> ' + this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.status') + ' </strong>';
      this.responsavelI18N = '<strong> ' + this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.responsavel') + ' </strong>';
      this.observacaoI18N = '<strong> ' + this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.observacao') + ' </strong>';
      this.anexoI18N = '<strong> ' + this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.anexo') + ' </strong>';
      this.asI18N = this.translate.instant('ce.tempoReal.quadroDeOcorrencias.common.as');
      this.usuarioAtribuidoNenhumI18N = this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.usuarioAtribuidoNenhum');
      this.httpService = HttpServiceV3g;
      this.alertMessage = AlertMessage;
      this.mapUuidUrl = new Map();
    }

    $onInit() {
      if (this.item.statusAnterior) {
        this.statusAnterior = this.ocorrenciasEnum.getStatusById(this.item.statusAnterior).descricao;
        this.statusNovo = this.ocorrenciasEnum.getStatusById(this.item.statusNovo).descricao;
      }
      this.comentario = this.item.comentario;
      this.usuario = this.item.usuario.login;
      this.dataHora = this.filter('date')(this.item.dataHora, 'dd/MM/yyyy') + ' ' + this.asI18N + ' ' +
            this.filter('date')(this.item.dataHora, 'HH:mm');

      if (this.item.usuarioAtribuidoAnterior || this.item.usuarioAtribuidoNovo) {
        this.resNovo = this.item.usuarioAtribuidoNovo ? this.item.usuarioAtribuidoNovo.login : this.usuarioAtribuidoNenhumI18N;
        this.resAnterior = this.item.usuarioAtribuidoAnterior ? this.item.usuarioAtribuidoAnterior.login : this.usuarioAtribuidoNenhumI18N;
      }
    }

    downloadAnexo(uuid, nome) {
      const url = this.mapUuidUrl.get(uuid);
      if (angular.isDefined(url)) {
        this.createAnchorElementAndClick(url, nome);
      } else {
        this.httpService.get(`/anexo/anexos/${uuid}/download?entidadeId=${this.veiculoId}`)
          .then((fileDownloadUrl) => {
            if (fileDownloadUrl && fileDownloadUrl.data.url) {
              const urlDownload = fileDownloadUrl.data.url;
              this.mapUuidUrl.set(uuid, urlDownload);
              this.createAnchorElementAndClick(urlDownload, nome);
            }
          })
          .catch(() => {
            this.alertMessage.create({
              type: 'error',
              message: this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.erroUrlAnexo'),
              appendTo: '.alerta-aqui-anexo'
            });
          });
      }
    }

    createAnchorElementAndClick(url, nome) {
      const anchor = angular.element('<a/>');
      anchor.attr({
        href: url,
        download: nome
      })[0].click();
    }
  }

  angular
    .module('quadroOcorrencias')
    .component('histItem', {
      controller: HistItemCtrl,
      templateUrl: 'quadro-ocorrencias/ocorrencia-modal/hist-item/hist-item.tpl.html',
      bindings: {
        item: '=',
        veiculoId: '='
      }
    });
}());
