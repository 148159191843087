(function () {
  'use strict';

  angular
    .module('indicadores.UtilizacaoVeiculo', [
      'ui.router',
      'chartModule',
      'angularMoment',
      'chartModule',
      'indicadores',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'pascalprecht.translate'
    ])
    .factory('UtilizacaoVeiculo', Restangular => Restangular.service('boletim/utilizacao-veiculo'));
}());
