(function () {
  'use strict';

  class PlanosSolucoesCtrl {
    constructor(Authenticator) {
      this.auth = Authenticator;
      this.init();
    }

    init() {
      this.auth.getUser().then((user) => {
        const contratacaoPlanos = angular.element('vfwc-contratacao-planos')[0];
        if (contratacaoPlanos) {
          contratacaoPlanos.solucaoIds = user.plain().solucoes.map(solucao => solucao.solucaoId);
          contratacaoPlanos.uoId = user.plain().uo.id;
        }
      });
    }
  }

  angular
    .module('planosSolucoesModule', [
      'ui.router',
      'v3gAuthenticatorModule'
    ])
    .controller('PlanosSolucoesCtrl', PlanosSolucoesCtrl);
}());
