(function () {
  'use strict';

  class ConfigVdriversService {
    constructor($q, $uibModal, Restangular, AlertMessage) {
      this.q = $q;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;

      this.umMinutoEmSegundos = 60;
      this.uoId = 0;
      this.tipo = '';
      this.roleCadastrar = true;
      this.tipoSubpagina = {
        VIGENCIA: 'vigencia',
        SAFEDRIVE: 'safedrive',
        APLICATIVO: 'aplicativo',
        CHECKLIST: 'checklist'
      };
      this.model = {
        configuracoes: [],
        configuracoesBackup: [],
        checklist: [],
        checklistBackup: [],
        checklistAtivos: [],
        checklistInativos: [],
        textChecklist: ''
      };
      this.mapLabel = {
        aplicativo: {
          manterTelaAtiva: {
            titulo: 'Manter tela ativa',
            valueDefault: true
          },
          evitarFechamentoApp: {
            titulo: 'Evitar fechamento do aplicativo',
            valueDefault: true
          }
        },
        vigencia: {
          habilitarVigencia: {
            titulo: 'Habilitar <strong>uso</strong> de Vigência',
            valueDefault: true
          },
          fecharVigenciaPorIgnicaoOff: {
            titulo: 'Habilitar <strong>fechamento</strong> Automático de Vigência por ignição <i>off</i>',
            valueDefault: true
          },
          habilitarVigenciaAutomatica: {
            titulo: 'Habilitar <strong>identificação</strong> Automática da Vigência',
            valueDefault: true
          },
          tempoVerificacao: {
            titulo: 'Tempo de verificação para identificação de veículos',
            mask: 'min',
            valueDefault: 120
          },
          velocidadeMinima: {
            titulo: 'Velocidade mínima',
            subtitulo: 'Para iniciar monitoramento do motorista',
            mask: 'km/h',
            valueDefault: 15
          },
          distanciaMinima: {
            titulo: 'Distância máxima',
            subtitulo: 'Medida usada para iniciar uma vigência',
            mask: 'm',
            valueDefault: 150
          },
          tempoMaximoIgnicao: {
            titulo: 'Limite de tempo para considerar Início de Vigência via ignição',
            mask: 'min',
            valueDefault: 7200
          },
          tempoVeiculoParado: {
            titulo: 'Limite de tempo parado para cancelar monitoramento',
            mask: 'min',
            valueDefault: 900
          },
          tempoMaximoEntreVigencias: {
            titulo: 'Tempo para unificar vigência',
            mask: 'min',
            valueDefault: 900
          }
        },
        safedrive: {
          habilitarResumo: {
            titulo: 'Habilitar <i>Dashboard SafeDrive</i>',
            valueDefault: true
          },
          atualizarEventosVigencia: {
            titulo: 'Tempo de atualização de Eventos para <i>Dashboard SafeDrive</i>',
            mask: 'min',
            valueDefault: 120
          }
        },
        checklist: {
          habilitarChecklist: {
            titulo: 'Habilitar checklist do veículo',
            valueDefault: true
          }
        }
      };
    }

    getTipo(tipo) {
      switch (tipo) {
        case this.tipoSubpagina.VIGENCIA:
          return this.tipoSubpagina.VIGENCIA;
        case this.tipoSubpagina.SAFEDRIVE:
          return this.tipoSubpagina.SAFEDRIVE;
        case this.tipoSubpagina.CHECKLIST:
          return this.tipoSubpagina.CHECKLIST;
        default:
          return this.tipoSubpagina.APLICATIVO;
      }
    }

    carregarConfigUo(uoId, tipo) {
      this.uoId = uoId;
      this.tipo = tipo;
      this.model = {
        configuracoes: [],
        configuracoesBackup: [],
        checklist: [],
        checklistBackup: [],
        checklistAtivos: [],
        checklistInativos: [],
        textChecklist: ''
      };

      let getters = [this.restangular.one(`/mobile/config/uo/${uoId}/vdrivers`).get()];

      if (this.tipo === this.tipoSubpagina.CHECKLIST) {
        const fields = [
          'item.id',
          'item.nome',
          'item.uoId',
          'item.status'
        ];
        getters.push(this.restangular.one(`/veiculo/checklist/ativo/${uoId}?fields=${fields.toString()}`).get());
      }

      this.q.all(getters)
      .then(data => this.preecheVariaveis(data))
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: 'Ocorreu um erro ao carregar as configurações',
          appendTo: '.alerta-aqui'
        });
      });
    }

    preecheVariaveis(data) {
      const configVdrivers = data[0],
          configChecklist = data[1];

      this.model.id = configVdrivers.id;
      this.model.uoId = configVdrivers.uoId;
      this.vdriverConfig = angular.copy(configVdrivers.vdriverConfig);
      this.model.configuracoes = this.buildConfig(configVdrivers.vdriverConfig, this.mapLabel[this.tipo]);
      this.model.configuracoesBackup = angular.copy(this.model.configuracoes);

      if (configChecklist) {
        this.model.checklist = configChecklist.itens;
        this.model.checklistBackup = angular.copy(configChecklist.itens);
        this.model.checklist.forEach(item => item.status ? this.model.checklistAtivos.push(item) : this.model.checklistInativos.push(item));
      }
    }

    buildConfig(config, configDefault) {
      Object.keys(config)
      .forEach(tipo => {
        if (configDefault[tipo]) {
          let value = config[tipo];
          if (configDefault[tipo].mask === 'min') {
            value /= this.umMinutoEmSegundos;
          }
          configDefault[tipo].value = value;
        }
      });
      return configDefault;
    }

    salvar(form) {
      Object.keys(this.vdriverConfig)
      .forEach(item => {
        if (this.model.configuracoes[item]) {
          let value = this.model.configuracoes[item].value;
          if (this.model.configuracoes[item].mask === 'min') {
            value *= this.umMinutoEmSegundos;
          }
          this.vdriverConfig[item] = value;
        }
      });

      const config = {
            id: this.model.id,
            uoId: this.uoId,
            vdriverConfig: this.vdriverConfig
          },
          checklist = this.model.checklistAtivos.map(item => ({
            id: item.id || null,
            uoId: this.uoId,
            nome: item.nome,
            status: item.status
          }));

      let setters = [this.restangular.all('/mobile/config/vdrivers')[this.model.uoId === 1 ? 'post' : 'customPUT'](config)];

      if (this.model.configuracoes.habilitarChecklist &&
        this.model.configuracoes.habilitarChecklist.value &&
        this.tipo === this.tipoSubpagina.CHECKLIST) {
        setters.push(this.restangular.all(`/veiculo/checklist`).post(checklist));
      }

      this.q.all(setters)
      .then((data) => {
        this.model.configuracoesBackup = angular.copy(this.model.configuracoes);

        if (data[1]) {
          this.model.checklistAtivos = data[1].map(item => angular.extend(item, {status: true}));
          this.model.checklistBackup = angular.copy(this.model.checklistAtivos.concat(this.model.checklistInativos));
        }

        if (form) {
          form.$setPristine();
        }
        this.alertMessage.create({
          type: 'success',
          message: 'Salvo com sucesso',
          appendTo: '.alerta-aqui'
        });
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: 'Ocorreu um erro ao salvar as configurações',
          appendTo: '.alerta-aqui'
        });
      });

      this.model.uoId = this.uoId;
    }

    resetarValores() {
      Object.keys(this.model.configuracoes)
      .forEach(item => {
        let value = this.model.configuracoes[item].valueDefault;
        if (this.model.configuracoes[item].mask === 'min') {
          value /= this.umMinutoEmSegundos;
        }
        this.model.configuracoes[item].value = value;
      });
      this.salvar();
    }

    descartarAlteracoes() {
      this.model.configuracoes = angular.copy(this.model.configuracoesBackup);
      this.model.checklist = angular.copy(this.model.checklistBackup);
      this.model.checklistAtivos = [];
      this.model.checklistInativos = [];
      this.model.checklist.forEach(item => item.status ? this.model.checklistAtivos.push(item) : this.model.checklistInativos.push(item));
    }

    abrirModal() {
      this.modalService.open({
        animation: true,
        templateUrl: 'configuracoes/vdrivers/modal/reset/reset-vdrivers.tpl.html',
        controller: 'ModalResetVdriversCtrl',
        controllerAs: '$ctrl'
      })
      .result
      .then(() => {
        this.resetarValores();
      });
    }

    changeStatus(i, tipo) {
      if (i.status !== tipo) {
        if (tipo) {
          this.model.checklistInativos.push(i);
          this.model.checklistAtivos = this.model.checklistAtivos.filter(x => x !== i);
        } else {
          this.model.checklistAtivos.push(i);
          this.model.checklistInativos = this.model.checklistInativos.filter(x => x !== i);
        }
      }
    }

    inserirChecklist() {
      if (this.model.textChecklist && !this.model.checklist.find(item => item.nome && item.nome.toLowerCase() === this.model.textChecklist.toLowerCase())) {
        const item = {
          id: null,
          nome: this.model.textChecklist,
          uoId: this.uoId,
          status: true
        };
        this.model.checklist.push(item);
        this.model.checklistAtivos.push(item);
        this.model.textChecklist = '';
      } else {
        this.erroForm = true;
      }
    }

    removeItem(item) {
      this.model.checklist = this.model.checklist.filter(x => x !== item);
      this.model.checklistAtivos = this.model.checklistAtivos.filter(x => x !== item);
    }

    invalidChecklist() {
      return this.model.configuracoes.habilitarChecklist &&
        this.model.configuracoes.habilitarChecklist.value &&
        (this.model.checklistAtivos.length === 0 ||
        !!this.model.checklistAtivos.find(item => !item.nome));
    }

    validateChecklistInput(item, nome) {
      if (!item) {
        return 'Esse campo não pode ser vazio.';
      } else if (item !== nome && this.model.checklistAtivos.find(i => i.nome.toLowerCase() === item.toLowerCase())) {
        return 'Esse item já está cadastrado.';
      } else if (item.length > 80) {
        return 'São permitidos até 80 caracteres.';
      }
      return true;
    }
  }

  angular.module('configVdrivers')
  .service('ConfigVdriversService', ConfigVdriversService);
}());
