(function () {
  'use strict';

  class MenuEditarCercaEletronicaCtrl {
    constructor(CercaEletronicaMapService, PontosReferenciaMenu, CercaEletronicaService) {
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.cercaEletronicaService = CercaEletronicaService;
      this.init();
    }

    init() {
      let buttonsPrincipal = {
            active: 'trimble-control-area',
            resting: 'trimble-control-area'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtEditarCadastro',
                icon: 'fa fa-pencil',
                color: 'default',
                tipo: 'editar-cerca-eletronica',
                funcao: this.editarCercaEletronica.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtExcluirCerca',
                icon: 'fa fa-trash-o',
                color: 'default',
                tipo: 'excluir-cerca-eletronica'
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.cancelarAreaControle.bind(this)
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
    }

    editarCercaEletronica() {
      this.pontosReferenciaMenuService.drawerClose();
      this.cercaEletronicaMapService.editarCadastroCercaEletronica();
    }

    cancelarAreaControle() {
      this.cercaEletronicaMapService.setCercaEletronicaSelecionada(null);
      this.pontosReferenciaMenuService.setFABDefault();
    }
  }

  angular
    .module('mapControlModule')
    .component('menuEditarCercaEletronica', {
      controller: MenuEditarCercaEletronicaCtrl,
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controllerAs: 'ctrl'
    });
}());
