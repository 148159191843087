/* global L*/
(function () {
  'use strict';

  function menuAdicionarPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuAdicionarPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuAdicionarPontoReferenciaCtrl {
    constructor(PontosReferenciaMap, PontosReferenciaMenu, PontosReferencia) {
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.adicionarNovoPonto();
    }

    adicionarNovoPonto() {
      this.pontosReferenciaMenuService.drawerClose();
      this.pontosReferenciaMapService.retangulo = new L.Draw.Rectangle(this.pontosReferenciaMapService.map, {shapeOptions: {color: '#000000', weight: 4, fillOpacity: 0.75}});
      let buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.pontosReferenciaMapService.cancelarPonto.bind(this.pontosReferenciaMapService)
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMenuService.menuOpen();
      this.pontosReferenciaMapService.setVerificaZoom();
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuAdicionarPontoReferenciaCtrl', MenuAdicionarPontoReferenciaCtrl)
    .directive('menuAdicionarPontoReferenciaDirective', menuAdicionarPontoReferenciaDirective);
}());
