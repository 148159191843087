/* global document */
(function () {
  'use strict';

  class PontosReferenciaMenu {
    constructor(ReprodRotasService, $rootScope, Authenticator, $window, MapasUtil) {
      this.menustate = 'closed';
      this.menu = 'closed';
      this.drawerBusca = false;
      this.drawerAdvisor = false;
      this.tabelaPontosProximos = false;
      this.buttons = [];
      this.buttonsPrincipal = null;
      this.idMapa = '';
      this.reprodRotasService = ReprodRotasService;
      this.rootScope = $rootScope;
      this.authenticator = Authenticator;
      this.window = $window;
      this.mapasUtilService = MapasUtil;

      this.rootScope.$on('toggle-drawer', () => this.toggleDrawer());
    }

    menuOpen() {
      this.menustate = 'open';
      this.menu = 'open';
    }

    menuClose() {
      this.menustate = 'closed';
      this.menu = 'closed';
    }

    isDrawerOpen() {
      return this.drawerAdvisor || this.drawerBusca;
    }

    drawerOpen() {
      this.drawerBusca = true;
    }

    drawerAdvisorOpen() {
      this.drawerAdvisor = true;
      this.rootScope.$broadcast('openAdvisorList');
    }

    drawerClose() {
      this.drawerBusca = false;
    }

    drawerAdvisorClose() {
      this.drawerAdvisor = false;
    }

    toggleSnackbarSugestion() {
      /* eslint-disable */
      const snackbarElement = document.querySelector('vfwc-snackbar-sugestao-rotograma'); /* eslint-enable */
      if (snackbarElement) {
        if (this.drawerAdvisor) {
          snackbarElement.style.display = 'inherit';
        } else {
          snackbarElement.style.display = 'none';
        }
      }
    }

    tabelaPontosProximosOpen() {
      this.tabelaPontosProximos = true;
    }

    tabelaPontosProximosClose() {
      this.tabelaPontosProximos = false;
    }

    toggleDrawer() {
      if (this.tipoFAB !== 'adicionar-pontos-proximos') {
        if (this.drawerBusca) {
          this.setFABActive('default');
        } else {
          this.activateMapLayers();
          this.setFABActive('pesquisar');
        }
      } else {
        this.togglePesquisa(!this.drawerBusca);
      }
    }

    setFABDefault() {
      this.limpaClassePesquisa();
      this.menuClose();
      this.drawerClose();
      this.setFABActive('default');
    }

    setFABActive(type) {
      if (type === 'reproducao-rotas') {
        this.reprodRotasService.createReprodRotas();
      }
      this.togglePesquisa(type === 'pesquisar');
      this.tipoFAB = type;
    }

    togglePesquisa(fun) {
      const mapa = angular.element(`#${this.idMapa}`);
      mapa.removeClass('drawer-open');
      this.drawerAdvisorClose();

      if (fun) {
        mapa.addClass('drawer-open');
        this.drawerOpen();
        this.menuClose();
      } else {
        mapa.removeClass('drawer-open');
        this.drawerClose();
      }
    }

    clickAdvisorButton() {
      this.authenticator.getUser().then(user => {
        this.mapasUtilService.activateAllMapLayers(user.id);
        const jaVisualizouBoasVindas = this.window.localStorage.getItem(`boas-vindas-advisor-${user.id}`);
        if (jaVisualizouBoasVindas) {
          this.toggleAdvisor();
        } else {
          this.createBoasVindasAdvisor();
        }
      });
    }

    createBoasVindasAdvisor() {
      /* eslint-disable */
      const body = document.querySelector('body'),
      boasVindasElement = document.createElement('vfwc-open-dialog-boas-vindas-sugestao');
      /* eslint-enable */
      body.append(boasVindasElement);
      this.setOnCloseBoasVindas(boasVindasElement);
    }

    setOnCloseBoasVindas(element) {
      element.addEventListener('onClose', (event) => {
        if (event.detail && event.detail.onCloseBoasVindasAdvisor) {
          this.toggleAdvisor();
          this.setModalBoasVindasInLocalStorage();
        }
      });
    }

    setModalBoasVindasInLocalStorage() {
      this.authenticator.getUser().then(user => {
        this.window.localStorage.setItem(`boas-vindas-advisor-${user.id}`, true);
      });
    }

    toggleAdvisor() {
      const mapa = angular.element(`#${this.idMapa}`);
      this.drawerClose();

      if (!this.drawerAdvisor) {
        // map width control
        mapa.addClass('drawer-open');
        this.drawerAdvisorOpen();
        this.menuClose();
      } else {
        mapa.removeClass('drawer-open');
        this.drawerAdvisorClose();
      }
      this.toggleSnackbarSugestion();
    }

    setMenu(buttons, buttonsPrincipal = {active: 'fa fa-plus', resting: 'fa fa-plus'}) {
      this.buttonsPrincipal = buttonsPrincipal;
      this.buttons = buttons;
    }

    limpaClassePesquisa() {
      angular.element('.lista-resultado-pesquisa').removeClass('ativo');
    }

    fecharDrawer() {
      this.drawerClose();
      this.setFABDefault();
    }

    verificaMenuDefaultOuPesquisa() {
      return this.tipoFAB === 'default' ||
        this.tipoFAB === 'pesquisar' ||
        this.tipoFAB === 'reproducao-rotas';
    }

    verificaMenuDefaultOuPesquisaOuAdicionarPontoProximo() {
      return this.verificaMenuDefaultOuPesquisa() || this.tipoFAB === 'adicionar-pontos-proximos';
    }

    activateMapLayers() {
      this.authenticator.getUser().then(user => {
        this.mapasUtilService.activateAllMapLayers(user.id);
      });
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosReferenciaMenu', PontosReferenciaMenu);
}());
