(function () {
  'use strict';

  angular
    .module('vflashModule', ['flash']).factory('VFlash', Flash => {
      let vflash = Flash;

      vflash.filterError = name => Flash.create('error', `O filtro ${name} é obrigatório.`);

      vflash.filterWarn = () => {
        Flash.create('warning', 'Nenhum resultado encontrado para os filtros informados.');
      };

      return vflash;
    });
}());
