(function () {
  'use strict';

  angular
    .module('configVdrivers', [
      'SubpaginasVdrivers',
      'ModalVdrivers',
      'ui.router'
    ]);
}());
