(function () {
  'use strict';

  function v3gTableState(v3gTableStateLinkFunction) {
    return {
      require: 'stTable',
      scope: {
        v3gTableState: '=',
        onSort: '='
      },
      link: v3gTableStateLinkFunction
    };
  }

  angular
    .module('smart-table')
    .directive('v3gTableState', v3gTableState)
    .factory('v3gTableStateLinkFunction', function () {
      return {
        pre: function (scope, element, attrs, ctrl) {
          const sortBy = ctrl.sortBy;
          if (scope.onSort) {
            ctrl.sortBy = function (predicate, reverse) {
              sortBy.apply(ctrl, [predicate, reverse]);
              scope.onSort.apply(scope, [ctrl.tableState(), ctrl]);
            };
          }
          scope.v3gTableState(ctrl.tableState(), ctrl);
        }
      };
    });
}());

