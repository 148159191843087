(function () {
  'use strict';

  function semSinalOnePageGraficoDirective() {
    return {
      templateUrl: 'one-page/directives/sem-sinal/grafico/sem-sinal-one-page-grafico.tpl.html',
      controller: 'SemSinalOnePageGraficoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class SemSinalOnePageGraficoCtrl {
    constructor($scope, $window, $httpParamSerializer, HalfPieChart, moment, OnePageService, $uibModal, Keys,
      UrlService, $translate, GoogleTagManagerUtils, ServiceVfiltro) {
      this.scope = $scope;
      this.window = $window;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.onePageService = OnePageService;
      this.modalService = $uibModal;
      this.keys = Keys;
      this.urlService = UrlService;
      this.translate = $translate;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.serviceVfiltro = ServiceVfiltro;

      this.semSinal = HalfPieChart.get();
      this.semSinal.removeTitleCategories();
      this.semSinal.setClickEvent(this.clickBinding().onClickColumn);
      this.comSinalEnum = this.translate.instant('ce.enum.common.comSinal');
      this.semSinalEnum = this.translate.instant('ce.enum.common.semSinal');

      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateStatusVeiculoPassivelManutencao();
      });

      this.init();
    }

    init() {
      this.onePageService.checkStatusVeiculoPassivelManutencao({uoId: this.scope.onePage.getParamValue(this.keys.uo.name)})
      .then(() => {
        if (this.onePageService.listaCacheadaPassivelManutencao) {
          this.semSinal.setData(this.buildGraficoSemSinal(this.onePageService.listaCacheadaPassivelManutencao));
        }
      });
      this.onePageService.onUpdateStatusVeiculoPassivelManutencao((data) => this.semSinal.setData(this.buildGraficoSemSinal(data)), this.semSinalEnum);
    }

    buildGraficoSemSinal(data) {
      let retorno = {categorias: [this.comSinalEnum, this.semSinalEnum], series: []};
      if (data && data.length > 0) {
        this.semSinal.erroSemData = false;
        let comSinal = data.filter(x => x.diasSemSinal === 0),
            semSinal = data.filter(x => x.diasSemSinal > 0),
            qntComSinal = comSinal.length,
            qntSemSinal = data.length - qntComSinal;
        retorno.series.push({name: this.comSinalEnum, y: qntComSinal > 0 ? qntComSinal : null, ids: comSinal.map(x => x.veiculoId)});
        retorno.series.push({name: this.semSinalEnum, y: qntSemSinal > 0 ? qntSemSinal : null, ids: semSinal.map(x => x.veiculoId)});
      } else {
        this.semSinal.erroSemData = true;
      }
      return retorno;
    }

    clickBinding() {
      let self = this;

      return {
        onClickColumn: function (e) {
          const qntMaxVeiculosId = 300;
          self.googleTagManagerUtils.sendEventClickGTM('status_frota_drill_down', self.serviceVfiltro.factoryVfiltro.user);
          let info = self.semSinal.series[0].data.filter(i => i[0] === e.point.name)[0][2];
          if (info.length <= qntMaxVeiculosId) {
            let urlRedirect = self.filtroLinkMapGrid(self.scope.onePage.getParamValue(self.keys.uo.name), info);
            if (e.point.name === self.semSinalEnum) {
              urlRedirect = self.filtroLinkHistoricoAlerta(self.scope.onePage.getParamValue(self.keys.uo.name), 'VEICULO_SEM_SINAL');
            }
            self.redirectTo(urlRedirect);
          } else {
            self.modalService.open({
              animation: true,
              templateUrl: 'one-page/directives/sem-sinal/modal/sem-sinal-one-page-modal.tpl.html',
              controller: 'SemSinalModalCtrl',
              controllerAs: '$ctrl'
            });
          }
        }
      };
    }

    redirectTo(url) {
      this.window.location.href = url;
    }

    filtroLinkMapGrid(uoId, veiculos) {
      return '/mapas-grid?forceRenderLayer=true&vfiltro=' + this.urlService.getObjectVfiltro([
        {key: this.keys.uo.name, value: {id: uoId}},
        {key: this.keys.veiculos.name, values: veiculos.map(x => {
          return {id: x};
        })}
      ]);
    }

    filtroLinkHistoricoAlerta(uoId, tipo) {
      let filtro = [
          {
            key: this.keys.dateTime.name,
            value: {
              startDate: this.moment().startOf('day'),
              endDate: this.moment().endOf('day'),
              tipo: 'personalizado'
            }
          },
          {key: this.keys.uo.name, value: {id: uoId}},
          {key: this.keys.tipos.name, value: {id: tipo}}
      ];
      return '/historico-alerta?vfiltro=' + this.urlService.getObjectVfiltro(filtro);
    }
  }

  angular
    .module('onePageModule')
    .controller('SemSinalOnePageGraficoCtrl', SemSinalOnePageGraficoCtrl)
    .directive('semSinalOnePageGraficoDirective', semSinalOnePageGraficoDirective);
}());
