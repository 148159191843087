(function () {
  'use strict';

  class UoSelectInputCtrl {
    constructor(Uos, UoArvoreNivel, Authenticator, $scope) {
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.authenticator = Authenticator;
      this.uos = [];
      this.uoUser = null;
      this.scope = $scope;
    }

    $onInit() {
      this.authenticator.getUser().then(user => {
        this.carregarUos(user.uo.id);
        this.uoUser = user.uo.id;
      });
    }

    bindModelSelect() {
      if (this.model && this.uos) {
        this.model = this.findUoById(this.model.id);
      }
    }

    carregarUos(uoId) {
      this.uoService.getFilhas(uoId).then(data => {
        this.uos = this.uoArvoreNivel.converterEmLista(data.plain());
        if (this.model) {
          this.model = this.findUoById(this.model.id);
        } else if (this.defaultUserUo) {
          this.model = this.findUoById(this.uoUser);
        }
      });
    }

    findUoById(uoId) {
      return this.uos.find(uo => uo.id === uoId);
    }

    onSelect(uo) {
      this.onSelectUo({uo});
    }
  }

  angular
    .module('uoSelectInputModule', ['uoModule', 'uoArvoreNivelModule', 'v3gAuthenticatorModule'])
    .component('uoSelectInput', {
      controller: UoSelectInputCtrl,
      templateUrl: 'directives/uo-select-input/uo-select-input-component.tpl.html',
      bindings: {
        model: '=',
        required: '=',
        name: '@',
        disabled: '<',
        defaultUserUo: '<',
        onSelectUo: '&'
      }
    });
}());
