(function () {
  'use strict';

  class PowerBiCtrl {

    constructor(FactoryVfiltro, ServiceVfiltro, Restangular) {
      this.restangular = Restangular;
      this.response = {};

      ServiceVfiltro.init(FactoryVfiltro.get([]));
      this.getReport();
    }

    getReport() {
      this.restangular.one('powerbi/reports/VFLEETS_ALERTAS').get()
        .then(config => {
          this.response.accessToken = config.embedToken;
          this.response.embedUrl = config.embedUrl;
          this.response.id = config.id;
          this.response.name = '';
          this.response.type = 'report';
          this.response.webUrl = `https://app.powerbi.com/reports/${config.id}`;
          this.response.permissions = 0;
          this.response.pageView = 'fitToWidth';
          this.response.tokenType = 1;
          this.response.settings = {
            localeSettings: {
              language: 'pt-br'
            },
            filterPaneEnabled: true,
            navContentPaneEnabled: true
          };
        });
    }
  }

  angular
    .module('indicadores.powerBi')
    .controller('PowerBiCtrl', PowerBiCtrl);
}());
