(function () {
  'use strict';

  class BdvChecklistCtrl {
    constructor($stateParams, Bdv, AlertMessage, ChecklistEnum, moment,
      ServiceVfiltro, FactoryVfiltro, $translate) {
      this.stateParams = $stateParams;
      this.bdvService = Bdv;
      this.alertMessage = AlertMessage;
      this.checklistEnum = ChecklistEnum;
      this.moment = moment;
      this.translate = $translate;

      this.data = [];
      this.veiculo = {};
      this.itemSelected = {};
      this.itemSelectedCopy = {};

      ServiceVfiltro.init(FactoryVfiltro.get([]))
      .then(() => {
        this.init();
      });
    }

    init() {
      if (!this.stateParams.veiculoId || !this.stateParams.dia) {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.boletim.diarioVeiculoChecklist.errorMessage'),
          appendTo: '.alerta-aqui'
        });
      } else {
        const dia = this.moment(this.stateParams.dia, 'YYYY-MM-DD');
        this.bdvService.getChecklist(this.stateParams.veiculoId, dia.startOf('day').format(), dia.endOf('day').format())
        .then(data => this.setInfo(data));
      }
    }

    setInfo(data) {
      this.data = data.content;
      const item = this.data[0];
      this.veiculo = item && item.veiculo || {placa: '', prefixo: ''};
      this.changeTab(item || {itens: []});
    }

    changeTab(item) {
      this.itemSelected = item;
      this.itemSelectedCopy = this.itemSelected.itens;
    }

    isSelected(item) {
      return this.itemSelected.id === item.id;
    }

    getCount(tipo) {
      return this.itemSelected.itens &&
      this.itemSelected.itens
      .reduce((acc, atual) => atual.opcao === tipo ? acc + 1 : acc, 0);
    }
  }

  angular
    .module('relatorios.bdv')
    .controller('BdvChecklistCtrl', BdvChecklistCtrl);
}());
