(function () {
  'use strict';

  class BtnAdvisorRotogramasCtrl {

    qtdSugestoesExibicao() {
      return this.qtdSugestoes > 9 ? '9+' : this.qtdSugestoes;
    }

    getIcon() {
      return this.active ? 'fa-caret-right' : 'fa-map-marker';
    }

  }

  angular
    .module('mapControlModule')
    .component('btnAdvisorRotogramas', {
      controller: BtnAdvisorRotogramasCtrl,
      templateUrl: 'directives/map-control/advisor-rotogramas/btn-advisor-rotogramas-component.tpl.html',
      bindings: {
        qtdSugestoes: '<',
        active: '<'
      }
    });
}());
