(function () {
  'use strict';

  /* @ngdoc object
   * @name cadastros.pontoReferencia
   * @description
   *
   */
  angular
    .module('cadastros.pontoReferencia', [
      'ui.router',
      'mapControlModule',
      'alert-message',
      'smart-table',
      'validateUtilModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'enumModule',
      'pascalprecht.translate',
      'v3gAuthenticatorModule',
      'validateUtilModule'
    ]);
}());
