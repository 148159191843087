(function () {
  'use strict';

  class PermanenciaCtrl {
    constructor(Permanencia, $httpParamSerializer, moment, ServiceVfiltro, ObjetosVfiltroService, FactoryVfiltro, Keys, VtabelaFactory, uiGridConstants,
      FormatVfiltro, $filter, $translate, Eventos, UrlConfigService) {
      this.permanenciaService = Permanencia;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.httpParamSerializer = $httpParamSerializer;
      this.moment = moment;
      this.formatVfiltro = FormatVfiltro;
      this.filter = $filter;
      this.translate = $translate;
      this.eventos = Eventos;
      this.urlConfigService = UrlConfigService;

      this.vtabela = null;
      this.exportsPdf = false;
      this.uoSelecionada = null;
      this.labelCercaEletronica = this.translate.instant('ce.common.permanencia.cercaEletronica');
      this.labelPontoReferencia = this.translate.instant('ce.common.permanencia.pontoReferencia');
      this.mapTipoPermanenciaToTipoEvento = new Map([
        ['PONTO_REFERENCIA', this.eventos.PERMANENCIA_PONTO.alerta],
        ['CERCA_ELETRONICA', this.eventos.PERMANENCIA_CERCA.alerta]
      ]);

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 1,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          name: 'uoId',
          key: this.keys.uo.name
        },
        {
          name: 'veiculoIds',
          key: this.keys.veiculos.name,
          ignoreValidation: true
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.user = this.serviceVfiltro.factoryVfiltro.user;
        this.selectOptions = this.getTiposPermanenciaPermissao();
        this.roleHistoricoDetalhado = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('HISTORICO_DETALHADO_VISUALIZAR');
        this.consultar();
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'relatorioPermanencia', 'v1');
      })
      .catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
      });
    }

    initVtabela() {
      const exporterBaseName = this.translate.instant('ce.analise.permanencia.exporter');
      let objVtabela = {
        exporterPdfFilename: `${exporterBaseName} PDF.pdf`,
        exporterCsvFilename: `${exporterBaseName} CSV.csv`,
        exporterExcelFilename: `${exporterBaseName} XLSX.xlsx`,
        appScopeProvider: this,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        enableSelectAll: false,
        isRowSelectable: () => {
          return false;
        },
        columnDefs: [
            {
              name: 'veiculo',
              displayName: 'ce.analise.permanencia.colunas.veiculo',
              pinnedLeft: true,
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'isPermanenciaPontoReferencia',
              displayName: 'ce.common.permanencia.tipoPermanencia',
              pinnedLeft: true,
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: this.selectOptions
              },
              type: 'boolean',
              cellTemplate: `
              <div
                class="ui-grid-cell-contents text-center">
                {{ grid.appScope.getLabelPermanenciaTipo(row.entity.isPermanenciaPontoReferencia) }}
              </div>`
            },
            {
              name: 'nomeRegiao',
              displayName: 'ce.common.nome',
              filterCellFiltered: true,
              pinnedLeft: true,
              enableHiding: false,
              cellClass: 'text-center'
            },
            {
              name: 'tempoPermanencia',
              displayName: 'ce.analise.permanencia.colunas.tempoPermanencia',
              cellFilter: 'humanizedDuration',
              cellClass: 'text-center',
              pinnedLeft: true,
              enableHiding: false,
              filterCellFiltered: true
            },
            {
              name: 'uo',
              displayName: 'ce.analise.permanencia.colunas.uo',
              filterCellFiltered: true
            },
            {
              name: 'motorista',
              displayName: 'ce.analise.permanencia.colunas.motoristas',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'dataHoraInicial',
              displayName: 'ce.analise.permanencia.colunas.dataHoraInicial',
              cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'dataHoraFinal',
              displayName: 'ce.analise.permanencia.colunas.dataHoraFinal',
              cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'grupoVeiculo',
              displayName: 'ce.analise.permanencia.colunas.grupoVeiculo',
              filterCellFiltered: true
            },
            {
              name: 'logradouro',
              displayName: 'ce.analise.permanencia.colunas.logradouro',
              filterCellFiltered: true,
              type: 'string',
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{row.entity.logradouro}}">
                <a ng-href='{{row.entity.link}}' target='_blank' ng-if='grid.appScope.roleHistoricoDetalhado'>
                  {{ row.entity.logradouro }}
                </a>
                <span ng-if='!grid.appScope.roleHistoricoDetalhado'> {{row.entity.logradouro}} </span>
              </div>
              `
            },
            {
              name: 'localizacao',
              displayName: 'ce.analise.permanencia.colunas.localizacao',
              filterCellFiltered: true
            },
            {
              name: 'categoria',
              displayName: 'ce.analise.permanencia.colunas.categoria',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'hodometroInicial',
              displayName: 'ce.analise.permanencia.colunas.hodometroInicial',
              cellFilter: 'decimalWithLabel:\'Km\'',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'hodometroFinal',
              displayName: 'ce.analise.permanencia.colunas.hodometroFinal',
              cellFilter: 'decimalWithLabel:\'Km\'',
              filterCellFiltered: true,
              cellClass: 'text-center'
            },
            {
              name: 'distancia',
              displayName: 'ce.analise.permanencia.colunas.distanciaPercorrida',
              cellClass: 'text-center',
              filterCellFiltered: true
            }
        ]
      };

      return objVtabela;
    }

    getItemVtabela(item) {
      const permanencia = this.getPermanenciaByTipo(item);
      return {
        veiculo: this.geraVeiculo(item.veiculo),
        grupoVeiculo: item.veiculo.grupo && item.veiculo.grupo.nome,
        motorista: angular.isDefined(item.motoristas) ? this.geraMotoristas(item.motoristas) : null,
        uo: item.veiculo.uo && item.veiculo.uo.nome,
        nomeRegiao: permanencia.nome,
        categoria: permanencia.categoriaNome,
        tempoPermanencia: this.geraTempoPermanenciaSegundos(item),
        dataHoraInicial: item.inicio,
        dataHoraFinal: item.fim,
        localizacao: permanencia.localizacao,
        logradouro: permanencia.logradouro,
        hodometroInicial: item.hodometroInicial,
        hodometroFinal: item.hodometroFinal,
        distancia: this.geraDistancia(item),
        link: this.getLinkHistoricoDetalhado(item),
        isPermanenciaPontoReferencia: item.permanenciaTipo === 'PONTO_REFERENCIA'
      };
    }

    getPermanenciaByTipo(item) {
      const isPontoReferencia = item.permanenciaTipo === 'PONTO_REFERENCIA';
      let categoriaCercaNome = '';
      if (!isPontoReferencia && item.cercaEletronica.categoria) {
        categoriaCercaNome = item.cercaEletronica.categoria.nome;
      }

      return {
        nome: isPontoReferencia ? item.pontoReferencia.nome : item.cercaEletronica.nome,
        categoriaNome: isPontoReferencia ? item.pontoReferencia.categoriaNome : categoriaCercaNome,
        logradouro: isPontoReferencia ? item.pontoReferencia.logradouro : item.cercaEletronica.logradouro,
        localizacao: isPontoReferencia ? item.pontoReferencia.localizacao : item.cercaEletronica.localizacao
      };
    }

    getLabelPermanenciaTipo(isPermanenciaPontoReferencia) {
      return isPermanenciaPontoReferencia ? this.labelPontoReferencia : this.labelCercaEletronica;
    }

    getTiposPermanenciaPermissao() {
      const permissionPonto = this.user.permissoes.includes('PERMANENCIA_PONTO_REFERENCIA_VISUALIZAR'),
          permissionCerca = this.user.permissoes.includes('PERMANENCIA_CERCA_ELETRONICA_VISUALIZAR');

      let options = [
        {value: true, label: this.labelPontoReferencia},
        {value: false, label: this.labelCercaEletronica}
      ];

      if (!permissionPonto && permissionCerca) {
        options = [
          {value: false, label: this.labelCercaEletronica}
        ];
      } else if (permissionPonto && !permissionCerca) {
        options = [
          {value: true, label: this.labelPontoReferencia}
        ];
      }

      return options;
    }

    getLinkHistoricoDetalhado(row) {
      let momentEvento = this.moment(row.inicio),
          eventoTipo = this.mapTipoPermanenciaToTipoEvento.get(row.permanenciaTipo),
          eventoIsoDate = new Date(row.inicio).toISOString(),
          inicioEvento = momentEvento.format(this.formatVfiltro),
          inicioDia = momentEvento.startOf('day').format(this.formatVfiltro);

      return `/historico-timeline?vfiltro=veiculo:${row.veiculoId};uo:${row.veiculo.uo.id};dateTime:${inicioDia},${inicioEvento}&evento=${eventoTipo}&date=${eventoIsoDate}&ultimo=true&forceRenderLayer=true`;
    }

    geraVeiculo(veiculo) {
      return `${veiculo.prefixo ? veiculo.prefixo + ' - ' : ''}${veiculo.placa}`;
    }

    geraMotoristas(motoristas) {
      return motoristas.map(motorista => motorista.nome).join(', ');
    }

    geraDistancia(item) {
      if (item.hodometroFinal && item.hodometroInicial) {
        return this.filter('decimalWithLabel')(item.hodometroFinal - item.hodometroInicial, 'Km');
      }
      return '-';
    }

    geraTempoPermanenciaSegundos(item) {
      return (new Date(item.fim) - new Date(item.inicio)) / 1000;
    }

    consultar() {
      this.permanenciaService.one().get(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))
        .then(data => this.setInfoTable(data))
        .catch(() => this.vtabela.setError());
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    getUrlCsv() {
      return `/ponto-referencia/permanencias/csv?${this.httpParamSerializer(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))}`;
    }

    getUrlPdf() {
      return `/ponto-referencia/historico-permanencia/pdf?${this.httpParamSerializer(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))}`;
    }

  }

  angular
  .module('relatorios.permanencia')
  .controller('PermanenciaCtrl', PermanenciaCtrl);
}());
