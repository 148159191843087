(function () {
  'use strict';

  class ModalTurnoEventos {
    constructor($uibModalInstance, titulo, texto, texto2, clique) {
      this.modalInstance = $uibModalInstance;
      this.titulo = titulo;
      this.texto = texto;
      this.texto2 = texto2;
      this.clique = clique;
    }
  }

  angular
    .module('ModalEventos')
    .controller('ModalTurnoEventosCtrl', ModalTurnoEventos);
}());
