(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('unprotected.errorPage', {
      url: '/error',
      params: {
        lastPage: null
      },
      views: {
        'guest@': {
          templateUrl: 'components/error-page/error-page.tpl.html',
          controller: 'ErrorPageCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
  }

  angular
    .module('errorPageModule')
    .config(pageConfig);
}());
