(function () {
  'use strict';

  /* @ngdoc object
   * @name seguranca
   * @description
   *
   */
  angular
    .module('segurancaModule', ['ui.router', 'FeatureFlagModule']);
}());
