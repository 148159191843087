(function () {
  'use strict';

  angular
    .module('relatorios.velocidadeRpm', [
      'ui.router',
      'restangular',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'alert-message',
      'ui.bootstrap.modal',
      'pascalprecht.translate',
      'ConstantModule',
      'angularMoment'
    ])
    .constant('LIMIAR', {INFERIOR: 60, SUPERIOR: 80})
    .factory('VelocidadeRpm', Restangular => Restangular.service('boletim/velocidade-rpm-limpador-parabrisa'));
}());
