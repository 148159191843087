(function () {
  'use strict';

  class PieChart {
    constructor(CommonChart) {
      this.commonChartService = CommonChart;
      this.title = this.commonChartService.getTitle();
      this.subtitle = this.commonChartService.getSubtitle();
      this.xAxis = this.commonChartService.getXAxis();
      this.yAxis = this.commonChartService.getYAxis(false, true);
      this.options = this.getOptions();
      this.series = null;
    }

    getOptions() {
      return {
        chart: {renderTo: 'container', type: 'pie'},
        colors: [
          'rgb(204,38,54)',
          'rgb(255,154,0)',
          'rgb(139,188,33)',
          'rgb(49,139,255)',
          'rgb(136,84,196)',
          'rgb(255,203,63)',
          'rgb(255,73,121)',
          'rgb(255,108,58)',
          'rgb(8,204,180)',
          'rgb(83,119,0)',
          'rgb(255,63,0)',
          'rgb(79,73,255)',
          'rgb(209,153,12)'
        ],
        plotOptions: {
          column: {},
          line: {},
          pie: {
            borderColor: '#FFFFFF',
            borderWidth: 1,
            innerSize: '60%'
          },
          series: {point: {events: {}}, events: {}}
        },
        credits: {enabled: false},
        legend: {
          itemStyle: {
            font: '9pt "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: 'black'
          }
        }
      };
    }

    setData(data) {
      this.xAxis.categories = data.categorias;
      this.series = [{
        data: data.map(d => [d.name, d.total]),
        size: '90%',
        innerSize: '60%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        }
      }];

      this.options.tooltip = {pointFormat: '<b>{point.y}</b>'};
    }

    setTitleText(chartTitle) {
      this.title.text = chartTitle;
    }

    setYAxisTitle(yAxisTitle) {
      this.yAxis.title.text = yAxisTitle;
    }

    setClickEvent(onClick) {
      this.options.plotOptions.series.point.events.click = onClick;
      this.options.plotOptions.series.events.click = () => {};
    }
  }

  angular
    .module('chartModule')
    .service('PieChart', (CommonChart) => ({get: () => new PieChart(CommonChart)}));
}());
