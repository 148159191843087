(function () {
  'use strict';

  function V3gImageCrop() {
    return {
      templateUrl: 'directives/v3g-image-crop/v3g-image-crop.tpl.html',
      restrict: 'E',
      controller: 'v3gImageCropCtrl',
      controllerAs: 'v3gImageCropCtrl',
      scope: {
        maxSize: '=?',
        required: '=?',
        onChangeFile: '=',
        onCrop: '='
      }
    };
  }

  angular
    .module('VImageCrop')
    .directive('v3gImageCrop', V3gImageCrop);
}());
