(function () {
  'use strict';

  angular
    .module('relatorios.ocorrencia', [
      'ui.router',
      'httpServiceV3gModule',
      'enumModule',
      'ServiceVfiltroModule',
      'ObjetosVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'pascalprecht.translate',
      'FeatureFlagModule',
      'urlConfigModule'
    ]);
}());
