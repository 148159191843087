(function () {
  'use strict';

  class ConfigVdriversCtrl {
    constructor($stateParams, ConfigVdriversService,
                Keys, ServiceVfiltro, FactoryVfiltro) {
      this.service = ConfigVdriversService;

      this.tipo = this.service.getTipo($stateParams.tipoSubpagina && $stateParams.tipoSubpagina.toLowerCase());

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: Keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.service.roleCadastrar = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_VDRIVERS_EDITAR');
        const uoId = filtro.find(x => x.key === Keys.uo.name).value.id;
        this.service.carregarConfigUo(uoId, this.tipo);
      });
    }
  }

  angular
    .module('configVdrivers')
    .controller('ConfigVdriversCtrl', ConfigVdriversCtrl);
}());
