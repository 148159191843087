(function () {
  'use strict';
  const FIELDS = [
    'id',
    'uoId',
    'vehicleId',
    'cameras',
    'dateTime',
    'latitude',
    'longitude',
    'bucket',
    'label'
  ];

  class VisaoCamerasService {
    constructor(HttpServiceV3g, $httpParamSerializer, AGRUPAMENTO_EQUIPAMENTO) {
      this.httpService = HttpServiceV3g;
      this.httpParamSerializer = $httpParamSerializer;
      this.Agrupamento = AGRUPAMENTO_EQUIPAMENTO;
    }

    hasVideo(veiculoId) {
      const params = this.httpParamSerializer({
        veiculoId: veiculoId,
        agrupamentosEquipamentos: [this.Agrupamento.VSAFE, this.Agrupamento.VMOV].join()
      });

      return this.httpService
        .get(`/veiculo/v2/veiculos?${params}`)
        .then(responseVeiculos => Boolean(responseVeiculos.data.numberOfElements));
    }

    getSnapshots(veiculoId, datetime, currentPage, labels) {
      const params = this.httpParamSerializer({
        fields: FIELDS.join(),
        begin: datetime.startDate,
        end: datetime.endDate,
        vehiclesId: veiculoId,
        labels: labels,
        page: currentPage,
        size: 10
      });

      return this.httpService.get(`/video/snapshots/pageable?${params}`);
    }

    getLabels(datetime, veiculoId) {
      const params = this.httpParamSerializer({
        begin: datetime.startDate,
        end: datetime.endDate,
        vehicleId: veiculoId
      });

      return this.httpService.get(`/video/snapshots/labels?${params}`);
    }
  }

  angular
    .module('historicoTimeline')
    .service('VisaoCamerasService', VisaoCamerasService);
}());
