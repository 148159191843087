(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.ocorrenciaDetalhes', {
      url: '/relatorios/ocorrencia-detalhes/:ocorrenciaId/:dataHora',
      views: {
        'content@': {
          templateUrl: 'relatorios/ocorrencia-detalhes/ocorrencia-detalhes.tpl.html',
          controller: 'OcorrenciaDetalhesCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'ce.tempoReal.quadroDeOcorrencias.ocorrenciaDetalhes.titulo',
        backButton: true,
        role: 'OCORRENCIA_VISUALIZAR',
        backState: 'root.ocorrencia'
      }
    });
  }

  angular
    .module('relatorios.ocorrenciaDetalhes')
    .config(pageConfig);
}());
