(function () {
  'use strict';

  class MotoristaService {
    constructor(Restangular) {
      this.restangular = Restangular;
    }

    getMotoristasSemFoto(userUoId) {
      return this.restangular.all(`/motorista/v2/motoristas?fields=id,fotos&uoIds=${userUoId}`).getList();
    }

    getQRCode(motoristaUoId, motoristaId) {
      return this.restangular.one(`/motorista/motoristas/${motoristaUoId}/qrcode/${motoristaId}/base64`).get();
    }
  }

  angular
    .module('motoristaModule')
    .service('MotoristaService', MotoristaService);
}());
