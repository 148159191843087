(function () {
  'use strict';
  class OcorrenciaModalCtrl {
    constructor($uibModalInstance, HttpServiceV3g, ModalInfoConfirmService, Ocorrencias, $q, ocorrencia, AlertasEnum, ocorrenciasMotorista,
      moment, OcorrenciasEnum, $scope, permissaoEditarOcorrencia, $translate) {
      this.modalInstance = $uibModalInstance;
      this.httpService = HttpServiceV3g;
      this.modalInfoConfirmService = ModalInfoConfirmService;
      this.ocorrenciasService = Ocorrencias;
      this.ocorrenciasEnum = OcorrenciasEnum;
      this.alertasEnum = AlertasEnum;
      this.q = $q;
      this.permissaoEditarOcorrencia = permissaoEditarOcorrencia;
      this.ocorrenciasMotorista = ocorrenciasMotorista;
      this.ocorrencia = ocorrencia;
      this.moment = moment;
      this.scope = $scope;

      this.nenhumUsuario = {id: null, login: $translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoItem.usuarioAtribuidoNenhum')};
      this.requestError = false;
      this.model = {
        responsavel: null,
        status: null,
        obs: null
      };
      this.limitScroll = 10;
      this.init();
      // não mostrar label do video até preparar backend
      this.showLabelVideo = false;
    }

    loadMore() {
      this.limitScroll += 10;
    }

    init() {
      this.initRequests();

      this.ocorrenciasAnexo = this.getAnexo();
      this.setForm();
      this.filterStatusSelect();
    }

    filterStatusSelect() {
      const ocorrenciaStatus = this.ocorrenciasEnum.getStatusById(this.ocorrencia.status);
      this.selectStatus = this.ocorrenciasEnum.getStatus()
        .filter(status => status.nivel >= ocorrenciaStatus.nivel);
    }

    initRequests() {
      let requests = [
        this.ocorrenciasService.getOcorrenciaById(this.ocorrencia.id, this.ocorrencia.dataHora),
        this.ocorrenciasService.getUsers(this.ocorrencia.veiculoId),
        this.ocorrenciasService.getHistorico(this.ocorrencia)
      ];

      this.q.all(requests)
        .then(([ocorrencia, userRes, histRes]) => {
          this.ocorrencia = ocorrencia.data[0];
          this.usuarios = userRes.data;
          this.model.responsavel = this.usuarios
            .find(user => user.id === this.ocorrencia.usuarioIdAtribuido) || this.nenhumUsuario;
          this.usuarios.unshift(this.nenhumUsuario);
          if (histRes) {
            this.ocorrencia.hist = histRes.data;
          }
        })
        .catch(() => {
          this.requestError = true;
        });
    }

    setForm() {
      this.model.status = this.ocorrenciasEnum.getStatusById(this.ocorrencia.status);
    }

    getAnexo() {
      return this.ocorrenciasMotorista.filter(ocorr =>
        new Date(ocorr.dataHora).getTime() < new Date(this.ocorrencia.dataHora).getTime());
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    abreOcorrenciaAnexo(ocorrencia) {
      this.modalInstance.close({isAnexo: true, ocorrenciaAnexo: ocorrencia});
    }

    salvar() {
      const form = this.scope.formTratativa;
      let objTrativa = {
        uoId: this.ocorrencia.uoId,
        ocorrenciaId: this.ocorrencia.id
      };

      objTrativa.comentario = form.obs.$dirty ? this.model.obs : null;
      objTrativa.statusNovo = form.status.$dirty ? this.model.status.id : null;
      objTrativa.usuarioIdAtribuidoNovo = this.model.responsavel.id;

      this.httpService.post(`/alerta/ocorrencias/${this.ocorrencia.id}/historicos?dataHoraOcorrencia=${this.ocorrencia.dataHora}`, objTrativa)
        .then(() => {
          this.modalInstance.close({
            isAnexo: false,
            status: this.model.status.id,
            usuarioAtribuido: this.model.responsavel
          });
        })
        .catch(() => {
          this.modalInstance.dismiss(true);
        });
    }

    isDisabled() {
      return this.ocorrencia.status === this.ocorrenciasEnum.getStatusById('RESOLVIDA').id ||
      !this.permissaoEditarOcorrencia;
    }

    showHistorico() {
      return this.ocorrenciasAnexo.length || this.ocorrencia.hist && this.ocorrencia.hist.length;
    }

    responsavelValidate(value) {
      return () => {
        if (this.model.status && this.model.status.id === 'EM_TRATATIVA') {
          return value.$viewValue && !!value.$viewValue.id;
        }
        return true;
      };
    }

    formChanged() {
      if (!this.model.responsavel) {
        return false;
      }
      const usuarioIdAtribuido = angular.isUndefined(this.ocorrencia.usuarioIdAtribuido) ? null : this.ocorrencia.usuarioIdAtribuido,
          observacaoChanged = !!this.model.obs,
          statusChanged = this.model.status.id !== this.ocorrencia.status,
          responsavelChanged = this.model.responsavel.id !== usuarioIdAtribuido;
      return observacaoChanged || statusChanged || responsavelChanged;
    }
  }

  angular
    .module('quadroOcorrencias')
    .controller('OcorrenciaModalCtrl', OcorrenciaModalCtrl);
}());
