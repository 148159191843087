(function () {
  'use strict';

  /* global _ */
  class SingleColumnChart {
    constructor(CommonChart, $timeout, $rootScope) {
      this.commonChartService = CommonChart;
      this.timeout = $timeout;
      this.scope = $rootScope;
      this.title = this.commonChartService.getTitle();
      this.subtitle = this.commonChartService.getSubtitle();
      this.xAxis = this.commonChartService.getXAxis();
      this.yAxis = this.commonChartService.getYAxis(false, false);
      this.options = this.getOptions();
      this.series = null;
      this.animation = false;
      this.erroSemData = true;
    }

    getOptions() {
      return {
        chart: {
          events: {
            load: () => {
              this.timeout(() => this.scope.$broadcast('highchartsng.reflow'), 100);
            }
          },
          type: 'column'
        },
        colors: [
          'rgb(244, 150, 59)',
          'rgb(72, 60, 70)',
          'rgb(60, 110, 113)',
          'rgb(136,84,196)',
          'rgb(112,174,110)',
          'rgb(139,188,33)',
          'rgb(255,108,58)',
          'rgb(49,139,255)',
          'rgb(204,38,54)',
          '#F2994A',
          'rgb(136,84,196)',
          'rgb(255,203,63)',
          'rgb(255,73,121)',
          '#6790AB',
          '#FF94C2',
          '#880E4F',
          '#C62828',
          '#424242',
          '#9E9E9E'
        ],
        exporting: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            grouping: false,
            animation: true
          },
          series: {
            data: [],
            point: {events: {}},
            events: {
              click: this.commonChartService.filterAllSeries,
              legendItemClick: this.commonChartService.filterAllSeries
            }
          }
        },
        credits: {enabled: false},
        legend: {
          itemStyle: {
            font: '9pt "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: 'black'
          }
        },
        tooltip: {
          shared: false
        }
      };
    }

    setCustomTooltip() {
      this.options.tooltip.formatter = function () {
        return `<span style="color: ${this.color}">●</span> ${this.series.name}: <b>${this.y}</b>`;
      };
    }

    setAnimation() {
      if (this.animation) {
        this.options.plotOptions.column.animation = false;
      } else {
        this.options.plotOptions.column.animation = true;
        this.animation = true;
      }
    }

    setData(data) {
      if (data.series) {
        this.xAxis.categories = data.categorias;
        this.series = this.mapSingleSeries(data.series);
        this.setAnimation();
      }
    }

    setClickEvent(onClickColumn) {
      this.options.plotOptions.series.point.events.click = onClickColumn;
      this.options.plotOptions.series.events.click = () => {};
    }

    setLegendItemClickEvent(onLegendItemClick) {
      this.options.plotOptions.series.events.legendItemClick = onLegendItemClick;
    }

    setTitleText(chartTitle) {
      this.title.text = chartTitle;
    }

    setYAxisTitle(yAxisTitle, axisIndex) {
      if (angular.isArray(this.yAxis)) {
        if (axisIndex) {
          this.yAxis[axisIndex].title.text = yAxisTitle;
        } else {
          this.yAxis[0].title.text = yAxisTitle;
        }
      } else {
        this.yAxis.title.text = yAxisTitle;
      }
    }

    mapSingleSeries(series) {
      return _.chain(series)
        .map((serie, index) => {
          return {
            name: serie.name,
            data: this.preencheDados(serie.data, index, series.length),
            visible: angular.isUndefined(serie.visible) || serie.visible,
            color: serie.color || this.options.colors[serie.indexColor],
            key: serie.key
          };
        })
        .flatten()
        .value();
    }

    preencheDados(valor, index, qntItem) {
      let data = [];
      for (let i = 0; i < qntItem; i++) {
        if (i === index) {
          data.push(valor);
        } else {
          data.push(null);
        }
      }
      return data;
    }

    removeTitleCategories() {
      this.xAxis.labels = {
        enabled: false
      };
    }

    disableLegend() {
      this.options.legend.enabled = false;
    }
  }

  angular
    .module('chartModule')
    .service('SingleColumnChart', (CommonChart, $timeout, $rootScope) => ({get: () => new SingleColumnChart(CommonChart, $timeout, $rootScope)}));
}());
