(function () {
  'use strict';

  class HalfPieChart {
    constructor(CommonChart, $timeout, $rootScope, $translate) {
      this.commonChartService = CommonChart;
      this.timeout = $timeout;
      this.scope = $rootScope;
      this.translate = $translate;
      this.title = this.commonChartService.getTitle();
      this.subtitle = this.commonChartService.getSubtitle();
      this.xAxis = this.commonChartService.getXAxis();
      this.yAxis = this.commonChartService.getYAxis(false, false);
      this.options = this.getOptions();
      this.series = null;
      this.animation = false;
      this.erroSemData = true;
    }

    getOptions() {
      return {
        chart: {
          events: {
            load: () => {
              this.timeout(() => this.scope.$broadcast('highchartsng.reflow'), 100);
            }
          },
          type: 'pie'
        },
        exporting: {
          enabled: false
        },
        colors: [
          'rgb(139,188,33)',
          'rgb(130,130,130)'
        ],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '85%'],
            animation: true,
            size: '150%',
            dataLabels: {
              enabled: true,
              distance: -40,
              style: {
                fontWeight: 'bold',
                color: 'black'
              },
              formatter: function () {
                return this.percentage.toFixed(2) + '%';
              }
            }
          },
          series: {
            type: 'pie',
            innerSize: '50%',
            point: {events: {}},
            events: {}
          }
        },
        credits: {enabled: false},
        legend: {
          itemStyle: {
            font: '9pt "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
            color: 'black'
          }
        },
        tooltip: {
          shared: false
        }
      };
    }

    setAnimation() {
      if (this.animation) {
        this.options.plotOptions.pie.animation = false;
      } else {
        this.options.plotOptions.pie.animation = true;
        this.animation = true;
      }
    }

    setData(data) {
      if (data.series) {
        this.xAxis.categories = data.categorias;
        this.series = [{
          name: this.translate.instant('ce.tempoReal.statusFrota.charts.common.veiculos'),
          data: data.series.map(d => [d.name, d.y, d.ids]),
          showInLegend: true
        }];
        this.setAnimation();
      }
    }

    setClickEvent(onClickColumn) {
      this.options.plotOptions.series.point.events.click = onClickColumn;
      this.options.plotOptions.series.events.click = () => {};
    }

    setTitleText(chartTitle) {
      this.title.text = chartTitle;
    }

    setYAxisTitle(yAxisTitle) {
      this.yAxis.title.text = yAxisTitle;
    }

    removeTitleCategories() {
      this.xAxis.labels = {
        enabled: false
      };
    }
  }

  angular
    .module('chartModule')
    .service('HalfPieChart', (CommonChart, $timeout, $rootScope, $translate) => ({get: () => new HalfPieChart(CommonChart, $timeout, $rootScope, $translate)}));
}());
