(function () {
  'use strict';

  class CancelarSolicitacaoCtrl {
    constructor(solicitacao, $uibModalInstance, Restangular) {
      this.modalInstance = $uibModalInstance;
      this.restangular = Restangular;

      this.solicitacao = solicitacao;
    }

    ok() {
      this.restangular.all(`/relatorios-agendado/historico-requisicoes/cancelar/${this.solicitacao.id}`)
      .post()
      .then((cancelado) => {
        this.modalInstance.close(cancelado);
      })
      .catch((cancelado) => {
        this.modalInstance.close(cancelado);
      });
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('relatorios.historicoSolicitacao')
    .controller('CancelarSolicitacaoCtrl', CancelarSolicitacaoCtrl);
}());
