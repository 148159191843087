(function () {
  'use strict';

  class PesquisaPontoReferenciaService {
    constructor() {
      this.pontos = [];
      this.cercasEletronicas = [];
      this.buscaPontoArea = [];
      this.pesquisa = '';
      this.logradouroIndex = null;
      this.logradouros = null;
      this.pesquisaCruzamento = '';
      this.pesquisaCidadeCruzamento = '';
      this.coordenadaCruzamento = {};
      this.encontrouCruzamento = null;
      this.tabLogradouro = false;
      this.tabPontoReferencia = true;
      this.tabBuscaPorRegiao = false;
      this.logradouroMarker = null;
      this.enableFieldCruzamento = false;
    }
  }

  angular
    .module('mapControlModule')
    .service('PesquisaPontoReferenciaService', PesquisaPontoReferenciaService);
}());
