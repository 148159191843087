(function () {
  'use strict';

  class TempoPermanenciaInputCtrl {
    onChangeConfigTempoMinimo() {
      if (!this.configurarTempo) {
        this.model = null;
      }
    }

    tempoPermanenciaValid() {
      return () => {
        return !(this.model && this.model < 3);
      };
    }
  }

  angular
    .module('mapControlModule')
    .component('tempoPermanenciaInput', {
      controller: TempoPermanenciaInputCtrl,
      templateUrl: 'directives/map-control/tempo-permanencia-input/tempo-permanencia-input-component.tpl.html',
      bindings: {
        model: '=',
        configurarTempo: '=',
        name: '@',
        info: '@'
      }
    });
}());
