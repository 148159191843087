(function () {
  'use strict';

  angular
    .module('ProntuarioModule', [
      'VImageCrop',
      'ui.bootstrap.modal',
      'restangular',
      'AlertasModule',
      'angularMoment',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'FeatureFlagModule',
      'v3gAuthenticatorModule',
      'enumModule',
      'middlewareModule',
      'pascalprecht.translate',
      'ConstantModule',
      'urlConfigModule',
      'alert-message'
    ])
    .factory('Prontuario', Restangular => Restangular.service('boletim/ranking/pontuacao-motoristas/mensal'));
}());
