(function () {
  'use strict';

  class ConfiguracoesEvento {

    constructor(Restangular) {
      this.restangular = Restangular;
    }

    getConfiguracoes(uoId) {
      return this.restangular.one(`/sensor/uos/${uoId}/configuracoes/evento`).get();
    }
  }

  angular
    .module('configuracoesEvento')
    .service('ConfiguracoesEvento', ConfiguracoesEvento);
}());
