(function () {
  'use strict';
  class ObjetosVfiltro {
    constructor(UrlService, ServiceVfiltro, Keys) {
      this.urlService = UrlService;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;

      this.tableStateDefault = {
        sort: {
          predicate: '',
          reverse: ''
        }
      };
    }

    getVfiltroTableState(table, exclude) {
      let vfiltro = this.urlService.getObjectVfiltro(this.serviceVfiltro.filtroTopbar.filter(item => item.key !== exclude)),
          tablestate = this.getTableState(table);
      if (tablestate) {
        return `tablestate=${tablestate}&vfiltro=${vfiltro}`;
      }
      return `vfiltro=${vfiltro}`;
    }

    getTableState(table) {
      let tableState = {
        predicate: table.sort && table.sort.predicate,
        reverse: table.sort && table.sort.reverse
      };
      return angular.isFunction(table.sort.predicate) ? null : this.urlService.getObjectVfiltroUrl(tableState);
    }

    setTableStateUrl(tablestate, replace) {
      const objTableState = this.getTableState(tablestate);
      if (objTableState) {
        this.urlService.setQueryString('tablestate', objTableState, replace);
      }
    }

    getTableStateUrl() {
      let tablestate = this.urlService.getVfiltroUrl('tablestate');
      if (tablestate) {
        return {
          sort: {
            predicate: tablestate.predicate || '',
            reverse: angular.fromJson(tablestate.reverse) || ''
          }
        };
      }
      return angular.copy(this.tableStateDefault);
    }
  }

  angular
    .module('ObjetosVfiltroModule', [
      'UrlModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule'
    ])
    .service('ObjetosVfiltroService', ObjetosVfiltro);
}());
