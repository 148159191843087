(function () {
  'use strict';

  /* global _, L */
  class EditorRotogramasCtrl {
    constructor(MapasUtil, leafletData, PontosReferenciaTools, CadastroRotogramaService, CategoriaPontosReferencia,
      PosicoesRotogramas, ControleVelocidadeRotograma, RotogramaMapService, PontosReferenciaMap, $scope, $compile) {
      this.mapasUtil = MapasUtil;
      this.leafletData = leafletData;
      this.pontosReferenciaTools = PontosReferenciaTools;
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.categoriaPontosReferenciaService = CategoriaPontosReferencia;
      this.posicoesRotogramas = PosicoesRotogramas;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.rotogramaMapService = RotogramaMapService;
      this.pontoReferenciaMapService = PontosReferenciaMap;
      this.scope = $scope;
      this.compile = $compile;
      this.MAPA_ID = 'mapa-rotograma';
    }

    $onInit() {
      this.inicializarMapa();
    }

    $onDestroy() {
      this.cadastroRotogramaService.encerrarCadastro(this.map);
    }

    inicializarMapa() {
      this.tiles = this.mapasUtil.tiles.veltec;
      this.defaults = this.mapasUtil.defaults;
      this.center = {
        zoom: 17,
        lat: this.pontoReferencia.latitude,
        lng: this.pontoReferencia.longitude
      };

      this.leafletData.getMap(this.MAPA_ID).then(map => {
        const latlngsExternos = this.pontosReferenciaTools.geraLatLngsExternos(
          this.pontoReferencia.latitude,
          this.pontoReferencia.longitude,
          this.pontoReferencia.raio
        ),
            rotogramasPorPosicao = this.associarRotogramasAsPosicoes(),
            markersRotogramas = _.values(this.posicoesRotogramas).map(p => this.mapearMarcador(p, rotogramasPorPosicao));

        this.rotogramaMapService.setLatLngPontoReferencia(
          [latlngsExternos.latlngLeftBottom.latitude, latlngsExternos.latlngLeftBottom.longitude],
          [latlngsExternos.latlngRightTop.latitude, latlngsExternos.latlngRightTop.longitude]
        );
        this.rotogramaMapService.renderizarPontoReferencia(
          map,
          latlngsExternos,
          this.categoria && this.categoria.cor || this.categoriaPontosReferenciaService.categoriaDefault.cor
        );

        this.cadastroRotogramaService.iniciarCadastro(this.rotogramas)
          .then(() => {
            if (this.advisorSuggestion) {
              this.handleAdvisorSuggestion(markersRotogramas);
            }

            markersRotogramas.forEach(marker => {
              map.addLayer(marker);
              const scope = this.scope.$new(true);
              scope.marker = marker;
              scope.posicao = marker.options.posicao;
              marker.getElement().appendChild(
                this.compile('<rotograma marker="marker" posicao="posicao"></rotograma>')(scope)[0]
              );
            });
          });
      });
    }

    handleAdvisorSuggestion(markersRotogramas) {
      const slowestSpeed = this.pontoReferenciaMapService.getSlowestSpeed(this.advisorSuggestion),
          grupoVelocidade = _.values(this.cadastroRotogramaService.gruposVelocidadePorId)
          .find(grupoVel => grupoVel.velocidade === slowestSpeed);
      if (slowestSpeed && grupoVelocidade) {
        let centro = markersRotogramas.find(marker => marker.options.posicao === this.posicoesRotogramas.CENTRO);
        centro.options.rotogramaCadastrado = {
          grupoVelocidadePadrao: Object.assign({}, grupoVelocidade),
          status: this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value
        };
      }
    }

    associarRotogramasAsPosicoes() {
      return this.rotogramas.reduce((acc, atual) => {
        const posicao = _.find(_.values(this.posicoesRotogramas), p => p.contemAngulo && p.contemAngulo(atual.grau));
        acc[posicao && posicao.key || this.posicoesRotogramas.CENTRO.key] = atual;
        return acc;
      }, {});
    }

    mapearMarcador(posicao, rotogramasPorPosicao) {
      if (rotogramasPorPosicao[posicao.key]) {
        const rotograma = rotogramasPorPosicao[posicao.key];
        return L.marker([rotograma.latitude, rotograma.longitude], {
          icon: L.divIcon({className: 'icon-rotograma'}),
          draggable: posicao !== this.posicoesRotogramas.CENTRO,
          posicao: posicao,
          rotogramaCadastrado: rotograma
        });
      } else if (posicao === this.posicoesRotogramas.CENTRO) {
        return L.marker([this.pontoReferencia.latitude, this.pontoReferencia.longitude], {
          icon: L.divIcon({className: 'icon-rotograma'}),
          posicao: posicao
        });
      }
      const latLngRotograma = this.pontosReferenciaTools.geraLatLngsRotograma(
        posicao.anguloInicial,
        this.pontoReferencia.raio,
        this.pontoReferencia.latitude,
        this.pontoReferencia.longitude
      );
      return L.marker([latLngRotograma.latlng.latitude, latLngRotograma.latlng.longitude], {
        icon: L.divIcon({className: 'icon-rotograma'}),
        draggable: true,
        posicao: posicao
      });
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }
  }

  angular
    .module('rotogramaModule')
    .component('editorRotogramas', {
      controller: EditorRotogramasCtrl,
      bindings: {
        pontoReferencia: '<',
        categoria: '<',
        rotogramas: '<',
        advisorSuggestion: '<'
      },
      templateUrl: 'directives/map-control/rotograma/editor-rotogramas.tpl.html'
    });
}());
