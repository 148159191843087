(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider
      .state('root.bds', {
        url: '/relatorios/bds?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'relatorios/bds/bds.tpl.html',
            controller: 'BdsCtrl',
            controllerAs: 'bds'
          }
        },
        data: {
          titulo: 'ce.boletim.servicos.titulo',
          tituloDescricao: 'ce.boletim.servicos.tituloDescricao',
          role: 'BOLETIM_SERVICO_VISUALIZAR'
        }
      })
      .state('root.bdsDetalhes', {
        url: '/relatorios/bds/:execucaoServico',
        views: {
          'content@': {
            templateUrl: 'relatorios/bds/bds-detalhes.tpl.html',
            controller: 'BdsDetalhesCtrl',
            controllerAs: 'bdsDetalhes'
          }
        },
        data: {
          titulo: 'ce.boletim.servicos.servicosDetalhes.titulo',
          tituloDescricao: 'ce.boletim.servicos.servicosDetalhes.tituloDescricao',
          backButton: true,
          backState: 'root.bds',
          role: 'BOLETIM_SERVICO_VISUALIZAR'
        }
      });
  }

  angular
    .module('relatorios.bds')
    .config(pageConfig);
}());
