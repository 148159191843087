(function () {
  'use strict';

  function checkboxPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/pesquisa-ponto-referencia/filtros-ponto-referencia/checkbox-ponto-referencia/checkbox-ponto-referencia.tpl.html',
      controller: 'CheckboxPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class CheckboxPontoReferenciaCtrl {
    constructor($scope, PontosReferenciaFiltro, PontosReferenciaMap, CercaEletronicaMapService) {
      this.scope = $scope;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.cercaEletronicaMapService = CercaEletronicaMapService;

      this.pontosReferenciaFiltroService.verificaFiltroLocalstorage();
    }

    checkboxSelecionarTodos() {
      return this.pontosReferenciaFiltroService.filtroSelecionado.lista.filter(x => !x.check).length === 0;
    }

    selecionarTodosChecks() {
      const todos = this.checkboxSelecionarTodos();

      this.pontosReferenciaFiltroService.filtroSelecionado.lista.forEach(x => x.check = !todos);
    }

    aplicarFiltro() {
      this.pontosReferenciaFiltroService.salvarFiltroItensEscondidos(
        this.pontosReferenciaFiltroService.filtroSelecionado.key,
        this.pontosReferenciaFiltroService.filtroSelecionado.lista
          .filter(x => !x.check)
          .map(x => x.id)
      );
      this.pontosReferenciaMapService.recarregaPontos();
      this.cercaEletronicaMapService.mostrarCercasBoundsAtual();
      this.pontosReferenciaFiltroService.filtroSelecionado = null;
      this.pontosReferenciaFiltroService.selecionarFiltro = false;
    }
  }

  angular
    .module('mapControlModule')
    .controller('CheckboxPontoReferenciaCtrl', CheckboxPontoReferenciaCtrl)
    .directive('checkboxPontoReferenciaDirective', checkboxPontoReferenciaDirective);
}());
