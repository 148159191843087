/* global document */
(function () {
  'use strict';

  function mapControlDirective() {
    return {
      templateUrl: 'directives/map-control/map-control-directive.tpl.html',
      controller: 'MapControlDirectiveCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {
        idPonto: '=',
        idMapa: '=',
        pontosProximos: '=',
        onInit: '&'
      }
    };
  }

  class MapControlDirectiveCtrl {
    constructor($scope, $window, $q, $uibModal, moment, leafletData, Authenticator, AlertMessage,
                PontosReferenciaTools, PontosReferenciaMap, PontosReferenciaMenu, PontosReferencia,
                CategoriaPontosReferencia, PontosReferenciaFiltro, PontosProximos, CercaEletronicaMapService,
                CercaEletronicaService, $translate, BuscaPorRegiaoMapService, AdvisorRotogramasService,
                FeatureFlag, Onboarding, IDBuscaPorRegiao, $location, MapasUtil, MapLayerVisibility) {
      this.scope = $scope;
      this.window = $window;
      this.q = $q;
      this.modalService = $uibModal;
      this.moment = moment;
      this.translate = $translate;
      this.featureFlag = FeatureFlag;
      this.onboarding = Onboarding;
      this.idBuscaPorRegiao = IDBuscaPorRegiao;
      this.mapasUtil = MapasUtil;
      this.mapLayerConstant = MapLayerVisibility;

      this.leafletData = leafletData;
      this.authenticator = Authenticator;
      this.alertMessage = AlertMessage;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.cercaEletronicaService = CercaEletronicaService;
      this.pontosCategoriaReferenciaService = CategoriaPontosReferencia;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.pontosProximosService = PontosProximos;
      this.buscaPorRegiaoMapService = BuscaPorRegiaoMapService;
      this.advisorRotogramasService = AdvisorRotogramasService;
      this.location = $location;
      this.isDemo = this.location.search().demo;
      this.advisorRotogramasService.isDemo = this.isDemo;
      this.user = null;

      this.hasRoleUpdateAdvisorSuggestion = false;
      this.suggestions = [];

      this.initPermissions();
      this.pontosReferenciaMenuService.idMapa = this.scope.idMapa;
      this.scope.$on('initPontoReferencia', (e, mapa, historico = []) => {
        this.pontosProximosService.init(historico);
        this.init(mapa);
      });
      this.scope.$on('layerVisibilityHasChanged', (e, entityType, shouldShow) => {
        if (this.mapLayerConstant.PONTO_REFERENCIA === entityType && this.pontosReferenciaService.roleVisualizar) {
          this.refreshPontosDeReferencia(shouldShow);
        }
        if (this.mapLayerConstant.CERCA_ELETRONICA === entityType && this.cercaEletronicaService.roleVisualizar) {
          this.refreshCercasEletronicas(shouldShow);
        }
      });
      this.alertaZoomCercaEletronica = 'ce.mapa.common.mapControlDirective.alertaZoomCercaEletronica';
      this.alertaZoomPontoReferencia = 'ce.mapa.common.mapControlDirective.alertaZoomPontoReferencia';
      if (this.scope.onInit) {
        this.scope.onInit();
      }
    }

    initPermissions() {
      this.authenticator.getUser().then(user => {
        this.user = user;
        this.pontosReferenciaService.roleCadastrar = user.permissoesSolucao.includes('PONTO_REFERENCIA_EDITAR');
        this.pontosReferenciaService.roleVisualizar = user.permissoesSolucao.includes('PONTO_REFERENCIA_VISUALIZAR');
        this.pontosReferenciaService.roleVisualizarRotograma = user.permissoesSolucao.includes('ROTOGRAMA_VISUALIZAR');
        this.cercaEletronicaService.roleCadastrar = user.permissoesSolucao.includes('CERCA_ELETRONICA_EDITAR');
        this.cercaEletronicaService.roleVisualizar = user.permissoesSolucao.includes('CERCA_ELETRONICA_VISUALIZAR');
        this.pontosReferenciaService.user = user.plain();
        this.hasRoleUpdateAdvisorSuggestion = user.permissoesSolucao.includes('ROTOGRAMA_EDITAR') && user.permissoesSolucao.includes('ADVISOR_ROTOGRAMA_EDITAR');
      });
    }

    init(mapa) {
      if (this.pontosReferenciaService.roleVisualizar) {
        this.pontosReferenciaMapService.setVariaveis();

        this.pontosReferenciaMapService.setMap(mapa);
        this.pontosReferenciaMenuService.setFABDefault();
        this.pontosReferenciaMenuService.tabelaPontosProximosClose();

        if (this.pontosReferenciaService.roleCadastrar) {
          this.pontosReferenciaMapService.iniciaEventos();
        }

        this.pontosReferenciaMapService.eventoGeral();
        if (this.shouldRenderMarkers(this.mapLayerConstant.PONTO_REFERENCIA)) {
          this.loadPontosDeReferencia();
        }
      }

      if (this.cercaEletronicaService.roleVisualizar) {
        this.cercaEletronicaMapService.setMap(mapa);
        this.pontosReferenciaMenuService.setFABDefault();
        this.pontosReferenciaMenuService.tabelaPontosProximosClose();

        if (this.cercaEletronicaService.roleCadastrar) {
          this.cercaEletronicaMapService.iniciaEventosEdicao();
        }
        this.cercaEletronicaMapService.iniciaEventosVisualizacao();

        if (this.shouldRenderMarkers(this.mapLayerConstant.CERCA_ELETRONICA)) {
          this.loadCercasEletronica();
        }
      }

      if (this.hasRoleUpdateAdvisorSuggestion) {
        this.loadRotogramaSuggestions();
      }

      this.buscaPorRegiaoMapService.setMap(mapa);
      if (this.isDemo) {
        this.onboarding.startOnboarding(this.idBuscaPorRegiao);
      }
    }

    refreshPontosDeReferencia(shouldShow) {
      if (shouldShow) {
        this.loadPontosDeReferencia();
      } else {
        this.pontosReferenciaMapService.clearMarkers();
      }
    }

    loadPontosDeReferencia() {
      this.q.all([
        this.pontosReferenciaService.getListPontoReferencia(),
        this.pontosCategoriaReferenciaService.getListCategoriasPontoReferencia()
      ])
      .then(([pontoReferencia, categorias]) =>
        this.pontosReferenciaMapService.setPontosReferencia(pontoReferencia.data, categorias, this.scope.idPonto))
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.mapControlDirective.erroCarregarPontos'),
          appendTo: '.alerta-aqui-ponto'
        });
      });
    }

    loadRotogramaSuggestions() {
      this.advisorRotogramasService.getMany().then(res => {
        this.suggestions = res.data;
        if (this.suggestions.length > 0) {
          this.loadRotogramaToast();
        }
      });
    }

    loadRotogramaToast() {
      this.authenticator.getUser().then(user => {
        const userUltimoAcesso = this.window.localStorage.getItem('toast-advisor');
        if (!userUltimoAcesso || userUltimoAcesso !== user.ultimoAcesso) {
          this.createToastElement();
        }
      });
    }

    createToastElement() {
      /* eslint-disable */
      if (!document.querySelector('vfwc-toast-arrow')) {
        const toastContainer = document.querySelector('#toast-advisor'),
        toastElement = document.createElement('vfwc-toast-arrow');
        /* eslint-enable */
        toastElement.text = this.translate.instant('ce.mapa.common.modal.toastAdvisor.msg',
          {x: this.suggestions.length}
        );
        toastContainer.appendChild(toastElement);
        this.setOnCloseToastAdvisor(toastElement);
      }
    }

    setOnCloseToastAdvisor(toastElement) {
      toastElement.addEventListener('onClose', (event) => {
        if (event.detail && event.detail.toastClosed) {
          this.setToastAdvisorInLocalStorage();
        }
      });
    }

    setToastAdvisorInLocalStorage() {
      this.authenticator.getUser().then(user => {
        this.window.localStorage.setItem('toast-advisor', user.ultimoAcesso);
      });
    }

    refreshCercasEletronicas(shouldShow) {
      if (shouldShow) {
        this.loadCercasEletronica();
      } else {
        this.cercaEletronicaMapService.clearMarkers();
      }
    }

    loadCercasEletronica() {
      this.cercaEletronicaService.getListCercaEletronica()
      .then(responseCercasEletronicas => {
        this.cercaEletronicaMapService.setCercasEletronicas(responseCercasEletronicas.data);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.mapControlDirective.erroCarregarCercas'),
          appendTo: '.alerta-aqui-ponto'
        });
      });
    }

    limparFiltro() {
      this.pontosReferenciaFiltroService.limparFiltro();
      this.pontosReferenciaMapService.recarregaPontos();
      this.cercaEletronicaMapService.mostrarCercasBoundsAtual();
    }

    activeDrawerStyle() {
      return this.pontosReferenciaMenuService.drawerBusca || this.pontosReferenciaMenuService.drawerAdvisor ?
        'active' :
        '';
    }

    shouldRenderMarkers(entity) {
      const preferences = this.mapasUtil.getLayersVisibilityLocalStorage(this.user.id);
      if (preferences) {
        return !preferences.some((l) => l.name === entity && l.shouldShow === false);
      }
      return true;
    }
  }

  angular
    .module('mapControlModule')
    .controller('MapControlDirectiveCtrl', MapControlDirectiveCtrl)
    .directive('mapControl', mapControlDirective);
}());
