(function () {
  'use strict';

  class AgrupamentosRotogramasList {
    constructor(CadastroRotogramaService, $translate) {
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.translate = $translate;
    }

    devePermitirCriacaoGrupos() {
      return angular.isDefined(this.cadastroRotogramaService.agrupamentos) &&
        this.cadastroRotogramaService.agrupamentos.length < 5 &&
        this.cadastroRotogramaService.getRotogramas().length < 5 &&
        this.cadastroRotogramaService.agrupamentoAtivo.rotogramas.length > 0;
    }

    getTooltipApoioCadastro() {
      return this.translate.instant('ce.mapa.common.modal.agrupamentoRotogramas.tooltipApoioCadastro');
    }
  }

  angular
    .module('rotogramaModule')
    .component('agrupamentosRotogramasList', {
      controller: AgrupamentosRotogramasList,
      bindings: {
      },
      templateUrl: 'directives/map-control/rotograma/agrupamentos-rotogramas-list.tpl.html'
    });
}());
