(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.historicoAlerta', {
      url: '/historico-alerta?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'historico-alerta/historico-alerta.tpl.html',
          controller: 'HistoricoAlertaCtrl',
          controllerAs: 'historicoAlerta'
        }
      },
      data: {
        titulo: 'ce.analise.alertas.titulo',
        role: 'HISTORICO_ALERTA_VISUALIZAR'
      }
    });
  }

  angular
    .module('historicoAlerta')
    .config(pageConfig);
}());
