(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.velocidadeRpm', {
      url: '/relatorios/velocidade-rpm?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/velocidade-rpm/velocidade-rpm.tpl.html',
          controller: 'VelocidadeRpmCtrl',
          controllerAs: 'velocidadeRpm'
        }
      },
      data: {
        titulo: 'ce.analise.velocidadeXrpm.titulo',
        role: 'ANALISE_VELOCIDADE_RPM_PARABRISA_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.velocidadeRpm')
    .config(pageConfig);
}());
