(function () {
  'use strict';

  /* global _ */
  const status = {
        NOVA: {
          id: 'NOVA',
          nivel: 0,
          descricao: 'ce.enum.ocorrencia.nova.descricao',
          title: 'ce.enum.ocorrencia.nova.titulo'
        },
        EM_TRATATIVA: {
          id: 'EM_TRATATIVA',
          nivel: 1,
          descricao: 'ce.enum.ocorrencia.emTratativa.descricao',
          title: 'ce.enum.ocorrencia.emTratativa.titulo'
        },
        RESOLVIDA: {
          id: 'RESOLVIDA',
          nivel: 2,
          descricao: 'ce.enum.ocorrencia.resolvida.descricao',
          title: 'ce.enum.ocorrencia.resolvida.titulo'
        }
      },
      funcoes = {
        getStatusById: (id) => status[id],
        getStatusObject: () => status,
        getStatus: () => _.values(status),
        getKeys: () => Object.keys(status)
      };

  angular
    .module('enumModule')
    .constant('OcorrenciasEnum', Object.assign({}, status, funcoes));
}());
