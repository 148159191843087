(function () {
  'use strict';

  function inputPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/pesquisa-ponto-referencia/filtros-ponto-referencia/input-ponto-referencia/input-ponto-referencia.tpl.html',
      controller: 'InputPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class InputPontoReferenciaCtrl {
    constructor($scope, PontosReferenciaFiltro, PontosReferenciaMap, CercaEletronicaMapService) {
      this.scope = $scope;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.cercaEletronicaMapService = CercaEletronicaMapService;

      this.pesquisa = '';
      this.listaItem = this.pontosReferenciaFiltroService.getFiltroByKeyOrDefault('palavra', {itensEscondidos: []}).itensEscondidos;
      this.validaPontos = false;
    }

    adicionarItem() {
      const pesquisa = this.pesquisa ? this.pesquisa.toLowerCase() : '';
      if (pesquisa && this.verificaLista(pesquisa)) {
        this.listaItem.push(pesquisa);
        this.pesquisa = '';
        this.validaPontos = false;
        this.aplicarFiltro();
      } else {
        this.validaPontos = true;
      }
    }

    removeItem(i) {
      this.listaItem = this.listaItem.filter((item, index) => index !== i);
      this.aplicarFiltro();
    }

    verificaLista(pesquisa) {
      return angular.isUndefined(this.listaItem.find(x => x === pesquisa));
    }

    aplicarFiltro() {
      this.pontosReferenciaFiltroService.salvarFiltroItensEscondidos(this.pontosReferenciaFiltroService.filtroSelecionado.key, this.listaItem);
      this.pontosReferenciaMapService.recarregaPontos();
      this.cercaEletronicaMapService.mostrarCercasBoundsAtual();
    }
  }

  angular
    .module('mapControlModule')
    .controller('InputPontoReferenciaCtrl', InputPontoReferenciaCtrl)
    .directive('inputPontoReferenciaDirective', inputPontoReferenciaDirective);
}());
