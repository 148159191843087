(function () {
  'use strict';

  class ModalDetalhesRotogramaCtrl {
    constructor(PontosReferencia, AlertMessage, ControleVelocidadeRotograma, $translate) {
      this.pontoReferenciaService = PontosReferencia;
      this.alertMessage = AlertMessage;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.translate = $translate;
    }

    $onInit() {
      this.velocidadePadrao = this.resolve.velocidade;
      this.status = this.resolve.status;
      this.pontoReferenciaService.getRotograma(this.resolve.id)
      .then(data => {
        this.model = data.plain();
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.modalDetalhesRotograma.errorCarregarRotograma'),
          appendTo: '.alerta-aqui-rotograma-ponto'
        });
      });
    }

    getDescricao() {
      if (this.status === this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR.value) {
        return this.translate.instant('ce.mapa.common.modal.modalDetalhesRotograma.descricaoVelocidadeAnterior');
      }
      return `${this.translate.instant('ce.mapa.common.modal.modalDetalhesRotograma.descricaoPadrao')}: <strong>${this.velocidadePadrao} km/h</strong>`;
    }
  }

  angular
    .module('mapControlModule')
    .component('modalDetalhesRotograma', {
      controller: ModalDetalhesRotogramaCtrl,
      templateUrl: 'directives/map-control/rotograma-ponto-referencia/modal-detalhes-rotograma.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
