(function () {
  'use strict';

  angular
    .module('relatorios.ocorrenciaDetalhes', [
      'ui.router',
      'ocorrencia.component',
      'quadroOcorrencias',
      'httpServiceV3gModule'
    ]);
}());
