(function () {
  'use strict';

  angular
    .module('resumoPeriodoModule', [
      'enumModule',
      'pascalprecht.translate',
      'ModuleEventos',
      'ServiceVfiltroModule',
      'ConstantModule'
    ]);
}());
