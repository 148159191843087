(function () {
  'use strict';

  class ModalInfoEventos {
    constructor($uibModalInstance, MapasUtil) {
      this.modalInstance = $uibModalInstance;
      this.tiles = MapasUtil.tiles;
      this.defaults = MapasUtil.defaults;
      this.center = {
        lat: -23.310229,
        lng: -51.170495,
        zoom: 12
      };
      this.markers = {};
      this.geoJson = {};
    }

    close() {
      this.modalInstance.close();
    }
  }

  angular
    .module('ModalEventos')
    .controller('ModalInfoEventosCtrl', ModalInfoEventos);
}());
