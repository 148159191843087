(function () {
  'use strict';

  function menuAdicionarPontosProximosDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuAdicionarPontosProximosCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuAdicionarPontosProximosCtrl {
    constructor($timeout, PontosReferenciaTools, PontosReferenciaMap, PontosReferenciaMenu, PontosReferencia, PontosProximos) {
      this.timeout = $timeout;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.pontosProximosService = PontosProximos;
      this.adicionarNovoPonto();
    }

    adicionarNovoPonto() {
      let buttonsPrincipal = {
            active: 'fa fa-thumb-tack',
            resting: 'fa fa-thumb-tack'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'default',
                funcao: this.pontosReferenciaMapService.cancelarPonto.bind(this.pontosReferenciaMapService)
              }
          ];
      this.pontosReferenciaMenuService.menuOpen();
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMapService.setPontoProximo();
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuAdicionarPontosProximosCtrl', MenuAdicionarPontosProximosCtrl)
    .directive('menuAdicionarPontosProximosDirective', menuAdicionarPontosProximosDirective);
}());
