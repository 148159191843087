(function () {
  'use strict';

  /* @ngdoc object
  * @name mapasGrid
  * @description
  *
  */
  angular
    .module('mapaCalor', [
      'ui.router',
      'restangular',
      'ModuleMapasUtil',
      'angularMoment',
      'alert-message',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule',
      'leaflet-directive',
      'v3gAuthenticatorModule',
      'AlertasModule',
      'pascalprecht.translate'
    ]);
}());
