(function () {
  'use strict';
  /* global Intl */
  class HistoricoComandoCtrl {
    constructor(HistoricoComando, $httpParamSerializer, $filter, moment, ServiceVfiltro, ObjetosVfiltroService,
      FactoryVfiltro, Keys, VtabelaFactory, uiGridConstants, StatusComandoEnum, $translate, UrlConfigService) {
      this.historicoComandoService = HistoricoComando;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.moment = moment;
      this.httpParamSerializer = $httpParamSerializer;
      this.filter = $filter;
      this.statusComando = StatusComandoEnum;
      this.timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;

      this.vtabela = null;
      this.exportsPdf = false;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          time: true,
          dateLimit: 1,
          defaultValues: {
            hoje: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          name: 'uoId',
          key: this.keys.uo.name,
          size: 12
        },
        {
          name: 'veiculoIds',
          required: true,
          key: this.keys.veiculos.name,
          size: 12
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.user = this.serviceVfiltro.factoryVfiltro.user;
        this.consultar();
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'historicoComando', 'v1');
      })
      .catch(() => {
        this.serviceVfiltro.openFiltroDetalhado = true;
      });
    }

    initVtabela() {
      let objVtabela = {
        appScopeProvider: this,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        multiSelect: false,
        enableSelectAll: false,
        isRowSelectable: () => {
          return false;
        },
        columnDefs: [
            {
              name: 'veiculo',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.veiculo',
              type: 'string',
              pinnedLeft: true,
              enableHiding: false,
              filterCellFiltered: true
            },
            {
              name: 'usuario',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.usuario',
              enableHiding: false,
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'Sem identificação\' | splitComposedLabels:true'
            },
            {
              name: 'comando',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.comando',
              enableHiding: false,
              filterCellFiltered: true
            },
            {
              name: 'dataEnvio',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.dataHoraEnvio',
              type: 'date',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm\''
            },
            {
              name: 'dataConfirmacao',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.dataHoraConfirmacao',
              type: 'date',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm\' | withLabelToUndefined:\'-\''
            },
            {
              name: 'status',
              displayName: 'ce.mapa.localizacaoDaFrota.historicoComando.colunas.status',
              type: 'date',
              filterCellFiltered: true
            }
        ]
      };
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        veiculo: this.filter('prefixoPlaca')(item.veiculo),
        usuario: item.login,
        comando: item.comando.nome,
        dataEnvio: item.dataHoraCriacao,
        dataConfirmacao: item.dataHoraConfirmacao,
        status: this.translate.instant(this.statusComando[item.statusDescricao])
      };
    }

    consultar() {
      this.historicoComandoService.get(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))
        .then(data => this.setInfoTable(data))
        .catch(() => this.vtabela.setError());
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    getParams() {
      return this.serviceVfiltro.getFiltroBackendNovo(this.filtro);
    }

    getUrlCsv() {
      return `/comando/historicos/csv?timezone=${this.timezone}&${this.httpParamSerializer(this.getParams())}`;
    }
  }

  angular
  .module('relatorios.historicoComando')
  .controller('HistoricoComandoCtrl', HistoricoComandoCtrl);
}());
