(function () {
  'use strict';

  angular
    .module('historicoTimeline', [
      'ui.router',
      'restangular',
      'historico-timeline-dropdown-multiselect',
      'FeatureFlagModule',
      'ConstantModule',
      'ModuleEventos',
      'ModuleMapasUtil',
      'angularMoment',
      'alert-message',
      'leaflet-directive',
      'mapas',
      'enumModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'reprodRotasModule',
      'pascalprecht.translate',
      'urlConfigModule',
      'v3gAuthenticatorModule',
      'v3gTrimbleFilters',
      'httpServiceV3gModule',
      'AuditingEventsModule'
    ])
    .factory('HistoricoVeiculo', Restangular => Restangular.service('sensor/relatorio/historico-veiculo'));
}());
