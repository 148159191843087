(function () {
  'use strict';

  const ANGULO_MINIMO_RADIANOS = 0.610865,
      PRECISAO_UNITARIA = 1,
      PRECISAO_CENTIMETROS = 3;

  /* global geolib, _, L */
  class RotogramaMapService {
    constructor(PosicoesRotogramas, ControleVelocidadeRotograma, $timeout) {
      this.posicoesRotogramas = PosicoesRotogramas;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.timeout = $timeout;
      this.rotogramaMarkers = [];
    }

    setLatLngPontoReferencia(latLngBaseEsquerda, latLngTopoDireita) {
      this.pontoReferenciaLatLngs = {
        latLngBaseEsquerda: {lat: latLngBaseEsquerda[0], lng: latLngBaseEsquerda[1]},
        latLngTopoDireita: {lat: latLngTopoDireita[0], lng: latLngTopoDireita[1]}
      };
    }

    adicionarMarker(marker, posicao) {
      if (posicao.key === this.posicoesRotogramas.CENTRO.key) {
        this.centro = marker;
      } else {
        this.rotogramaMarkers.push({marker, posicao, posicaoAnterior: marker.getLatLng()});
      }
    }

    encerrarCadastro() {
      this.rotogramaMarkers = [];
      this.pontoReferenciaLatLngs = null;
    }

    forcarLatLngValida(dragEvent, posicaoRotograma) {
      const markerRotograma = _.find(this.rotogramaMarkers, r => r.posicao.key === posicaoRotograma.key);
      if (this.isTopoOuBase(posicaoRotograma) && this.isLongitudeValida(markerRotograma.marker.getLatLng().lng)) {
        markerRotograma.marker.setLatLng([dragEvent.oldLatLng.lat, markerRotograma.marker.getLatLng().lng]);
        markerRotograma.posicaoAnterior = markerRotograma.marker.getLatLng();
      } else if (!this.isTopoOuBase(posicaoRotograma) && this.isLatitudeValida(markerRotograma.marker.getLatLng().lat)) {
        markerRotograma.marker.setLatLng([markerRotograma.marker.getLatLng().lat, dragEvent.oldLatLng.lng]);
        markerRotograma.posicaoAnterior = markerRotograma.marker.getLatLng();
      } else {
        markerRotograma.marker.setLatLng(markerRotograma.posicaoAnterior);
      }
    }

    isTopoOuBase(posicaoRotograma) {
      return posicaoRotograma === this.posicoesRotogramas.TOPO || posicaoRotograma === this.posicoesRotogramas.BASE;
    }

    isLongitudeValida(longitude) {
      return Math.abs(this.pontoReferenciaLatLngs.latLngBaseEsquerda.lng) >= Math.abs(longitude) &&
        Math.abs(this.pontoReferenciaLatLngs.latLngTopoDireita.lng) <= Math.abs(longitude);
    }

    isLatitudeValida(latitude) {
      return Math.abs(this.pontoReferenciaLatLngs.latLngBaseEsquerda.lat) >= Math.abs(latitude) &&
        Math.abs(this.pontoReferenciaLatLngs.latLngTopoDireita.lat) <= Math.abs(latitude);
    }

    forcarAnguloValido(posicaoRotograma, latLngInicial) {
      const markerRotograma = _.find(this.rotogramaMarkers, r => r.posicao.key === posicaoRotograma.key),
          markersAdjacentes = this.getMarkersAdjacentes(posicaoRotograma),
          markerComAnguloInvalido = _.find(
            markersAdjacentes,
            markerAdjacente => !this.isAnguloValido(markerRotograma.marker, markerAdjacente.marker)
          );
      if (angular.isDefined(markerComAnguloInvalido)) {
        markerRotograma.marker.setLatLng(latLngInicial);
        this.exibirPopupAnguloInvalido(markerRotograma.marker);
      }
    }

    exibirPopupAnguloInvalido(marker) {
      marker.bindPopup(
        L.popup({closeOnClick: false, offset: L.point(0, -5)}).setContent('<p>Os rotogramas estão muito próximos!</p>')
      ).openPopup();
      marker.off('click');
      if (this.timeoutPopupClose) {
        this.timeout.cancel(this.timeoutPopupClose);
      }
      this.timeoutPopupClose = this.timeout(() => {
        marker.closePopup();
        this.timeoutPopupClose = null;
      }, 2500);
    }

    /**
     * Retorna o ângulo em graus de um dado rotograma em relação ao centro do ponto de referência e um ponto de ângulo 0
     *
     * Na verificação é utilizada a Lei dos Cossenos, cuja forma mais conhecida é c² = a² + b² − 2ab cos(C),
     * aqui rearranjada na forma cos(C) = (a² + b² − c²) / (2ab).
     *
     * @param {Rotograma} rotograma rotograma com marker cujo ângulo será calculado
     * @return {Number} ângulo do rotograma a partir do centro do ponto de referência, em graus. Null caso o rotograma
     * passado seja falsy, não tenha marker ou seja o rotograma interno.
     */
    getAngulo(rotograma) {
      if (rotograma && rotograma.marker && !angular.equals(rotograma.marker.getLatLng(), this.centro.getLatLng())) {
        const latLngAnguloZero = L.latLng(this.centro.getLatLng().lat + 1, this.centro.getLatLng().lng),
            a = this.getDistance(latLngAnguloZero, this.centro.getLatLng()),
            b = this.getDistance(rotograma.marker.getLatLng(), this.centro.getLatLng()),
            c = this.getDistance(rotograma.marker.getLatLng(), latLngAnguloZero);
        let cos = (Math.pow(a, 2) + Math.pow(b, 2) - Math.pow(c, 2)) / (2 * a * b);
        if (cos > 1 || cos < -1) {
          cos = Math.round(cos);
        }
        return Math.abs(rotograma.marker.getLatLng().lng) < Math.abs(this.centro.getLatLng().lng) ?
            Math.acos(cos) * 180 / Math.PI : 360 - Math.acos(cos) * 180 / Math.PI;
      }
      return null;
    }

    /**
     * Verifica se o ângulo entre dois markers em relação ao centro do ponto de referência é maior que 35°.
     *
     * Na verificação é utilizada a Lei dos Cossenos, cuja forma mais conhecida é c² = a² + b² − 2ab cos(C),
     * aqui rearranjada na forma cos(C) = (a² + b² − c²) / (2ab).
     *
     * @param {L.Marker} markerAlvo Marker cujo ângulo em relação ao centro e ao marker adjacente será analisado.
     * @param {L.Marker} markerAdjacente Marker adjacente ao marker alvo.
     * @return {boolean} true se o ângulo entre o marker e seu marker adjacente for maior que 35°, do contrário false.
     */
    isAnguloValido(markerAlvo, markerAdjacente) {
      const a = this.getDistance(markerAdjacente.getLatLng(), this.centro.getLatLng()),
          b = this.getDistance(markerAlvo.getLatLng(), this.centro.getLatLng()),
          c = this.getDistance(markerAlvo.getLatLng(), markerAdjacente.getLatLng()),
          cos = (Math.pow(a, 2) + Math.pow(b, 2) - Math.pow(c, 2)) / (2 * a * b);
      return Math.acos(cos) > ANGULO_MINIMO_RADIANOS;
    }

    getMarkersAdjacentes(posicao) {
      return this.rotogramaMarkers.filter(m => posicao.ladosAdjacentes.indexOf(m.posicao) !== -1);
    }

    getDistance(pontoA, pontoB) {
      return geolib.getDistance(
        {latitude: pontoA.lat, longitude: pontoA.lng},
        {latitude: pontoB.lat, longitude: pontoB.lng},
        PRECISAO_UNITARIA,
        PRECISAO_CENTIMETROS
      );
    }

    buildMarkerRotograma(map, rotograma, grupoVelocidade) {
      let html;
      if (rotograma.status === this.controleVelocidadeRotograma.CONFIGURADO.value ||
          rotograma.status === this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value) {
        html = `<strong>${grupoVelocidade.velocidade}</strong><small>km/h</small>`;
      } else {
        html = '';
      }
      const marker = L.marker([rotograma.latitude, rotograma.longitude], {
        icon: L.divIcon({
          className: `limite-velocidade-icone-${this.getRotacaoRotograma(rotograma.status, rotograma.grau)} ` +
            `${this.controleVelocidadeRotograma[rotograma.status].classe}`,
          html
        }),
        opacity: map.getZoom() >= 15 ? 1 : 0,
        idRotograma: rotograma.id,
        velocidade: grupoVelocidade && grupoVelocidade.velocidade || null,
        status: rotograma.status,
        id: rotograma.id
      });
      return marker;
    }

    getRotacaoRotograma(tipoRotograma, angulo) {
      if (tipoRotograma === this.controleVelocidadeRotograma.CONTROLA_VELOCIDADE_INTERNA.value) {
        return -1;
      } else if (angulo > 45 && angulo <= 135) {
        return 90;
      } else if (angulo > 135 && angulo <= 225) {
        return 180;
      } else if (angulo > 225 && angulo <= 315) {
        return 270;
      }
      return 360;
    }

    renderizarPontoReferencia(map, latLngsExternos, cor) {
      map.addLayer(L.rectangle([
        [latLngsExternos.latlngLeftBottom.latitude, latLngsExternos.latlngLeftBottom.longitude],
        [latLngsExternos.latlngRightTop.latitude, latLngsExternos.latlngRightTop.longitude]
      ], {
        color: cor,
        weight: 0,
        fillOpacity: 0.4,
        editing: false
      }));
    }
  }

  angular
    .module('rotogramaModule')
    .service('RotogramaMapService', RotogramaMapService);
}());
