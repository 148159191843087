(function () {
  'use strict';

  class ModalSimuladorCtrl {
    constructor($uibModalInstance, ValidateUtil, ModalInfoConfirmService, HttpServiceV3g, $scope,
      moment, configsOcorrencia, AlertMessage, $translate) {
      this.modalInstance = $uibModalInstance;
      this.validadeService = ValidateUtil;
      this.modalInfoConfirmService = ModalInfoConfirmService;
      this.scope = $scope;
      this.moment = moment;
      this.model = {};
      this.httpService = HttpServiceV3g;
      this.configsOcorrencia = configsOcorrencia;
      this.alertMessage = AlertMessage;
      this.translate = $translate;
      this.dateOptions = {
        formatYear: 'yy',
        maxDate: this.moment().startOf('day').subtract(1, 'days'),
        minDate: this.moment().startOf('day').subtract(7, 'days'),
        startingDay: 0,
        showWeeks: false
      };

      this.simularClicked = false;
    }

    cancel() {
      this.modalInstance.dismiss();
    }

    simular() {
      if (this.scope.dateForm.data.$valid && this.dateInRange(this.model.data)) {
        const simuladorData = this.moment(this.model.data).format('YYYY-MM-DD');
        this.httpService.post(`/alerta/ocorrencias/simular/${simuladorData}`, this.configsOcorrencia)
          .then((res) => {
            this.modalInstance.close({ocorrencias: res.data.mediaOcorrencias, data: this.model.data});
          })
          .catch(() => {
            this.modalInstance.dismiss('ERRO_SIMULADOR');
          });
      } else {
        this.simularClicked = true;
      }
    }

    getMsgError(inputScope) {
      if (angular.isDefined(inputScope)) {
        if (inputScope.$invalid && (inputScope.$touched || this.simularClicked)) {
          return this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modalSimuladorDeOcorrencias.comoFunciona.dataObrigatoria');
        }
        if (this.model.data && !this.dateInRange(this.model.data)) {
          return this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.ocorrencias.salvar.modalSimuladorDeOcorrencias.comoFunciona.dataPeriodoPermitido');
        }
      }
      return null;
    }

    dateInRange(inputDate) {
      const maxDate = this.moment().startOf('day').subtract(1, 'days'),
          minDate = this.moment().startOf('day').subtract(7, 'days');
      return this.moment(inputDate).isBetween(minDate, maxDate, null, []);
    }

  }

  angular
    .module('SubpaginasEventos')
    .controller('ModalSimuladorCtrl', ModalSimuladorCtrl);
}());
