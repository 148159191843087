/* global document */
(function () {
  'use strict';

  class SnapshotLinkCtrl {
    constructor(Authenticator, ServiceVfiltro, $window, UrlConfigService, HttpServiceV3g, $httpParamSerializer) {
      this.authenticator = Authenticator;
      this.seePhotoLinkElement = null;
      this.window = $window;
      this.urlConfigService = UrlConfigService;
      this.httpService = HttpServiceV3g;
      this.httpParamSerializer = $httpParamSerializer;

      this.uoId = angular.fromJson(this.window.localStorage.getItem(`vfiltro-${ServiceVfiltro.factoryVfiltro.user.id}`)).uo;
    }

    $postLink() {
      this.seePhotoLinkElement = document.createElement('vfleets-see-photo'); // eslint-disable-line
      this.buildSeePhotoLinkElement();
    }

    /**
     * Necessário a utilização do $doCheck pois a div do template não era encontrado
     * na DOM na inicialização do component, fazendo com que o querySelector viesse
     * sempre null. Por isso foi criado condicionais para que depois que se encontre
     * uma vez o elemento na DOM não precisar mais buscar, para evitar o excesso de processamento
     * devido ao $doCheck que ocorre a cada modificação existente.
     */
    $doCheck() {
      const seePhotoLinkContainer = document.querySelector(`#${this.prefixContainer}-${this.alertId ? this.alertId : this.idSnapshot}`); // eslint-disable-line
      if (seePhotoLinkContainer && this.seePhotoLinkElement && seePhotoLinkContainer.innerHTML === '') {
        seePhotoLinkContainer.appendChild(this.seePhotoLinkElement);
      }
    }

    buildSeePhotoLinkElement() {
      const params = this.httpParamSerializer({
        s3key: this.s3key,
        uoVeiculo: this.uoVeiculo
      });
      this.seePhotoLinkElement.isWithVigenciaManual = this.showOptionVigenciaManual();
      this.seePhotoLinkElement.hasPermissionEditMotorista = this.hasPermissionEditMotorista();
      this.seePhotoLinkElement.imageUrl = `/motorista/reconhecimento-facial/v2/view?${params}`;
      this.seePhotoLinkElement.snapshotId = this.idSnapshot;
      this.seePhotoLinkElement.hasPermissionViewVideo = this.hasPermissionViewVideo();
      this.seePhotoLinkElement.ignitionsVehicle = this.buildIgnitionsVehicle();
      this.seePhotoLinkElement.vigenciaAtual = this.vigenciaAtual ? this.vigenciaAtual : this.buildVigenciaPopUp();
      this.seePhotoLinkElement.uoId = this.uoId;
      this.seePhotoLinkElement.customButtonText = this.textButton;
      this.seePhotoLinkElement.addEventListener('vigenciaManualCadastrada', e => {
        if (e.detail.reload) {
          if (this.isHistoricoDetalhado) {
            this.window.location.reload();
          } else {
            this.onCloseDialog();
          }
        }
      });
    }

    buildVigenciaPopUp() {
      return angular.fromJson(this.vigenciaAtualPopup);
    }

    showOptionVigenciaManual() {
      return this.podeAbrirVigenciaManual;
    }

    buildIgnitionsVehicle() {
      return {
        veiculoId: this.idVeiculo,
        dateIgnitionOn: this.dateIgnitionOn,
        dateIgnitionOff: this.dateIgnitionOff
      };
    }

    getUrl() {
      return `${this.urlConfigService.getApiGatewayUrl()}/motorista/reconhecimento-facial/view/${this.idSnapshot}?datetimeReferencia=${this.dateIgnitionOn}`;
    }

    hasPermissionViewVideo() {
      return this.authenticator.hasRole('VIDEO_VISUALIZAR');
    }

    hasPermissionEditMotorista() {
      return this.authenticator.hasRole('MOTORISTA_EDITAR');
    }
  }

  angular
    .module('videoPlayer')
    .controller('SnapshotLinkCtrl', SnapshotLinkCtrl)
    .component('snapshotLink', {
      template: `<div id='{{$ctrl.prefixContainer}}-{{$ctrl.alertId ? $ctrl.alertId : $ctrl.idSnapshot}}'></div>`,
      controller: 'SnapshotLinkCtrl',
      bindings: {
        idSnapshot: '@',
        s3key: '@',
        prefixContainer: '@',
        dateIgnitionOn: '@',
        dateIgnitionOff: '@',
        podeAbrirVigenciaManual: '<',
        vigenciaAtual: '<',
        vigenciaAtualPopup: '@',
        idVeiculo: '@',
        uoVeiculo: '@',
        textButton: '@',
        alertId: '@',
        onCloseDialog: '&'
      }
    });
}());
