/* global document */
(function () {
  'use strict';
  class CustomPopupCtrl {
    constructor($window, GoogleTagManagerUtils, ServiceVfiltro) {
      this.window = $window;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.serviceVfiltro = ServiceVfiltro;
    }

    isValid(row) {
      return row.infos.some(item => item.value);
    }

    getMobileSize(size) {
      return size > 6 ? 12 : 6;
    }

    onClickTratarOcorrencia(ocorrenciaId) {
      this.sendClickGTM();
      this.setOcorrenciaIdInLocalStorage(ocorrenciaId);
    }

    sendClickGTM() {
      this.googleTagManagerUtils.sendEventClickGTM('localizacao_frota_ir_para_ocorrencias', this.serviceVfiltro.factoryVfiltro.user);
    }

    setOcorrenciaIdInLocalStorage(ocorrenciaId) {
      this.window.localStorage.setItem('localizacao-frota-go-to-quadro-ocorrencias-ocorrenciaId', ocorrenciaId);
    }

    onItemClick(item) {
      if (item.onClick && item.onClick.type === 'openVehicleHealthEvidences') {
        this.openVehicleHealthEvidencesDialog(item.onClick);
      }
    }

    openVehicleHealthEvidencesDialog({veiculoPosicao, initialTab, canDisplayLivestream}) {
      /* eslint-disable */
      const elementContainer = document.querySelector('#dialog-vehicle-health');
      const dialogOpener = document.createElement('vfwc-open-dialog-vehicle-health-evidences');
      /* eslint-enable */

      dialogOpener.usuario = this.serviceVfiltro.factoryVfiltro.user;
      dialogOpener.veiculo = veiculoPosicao;
      dialogOpener.hasLivestreamPermission = canDisplayLivestream;
      dialogOpener.initialTab = initialTab;

      elementContainer.appendChild(dialogOpener);
    }
  }

  angular
    .module('v3gCustomPopupModule', ['enumModule', 'ServiceVfiltroModule'])
    .component('customPopup', {
      controller: CustomPopupCtrl,
      templateUrl: 'directives/custom-popup/custom-popup.tpl.html',
      bindings: {
        infos: '='
      }
    });
}());
