(function () {
  'use strict';

  class CustomGridster {
    constructor($window) {
      this.window = $window;
      this.resizable = {
        enabled: false
      };
      this.draggable = {
        enabled: false
      };
    }

    setStatusGridster(item) {
      this.resizable.enabled = item;
      this.draggable.enabled = item;
    }

    getOptions(rowHeight) {
      return {
        minRows: 1,
        maxRows: 200,
        columns: 12,
        rowHeight,
        margins: [15, 15],
        mobileBreakPoint: 800,
        resizable: this.resizable,
        draggable: this.draggable,
        swapping: true
      };
    }

    getLocalStorage(name) {
      return angular.fromJson(this.window.localStorage.getItem(name));
    }

    setLocalStorage(name, item) {
      this.window.localStorage.setItem(name, angular.toJson(item));
    }

    removeLocalStorage(name) {
      this.window.localStorage.removeItem(name);
    }
  }

  angular
    .module('customGridsterModule', [
      'gridster'
    ])
    .service('CustomGridster', CustomGridster);
}());
