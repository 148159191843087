(function () {
  'use strict';

  angular
    .module('configEventos')
    .config(function ($stateProvider) {
      return $stateProvider.state('root.configEventos', {
        url: '/configuracoes-eventos/:tipoSubpagina?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'configuracoes/eventos/configuracoes-eventos.tpl.html',
            controller: 'ConfigEventosCtrl',
            controllerAs: 'configEventosCtrl'
          }
        },
        data: {
          titulo: 'ce.configuracao.eventos.titulo',
          tituloDescricao: '',
          role: ['CONFIGURACAO_ALERTA_VISUALIZAR', 'CONFIG_OCORRENCIA_VISUALIZAR', 'CONFIG_PESO_EVENTO_VISUALIZAR', 'CONFIG_DESCARTE_VISUALIZAR', 'ONE_PAGE_VISUALIZAR']
        }
      });
    });
}());
