(function () {
  'use strict';

  const BEFORE_COLON = new RegExp('(.+?' + ':'.replace(/[^\w\s]/g, '\\$&') + ')');

  class PopupService {
    constructor($filter, Eventos, $translate, MeasurementUnitConverterService, ConvertersService, Authenticator) {
      this.filter = $filter;
      this.Eventos = Eventos;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.convertersService = ConvertersService;
      this.limitLabel = this.translate.instant('ce.mapa.historicoDetalhado.limiteLabel');

      this.authenticator.getUser().then(user => {
        this.usuario = user;
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormatYearWithTwo(this.usuario.measurementUnits.dateTimeFormat);
        this.speedUnit = this.measurementUnitConverterService.getSpeedUnit(this.usuario.measurementUnits.speedMeasurement);
      });
    }

    build(posicao) {
      if (posicao.evento && posicao.evento.includes(this.translate.instant(this.Eventos.DESCIDA.tipo))) {
        return this.getGps(posicao.gps) + this.getData(posicao, this.dateTimeFormat + ' HH:mm:ss') +
          this.getEvento(posicao) + this.getGoogleMapsLink(posicao.latitude, posicao.longitude) +
          this.getStreetViewLink(posicao.latitude, posicao.longitude) + this.getPontoRef(posicao);
      }
      return this.getGps(posicao.gps) + this.getData(posicao, this.dateTimeFormat + ' HH:mm:ss') + this.getDuracao(posicao.duracao) +
        this.getMotorista(posicao.motorista) + this.getEvento(posicao) +
        this.getIdentificacaoMotorista(posicao.identificacaoMotorista) + this.getVelocidade(posicao) +
        this.getDistancia(posicao.distancia) + this.getLimiar(posicao.limiar, posicao.pontoReferencia) +
        this.getIntensidade(posicao) + this.getChuva(posicao) + this.getVeiculo(posicao) +
        this.getObd(posicao) + this.getCan(posicao) + this.getUo(posicao) +
        this.getSnapshotLink(posicao) + this.getGoogleMapsLink(posicao.latitude, posicao.longitude) +
        this.getStreetViewLink(posicao.latitude, posicao.longitude) + this.getPontoRef(posicao) + this.getEventoTic(posicao.meio);
    }

    getSnapshotLink(posicao) {
      const ignOn = posicao.data || '',
          ignOff = posicao.snapshotDataIgnicaoOff || '',
          hasVigencia = !!posicao.vigencia,
          isEventoAbertura = posicao.intensidade === 'Abertura de vigência',
          isEventoIgnOn = posicao.tipoPosicao === 'IGNICAO_LIGADA',
          isVigenciaRFID = hasVigencia && posicao.vigencia.origem === 'RFID',
          vigenciaNotRfidNotAbertura = hasVigencia && !isVigenciaRFID && !isEventoAbertura,
          tagVerFoto = !hasVigencia || isVigenciaRFID ? 'historico_detalhado_ver_foto' : undefined,
          tagEdicaoVigencia = hasVigencia && !isVigenciaRFID && isEventoAbertura ? 'historico_detalhado_editar_vigencia' : undefined,
          hasSnapshotNotVigencia = !!posicao.snapshotId && !hasVigencia && !isEventoIgnOn,
          clickGtm = this.getLinkGtm(tagVerFoto, tagEdicaoVigencia);

      if (!posicao.snapshotId || isVigenciaRFID && isEventoAbertura || vigenciaNotRfidNotAbertura || hasSnapshotNotVigencia) {
        return '';
      }

      return `<snapshot-link id-snapshot="${posicao.snapshotId}" date-ignition-on="${ignOn}" date-ignition-off="${ignOff}" pode-abrir-vigencia-manual="${posicao.podeAbrirVigenciaManual}" vigencia-atual-popup='${this.buildVigenciaPopUpJson(posicao.vigencia)}' id-veiculo="${posicao.veiculo.id}" uo-veiculo="${posicao.veiculo.uo.id}" s3key="${posicao.s3Key}" prefix-container="popup-container" ng-click="${clickGtm}"></snapshot-link><br/>`;
    }

    getLinkGtm(tagVerFoto, tagEdicaoVigencia) {
      if (!tagVerFoto && !tagEdicaoVigencia) {
        return '';
      }

      return `historicoTimeline.sendClickGTM('${tagVerFoto ? tagVerFoto : tagEdicaoVigencia}')`;
    }

    buildVigenciaPopUpJson(vigencia) {
      if (angular.isDefined(vigencia) && vigencia !== null) {
        const vigenciaObjeto = {
          inicio: vigencia.inicio,
          fim: vigencia.fim,
          motoristaCpf: vigencia.motoristaCpf,
          motoristaId: vigencia.motoristaId,
          motoristaNome: vigencia.motoristaNome,
          origem: vigencia.origem,
          uoId: vigencia.uoId,
          uuid: vigencia.uuid,
          veiculoId: vigencia.veiculoId
        };
        return angular.toJson(vigenciaObjeto);
      }
      return null;
    }

    getPontoRef(posicao) {
      if (angular.isUndefined(posicao.pontoProximo)) {
        return '';
      }

      let distancia = '',
          pontoRef = this.translate.instant('ce.mapa.common.modal.popupService.pontoRef');
      if (posicao.pontoProximo.distancia !== 0) {
        distancia = `</strong>${posicao.pontoProximo.distancia}m de <strong>`;
      }
      return `<br/><br/><i class="fa fa-map-marker"></i> <strong>${pontoRef} ${distancia}${posicao.pontoProximo.pontoReferencia.nome}</strong><br/>`;
    }

    getCan(posicao) {
      if (posicao.descricao !== this.translate.instant('ce.enum.eventos.diagnosticoCan')) {
        return '';
      }

      return `<strong>${this.translate.instant('ce.mapa.common.modal.popupService.codigoCan')}</strong> ${posicao.intensidade}
        <br/>
        <br/>
        ${this.translate.instant('ce.mapa.common.modal.popupService.esseCodigoFalha')}
        <br/>
        ${this.translate.instant('ce.mapa.common.modal.popupService.paraSaberMaisSimples')}
        <br/>
        <br/>`;
    }

    getUo(posicao) {
      if (posicao.veiculo && posicao.veiculo.uo && posicao.veiculo.uo.nome) {
        return `<strong data-testid="popup-uo">${this.translate.instant('ce.mapa.common.modal.popupService.uo')}</strong> ${posicao.veiculo.uo.nome}<br/>`;
      }
      return '';
    }

    getObd(posicao) {
      if (posicao.descricao !== this.translate.instant('ce.enum.eventos.diagnosticoObd')) {
        return '';
      }

      let obd = '';
      if (!!posicao.miscInfo && !!posicao.miscInfo.obdDesc) {
        obd = `<br/>${posicao.miscInfo.obdDesc}<br/>`;
      }

      return `<strong>${this.translate.instant('ce.mapa.common.modal.popupService.codigoObd')}</strong> ${posicao.intensidade}
        <a href="#" id="obd-mostrar-detalhes-${posicao.index}">(${this.translate.instant('ce.mapa.common.modal.popupService.maisInformacoes')})</a>
        <br/>
        <div id="obd-detalhes-${posicao.index}">
        ${obd}
        <br />
        ${this.translate.instant('ce.mapa.common.modal.popupService.esseCodigoFalha')}
        <br/>
        ${this.translate.instant('ce.mapa.common.modal.popupService.paraSaberMaisClicando')}
        <a href="http://dtcsearch.com/${posicao.intensidade}" target="_blank">${this.translate.instant('ce.mapa.common.modal.popupService.aqui')}</a>
        </div>
        <br/>`;
    }

    getVeiculo(posicao) {
      if (angular.isUndefined(posicao.prefixo) && angular.isUndefined(posicao.placa)) {
        return '';
      }

      const veiculo = this.filter('prefixoPlaca')(posicao);
      return `<strong data-testid="popup-placa">${this.translate.instant('ce.mapa.common.modal.popupService.veiculo')} </strong> ${veiculo} <br/>`;
    }

    getChuva(posicao) {
      if (!!posicao.miscInfo && !!posicao.miscInfo.sensorChuva) {
        return `<strong>${this.translate.instant('ce.mapa.common.modal.popupService.chovendo')} </strong> ${this.translate.instant('ce.mapa.common.modal.popupService.sim')} <br/>`;
      }

      return '';
    }

    getLimiar(limiar, pontoReferencia) {
      let ponto = '';
      if (angular.isUndefined(limiar) || limiar === '-') {
        return '';
      }

      if (pontoReferencia && pontoReferencia.id) {
        ponto = `<a href="#" id="popup-ponto-referencia-${pontoReferencia.id}" focus-ponto-referencia-directive="${pontoReferencia.id}">(${this.translate.instant('ce.mapa.common.modal.popupService.rotograma')})</a>`;
      }

      if (this.usuario.measurementUnits.speedMeasurement === 'MILES' && limiar && limiar.includes('Km/h/s')) {
        let limiarEmKm = parseFloat(limiar.replace('Km/h/s', '').replace('Limite: ', ''));
        limiar = `${this.limitLabel} ${this.convertersService.handleKmToMiles(limiarEmKm)} mph/s`;
      } else if (this.usuario.measurementUnits.speedMeasurement === 'MILES' && limiar && limiar.includes('Km/h')) {
        let limiarEmKm = parseFloat(limiar.replace('Km/h', '').replace('Limite: ', ''));
        limiar = `${this.limitLabel} ${this.convertersService.handleKmToMiles(limiarEmKm)} mph`;
      }

      if (this.usuario.measurementUnits.dateTimeFormat === 'AMERICAN' && limiar && limiar.includes('RPM')) {
        limiar = limiar.replace('Limite: ', this.limitLabel);
      }

      return `${limiar.replace(BEFORE_COLON, '<strong>$1</strong>')} ${ponto}<br/>`;
    }

    getIntensidade(posicao) {
      const isStringValida = (s) => angular.isString(s) && s !== '-' && s !== '',
          isEventoValido = (evento) => {
            return evento === this.Eventos.FRENAGEM_BRUSCA.tipo ||
              evento === this.Eventos.ACELERACAO_BRUSCA.tipo ||
              this.Eventos.isCurvaBrusca(evento) ||
              evento === this.Eventos.RPM.tipo;
          };

      if (this.usuario.measurementUnits.speedMeasurement === 'MILES' && posicao.intensidade && posicao.intensidade.includes('Km/h/s')) {
        let intensidadeEmKm = parseFloat(posicao.intensidade.replace('Km/h/s', ''));
        posicao.intensidade = `${this.convertersService.handleKmToMiles(intensidadeEmKm)} mph`;
      }

      if (isStringValida(posicao.intensidade) && isEventoValido(posicao.evento)) {
        return `<strong data-testid="popup-intensidade">${this.translate.instant('ce.mapa.common.modal.popupService.excesso')}</strong> ${posicao.intensidade.replace(BEFORE_COLON, '<strong>$1</strong>')} <br/>`;
      }
      return '';
    }

    getDistancia(distancia) {
      if (distancia) {
        const km = this.filter('km')(distancia);
        return `<strong data-testid="popup-distancia">${this.translate.instant('ce.mapa.common.modal.popupService.distancia')}</strong> ${km} <br/>`;
      }
      return '';
    }

    getVelocidade(posicao) {
      if (angular.isUndefined(posicao.velocidade)) {
        return '';
      }

      const isExcesso = posicao.descricao && !posicao.descricao.indexOf(this.translate.instant('ce.enum.common.excessoVelocidade')),
          isEventoDeVelocidadeInicio = posicao.descricao && (!posicao.descricao.indexOf(this.translate.instant('ce.enum.common.curvaBrusca')) ||
          !posicao.descricao.indexOf(this.translate.instant('ce.enum.common.aceleracaoBrusca')) ||
          !posicao.descricao.indexOf(this.translate.instant('ce.enum.common.frenagemBrusca')));
      let descricao;

      if (isExcesso) {
        descricao = `<strong data-testid="popup-velocidade">${this.translate.instant('ce.mapa.common.modal.popupService.velMaxima')}</strong>`;
      } else if (isEventoDeVelocidadeInicio) {
        descricao = `<strong data-testid="popup-velocidade">${this.translate.instant('ce.mapa.common.modal.popupService.velInicio')}</strong>`;
      } else {
        descricao = `<strong data-testid="popup-velocidade">${this.translate.instant('ce.mapa.common.modal.popupService.velocidade')}</strong>`;
      }

      if (this.usuario.measurementUnits.speedMeasurement === 'MILES' && !posicao.velocidadeConvertida) {
        posicao.velocidade = this.convertersService.handleKmToMiles(posicao.velocidade);
        posicao.velocidadeConvertida = true;
      }

      return `${descricao} ${posicao.velocidade} ${this.speedUnit}  <br/>`;
    }

    getEvento(posicao) {
      switch (posicao.acionamentoFreioServico) {
        case true: return `<strong data-testid="popup-evento">${this.translate.instant('ce.mapa.common.modal.popupService.evento')}</strong> ${this.translate.instant('ce.enum.common.frenagemBruscaComPedal')} <br/>`;
        case false: return `<strong data-testid="popup-evento">${this.translate.instant('ce.mapa.common.modal.popupService.evento')}</strong> ${this.translate.instant('ce.enum.common.frenagemBruscaSemPedal')} <br/>`;
        default: return posicao.descricao ? `<strong data-testid="popup-evento">${this.translate.instant('ce.mapa.common.modal.popupService.evento')}</strong> ${posicao.descricao} <br/>` : '';
      }
    }

    getMotorista(motorista) {
      if (angular.isString(motorista)) {
        return `<strong data-testid="popup-motorista">${this.translate.instant('ce.mapa.common.modal.popupService.motorista')}</strong> ${motorista} <br/>`;
      }
      if (angular.isObject(motorista) && motorista.nome) {
        return `<strong data-testid="popup-motorista">${this.translate.instant('ce.mapa.common.modal.popupService.motorista')}</strong> ${motorista.nome} <br/>`;
      }
      return '';
    }

    getDuracao(duracao) {
      if (duracao) {
        return `<strong data-testid="popup-duracao">${this.translate.instant('ce.mapa.common.modal.popupService.duracao')}</strong> ${this.filter('humanizedDuration')(duracao)} <br/>`;
      }
      return '';
    }

    getGps(gps) {
      if (gps !== 'Inválido') {
        return '';
      }
      return '<div class="text-center"><i class="fa fa-exclamation-triangle v3g-text-alt-red"></i></div>' +
        `<strong data-testid="popup-gps">${this.translate.instant('ce.mapa.common.modal.popupService.atencaoLocalizacao')}<br/><br/>`;
    }

    getIdentificacaoMotorista(identificacaoMotorista) {
      if (identificacaoMotorista) {
        return `<strong data-testid="popup-identificacao-motorista">${this.translate.instant('ce.mapa.common.modal.popupService.identificacao')}</strong> ${identificacaoMotorista}<br/>`;
      }
      return '';
    }

    getData(posicao, dateTimeFormat) {
      if (posicao.data) {
        const prefixo = posicao.duracao ?
            `<strong data-testid="popup-inicio">${this.translate.instant('ce.mapa.common.modal.popupService.inicio')}</strong>` :
            `<strong data-testid="popup-data">${this.translate.instant('ce.mapa.common.modal.popupService.dataHora')}</strong>`,
            data = this.filter('date')(posicao.data, dateTimeFormat);
        return `${prefixo} ${data} <br/>`;
      }
      return '';
    }

    getGoogleMapsLink(latitude, longitude) {
      return `<a data-testid="google-maps-link" href="https://www.google.com/maps/@${latitude},${longitude},16z/data=!5m1!1e1"` +
        ` target="_blank"><i class="trimble-traffic"></i> ${this.translate.instant('ce.mapa.common.modal.popupService.trafegoTempoReal')}</a><br/>`;
    }

    getStreetViewLink(latitude, longitude) {
      return `<a data-testid="street-view-link" href="https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}"` +
        ` target="_blank"><i class="fa fa-street-view"></i> ${this.translate.instant('ce.mapa.common.modal.popupService.explorarVisaoLocal')}</a><br/>`;
    }

    getEventoTic(meio) {
      if (meio === 'GTP') {
        return `<div class="evento-tic popup-tic">` +
                  `<div class="icon-tic">` +
                    `<span class="material-icons">hub</span>` +
                  `</div>` +
                  `<div>` +
                    `<small class="texto-tic">${this.translate.instant('ce.mapa.historicoDetalhado.eventoTic')}</small>` +
                  `</div>` +
                `</div>`;
      }
      return '';
    }
  }

  angular
    .module('mapas')
    .service('PopupService', PopupService);
}());
