(function () {
  'use strict';

  function paradoMovimentoOnePageCardDirective() {
    return {
      templateUrl: 'one-page/directives/parado-movimento/card/parado-movimento-one-page-card.tpl.html',
      controller: 'ParadoMovimentoOnePageCardCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class ParadoMovimentoOnePageCardCtrl {
    constructor($scope, OnePageService, $translate) {
      this.scope = $scope;
      this.onePageService = OnePageService;
      this.translate = $translate;

      this.paradoMovimento = {data: [], erroSemData: false};
      this.paradoEnum = this.translate.instant('ce.enum.eventos.parado');
      this.movimentoEnum = this.translate.instant('ce.enum.eventos.movimento');

      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateStatusVeiculo();
      });

      this.init();
    }

    init() {
      if (this.onePageService.listaCacheada) {
        this.buildGraficoParadoMovimento(this.onePageService.listaCacheada);
      }
      this.onePageService.onUpdateStatusVeiculo(data => this.buildGraficoParadoMovimento(data), 'paradoMovimento');
    }

    buildGraficoParadoMovimento(data) {
      this.paradoMovimento.data = [];
      this.paradoMovimento.erroSemData = false;

      if (data && data.length > 0) {
        this.paradoMovimento.erroSemData = false;
        let qntMovimento = data.filter(x => x.status === this.movimentoEnum).length,
            qntParado = data.length - qntMovimento;
        this.paradoMovimento.data.push({
          name: this.movimentoEnum,
          total: qntMovimento,
          porcentagem: qntMovimento > 0 ? Math.round(qntMovimento / data.length * 100 * 1e2) / 1e2 : 0
        });
        this.paradoMovimento.data.push({
          name: this.paradoEnum,
          total: qntParado,
          porcentagem: qntParado > 0 ? Math.round(qntParado / data.length * 100 * 1e2) / 1e2 : 0
        });
      } else {
        this.paradoMovimento.erroSemData = true;
      }
    }
  }

  angular
    .module('onePageModule')
    .controller('ParadoMovimentoOnePageCardCtrl', ParadoMovimentoOnePageCardCtrl)
    .directive('paradoMovimentoOnePageCardDirective', paradoMovimentoOnePageCardDirective);
}());
