(function () {
  'use strict';

  class RankingEconomyCtrl {
    constructor(Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, VtabelaFactory, uiGridConstants,
                RankingEconomy, $q, $httpParamSerializer, Restangular, $filter, $translate, Authenticator, CadastroVeiculoService,
                UrlConfigService) {
      this.authenticator = Authenticator;
      this.veiculoService = CadastroVeiculoService;
      this.q = $q;
      this.hasSensorAcionado = false;
      this.rankingEconomyService = RankingEconomy;
      this.restangular = Restangular;
      this.filter = $filter;
      this.httpParamSerializer = $httpParamSerializer;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
      this.labelSemMotorista = this.translate.instant('ce.ranking.conducaoEconomica.labelSemMotorista');

      this.vtabela = null;
      this.exportsPdf = false;
      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo_id',
          key: this.keys.uo.name
        },
        {
          name: 'uo_parceira_id',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'motorista_id',
          key: this.keys.motoristas.name,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.roleVisualizarBdm = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_MOTORISTA_VISUALIZAR');
        this.roleVisualizarBdv = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_VEICULO_VISUALIZAR');
        this.consultar(ServiceVfiltro.getFiltroBackendAntigo(filtro));
      });
    }

    initVtabela() {
      let drilDownSorting, drilDownCol, objVtabela = {
            exporterPdfFilename: 'Ranking Condução Econômica PDF.pdf',
            exporterCsvFilename: 'Ranking Condução Econômica CSV.csv',
            exporterExcelFilename: 'Ranking Condução Econômica XLSX.xlsx',
            appScopeProvider: this,
            enableRowSelection: false,
            enableRowHeaderSelection: false,
            multiSelect: false,
            enableSelectAll: false,
            showColumnFooter: true,
            isRowSelectable: () => {
              return false;
            },
            columnDefs: [
                {
                  name: 'motorista',
                  displayName: 'ce.ranking.conducaoEconomica.colunas.motorista',
                  type: 'string',
                  pinnedLeft: true,
                  width: 150,
                  filterCellFiltered: true,
                  enableHiding: false,
                  aggregationTotalTitle: 'Total',
                  cellTemplate: `
                  <div class='ui-grid-cell-contents' title="{{row.entity.ic}}">
                    <a
                    ng-href='{{(row.entity.linkBdm)}}'
                    target='_blank'
                    ng-if='grid.appScope.roleVisualizarBdm && row.entity.motorista !== grid.appScope.labelSemMotorista && row.entity.linkBdm'
                    title="{{row.entity.motorista}}">
                      {{row.entity.motorista}}
                    </a>
                    <span
                    ng-if='!grid.appScope.roleVisualizarBdm || row.entity.motorista === grid.appScope.labelSemMotorista''
                    title="{{row.entity.motorista}}">
                      {{row.entity.motorista | withLabelToUndefined:\'Sem identificação\' | splitComposedLabels:true}}
                    </span>
                  </div> `
                },
                {
                  name: 'departamento',
                  displayName: 'ce.ranking.conducaoEconomica.colunas.departamento',
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false
                },
                {
                  name: 'veiculos',
                  displayName: 'ce.ranking.conducaoEconomica.colunas.veiculos',
                  enableHiding: false,
                  filterCellFiltered: true,
                  cellTemplate: `
                  <div class='ui-grid-cell-contents' title="{{row.entity.veiculos}}">
                    <a ng-href='{{(row.entity.linkBdv)}}' target='_blank' ng-if='grid.appScope.roleVisualizarBdv && row.entity.veiculos && row.entity.linkBdv'>
                      {{row.entity.veiculos }}
                    </a>
                    <span ng-if='!grid.appScope.roleVisualizarBdv || !row.entity.veiculos || !row.entity.linkBdv'> {{row.entity.veiculos}} </span>
                  </div>
                  `
                },
                {name: 'totalEventos', displayName: 'ce.ranking.conducaoEconomica.colunas.totalEventos', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false},
                {name: 'tempoIgnicaoLigada', displayName: 'ce.ranking.conducaoEconomica.colunas.tempoIgnicaoLigada', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false},
                {name: 'distanciaPercorrida', displayName: 'ce.ranking.conducaoEconomica.colunas.distanciaPercorrida', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'decimalWithLabel:\'Km\'', cellClass: 'text-center', enableFiltering: false},
                {name: 'banguelas', displayName: 'ce.ranking.conducaoEconomica.colunas.banguelas', cellFilter: 'humanizedDuration', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellClass: 'text-center', enableFiltering: false},
                {name: 'rpmExcessivo', displayName: 'ce.ranking.conducaoEconomica.colunas.rpmExcessivo', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false},
                {name: 'motorOcioso', displayName: 'ce.ranking.conducaoEconomica.colunas.motorOcioso', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false}
            ]
          };

      if (this.hasSensorAcionado) {
        objVtabela.columnDefs.push({
          name: 'tempoSensorDesengate',
          displayName: 'ce.ranking.conducaoEconomica.colunas.sensorAcionado',
          aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
          sortWithUndefinedValues: true,
          footerCellClass: 'text-center',
          cellTemplate: `
            <div class="ui-grid-cell-contents text-center">
              <span title="{{row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'Não se aplica.\'}}">
                {{row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'N/A\'}}
              </span>
            </div>`,
          cellFilter: 'humanizedOrUndefinedWithLabel:\'N/A\'',
          enableFiltering: false
        });
      }

      drilDownSorting = this.getDrilDownSortingDirection(this.tableState);
      drilDownCol = objVtabela.columnDefs.find(column => column.name === this.tableState.sort.predicate);

      if (drilDownSorting && drilDownCol) {
        angular.merge(drilDownCol, drilDownSorting);
      }
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        id: item.motoristaId,
        motorista: this.filter('withLabelToUndefined')(item.nomeMotorista, this.labelSemMotorista),
        departamento: item.departamento,
        veiculos: item.veiculos,
        totalEventos: item.totalEventos,
        tempoIgnicaoLigada: item.tempoEmDirecao,
        distanciaPercorrida: item.distanciaPercorrida,
        banguelas: item.banguelas,
        rpmExcessivo: item.rpmExcessivo,
        motorOcioso: item.motorOcioso,
        tempoSensorDesengate: item.tempoSensorDesengate,
        linkBdv: this.getLinkBdv(item.veiculosIds),
        linkBdm: this.getLinkBdm(item.motoristaId)
      };
    }

    loadGridRankingEconomy(data) {
      this.authenticator.getUser().then(user => {
        const usuarioUoId = user.plain().uo.id;

        this.veiculoService.hasSensor(usuarioUoId).then(hasSensor => {
          this.hasSensorAcionado = hasSensor;
          this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'rankingEconomicoTable', 'v1');
          this.setInfoTable(data);
        });
      });
    }

    consultar(filtro) {
      this.rankingEconomyService.one().get({filtros: filtro})
        .then(data => {
          this.loadGridRankingEconomy(data);
        });
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    getLinkBdv(veiculosIds) {
      if (veiculosIds.length === 1) {
        return `/relatorios/bdv?${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.veiculos.name)};veiculos:${veiculosIds.toString()}`;
      }
      return null;
    }

    getLinkBdm(motoristaId) {
      return `/relatorios/bdm?${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.motoristas.name)};motoristas:${motoristaId.toString()}`;
    }

    getDrilDownSortingDirection(tableState) {
      if (tableState && tableState.sort.reverse) {
        return {
          sort: {
            direction: this.uiGridConstants.DESC
          }
        };
      }

      return {
        sort: {
          direction: this.uiGridConstants.ASC
        }
      };
    }

    getParams() {
      return {
        filtros: this.serviceVfiltro.getFiltroBackendAntigo(this.serviceVfiltro.filtroTopbar)
      };
    }

    getUrlCsv() {
      return `/boletim/ranking/economy/csv?${this.httpParamSerializer(this.getParams())}`;
    }
    getUrlPdf() {
      return `/boletim/ranking/economy/pdf?${this.httpParamSerializer(this.getParams())}`;
    }
  }

  /**
   * @ngdoc object
   * @name relatorios.rankingEconomy.controller:RankingEconomyCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.rankingEconomy')
    .controller('RankingEconomyCtrl', RankingEconomyCtrl);
}());
