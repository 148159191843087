(function () {
  'use strict';

  angular
    .module('relatorios.historicoSolicitacao', [
      'ui.router',
      'ui.bootstrap.modal',
      'restangular',
      'urlConfigModule',
      'alert-message',
      'pascalprecht.translate',
      'angularMoment',
      'smart-table',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'v3gAuthenticatorModule',
      'httpServiceV3gModule'
    ])
    .constant('StatusSolicitacao', {
      AGUARDANDO: {id: 'AGUARDANDO', label: 'ce.analise.solicitacoesAgendadas.statusSolicitacao.aguardando', class: 'text-light-blue', ordem: 0},
      FINALIZANDO: {id: 'FINALIZANDO', label: 'ce.analise.solicitacoesAgendadas.statusSolicitacao.finalizando', class: 'text-light-blue', ordem: 1},
      PROCESSANDO: {id: 'PROCESSANDO', label: 'ce.analise.solicitacoesAgendadas.statusSolicitacao.processando', class: 'text-light-blue', ordem: 2},
      CONCLUIDO: {id: 'CONCLUIDO', label: 'ce.analise.solicitacoesAgendadas.statusSolicitacao.concluido', class: 'text-green', ordem: 3},
      CANCELADO: {id: 'CANCELADO', label: 'ce.analise.solicitacoesAgendadas.statusSolicitacao.cancelado', class: 'text-red', ordem: 4}
    })
    .constant('TipoPermissao', {
      HISTORICO_DETALHADO_VISUALIZAR: 'HISTORICO_DETALHADO',
      HISTORICO_ALERTA_VISUALIZAR: 'HISTORICO_ALERTA',
      HISTORICO_CONSOLIDADO_VISUALIZAR: 'MOVIMENTO_VEICULO'
    })
    .factory('HistoricoSolicitacao', Restangular => Restangular.service('relatorios-agendado/historico-requisicoes'));
}());
