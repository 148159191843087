(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.direcaoSegura', {
      url: '/direcao-segura?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'indicadores/direcao-segura/direcao-segura.tpl.html',
          controller: 'DirecaoSeguraCtrl',
          controllerAs: 'direcaoSegura'
        }
      },
      data: {
        titulo: 'ce.indicadores.direcaoSegura.titulo',
        role: 'DIRECAO_SEGURA_VISUALIZAR'
      }
    });
  }

  angular
    .module('indicadores.DirecaoSegura')
    .config(pageConfig);
}());
