(function () {
  'use strict';

  const tipos = {
        CONFORME: {id: 'CONFORME', label: 'ce.enum.checklist.conforme', class: 'text-success'},
        NAO_CONFORME: {id: 'NAO_CONFORME', label: 'ce.enum.checklist.naoConforme', class: 'text-danger'},
        NAO_APLICA: {id: 'NAO_APLICA', label: 'ce.enum.checklist.naoSeAplica', class: ''}
      },
      func = {
        getTipoChecklist: (tipo) => tipos[tipo] || {id: '', label: '', class: ''}
      };
  angular
    .module('ChecklistEnum', [])
    .constant('ChecklistEnum', Object.assign({}, tipos, func));
}());
