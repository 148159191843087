(function () {
  'use strict';

  angular
  .module('usuarioModule')
  .factory('UsuariosVfleets', Restangular => {
    let service = Restangular.service('auth/usuario');
    service.getUsuariosDaUo = () => {
      return Restangular.all('auth/usuario/uo').withHttpConfig({cache: true}).getList();
    };
    service.getUsuarios = (params) => Restangular.all('auth/usuarios').getList(params);
    service.getUsuariosAtribuiveis = (veiculoIds) => Restangular.all('alerta/usuarios/atribuiveis').getList({
      veiculoIds: veiculoIds.join()
    });
    return service;
  });
}());
