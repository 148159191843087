(function () {
  'use strict';
  /* global _ */
  class AlertaVeiculoService {

    constructor(HttpServiceV3g, $timeout, moment, AlertaVeiculoConfig, AlertasEnum, $translate) {
      this.http = HttpServiceV3g;
      this.timeout = $timeout;
      this.moment = moment;
      this.config = AlertaVeiculoConfig;
      this.callbacksPorTipo = [];
      this.callbacksPorTipoErrorHandler = [];
      this.translate = $translate;
      this.alertaMapPtBr = AlertasEnum
        .getTipos()
        .reduce((acc, atual) => {
          acc[atual.id] = {
            key: atual.id,
            texto: this.translate.instant(atual.descricao),
            icone: atual.icone,
            ordem: atual.ordem,
            chartColor: atual.chartColor
          };
          return acc;
        }, {});
    }

    onUpdateAlertaPorTipo(callback, id) {
      if (this.callbacksPorTipo.filter(e => e.id === id).length === 0) {
        this.callbacksPorTipo.push({callback, id});
      }
    }

    onUpdateAlertaPorTipoError(callback, id) {
      if (this.callbacksPorTipoErrorHandler.filter(e => e.id === id).length === 0) {
        this.callbacksPorTipoErrorHandler.push({callback, id});
        if (this.errorcheckAlertaPorTipo) {
          this.checkAlertaPorTipo();
        }
      }
    }

    checkAlertaPorTipo() {
      return this.http.get(this.getUrlAlertaPorTipo()).then((response) => {
        this.updateAlertaPorTipo(this.mapearPtBr(response.data));
      }, error => {
        this.errorcheckAlertaPorTipo = error;
        this.callbacksPorTipoErrorHandler.forEach(cb => cb.callback(error));
        this.timeoutPorTipo = this.timeout(() => this.checkAlertaPorTipo(), 10000);
      });
    }

    updateAlertaPorTipo(data) {
      if (data && data !== 0) {
        this.listaCacheada = data;
      }
      this.callbacksPorTipo.forEach(cb => cb.callback(data));
      this.timeoutPorTipo = this.timeout(() => this.checkAlertaPorTipo(), 10000);
    }

    removeCallbackPorTipo(id) {
      this.callbacksPorTipo = this.callbacksPorTipo.filter(x => x.id !== id);
    }

    getUrlAlertaPorTipo() {
      return `${this.config.url}/quantidade/${this.config.uoId}/tipo?dia=${this.moment().format('YYYY-MM-DD')}`;
    }

    mapearPtBr(listaBackEnd) {
      return _(listaBackEnd)
              .map((value, tipo) => {
                const {key, texto, icone, ordem, chartColor} = this.alertaMapPtBr[tipo];
                return {key, texto, quantidade: value, icone, ordem, chartColor};
              })
              .sortBy('ordem')
              .value();
    }

    stop() {
      this.timeout.cancel(this.timeoutPorTipo);
    }

    setUoId(uoId) {
      this.config.uoId = uoId;
    }
  }

  angular
    .module('onePageModule')
    .service('AlertaVeiculoService', AlertaVeiculoService)
    .value('AlertaVeiculoConfig', {
      url: '/alerta',
      uoId: -1
    });
}());
