(function () {
  'use strict';

  function cardOnePageDirective() {
    return {
      templateUrl: 'one-page/view/card/card-one-page.tpl.html',
      controller: 'CardOnePageCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class CardOnePageCtrl {
    constructor($scope, $window, CustomGridster, Authenticator, OnePageService) {
      this.scope = $scope;
      this.window = $window;
      this.customGridster = CustomGridster;
      this.authenticator = Authenticator;
      this.onePageService = OnePageService;

      this.gridster = this.customGridster.getOptions(115);

      this.posicoesGridster = {semSinal: {}, paradoMovimento: {}};
      this.visualizarHistoricoAlerta = false;
      this.visualizarLocalizao = false;
      this.visualizarNotificacoes = false;

      this.authenticator.getUser().then(() => {
        if (this.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.visualizarHistoricoAlerta = true;
        }
        if (this.authenticator.hasRole('LOCALIZACAO_ATUAL_VISUALIZAR')) {
          this.visualizarLocalizao = true;
        }
        if (this.authenticator.hasRole('NOTIFICACOES_VISUALIZAR')) {
          this.visualizarNotificacoes = true;
        }

        // this.init(user);
      });
    }

    init(user) {
      this.user = user;
      this.setPosicaoGrafico();
    }

    setPosicaoGrafico() {
      this.setPosicaoSemSinal();
      this.setPosicaoParadoMovimento();
    }

    setPosicaoSemSinal() {
      this.posicoesGridster.semSinal = this.scope.onePage.getPosicaoGrafico(`c-semsinal-${this.user.id}`, {
        row: 0,
        col: 0,
        sizeX: 6,
        sizeY: 1,
        minSizeY: 1,
        maxSizeY: 1,
        minSizeX: 4,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `c-semsinal-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.semSinal);
    }

    setPosicaoParadoMovimento() {
      this.posicoesGridster.paradoMovimento = this.scope.onePage.getPosicaoGrafico(`c-paradoMovimento-${this.user.id}`, {
        row: 0,
        col: this.visualizarHistoricoAlerta ? 6 : 0,
        sizeX: 6,
        sizeY: 1,
        minSizeY: 1,
        maxSizeY: 1,
        minSizeX: 4,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `c-paradoMovimento-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.paradoMovimento);
    }
  }

  angular
    .module('onePageModule')
    .controller('CardOnePageCtrl', CardOnePageCtrl)
    .directive('cardOnePageDirective', cardOnePageDirective);
}());
