(function () {
  'use strict';

  // Mantêm estados entre Ponto de Referência e Area de Controle
  class MapControlService {
    constructor() {
      this.isDrawingCercaEletronica = false;
      this.isDrawingPontoRef = false;
    }
  }

  angular
    .module('mapControlModule')
    .service('MapControlService', MapControlService);
}());
