/* global window */
(function () {
  'use strict';

  class HistoricoSolicitacaoCtrl {
    constructor(ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, Keys,
      $filter, $uibModal, HistoricoSolicitacao, v3gTableLimitService, moment, AlertMessage, StatusSolicitacao,
      RelatorioAgendadoService, $translate, UrlConfigService, HttpServiceV3g, TipoPermissao) {
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.filter = $filter;
      this.modalService = $uibModal;
      this.historicoSolicitacao = HistoricoSolicitacao;
      this.v3gTableLimitService = v3gTableLimitService;
      this.moment = moment;
      this.alertMessage = AlertMessage;
      this.status = StatusSolicitacao;
      this.relatorioAgendadoService = RelatorioAgendadoService;
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
      this.httpServiceV3g = HttpServiceV3g;
      this.tipoPermissao = TipoPermissao;

      this.tableState = this.objetosVfiltroService.getTableStateUrl();
      this.currentPage = 0;
      this.relatorio = null;
      this.relatorioSafe = null;
      this.hasSolucaoRequestReport = false;
      this.permissoesSolucao = null;
      ServiceVfiltro.init(FactoryVfiltro.get([]))
      .then(() => {
        this.roleAdminVeltec = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('ADMIN_VELTEC');
        this.serviceVfiltro.factoryVfiltro.authenticator.getUser().then((user) => {
          this.permissoesSolucao = user.permissoesSolucao;
          this.hasSolucaoRequestReport = user.permissoesSolucao.includes('HISTORICO_ALERTA_VISUALIZAR') || user.permissoesSolucao.includes('HISTORICO_CONSOLIDADO_VISUALIZAR') || user.permissoesSolucao.includes('HISTORICO_DETALHADO_VISUALIZAR');
          this.consultar(this.getPage());
        });
      });
    }

    getPage() {
      if (angular.isDefined(this.tableState) && this.tableState !== null) {
        if (isNaN(this.tableState.pagination.number)) {
          this.tableState.pagination.number = 1;
        }
        return Math.floor(this.tableState.pagination.start / this.tableState.pagination.number) + 1;
      }
      return this.currentPage;
    }

    consultar(currentPage) {
      if (this.tableState && this.tableState.pagination) {
        angular.extend(this.tableState.pagination, this.v3gTableLimitService.init(this.serviceVfiltro.factoryVfiltro.user));
        this.objetosVfiltroService.setTableStateUrl(this.tableState, true);
      }

      let tipos = new Set();

      Object.keys(this.tipoPermissao).forEach((key) => {
        if (this.permissoesSolucao.includes(key)) {
          tipos.add(this.tipoPermissao[key]);
        }
      });

      this.historicoSolicitacao.one().get({
        inicio: this.moment().subtract(30, 'days').endOf('day').format(),
        fim: this.moment().endOf('day').format(),
        uoId: this.serviceVfiltro.factoryVfiltro.user.uo.id,
        tipos: Array.from(tipos)
      })
      .then(data => this.setInfoTable(data, currentPage));
    }

    setInfoTable(data, currentPage) {
      this.relatorioTable = this.filter('orderBy')(
        data.map(item => {
          let progresso = '-';
          switch (item.status) {
            case this.status.PROCESSANDO.id:
              if (item.quantidadePartes > 0 && item.currentPart !== null && angular.isDefined(item.currentPart)) {
                const quantidadePendente = item.quantidadePartes - item.currentPart;
                progresso = `${this.filter('number')(quantidadePendente / item.quantidadePartes * 100, 2)}%`;
              } else if (item.quantidadePartes > 0 && !item.currentPart) {
                progresso = '0,00%';
              }
              break;
            case this.status.CONCLUIDO.id:
              progresso = '100%';
              break;
            default:
              progresso = '-';
          }

          return Object.assign(
            {},
            item,
            this.setStatus(item),
            {nomeRelatorio: this.getRelatorioLabel(item.filtro.type), progresso}
          );
        }),
        ['status.ordem', '-id']
      );
      this.relatorio = this.relatorioTable;
      if (angular.isDefined(currentPage)) {
        this.currentPage = currentPage;
      }
    }

    setTableState(tableState) {
      if (this.$parent.historicoSolicitacao.tableState !== null) {
        tableState.sort = this.$parent.historicoSolicitacao.tableState.sort;
      }
      this.$parent.historicoSolicitacao.tableState = tableState;
    }

    getPeriodo(solicitacao) {
      let inicio = this.moment(solicitacao.filtro.inicio),
          fim = this.moment(solicitacao.filtro.fim);
      return inicio.format('DD/MM/YYYY') + ' - ' + fim.format('DD/MM/YYYY');
    }

    setStatus(solicitacao) {
      return {status: this.status[solicitacao.status] || {id: 'N/A', class: '', label: 'N/A', ordem: 5}};
    }

    getRelatorioLabel(tipoRelatorio) {
      if (!this.labelsPorTipoRelatorio) {
        this.labelsPorTipoRelatorio = Object.values(this.relatorioAgendadoService.getTiposRelatoriosAgendaveis())
          .reduce((acc, atual) => Object.assign(acc, {[atual.tipo]: atual.label}), {});
      }
      return this.labelsPorTipoRelatorio[tipoRelatorio];
    }

    download(solicitacao) {
      if (solicitacao.link) {
        this.httpServiceV3g.get(`/relatorios-agendado/historico-requisicoes/download/${solicitacao.id}`)
        .then(response => {
          window.open(response.data.url, '_blank');// eslint-disable-line
        });
      }
    }

    isExpirado(solicitacao) {
      return solicitacao.status.id === this.status.CONCLUIDO.id && this.moment(solicitacao.dataExpiracao).isSameOrBefore(this.moment());
    }

    atualizarStatus(relatorio, solicitacao) {
      return relatorio.map(x => {
        if (x.id === solicitacao.id) {
          x.status = this.status.CANCELADO;
        }
        return x;
      });
    }

    atualizarTable(solicitacao, remover) {
      let msg = this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.cadastradaComSucesso');
      if (remover) {
        this.relatorioTable = this.atualizarStatus(this.relatorioTable, solicitacao);
        this.relatorio = this.atualizarStatus(this.relatorio, solicitacao);
        msg = this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.canceladaComSucesso');
      } else {
        solicitacao.uo = {
          nome: this.serviceVfiltro.factoryVfiltro.user.uo.nome
        };
        angular.extend(solicitacao, this.setStatus(solicitacao), {nomeRelatorio: this.getRelatorioLabel(solicitacao.tipo)});
        this.relatorio.unshift(solicitacao);
        this.relatorioTable.unshift(solicitacao);
      }
      this.alertMessage.create({
        type: 'success',
        message: msg,
        appendTo: '.alerta-aqui'
      });
    }

    getWarningMessage() {
      const msgPrefix = this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.prefixWarning'),
          maxDate = this.getMaxDate(),
          msgSuffix = this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.suffixWarning');
      return `${msgPrefix} ${maxDate} ${msgSuffix}`;
    }

    modalFiltro(filtro = null, somenteVisualizacao = false, tipoRelatorio = null) {
      this.modalService.open({
        animation: true,
        component: 'modalCadastrarSolicitacao',
        size: 'custom-size',
        backdropClass: 'cadastro-solicitacao-modal-backdrop',
        resolve: {
          filtroValues: () => filtro || {uoId: this.serviceVfiltro.factoryVfiltro.user.uo.id},
          somenteVisualizacao: () => somenteVisualizacao,
          tipoRelatorio: () => tipoRelatorio
        }
      })
      .result
      .then(solicitacao => this.atualizarTable(solicitacao, false));
    }

    modalCancelar(solicitacao) {
      this.modalService.open({
        animation: true,
        templateUrl: 'relatorios/historico-solicitacao/cancelar/cancelar-solicitacao.tpl.html',
        controller: 'CancelarSolicitacaoCtrl',
        controllerAs: '$ctrl',
        size: 'md',
        resolve: {
          solicitacao: () => solicitacao
        }
      })
      .result
      .then((cancelado) => {
        if (cancelado) {
          this.atualizarTable(solicitacao, true);
        } else {
          this.consultar(this.getPage());
          this.alertMessage.create({
            type: 'warning',
            message: this.translate.instant('ce.analise.solicitacoesAgendadas.mensagem.erroParaCancelarSolicitacao'),
            appendTo: '.alerta-aqui'
          });
        }
      });
    }

    getMaxDate() {
      return this.moment().subtract(30, 'days').endOf('day').format('DD/MM');
    }
  }

  angular
    .module('relatorios.historicoSolicitacao')
    .controller('HistoricoSolicitacaoCtrl', HistoricoSolicitacaoCtrl);
}());
