(function () {
  'use strict';

  angular
    .module('indicadores.ConducaoEconomica', [
      'restangular',
      'angularMoment',
      'chartModule',
      'confluenceID',
      'indicadores',
      'ConstantModule',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate'
    ])
    .filter('ceilAndRemoveDecimals', function () {
      return function (input) {
        var number;

        if (!angular.isNumber(input) && !angular.isString(input)) {
          return input;
        }

        number = parseFloat(input);

        number = Math.ceil(number);

        return number.toString();
      };
    })
    .factory('ConducaoEconomica', Restangular => Restangular.service('boletim/painel-conducao-economica'));
}());
