(function () {
  'use strict';

  const saudeVeiculoStatusToColorMap = {
    byStatus: {
      RELEASED: 'text-success',
      REQUIRES_ATTENTION: 'modus-text-warning',
      EVALUATE_URGENTLY: 'text-danger',
      HEALTHY: 'text-success',
      NOT_HEALTHY: 'text-danger'
    },
    default: 'modus-text-blue'
  };

  angular
    .module('enumModule')
    .constant('SaudeVeiculoStatusToColorMap', saudeVeiculoStatusToColorMap);
}());
