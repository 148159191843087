(function () {
  'use strict';

  class CadastrarCategoriaPontoReferencia {
    constructor($uibModalInstance, CategoriaPontosReferencia, AlertMessage, Restangular, uoSelecionada, TIPOS_CATEGORIA, $q, $translate) {
      this.q = $q;
      this.modalInstance = $uibModalInstance;
      this.model = {
        cor: '#000000',
        audioHabilitado: false
      };
      this.hasVtalks = false;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.uoSelecionada = uoSelecionada;
      this.categoriaPontoReferenciaService = CategoriaPontosReferencia;
      this.AREA_RISCO = 'AREA_RISCO';
      this.GARAGEM = 'GARAGEM';
      this.TIPOS_CATEGORIA = TIPOS_CATEGORIA;
      this.audios = null;
      this.temRegra = false;
      this.translate = $translate;
      this.init();
    }

    init() {
      this.q.all([
        this.categoriaPontoReferenciaService.getCategoriaAudios(this.uoSelecionada.id),
        this.categoriaPontoReferenciaService.hasVtalks()
      ]).then(([audios, hasVtalks]) => {
        this.hasVtalks = hasVtalks;
        this.audios = this.ordenarAudios(audios.plain());
        // API retorna uma lista vazia quando a uo não possui regra cadastrada
        this.temRegra = this.audios.length > 0;
      });
    }

    ordenarAudios(audios) {
      return audios.sort((a, b) => a.nome.localeCompare(b.nome));
    }

    changeAudioHabilitado() {
      this.model.audioHabilitado = !this.model.audioHabilitado;
    }

    ok() {
      let categoria = {
        uo: this.uoSelecionada.id,
        nome: this.model.nome,
        cor: this.model.cor,
        areaRisco: angular.isDefined(this.model.tipo) && this.model.tipo !== null && this.model.tipo.id === this.AREA_RISCO,
        garagem: angular.isDefined(this.model.tipo) && this.model.tipo !== null && this.model.tipo.id === this.GARAGEM,
        audioId: this.model.audioHabilitado && angular.isDefined(this.model.audio) ? this.model.audio.id : null
      };
      this.categoriaPontoReferenciaService.postCategoriaPontoReferencia(categoria)
      .then(data => {
        this.modalInstance.close(data.plain());
      })
      .catch((e) => {
        this.alertMessage.create({
          type: 'error',
          message: angular.isDefined(e) && e.status === 409 ?
          this.translate.instant('ce.mapa.common.modal.cadastrarCategoriaPonto.categoriaJaCadastrada') + ' "' + e.data.nome + '"'
          : this.translate.instant('ce.mapa.common.modal.cadastrarCategoriaPonto.errorCadastrarCategoria'),
          appendTo: '.alerta-aqui-categoria-ponto'
        });
      });
    }

    close() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('mapControlModule')
    .controller('CadastrarCategoriaPontoReferenciaCtrl', CadastrarCategoriaPontoReferencia);
}());
