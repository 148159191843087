(function () {
  'use strict';

  angular
    .module('relatorios.permanencia', [
      'ui.router',
      'restangular',
      'angularMoment',
      'FactoryVfiltroModule',
      'ServiceVfiltroModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'pascalprecht.translate',
      'ModuleEventos',
      'urlConfigModule',
      'VtabelaModule'
    ])
    .factory('Permanencia', Restangular => Restangular.service('/ponto-referencia/permanencias'));
}());
