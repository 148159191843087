(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.utilizacaoVeiculo', {
      url: '/utilizacao-veiculo?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'indicadores/utilizacao-veiculo/utilizacao-veiculo.tpl.html',
          controller: 'UtilizacaoVeiculoCtrl',
          controllerAs: 'utilizacaoVeiculo'
        }
      },
      data: {
        titulo: 'ce.indicadores.ociosidadeFrota.titulo',
        role: 'OCIOSIDADE_FROTA_VISUALIZAR'
      }
    });
  }

  angular
    .module('indicadores.UtilizacaoVeiculo')
    .config(pageConfig);
}());
