(function () {
  'use strict';
  class DatepickerCtrl {}
  angular
    .module('conducaoEconomica')
    .component('datepicker', {
      controller: DatepickerCtrl,
      templateUrl: 'directives/datepicker/datepicker.tpl.html',
      bindings: {
        options: '=',
        model: '=',
        name: '@',
        disabled: '=',
        required: '<'
      }
    });
}());
