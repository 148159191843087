(function () {
  'use strict';

  angular
    .module('confluenceID', [])
    .constant('IDDirecaoSegura', 115409915)
    .constant('IDRankingSafety', 115409940)
    .constant('IDBDV', 115378154)
    .constant('IDBDVDetalhes', 127926344)
    .constant('IDBDM', 127501023)
    .constant('IDBDMDetalhes', 129105986)
    .constant('IDBuscaPorRegiao', 1849917446);
}());
