/* global document */
(function () {
  'use strict';

  class BtnAdvisorRotogramasCtrl {
    constructor(PontosReferenciaMap, Authenticator, $rootScope) {
      this.rootScope = $rootScope;
      this.pontosReferenciaMap = PontosReferenciaMap;
      this.lastActive = null;
      this.clickOtherSuggestion = false;
      this.user = null;
      this.authenticator = Authenticator;
      this.suggestionCardHeight = 80;
      this.limit = 10;
    }

    $onInit() {
      this.rootScope.$on('advisorAtualizado', () => {
        this.removeSuggestionList();
      });
      this.authenticator.getUser().then(user => {
        this.user = user;
      });
      this.limit = this.getSuggestionLimit();
    }

    activeItem(item) {
      if (this.hasSuggestionClicked() && this.lastActive.uuid === item.uuid) {
        this.lastActive = Object.assign(this.lastActive, {active: false});
        this.clickOtherSuggestion = false;
      } else {
        if (this.hasSuggestionClicked()) {
          this.lastActive = Object.assign(this.lastActive, {active: false});
          this.clickOtherSuggestion = true;
        }
        item = Object.assign(item, {active: true});
        this.lastActive = item;
      }

      this.toggleSnackbarSuggestion();
    }

    hasSuggestionClicked() {
      return this.lastActive && this.lastActive.active;
    }

    toggleSnackbarSuggestion() {
      /* eslint-disable */
      const snackbarElement = document.querySelector('vfwc-snackbar-sugestao-rotograma'); /* eslint-enable */
      if (this.clickOtherSuggestion) {
        this.closeSnackbarSuggestion(snackbarElement);
        this.openSnackbarSuggestion();
        this.focusAndRenderAdvisor();
      } else if (snackbarElement) {
        this.closeSnackbarSuggestion(snackbarElement);
      } else {
        this.openSnackbarSuggestion();
        this.focusAndRenderAdvisor();
      }
    }

    openSnackbarSuggestion() {
      /* eslint-disable */
      const snackbarContainer = document.querySelector('#sugestion-rotograma-snackbar'),
            snackbarElement = document.createElement('vfwc-snackbar-sugestao-rotograma'); /* eslint-enable */
      snackbarElement.suggestion = this.lastActive;
      snackbarElement.usuario = this.user;
      snackbarContainer.appendChild(snackbarElement);
      this.onListenerEvents(snackbarElement);
    }

    focusAndRenderAdvisor() {
      const posicao = {lat: this.lastActive.lat, lng: this.lastActive.lng, raio: this.lastActive.raio};
      this.pontosReferenciaMap.renderAdvisorSuggestion(posicao, this.lastActive);
      this.pontosReferenciaMap.focusLocal(posicao);
    }

    closeSnackbarSuggestion(element) {
      if (element) {
        element.remove();
      }
      this.pontosReferenciaMap.unrenderAdvisorSuggestion();
    }

    removeSuggestionList() {
      this.clickOtherSuggestion = false;
      this.suggestions = this.suggestions.filter(suggestion => suggestion.active !== true);
      this.toggleSnackbarSuggestion();
    }

    onListenerEvents(element) {
      const posicao = {lat: this.lastActive.lat, lng: this.lastActive.lng, raio: this.lastActive.raio, advisorSuggestion: this.lastActive};

      element.addEventListener('reject', (event) => {
        if (event.detail && event.detail.wasRejected) {
          this.removeSuggestionList();
        }
      });

      element.addEventListener('openRotogramaModal', () => {
        this.pontosReferenciaMap.openModalCreatePontoReferencia(posicao);
      });

      element.addEventListener('verLocal', () => {
        this.pontosReferenciaMap.focusLocal(posicao);
      });
    }

    getSuggestionLimit() {
      const container = angular.element('#advisor-container')[0];
      return container.offsetHeight / this.suggestionCardHeight;
    }

    loadMore() {
      this.limit += this.getSuggestionLimit();
    }

  }

  angular
    .module('mapControlModule')
    .component('listAdvisorRotogramas', {
      controller: BtnAdvisorRotogramasCtrl,
      templateUrl: 'directives/map-control/advisor-rotogramas/list-advisor-rotogramas-component.tpl.html',
      bindings: {
        suggestions: '='
      }
    });
}());
