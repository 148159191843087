(function () {
  'use strict';

  /* global _ */
  function alertaDiaOnePageGraficoDirective() {
    return {
      templateUrl: 'one-page/directives/alerta-dia/grafico/alerta-dia-one-page-grafico.tpl.html',
      controller: 'AlertaDiaOnePageGraficoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class AlertaDiaOnePageGraficoCtrl {
    constructor($scope, $window, FiltroAlertaService, AlertaVeiculoService, UrlService, OnePageService, SingleColumnChart, Keys, ServiceVfiltro, Restangular, FactoryVfiltro, $translate) {
      this.scope = $scope;
      this.window = $window;
      this.filtroAlertaService = FiltroAlertaService;
      this.alertaVeiculoService = AlertaVeiculoService;
      this.onePageService = OnePageService;
      this.keys = Keys;
      this.urlService = UrlService;
      this.serviceVfiltro = ServiceVfiltro;
      this.restangular = Restangular;
      this.factoryVfiltro = FactoryVfiltro;
      this.translate = $translate;

      this.alertas = SingleColumnChart.get();
      this.alertas.removeTitleCategories();
      this.alertas.setCustomTooltip();

      this.scope.$on('$destroy', () => {
        this.alertaVeiculoService.removeCallbackPorTipo('onepage');
        this.alertaVeiculoService.stop();
      });

      this.loadFiltro();

      this.alertasDesativados = [
        'VEICULO_SEM_SINAL'
      ];
    }

    loadFiltro() {
      this.serviceVfiltro.init(this.factoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.uoId = filtro.find(x => x.key === this.keys.uo.name).value.id;
        if (this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.alertas.setClickEvent(this.clickBinding().onClickColumn);
          this.alertas.setLegendItemClickEvent(this.clickBinding().onLegendItemClick);
        }
        this.alertaVeiculoService.setUoId(this.serviceVfiltro.factoryVfiltro.user.uo.id);
        this.alertaVeiculoService.checkAlertaPorTipo();
        this.init();
      });
    }

    init() {
      this.alertaVeiculoService.checkAlertaPorTipo()
      .then(() => {
        if (this.alertaVeiculoService.listaCacheada) {
          this.alertas.setData(this.buildGraficoAlerta(this.alertaVeiculoService.listaCacheada));
        } else if (angular.isUndefined(this.alertaVeiculoService.listaCacheada)) {
          this.verificarAlertasInativos();
        }

        this.alertaVeiculoService.onUpdateAlertaPorTipo(data => this.alertas.setData(this.buildGraficoAlerta(data)), 'onepage');

        this.alertaVeiculoService.onUpdateAlertaPorTipoError((error) => {
          if (error) {
            this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
          }
        }, 'onepageError');
      });
    }

    verificarAlertasInativos() {
      this.restangular.one(`configuracao/configuracoes/${this.uoId}/alerta`).get()
      .then(alertas => {
        this.erroAlertasInativos = _.values(alertas.configuracoes).filter(item => item.ativo).length === 0;
        if (this.erroAlertasInativos) {
          this.mensagemDeErro = 'ce.tempoReal.statusFrota.charts.alertaSeguranca.nenhumAlertaAtivo';
          this.linkConfigAlertas = this.receberUrlConfigAlertas();
        } else if (!this.erroAlertasInativos) {
          this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.mensagemSucesso');
        }
      }, () => {
        this.mensagemDeErro = this.translate.instant('ce.tempoReal.statusFrota.charts.alertaSeguranca.falhaComunicacaoServidor');
      });
    }

    buildGraficoAlerta(data) {
      let retorno = {categorias: [], series: []};
      if (data && data.length > 0 && data.filter(x => x.quantidade).length > 0) {
        data.filter(x => x.quantidade > 0 && this.alertasDesativados.indexOf(x.key) === -1)
          .forEach((item) => {
            if (this.alertas.erroSemData) {
              this.alertas.erroSemData = false;
            }

            let visibility = true;
            if (this.alertas.series) {
              let serieAlerta = this.alertas.series.find(sa => sa.key === item.key);
              if (angular.isDefined(serieAlerta)) {
                visibility = serieAlerta.visible;
              }
            }

            retorno.series.push({
              name: item.texto,
              data: item.quantidade,
              visible: visibility,
              color: item.chartColor,
              key: item.key
            });
          }
        );
      } else {
        this.verificarAlertasInativos();
      }

      return retorno;
    }

    clickBinding() {
      let self = this;

      return {
        onClickColumn: function () {
          let info = self.alertas.series.filter(i => i.name === this.series.name)[0];
          self.window.location.href = self.filtroAlertaService.filtroLink(self.scope.onePage.getParamValue(self.keys.uo.name), info);
        },
        onLegendItemClick: function () {
          self.alertas.commonChartService.filterAllSeries.call(this);

          this.chart.series.forEach(serieChart => {
            self.alertas.series
              .filter(sa => sa.name === serieChart.name)
              .forEach(sa => {
                sa.visible = serieChart.visible;
              });
          });
          return false;
        }
      };
    }
    receberUrlConfigAlertas() {
      if (this.erroAlertasInativos) {
        return `/configuracoes-eventos?vfiltro=${this.urlService.getObjectVfiltro(this.serviceVfiltro.filtroTopbar)}`;
      }
    }
  }

  angular
    .module('onePageModule')
    .controller('AlertaDiaOnePageGraficoCtrl', AlertaDiaOnePageGraficoCtrl)
    .directive('alertaDiaOnePageGraficoDirective', alertaDiaOnePageGraficoDirective);
}());
