(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.permanencia', {
      url: '/relatorios/permanencia?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/permanencia-ponto/permanencia-ponto.tpl.html',
          controller: 'PermanenciaCtrl',
          controllerAs: 'permanencia'
        }
      },
      data: {
        titulo: 'ce.analise.permanencia.titulo',
        role: ['PERMANENCIA_PONTO_REFERENCIA_VISUALIZAR', 'PERMANENCIA_CERCA_ELETRONICA_VISUALIZAR']
      }
    });
  }

  angular
    .module('relatorios.permanencia')
    .config(pageConfig);
}());
