(function () {
  'use strict';

  angular
    .module('onePageModule')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('root.onePage', {
        url: '/tempo-real?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'one-page/one-page.tpl.html',
            controller: 'OnePageCtrl',
            controllerAs: 'onePage'
          }
        },
        data: {
          titulo: 'ce.tempoReal.statusFrota.titulo',
          roles: ['ONE_PAGE_VISUALIZAR']
        }
      });
  }
}());
