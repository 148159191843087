(function () {
  'use strict';

  function paradoMovimentoOnePageGraficoDirective() {
    return {
      templateUrl: 'one-page/directives/parado-movimento/grafico/parado-movimento-one-page-grafico.tpl.html',
      controller: 'ParadoMovimentoOnePageGraficoCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class ParadoMovimentoOnePageGraficoCtrl {
    constructor($scope, $timeout, HalfPieChart, OnePageService, Keys, $translate) {
      this.scope = $scope;
      this.onePageService = OnePageService;
      this.translate = $translate;
      this.paradoMovimento = HalfPieChart.get();
      this.paradoMovimento.removeTitleCategories();
      this.keys = Keys;
      this.scope.$on('$destroy', () => {
        this.onePageService.removeUpdateStatusVeiculo();
      });

      this.paradoEnum = this.translate.instant('ce.enum.eventos.parado');
      this.movimentoEnum = this.translate.instant('ce.enum.eventos.movimento');
      this.init();
    }

    init() {
      this.buildGraficoParadoMovimento();
      this.setOnUpdateStatus();
    }

    buildWithListaCacheada() {
      this.onePageService.checkStatusVeiculoPassivelManutencao({uoId: this.scope.onePage.getParamValue(this.keys.uo.name)})
      .then(() => {
        if (this.onePageService.listaCacheada) {
          this.paradoMovimento.setData(this.buildGraficoParadoMovimento(this.onePageService.listaCacheada));
        }
      });
    }

    setOnUpdateStatus() {
      this.onePageService.onUpdateStatusVeiculo(
        data => this.paradoMovimento.setData(this.buildGraficoParadoMovimento(data)),
        'paradoMovimento'
      );
    }

    buildGraficoParadoMovimento(data) {
      let retorno = {categorias: [this.movimentoEnum, this.paradoEnum], series: []};
      if (data && data.length > 0) {
        this.paradoMovimento.erroSemData = false;
        let qntMovimento = data.filter(x => x.status === this.movimentoEnum).length,
            qntParado = data.length - qntMovimento;
        retorno.series.push({name: this.movimentoEnum, y: qntMovimento > 0 ? qntMovimento : null});
        retorno.series.push({name: this.paradoEnum, y: qntParado > 0 ? qntParado : null});
      } else {
        this.paradoMovimento.erroSemData = true;
      }
      return retorno;
    }
  }

  angular
    .module('onePageModule')
    .controller('ParadoMovimentoOnePageGraficoCtrl', ParadoMovimentoOnePageGraficoCtrl)
    .directive('paradoMovimentoOnePageGraficoDirective', paradoMovimentoOnePageGraficoDirective);
}());
