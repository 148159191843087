(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.saudeFrota', {
      url: '/relatorios/saude-frota?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/saude-frota/saude-frota.tpl.html',
          controller: 'SaudeFrotaCtrl',
          controllerAs: 'saudeFrota'
        }
      },
      data: {
        titulo: 'ce.analise.saudeDaFrota.titulo',
        role: 'SAUDE_FROTA_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.saudeFrota')
    .config(pageConfig);
}());
