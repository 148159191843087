(function () {
  'use strict';

  const tooltipInfosTextPrefix = 'ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos',
      storageHealthTextPrefix = 'ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.sdCardStatus';
  class VpopupService {
    constructor($compile, $filter, $rootScope, ConvertersService, UrlService, ServiceVfiltro, Keys, moment, VeiculoStatusEnum, 
      $translate, Authenticator, MeasurementUnitConverterService, SaudeVeiculoStatusToColorMap, FeaturehubJsSdk, FeatureHubConfigKeys
    ) {
      this.filter = $filter;
      this.compile = $compile;
      this.rootScope = $rootScope;
      this.veiculoStatusEnum = VeiculoStatusEnum;
      this.translate = $translate;
      this.convertersService = ConvertersService;
      this.urlService = UrlService;
      this.keys = Keys;
      this.moment = moment;
      this.serviceVfiltro = ServiceVfiltro;

      this.authenticator = Authenticator;
      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.saudeVeiculoStatusToColorMap = SaudeVeiculoStatusToColorMap;
      this.flagPremiumTelemetry = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);

      this.initUserMeasureSettings();
    }

    initUserMeasureSettings() {
      this.authenticator.getUser().then(user => {
        this.measurementUnits = user.measurementUnits;
        this.distanceMeasurement = this.measurementUnits.distanceMeasurement;
        this.dateFormat = this.measurementUnits.dateTimeFormat;
        this.speedUnit = this.measurementUnitConverterService.getSpeedUnit(this.distanceMeasurement);
        this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.distanceMeasurement);
        this.dateTimeFormat = this.measurementUnitConverterService.getDateTimeFormat(this.dateFormat);
      });
    }

    getVeiculoElement(posicao) {
      const scope = this.rootScope.$new(true);
      scope.eventoinfo = this.getVeiculoPopup(posicao);
      scope.onClosePopup = this.onClosePopup;
      return this.compile(`<custom-popup infos='eventoinfo'"></custom-popup>`)(scope)[0];
    }

    geraClass(item, value) {
      switch (item) {
        case 'BLOQUEIO':
          return value ? 'text-danger' : 'text-success';
        case 'STATUS':
          return value === 'Movimento' ? 'label-primary' : 'label-danger';
        case 'GSM':
          return value ? 'text-success' : 'text-danger';
        default:
      }
    }

    getBateria(fontAlimentacao) {
      switch (fontAlimentacao) {
        case 'PRINCIPAL':
          return this.translate.instant('ce.mapa.common.vpopupService.fonteAlimentacao.principal');
        case 'BACKUP':
          return this.translate.instant('ce.mapa.common.vpopupService.fonteAlimentacao.backup');
        case 'SEM_ALIMENTACAO':
          return this.translate.instant('ce.mapa.common.vpopupService.fonteAlimentacao.descarregada');
        default:
      }
    }

    getPontoRef(ponto) {
      if (angular.isDefined(ponto)) {
        let pontoValue = ponto.pontoReferencia.nome;
        if (ponto.distancia) {
          pontoValue = ponto.distancia + ' ' +
            this.translate.instant('ce.mapa.common.vpopupService.unidadeDistanciaDe') + ' ' + pontoValue;
        }
        return pontoValue;
      }
    }

    getBloqueio(bloqueio) {
      if (angular.isDefined(bloqueio)) {
        return bloqueio ? this.translate.instant('ce.mapa.common.vpopupService.bloqueado')
          : this.translate.instant('ce.mapa.common.vpopupService.desbloqueado');
      }
    }

    getLogradouro(posicao) {
      if (posicao.logra) {
        return posicao.logra + ', ' + posicao.local;
      }
      return undefined;
    }

    getLinkCadastroVeiculo(veiculoId) {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/veiculo?vfiltro=${this.keys.uo.name}:${uoId};veiculo:${veiculoId}`;
    }

    getLinkAnaliseMovimento(veiculoId) {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/relatorios/movimento-veiculo?vfiltro=${this.keys.dateTime.name};${this.keys.uo.name}:${uoId};veiculo:${veiculoId}`;
    }

    getLinkProntuarioMotorista(motoristaId) {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/prontuario?vfiltro=${this.keys.uo.name}:${uoId};${this.keys.motorista.name}:${motoristaId}`;
    }

    getQuadroOcorrenciasLink() {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return `/tempo-real/ocorrencias?vfiltro=uo:${uoId}`;
    }

    getLabelNovaOcorrencia(qtdNovasOcorrencias) {
      return qtdNovasOcorrencias > 1 ?
        `${this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.novas')}` :
        `${this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.nova')}`;
    }

    getVeiculoPopup(posicao) {
      let veiculoPopup = {
        title: {
          text: posicao.prefixo + ' - ' + posicao.placa,
          linkRedirecionar: this.getLinkCadastroVeiculo(posicao.vei)
        },
        subTitle: posicao.uoNome,
        linkMaps: this.getGoogleMapsLink(posicao.lat, posicao.lng),
        linkStreetView: this.getStreetViewLink(posicao.lat, posicao.lng),
        linkTimeline: this.getLinkHistoricoTimeline(posicao.vei, posicao.data),
        permissaoTimeline: this.permissaoHistoricoTimeline(),
        alerta: posicao.statusGps,
        data: this.filter('date')(posicao.data, this.dateTimeFormat),
        rows: [{
          icon: 'fa-user',
          infos: [{
            label: this.translate.instant('ce.mapa.common.vpopupService.motorista'),
            value: posicao.motNome,
            linkRedirecionar: this.getLinkProntuarioMotorista(posicao.motoristaId),
            size: 9
          }, posicao.motDocIdentificador ? {
            label: this.translate.instant('ce.mapa.common.vpopupService.documentoIdentificador'),
            value: posicao.motDocIdentificador,
            size: 3
          } : {
            label: this.translate.instant('ce.mapa.common.vpopupService.cpf'),
            value: posicao.motCpf,
            size: 3
          }]
        }, {
          icon: 'trimble-vehicle',
          infos: [{
            label: this.translate.instant('ce.mapa.common.vpopupService.status'),
            value: this.filter('translate')(this.getVeiculoStatusi18n(posicao.status)),
            linkRedirecionar: this.getLinkAnaliseMovimento(posicao.vei),
            class: `text-uppercase label ${this.geraClass('STATUS', posicao.status)}`,
            size: 5
          }, {
            label: this.translate.instant('ce.mapa.common.vpopupService.velocidade'),
            value: this.filter('withLabel')(this.distanceMeasurement === 'KM' ? posicao.vel : this.convertersService.handleKmToMiles(posicao.vel), this.speedUnit),
            size: 3
          }, {
            label: this.translate.instant('ce.mapa.common.vpopupService.hodometro'),
            value: this.filter('decimalWithLabel')(posicao.hodo, this.distanceUnit),
            size: 4
          }]
        }, {
          icon: 'fa-podcast ',
          infos: [{
            label: this.translate.instant('ce.mapa.common.vpopupService.bloqueio'),
            class: `text-uppercase ${this.geraClass('BLOQUEIO', posicao.bloqueioPartida)}`,
            value: this.getBloqueio(posicao.bloqueioPartida),
            size: 5
          }, {
            label: this.translate.instant('ce.mapa.common.vpopupService.gsm'),
            class: `text-uppercase ${this.geraClass('GSM', posicao.gsm)}`,
            value: this.getRede(posicao.meio, posicao.gsm),
            size: 3
          }, {
            label: this.translate.instant('ce.mapa.common.vpopupService.bateria'),
            value: this.getBateria(posicao.fontAliment),
            size: 4
          }]
        }, {
          icon: 'fa-map-marker',
          infos: [{
            label: this.translate.instant('ce.mapa.common.vpopupService.logradouro'),
            value: this.getLogradouro(posicao),
            size: 6
          }, {
            label: this.translate.instant('ce.mapa.common.vpopupService.pontoDeReferencia'),
            value: this.getPontoRef(posicao.pontoProximo),
            size: 6
          }]
        }]
      };
      if (posicao.veiculoHasOcorrenciaNova) {
        veiculoPopup.rows.splice(1, 0, {
          icon: 'fa-exclamation-triangle red-icon',
          infos: [{
            label: this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.labelVeiculo'),
            value: this.getLabelNovaOcorrencia(posicao.ocorrenciasNovasDoVeiculo.length),
            qtdNovasOcorrencias: posicao.ocorrenciasNovasDoVeiculo.length,
            linkRedirecionar: this.getQuadroOcorrenciasLink(),
            veiculoHasOcorrenciaNova: posicao.veiculoHasOcorrenciaNova,
            ocorrenciaId: posicao.veiculoOcorrenciaNovaMaisRecente.id,
            class: `text-uppercase label custom-label`,
            size: 9
          }, {
            label: this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.geradaEm'),
            value: this.moment(posicao.veiculoOcorrenciaNovaMaisRecente.dataHora).format('HH:mm:ss'),
            size: 3
          }]
        });
      }

      if (this.permissaoLivestream()) {
        const rowIndex = posicao.veiculoHasOcorrenciaNova ? 3 : 2;
        veiculoPopup.rows[rowIndex].infos.splice(3, 0, {
          label: this.translate.instant('ce.mapa.common.vpopupService.videoTempoReal'),
          class: 'text-uppercase clickable-value',
          value: this.getTempoReal(posicao.hasStream, posicao.vmovOnline),
          size: 6,
          onClick: this.canDisplayLivestream(posicao) && {
            type: 'openVehicleHealthEvidences',
            initialTab: 2,
            canDisplayLivestream: this.canDisplayLivestream(posicao),
            veiculoPosicao: posicao
          } || undefined
        });
      }

      const videoVitalSigns = posicao.videoVitalSigns;
      if (videoVitalSigns) {
        const rowOrder = posicao.veiculoHasOcorrenciaNova ? 4 : 3;
        if (this.flagPremiumTelemetry && posicao.hasPremiumTelemetry) {
          veiculoPopup.rows.splice(rowOrder, 0, {
            infos: [{
              label: this.translate.instant(`${tooltipInfosTextPrefix}.cameras`),
              value: videoVitalSigns.cameras || '0',
              class: this.saudeVeiculoStatusToColorMap.default,
              size: 3,
              onClick: {
                type: 'openVehicleHealthEvidences',
                initialTab: 0,
                canDisplayLivestream: this.canDisplayLivestream(posicao),
                veiculoPosicao: posicao
              }
            }, {
              label: this.translate.instant(`${tooltipInfosTextPrefix}.fotos`),
              value: videoVitalSigns.lastPhoto ? this.moment(videoVitalSigns.lastPhoto).fromNow() : '-',
              class: this.saudeVeiculoStatusToColorMap.default,
              size: 3,
              onClick: {
                type: 'openVehicleHealthEvidences',
                initialTab: 0,
                canDisplayLivestream: this.canDisplayLivestream(posicao),
                veiculoPosicao: posicao
              }
            }, {
              label: 'SD CARD',
              value: this.getStorageHealth(videoVitalSigns.storageHealth),
              class: this.getSemaphoreItemColorClass(videoVitalSigns.storageHealth),
              size: 4
            }]
          });
        } else {
          veiculoPopup.rows.splice(rowOrder, 0, {
            icon: videoVitalSigns.videoSemaphoreStatusConfig && videoVitalSigns.videoSemaphoreStatusConfig.style,
            infos: [{
              label: this.translate.instant(`${tooltipInfosTextPrefix}.eventoComportamento`),
              value: videoVitalSigns.lastBehaviorEvent ? this.moment(videoVitalSigns.lastBehaviorEvent).fromNow() : '-',
              class: this.getSemaphoreItemColorClass(videoVitalSigns.statusBehaviorEvent),
              size: 5
            }, {
              label: this.translate.instant(`${tooltipInfosTextPrefix}.fotos`),
              value: videoVitalSigns.lastPhoto ? this.moment(videoVitalSigns.lastPhoto).fromNow() : '-',
              class: this.saudeVeiculoStatusToColorMap.default,
              size: 3,
              onClick: {
                type: 'openVehicleHealthEvidences',
                initialTab: 0,
                canDisplayLivestream: this.canDisplayLivestream(posicao),
                veiculoPosicao: posicao
              }
            }, {
              label: 'SD CARD',
              value: this.getStorageHealth(videoVitalSigns.storageHealth),
              class: this.getSemaphoreItemColorClass(videoVitalSigns.storageHealth),
              size: 4
            },
            {
              label: this.translate.instant(`${tooltipInfosTextPrefix}.cameras`),
              value: videoVitalSigns.cameras || '0',
              class: this.saudeVeiculoStatusToColorMap.default,
              size: 5,
              onClick: {
                type: 'openVehicleHealthEvidences',
                initialTab: 0,
                canDisplayLivestream: this.canDisplayLivestream(posicao),
                veiculoPosicao: posicao
              }
            }, {
              label: this.translate.instant(`${tooltipInfosTextPrefix}.video`),
              value: videoVitalSigns.lastVideo ? this.moment(videoVitalSigns.lastVideo).fromNow() : '-',
              class: this.saudeVeiculoStatusToColorMap.default,
              size: 5,
              onClick: {
                type: 'openVehicleHealthEvidences',
                initialTab: 1,
                canDisplayLivestream: this.canDisplayLivestream(posicao),
                veiculoPosicao: posicao
              }
            }]
          });
        }
      }
      return veiculoPopup;
    }

    permissaoHistoricoTimeline() {
      return this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('HISTORICO_DETALHADO_VISUALIZAR');
    }

    permissaoLivestream() {
      return this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('VPLAYER_LIVESTREAM_VISUALIZAR');
    }

    canDisplayLivestream(posicao) {
      return this.permissaoLivestream() && posicao.hasStream;
    }

    getLinkHistoricoTimeline(veiculoId, datatime) {
      if (this.permissaoHistoricoTimeline()) {
        return `/historico-timeline?vfiltro=${this.getFiltroHistoricoTimeline(veiculoId, datatime)}`;
      }
    }

    getFiltroHistoricoTimeline(veiculoId, datatime) {
      const eventoIsoDate = new Date(datatime).toISOString();
      let vfiltro = angular.copy(this.serviceVfiltro.filtroTopbar).filter(x => x.key !== this.keys.veiculos.name);
      vfiltro.unshift({
        key: this.keys.dateTime.name,
        value: {
          startDate: this.moment(datatime).startOf('day'),
          endDate: datatime,
          tipo: 'personalizado'
        }
      });
      vfiltro.push({
        key: this.keys.veiculo.name,
        value: {id: veiculoId}
      });
      return `${this.urlService.getObjectVfiltro(vfiltro)}&date=${eventoIsoDate}&ultimo=true`;
    }

    getGoogleMapsLink(latitude, longitude) {
      return `https://www.google.com/maps/@${latitude},${longitude},16z/data=!5m1!1e1`;
    }

    getStreetViewLink(latitude, longitude) {
      return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
    }

    getRede(meio, gsm) {
      if (meio === 'SAT' && gsm) {
        return this.translate.instant('ce.mapa.common.gsmSatelital');
      } else if (gsm) {
        return this.translate.instant('ce.mapa.common.gsmOnline');
      }
      return this.translate.instant('ce.mapa.common.gsmOffline');
    }

    getVeiculoStatusi18n(status) {
      if (!status) {
        return '';
      }
      let statusi18n = this.veiculoStatusEnum.getStatusById(status.toUpperCase());
      return statusi18n ? statusi18n.descricao : '';
    }

    getTempoReal(hasStream, vmovOnline) {
      if (hasStream && vmovOnline) {
        return this.translate.instant('ce.mapa.common.vpopupService.assistirTempoReal');
      }
      return '';
    }

    getSemaphoreItemColorClass(status) {
      if (status in this.saudeVeiculoStatusToColorMap.byStatus) {
        return this.saudeVeiculoStatusToColorMap.byStatus[status];
      }

      return this.saudeVeiculoStatusToColorMap.default;
    }

    getStorageHealth(storageHealth) {
      if (storageHealth === 'HEALTHY') {
        return this.translate.instant(`${storageHealthTextPrefix}.funcionando`);
      } else if (storageHealth === 'NOT_HEALTHY') {
        return this.translate.instant(`${storageHealthTextPrefix}.comProblema`);
      }
      return 'N/A';
    }
  }

  angular
    .module('mapas')
    .service('VpopupService', VpopupService);
}());
