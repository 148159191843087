(function () {
  'use strict';

  angular
    .module('mapControlModule', [
      'enumModule',
      'relatorios',
      'ui.bootstrap',
      'colorpicker.module',
      'ng-mfb',
      'configuracoesEvento',
      'mapas',
      'ModuleEventos',
      'InputCheckboxModule',
      'rotogramaModule',
      'alert-message',
      'reprodRotasModule',
      'pascalprecht.translate',
      'httpServiceV3gModule',
      'urlConfigModule',
      'ModuleMapasUtil',
      'FeatureFlagModule',
      'uoModule',
      'ServiceVfiltroModule',
      'reportwrongspeedviaModule',
      'ModuleConvertersUtil',
      'ngSanitize'
    ])
    .constant('TIPOS_CATEGORIA', {
      AREA_RISCO: {id: 'AREA_RISCO', descricao: 'Área de Risco'},
      GARAGEM: {id: 'GARAGEM', descricao: 'Garagem'}
    })
    .constant('LayerTypeEnum', {
      MARKER: 'marker',
      POLYGON: 'polygon',
      RECTANGLE: 'rectangle'
    });
}());
