(function () {
  'use strict';
  const FIELDS_OCORR = [
    'id',
    'uoId',
    'dataHora',
    'status',
    'alertasGerados',
    'veiculoId',
    'veiculo.id',
    'veiculo.placa',
    'veiculo.prefixo',
    'veiculo.grupo.id',
    'veiculo.grupo.nome',
    'veiculo.uo.nome',
    'veiculo.uo.id',
    'motorista.id',
    'motorista.nome',
    'motorista.uo.id',
    'motorista.uoId',
    'motorista.uo.nome',
    'motorista.departamentoId',
    'motorista.departamento.id',
    'motorista.departamento.nome',
    'usuarioIdAtribuido'
  ];

  class OcorrenciasDetalhes {
    constructor(HttpServiceV3g, $httpParamSerializer) {
      this.httpService = HttpServiceV3g;
      this.httpParamSerializer = $httpParamSerializer;
    }

    getOcorrenciaById(idOcorrencia, datahora) {
      const params = this.httpParamSerializer({
        fields: FIELDS_OCORR.join(),
        inicio: datahora,
        id: idOcorrencia
      });

      return this.httpService.get(`/alerta/ocorrencias?${params}`);
    }
  }

  angular
    .module('relatorios.ocorrenciaDetalhes')
    .service('OcorrenciasDetalhes', OcorrenciasDetalhes);
}());
