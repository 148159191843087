(function () {
  'use strict';

  angular
    .module('cadastros.motorista.filters', [])
    .filter('hexa', () => {
      return (codigo, isHexa) => {
        return isHexa && codigo ? Number(codigo).toString(16).toUpperCase() : codigo;
      };
    })
    .filter('dec', () => {
      return (codigo, isHexa) => {
        return isHexa && codigo ? parseInt(codigo, 16) : codigo;
      };
    });
}());
