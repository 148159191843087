(function () {
  'use strict';

  /* global _ */
  class AgrupamentoRotogramasCtrl {
    constructor(CadastroRotogramaService, RotogramaComponentStates, ControleVelocidadeRotograma, PosicoesRotogramas, $translate) {
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.rotogramaComponentStates = RotogramaComponentStates;
      this.controleVelocidadeRotograma = ControleVelocidadeRotograma;
      this.posicoesRotogramas = PosicoesRotogramas;
      this.translate = $translate;
    }

    getIconContent(rotograma) {
      return rotograma.state === this.rotogramaComponentStates.EXCLUSAO ? '+' : rotograma.indice;
    }

    getControlesVelocidadeRotograma() {
      return _.values(this.controleVelocidadeRotograma).filter(opcao => opcao.visible);
    }

    getGruposVelocidade() {
      return this.cadastroRotogramaService.getGruposVelocidade();
    }

    getGruposVeiculo() {
      return this.cadastroRotogramaService.getGruposVeiculo();
    }

    getTiposVeiculo() {
      return this.cadastroRotogramaService.getTiposVeiculo();
    }

    getTooltipUtilizaVelocidadeAnterior() {
      return this.translate.instant('ce.mapa.common.modal.agrupamentoRotogramas.tooltipUtilizaVelocidadeAnterior');
    }

    habilitarAdicaoProGrupo() {
      this.cadastroRotogramaService.setAgrupamentoAtivo(this.agrupamento);
      this.cadastroRotogramaService.destacarRotogramasDisponiveis();
    }

    adicionarRegraSeNaoExistir() {
      if (this.agrupamento.usarRegrasEspecificas && (!this.agrupamento.regras || this.agrupamento.regras.length === 0)) {
        this.cadastroRotogramaService.adicionarRegra(this.agrupamento);
      }
    }

    removerRegra(regra) {
      this.agrupamento.regras.splice(this.agrupamento.regras.indexOf(regra), 1);
    }

    deveExibirVelocidade() {
      return this.agrupamento.controleVelocidade !== this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR;
    }

    deveExibirTooltipUtilizaVelocidadeAnterior() {
      return this.agrupamento.controleVelocidade === this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR &&
        (!this.contemRotogramaInterno() || this.agrupamento.rotogramas.length > 1);
    }

    isControleVelocidadeValido() {
      return !this.contemRotogramaInterno() ||
        this.agrupamento.controleVelocidade !== this.controleVelocidadeRotograma.UTILIZA_VELOCIDADE_ANTERIOR;
    }

    contemRotogramaInterno() {
      return this.agrupamento.rotogramas
        .some(rotograma => rotograma.marker.options.posicao === this.posicoesRotogramas.CENTRO);
    }
  }

  angular
    .module('rotogramaModule')
    .component('agrupamentoRotogramas', {
      templateUrl: 'directives/map-control/rotograma/agrupamento-rotogramas.tpl.html',
      controller: AgrupamentoRotogramasCtrl,
      bindings: {
        agrupamento: '<'
      }
    });
}());
