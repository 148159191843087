(function () {
  'use strict';

  angular
    .module('cadastros.pontoReferencia')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.pontoReferencia', {
      url: '/ponto-referencia',
      views: {
        'content@': {
          templateUrl: 'cadastros/ponto-referencia/ponto-referencia.tpl.html',
          controller: 'PontoReferenciaCtrl',
          controllerAs: 'pontoReferenciaCtrl'
        }
      },
      data: {
        titulo: 'ce.cadastro.pontosReferencia.titulo',
        tituloDescricao: '',
        role: 'PONTO_REFERENCIA_VISUALIZAR'
      }
    });
  }
}());
