(function () {
  'use strict';

  const languages = {
        PT_BR: {
          underline: 'PT_BR',
          hyphen: 'PT-BR',
          moment: 'pt-br'
        },
        EN_US: {
          underline: 'EN_US',
          hyphen: 'EN-US',
          moment: 'en-us'
        },
        ES_ES: {
          underline: 'ES_ES',
          hyphen: 'ES-ES',
          moment: 'es'
        }
      },
      funcoes = {
        getLanguage: (id) => languages[id],
        getLanguagesObject: () => languages,
        getKeys: () => Object.keys(languages)
      };

  angular
    .module('enumModule')
    .constant('LanguagesEnum', Object.assign({}, languages, funcoes));
}());
