(function () {
  'use strict';

  class PontosReferencia {

    constructor(Restangular, HttpServiceV3g) {
      this.http = HttpServiceV3g;
      this.restangular = Restangular;
      this.roleCadastrar = false;
      this.roleVisualizar = false;
      this.roleVisualizarRotograma = false;
      this.user = null;
      this.categorias = [];
      this.uos = null;
      this.rotograma = false;
    }

    getListPontoReferencia(params = {rotograma: true, geocoding: false}) {
      return this.http.get('/ponto-referencia/pontos-referencia/permissao', {
        params: params
      });
    }

    getListPontoReferenciaRelatorio(params = {rotograma: true, geocoding: false}) {
      return this.restangular.all('ponto-referencia/pontos-referencia/permissao/relatorio').getList(params);
    }

    getPontoReferencia(idPonto) {
      return this.restangular.one(`ponto-referencia/pontos-referencia/permissao/${idPonto}`).get();
    }

    postPontoReferencia(item) {
      return this.restangular.all('ponto-referencia/pontos-referencia').post(item);
    }

    putPontoReferencia(item) {
      return this.restangular.all(`ponto-referencia/pontos-referencia/${item.id}`).customPUT(item);
    }

    deletePontoReferencia(idPonto) {
      return this.restangular.all(`ponto-referencia/pontos-referencia/${idPonto}`).remove();
    }

    getInfoGeocode(lat, lon) {
      return this.restangular.one('/geocode/localizacao/', 'getByLatLong').get({lat, lon});
    }

    getRotograma(id) {
      return this.restangular.all(`/ponto-referencia/config-rotograma?rotograma=${id}`).getList();
    }

    getPontosReferenciaV2(params) {
      return this.restangular.all(`/ponto-referencia/v2/pontos-referencia`).getList(params);
    }

    getCadastroPontoConfig() {
      return this.restangular.one('ponto-referencia/regra').get();
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosReferencia', PontosReferencia);
}());
