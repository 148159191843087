(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.indicadorPowerBi', {
      url: '/bi?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'indicadores/power-bi/power-bi.tpl.html',
          controller: 'PowerBiCtrl',
          controllerAs: 'powerBi'
        }
      },
      data: {
        titulo: 'Indicadores de BI',
        role: 'INDICADOR_POWER_BI_VISUALIZAR',
        hideTitulo: true
      }
    });
  }

  angular
    .module('indicadores.powerBi')
    .config(pageConfig);
}());
