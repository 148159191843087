(function () {
  'use strict';

  angular
    .module('relatorios.bdm')
    .constant('MockBDM', [
      {
        id: 1,
        data: {
          total: 2,
          list: [
            {
              motorista: {id: 1, nome: 'André Castanheira'},
              departamento: 'Coletas',
              veiculos: 'VW81502-AOI7238, FSADF45-VXZ9045',
              dia: '2016-01-15',
              excessosVelocidade: 900,
              distanciaPercorrida: 10000,
              aceleracoes: 5,
              frenagens: 2,
              curvas: 3,
              tempoDirecao: 3600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            },
            {
              motorista: {id: 1, nome: 'André Castanheira'},
              departamento: 'Coletas',
              veiculos: 'VW81502-AOI7238, FSADF45-VXZ9045',
              dia: '2016-01-15',
              excessosVelocidade: 90,
              distanciaPercorrida: 2341,
              aceleracoes: 1,
              frenagens: 0,
              curvas: 0,
              tempoDirecao: 1600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            }
          ]
        }
      },
      {
        id: 2,
        data: {
          total: 2,
          list: [
            {
              motorista: {id: 2, nome: 'Gil Larangeira'},
              departamento: 'Coletas',
              veiculos: 'ANGA01-ANR1131',
              dia: '2016-01-15',
              excessosVelocidade: 0,
              distanciaPercorrida: 10000,
              aceleracoes: 0,
              frenagens: 1,
              curvas: 1,
              tempoDirecao: 3600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            },
            {
              motorista: {id: 2, nome: 'Gil Larangeira'},
              departamento: 'Coletas',
              veiculos: 'ANGA01-ANR1131',
              dia: '2016-01-15',
              excessosVelocidade: 50,
              distanciaPercorrida: 2536,
              aceleracoes: 1,
              frenagens: 1,
              curvas: 2,
              tempoDirecao: 1600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            }
          ]
        }
      },
      {
        id: 3,
        data: {
          total: 2,
          list: [
            {
              motorista: {id: 3, nome: 'Iberê Lages'},
              departamento: 'Entregas',
              veiculos: 'VCBZ45-UKT3556',
              dia: '2016-01-15',
              excessosVelocidade: 100,
              distanciaPercorrida: 1000,
              aceleracoes: 5,
              frenagens: 1,
              curvas: 1,
              tempoDirecao: 3600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            },
            {
              motorista: {id: 3, nome: 'Iberê Lages'},
              departamento: 'Entregas',
              veiculos: 'VCBZ45-UKT3556',
              dia: '2016-01-15',
              excessosVelocidade: 50,
              distanciaPercorrida: 2536,
              aceleracoes: 1,
              frenagens: 1,
              curvas: 2,
              tempoDirecao: 1600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            }
          ]
        }
      },
      {
        id: 4,
        data: {
          total: 2,
          list: [
            {
              motorista: {id: 4, nome: 'Silvério Mansilla'},
              departamento: 'Coletas',
              veiculos: 'VCBZ45-UKT3556',
              dia: '2016-01-15',
              excessosVelocidade: 100,
              distanciaPercorrida: 1000,
              aceleracoes: 5,
              frenagens: 1,
              curvas: 1,
              tempoDirecao: 3600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            },
            {
              motorista: {id: 4, nome: 'Silvério Mansilla'},
              departamento: 'Coletas',
              veiculos: 'VCBZ45-UKT3556',
              dia: '2016-01-15',
              excessosVelocidade: 50,
              distanciaPercorrida: 2536,
              aceleracoes: 1,
              frenagens: 1,
              curvas: 2,
              tempoDirecao: 1600,
              trepidacoes: 0,
              banguelas: 1,
              rpmExcessivos: 1,
              tempoMotorOcioso: 0,
              logradouroInicio: 'Avenida Santos Dumont',
              logradouroFim: 'Rua Fernando de Noronha'
            }
          ]
        }
      }
    ])
    .constant('MockBDMDetalhes', [
      {
        motoristaId: 28232,
        veiculoId: 14932,
        veiculo: 'MLY3551 - MLY3551',
        motorista: 'Daniel Cesar Romano Luvizotto',
        posicoes: [
          {
            latitude: -23.317081451416016,
            longitude: -51.14942932128906,
            direcao: 143,
            hodometro: 2472000,
            hodometroAjustado: -1781100,
            velocidade: 0,
            gsm: true,
            evento: 'Ignição ligada',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:30:06.000Z'
          },
          {
            latitude: -23.31456184387207,
            longitude: -51.151676177978516,
            direcao: 282,
            hodometro: 2472000,
            hodometroAjustado: -1781100,
            velocidade: 38,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:30:26.000Z'
          },
          {
            latitude: -23.314363479614258,
            longitude: -51.15803909301758,
            direcao: 267,
            hodometro: 2473000,
            hodometroAjustado: -1780100,
            velocidade: 19,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:32:23.000Z'
          },
          {
            latitude: -23.314422607421875,
            longitude: -51.16783142089844,
            direcao: 275,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 40,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:37:01.000Z'
          },
          {
            latitude: -23.314300537109375,
            longitude: -51.16800308227539,
            direcao: 310,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 27,
            gsm: true,
            evento: 'Curva brusca',
            limiar: 'Limite: 0.6097 G',
            intensidade: '',
            gps: 'Inválido',
            duracao: 0,
            data: '2017-12-11T19:37:04.000Z'
          },
          {
            latitude: -23.314300537109375,
            longitude: -51.16800308227539,
            direcao: 310,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 27,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:37:04.000Z'
          },
          {
            latitude: -23.314014434814453,
            longitude: -51.16798782348633,
            direcao: 354,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 36,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:37:07.000Z'
          },
          {
            latitude: -23.31338119506836,
            longitude: -51.167945861816406,
            direcao: 44,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:37:19.000Z'
          },
          {
            latitude: -23.313220977783203,
            longitude: -51.16796112060547,
            direcao: 2,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 25,
            gsm: true,
            evento: 'Aceleração Brusca',
            limiar: 'Limite: 18,5 Km/h/s',
            intensidade: '',
            gps: 'Inválido',
            duracao: 0,
            data: '2017-12-11T19:37:34.000Z'
          },
          {
            latitude: -23.313220977783203,
            longitude: -51.16796112060547,
            direcao: 2,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 25,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:37:34.000Z'
          },
          {
            latitude: -23.307857513427734,
            longitude: -51.16816329956055,
            direcao: 295,
            hodometro: 2474000,
            hodometroAjustado: -1779100,
            velocidade: 14,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:39:05.000Z'
          },
          {
            latitude: -23.30779266357422,
            longitude: -51.17018508911133,
            direcao: 264,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 12,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:39:26.000Z'
          },
          {
            latitude: -23.307714462280273,
            longitude: -51.1707763671875,
            direcao: 271,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 28,
            gsm: true,
            evento: 'Movimento',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:40:20.000Z'
          },
          {
            latitude: -23.30774688720703,
            longitude: -51.17216873168945,
            direcao: 230,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 7,
            gsm: true,
            evento: 'Curva brusca',
            limiar: 'Limite: 0.6097 G',
            intensidade: '',
            gps: 'Inválido',
            duracao: 0,
            data: '2017-12-11T19:40:39.000Z'
          },
          {
            latitude: -23.30774688720703,
            longitude: -51.17216873168945,
            direcao: 230,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:40:39.000Z'
          },
          {
            latitude: -23.30780029296875,
            longitude: -51.17219161987305,
            direcao: 189,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:40:45.000Z'
          },
          {
            latitude: -23.30794906616211,
            longitude: -51.1721305847168,
            direcao: 130,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:40:57.000Z'
          },
          {
            latitude: -23.30797576904297,
            longitude: -51.17205810546875,
            direcao: 81,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:41:03.000Z'
          },
          {
            latitude: -23.307994842529297,
            longitude: -51.171695709228516,
            direcao: 37,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Parado',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:41:24.000Z'
          },
          {
            latitude: -23.3079833984375,
            longitude: -51.17171096801758,
            direcao: 37,
            hodometro: 2475000,
            hodometroAjustado: -1778100,
            velocidade: 0,
            gsm: true,
            evento: 'Ignição desligada',
            limiar: '-',
            intensidade: '-',
            gps: 'Precisão alta',
            duracao: 0,
            data: '2017-12-11T19:45:24.000Z'
          }
        ],
        banguela: 0,
        excessoVelocidade: 0,
        rpmExcessivo: 0,
        curvasBruscas: 2,
        aceleracoesBruscas: 1,
        trepidacoes: 0,
        distanciaPercorrida: 3000,
        inicio: '2017-12-11T19:29:53Z',
        fim: '2017-12-11T19:45:24Z',
        departamento: 'PRODUTO',
        freadasBruscas: 0,
        motorOcioso: 0,
        localChegada: 'Rua Fernando de Noronha',
        localSaida: 'Avenida Santos Dumont'
      }
    ]);
}());
