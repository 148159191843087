(function () {
  'use strict';

  class SuggestionCardCtrl {
    constructor($translate, Authenticator, ConvertersService) {
      this.translate = $translate;
      this.authenticator = Authenticator;
      this.converter = ConvertersService;
      this.authenticator.getUser().then((user) => {
        this.userSpeedMeasurement = user.measurementUnits.speedMeasurement;
      });
    }

    getTotalEventosBruscos() {
      const keyEvents = Object.keys(this.suggestion.eventos);

      return keyEvents.reduce((acc, keyEvent) => acc + this.suggestion.eventos[keyEvent], 0);
    }

    getMinSpeed() {
      const speedUnit = this.userSpeedMeasurement,
          speedValues = Object.keys(this.suggestion.velocidadeInterna).map(
          (key) => {
            return this.suggestion.velocidadeInterna[key];
          }
        ),
          speed = Math.min(...speedValues);

      if (speedUnit === 'MILES') {
        return this.converter.handleKmToMiles(speed);
      }

      return speed;
    }

    getLogradouro() {
      return this.suggestion.logradouro ?
              this.suggestion.logradouro :
              this.translate.instant('ce.common.logradouroNaoEncontrado');
    }
  }

  angular
    .module('mapControlModule')
    .component('suggestionCard', {
      controller: SuggestionCardCtrl,
      templateUrl: 'directives/map-control/advisor-rotogramas/suggestion-card-component.tpl.html',
      bindings: {
        suggestion: '<',
        index: '<'
      }
    });
}());
