(function () {
  'use strict';

  function DescarteEventos() {
    return {
      templateUrl: `configuracoes/eventos/subpaginas/descarte/descarte-eventos.tpl.html`,
      controller: 'DescarteEventosCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {}
    };
  }

  angular
    .module('SubpaginasEventos')
    .directive('descarteEventos', DescarteEventos);
}());
