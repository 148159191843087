(function () {
  'use strict';

  angular
    .module('reportwrongspeedviaModule')
    .constant('URL_GOOGLE_FORMS', 'https://docs.google.com/forms/d/e/1FAIpQLSeiK6r2qgaDmOR87d8keO6kmQOce_xaDECOZQPG9uKqkWI1eg/viewform')
    .constant('FORM_FIELD_NOME', 'entry.1172079207')
    .constant('FORM_FIELD_EMAIL', 'entry.1373458914')
    .constant('FORM_FIELD_COORDENADAS', 'entry.1196063270')
    .constant('UO_IDS_PERMITIDAS', [5373, 3854]);
}());
