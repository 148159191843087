(function () {
  'use strict';

  function v3gTableLimitDirective(v3gTableLimitService) {
    return {
      restrict: 'EA',
      templateUrl: 'directives/v3g-table-limit/v3g-table-limit.tpl.html',
      link: function (scope) {
        scope.v3gTableLimitService = v3gTableLimitService;
        scope.options = {
          10: '10',
          20: '20',
          50: '50'
        };
        scope.stItemsByPage = v3gTableLimitService.stItemsByPage;

        scope.changeValue = () => {
          v3gTableLimitService.salvarLimite(scope.stItemsByPage);
        };

        scope.$watch('v3gTableLimitService.stItemsByPage', (newValue) => {
          if (scope.stItemsByPage !== newValue) {
            scope.stItemsByPage = newValue;
          }
        });
      }
    };
  }

  angular
    .module('smart-table')
    .directive('v3gTableLimitDirective', v3gTableLimitDirective);
}());
