(function () {
  'use strict';

  angular
    .module('cadastros.motorista')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.motorista', {
      url: '/motorista?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'cadastros/motorista/motorista.tpl.html',
          controller: 'MotoristaCtrl',
          controllerAs: 'motoristaCtrl'
        }
      },
      data: {
        titulo: 'ce.cadastro.motoristas.titulo',
        tituloDescricao: '',
        roles: [['MOTORISTA_MENU_VISUALIZAR', 'DADOS_SENSIVEIS_VISUALIZAR']]
      }
    });
  }
}());
