(function () {
  'use strict';

  const MAX_ZINDEX = 9999999;

  /* global OverlappingMarkerSpiderfier */
  class SpiderfierService {
    createSpiderfier(map) {
      let oms = new OverlappingMarkerSpiderfier(map);

      oms.addListener('click', event => event.setZIndexOffset(MAX_ZINDEX));

      return oms;
    }
  }

  angular
    .module('mapas')
    .service('SpiderfierService', SpiderfierService);
}());
