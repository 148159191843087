(function () {
  'use strict';

  class DirecaoSeguraCtrl {

    constructor(DirecaoSegura, moment, ColumnChart, $window, IDDirecaoSegura, URLDirecaoSegura, IndicadoresService, Uos,
                Onboarding, MockDirecaoSegura, FactoryVfiltro, ServiceVfiltro, Keys, ObjetosVfiltroService, $translate) {
      this.service = DirecaoSegura;
      this.moment = moment;
      this.window = $window;
      this.onboarding = Onboarding;
      this.mockDirecaoSegura = MockDirecaoSegura;
      this.URLDirecaoSegura = URLDirecaoSegura;
      this.indicadoresService = IndicadoresService;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.translate = $translate;

      this.comparativoChart = ColumnChart.get(2);
      this.tituloComparativoChart = this.translate.instant('ce.indicadores.common.charts.comparativoChart.titulo');
      this.comparativoChart.setTitleText(this.tituloComparativoChart);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.comparativoChart.setClickEvent(this.clickBinding().onClickColumn);
      this.comparativoChart.withComparativeGradient();

      this.periodoChart = ColumnChart.get(2);
      this.tituloPeriodoChart = this.translate.instant('ce.indicadores.direcaoSegura.charts.periodoChart.titulo');
      this.periodoChart.setTitleText(this.tituloPeriodoChart);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.periodoChart.setClickEvent(this.clickBinding().onClickColumn);

      this.uoChart = ColumnChart.get(2);
      this.tituloUoChart = this.translate.instant('ce.indicadores.direcaoSegura.charts.uoChart.titulo');
      this.uoChart.setTitleText(this.tituloUoChart);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.uoChart.setClickEvent(this.clickBinding().onClickColumnUO);

      this.isDemo = this.onboarding.startOnboarding(IDDirecaoSegura);
      Uos.isDemo = this.isDemo;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'fat_conducao.dia',
          key: this.keys.dateTime.name,
          dateLimit: 180,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          key: this.keys.uo.name
        },
        {
          name: 'fat_conducao.uo_veiculo_id',
          key: this.keys.uoParceira.name
        }
      ]))
      .then((filtro) => {
        this.loadData(ServiceVfiltro.getFiltroBackendAntigo(filtro), ServiceVfiltro.getFiltroBackendNovo(filtro));
      });
    }

    loadData(filtro, filtroNovo) {
      if (this.isDemo) {
        this.setInfoGrafico(this.mockDirecaoSegura);
      } else {
        const inicio = this.moment(filtroNovo.inicio).format('YYYY-MM-DD'),
            fim = this.moment(filtroNovo.fim).format('YYYY-MM-DD');
        this.service.one().get({filtros: filtro, inicio, fim}).then(data => this.setInfoGrafico(data));
      }
    }

    setInfoGrafico(data) {
      this.comparativoChart.setData(data.comparativoChart);
      this.comparativoChart.disableLegend();
      this.periodoChart.setData(data.periodoChart);
      this.footer = data.footer;

      let series = data.uoChart.series.map(
        serie => ({name: serie.name, data: serie.atual, compareData: serie.anterior, type: serie.type}));
      this.uoChart.setData({
        categorias: data.uoChart.categorias,
        series,
        opcoes: data.uoChart.opcoes
      });
    }

    clickBinding() {
      let self = this;

      return {
        onClickColumn: function () {
          let evento = this.series.name,
              chart = self.getChart(this.series.chart.title.textStr),
              periodo = this.series.stackKey === 'columnanterior' ? self.comparativoChart.columnSeries.opcoes.periodos[0] : chart.columnSeries.opcoes.periodos[this.index],
              uoId = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id,
              uoParceira = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uoParceira.name),
              filtros = self.indicadoresService.getFiltrosParaRanking(periodo, uoId, uoParceira.value && uoParceira.value.id, evento);
          self.window.location.href = `${self.URLDirecaoSegura}?${self.isDemo ? 'demo' : ''}&${filtros}`;
        },
        onClickColumnUO: function () {
          let evento = this.series.name,
              chart = self.getChart(this.series.chart.title.textStr),
              periodo = this.series.stackKey === 'columnanterior' ? self.comparativoChart.columnSeries.opcoes.periodos[0] : chart.columnSeries.opcoes.periodos[this.index],
              uoChart = chart.columnSeries.opcoes.uos && chart.columnSeries.opcoes.uos[this.index],
              uoPai = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id,
              listaUos = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).defaultValues,
              uoParceira = !listaUos.some(x => x.id === uoChart.uoId) && uoChart.uoId,
              filtros = self.indicadoresService.getFiltrosParaRanking(periodo, !uoParceira ? uoChart.uoId : uoPai, uoParceira, evento);
          self.window.location.href = `${self.URLDirecaoSegura}?${self.isDemo ? 'demo' : ''}&${filtros}`;
        }
      };
    }

    getChart(chartLabel) {
      switch (chartLabel) {
        case 'Eventos por Período':
          return this.periodoChart;
        case 'Comparativo entre Períodos':
          return this.comparativoChart;
        case 'Eventos por Unidade Organizacional':
          return this.uoChart;
        default:
          return undefined;
      }
    }

    traduzLabelsCharts() {
      this.tituloComparativoChart = this.translate.instant('ce.indicadores.common.charts.comparativoChart.titulo');
      this.comparativoChart.setTitleText(this.tituloComparativoChart);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.tituloPeriodoChart = this.translate.instant('ce.indicadores.direcaoSegura.charts.periodoChart.titulo');
      this.periodoChart.setTitleText(this.tituloPeriodoChart);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.tituloUoChart = this.translate.instant('ce.indicadores.direcaoSegura.charts.uoChart.titulo');
      this.uoChart.setTitleText(this.tituloUoChart);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.direcaoSegura.charts.yEventos'), 0);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
    }
  }

  angular
    .module('indicadores.DirecaoSegura')
    .controller('DirecaoSeguraCtrl', DirecaoSeguraCtrl);
}());
