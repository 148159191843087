(function () {
  'use strict';

  angular
    .module('configVdrivers')
    .config(function ($stateProvider) {
      return $stateProvider.state('root.configVdrivers', {
        url: '/configuracoes-vdrivers/:tipoSubpagina?vfiltro=',
        views: {
          'content@': {
            templateUrl: 'configuracoes/vdrivers/configuracoes-vdrivers.tpl.html',
            controller: 'ConfigVdriversCtrl',
            controllerAs: 'configVdriversCtrl'
          }
        },
        data: {
          titulo: 'Configurações do Vdrivers',
          tituloDescricao: '',
          role: 'CONFIG_VDRIVERS_VISUALIZAR'
        }
      });
    });
}());
