(function () {
  'use strict';
  /* global _ */
  class ConducaoEconomicaCtrl {

    constructor(ConducaoEconomica, moment, ColumnChart, $window, URLConducaoEconomica, GoogleTagManagerUtils,
            IndicadoresService, ServiceVfiltro, FactoryVfiltro, Keys, ObjetosVfiltroService, $translate) {
      this.service = ConducaoEconomica;
      this.moment = moment;
      this.window = $window;
      this.URLConducaoEconomica = URLConducaoEconomica;
      this.indicadoresService = IndicadoresService;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.translate = $translate;
      this.colors = [
        'rgb(204,38,54)',
        'rgb(103,144,171)',
        'rgb(242,153,74)',
        '#c07d0e'
      ];
      this.googleTagManagerUtils = GoogleTagManagerUtils;

      this.comparativoChart = ColumnChart.get(2, this.colors);
      this.tituloComparativoChart = this.translate.instant('ce.indicadores.common.charts.comparativoChart.titulo');
      this.comparativoChart.setTitleText(this.tituloComparativoChart);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.comparativoChart.setClickEvent(this.clickBinding().onClickColumn);
      this.comparativoChart.withComparativeGradient();
      this.comparativoChart.setDateTooltip();

      this.periodoChart = ColumnChart.get(2, this.colors);
      this.tituloPeriodoChart = this.translate.instant('ce.indicadores.conducaoEconomica.charts.periodoChart.titulo');
      this.periodoChart.setTitleText(this.tituloPeriodoChart);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.periodoChart.setClickEvent(this.clickBinding().onClickColumn);
      this.periodoChart.setDateTooltip();

      this.uoChart = ColumnChart.get(2, this.colors);
      this.tituloUoChart = this.translate.instant('ce.indicadores.conducaoEconomica.charts.uoChart.titulo');
      this.uoChart.setTitleText(this.tituloUoChart);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.uoChart.setClickEvent(this.clickBinding().onClickColumnUO);
      this.uoChart.setDateTooltip();

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          name: 'fat_conducao.dia',
          key: this.keys.dateTime.name,
          dateLimit: 180,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          key: this.keys.uo.name
        },
        {
          name: 'fat_conducao.uo_veiculo_id',
          key: this.keys.uoParceira.name
        }
      ]))
      .then((filtro) => {
        this.loadData(ServiceVfiltro.getFiltroBackendAntigo(filtro));
      });
    }

    loadData(filtro) {
      this.service.one().get({filtros: filtro}).then(data => this.setInfoGrafico(data));
    }

    sortUoChart(eventosAtuaisTotal) {
      return (a, b) => {
        if (eventosAtuaisTotal[a] > eventosAtuaisTotal[b]) {
          return -1;
        } else if (eventosAtuaisTotal[b] > eventosAtuaisTotal[a]) {
          return 1;
        }
        return 0;
      };
    }

    reorderUoChart(uoChart) {
      let uoChartSorted = _.cloneDeep(uoChart), uoChartOld = _.cloneDeep(uoChart),
          eventosAtuaisConjunto = [], eventosAtuaisTotal, indicesReordenados;

      uoChartOld.series = uoChartOld.series.filter((serie) => serie.type !== 'VEICULOS_QTD');
      uoChartOld.series.forEach((evento) => {
        eventosAtuaisConjunto.push(evento.atual);
      });

      eventosAtuaisTotal = eventosAtuaisConjunto.reduce((soma, eventos) => eventos.map((valor, i) => soma[i] + valor));
      indicesReordenados = _.range(eventosAtuaisTotal.length)
                  .sort(this.sortUoChart(eventosAtuaisTotal));

      indicesReordenados.map((elementoOrdenado, index) => {
        uoChartSorted.categorias[index] = uoChart.categorias[elementoOrdenado];
        uoChartSorted.opcoes.periodos[index] = uoChart.opcoes.periodos[elementoOrdenado];
        if (angular.isDefined(uoChart.opcoes.uos)) {
          uoChartSorted.opcoes.uos[index] = uoChart.opcoes.uos[elementoOrdenado];
        }
        uoChartSorted.series.map((evento, eventoIndex) => {
          evento.atual[index] = uoChart.series[eventoIndex].atual[elementoOrdenado];
          evento.anterior[index] = uoChart.series[eventoIndex].anterior[elementoOrdenado];
        });
      });
      return uoChartSorted;
    }

    setInfoGrafico(data) {
      this.comparativoChart.setData(data.comparativoChart, true);
      this.comparativoChart.disableLegend();
      this.periodoChart.setData(data.periodoChart, true);
      this.footer = data.footer;

      data.uoChart = this.reorderUoChart(data.uoChart);
      let series = data.uoChart.series.map(
        serie => ({name: serie.name, data: serie.atual, compareData: serie.anterior, type: serie.type}));
      this.uoChart.setData({
        categorias: data.uoChart.categorias,
        series,
        opcoes: data.uoChart.opcoes
      }, true);
    }

    clickBinding() {
      let self = this;

      return {
        onClickColumn: function () {
          self.sendClickGTM();
          let evento = this.series.name,
              chart = self.getChart(this.series.chart.title.textStr),
              periodo = this.series.stackKey === 'columnanterior' ? self.comparativoChart.columnSeries.opcoes.periodos[0] : chart.columnSeries.opcoes.periodos[this.index],
              uoId = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id,
              uoParceira = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uoParceira.name),
              filtros = self.indicadoresService.getFiltrosParaRanking(periodo, uoId, uoParceira.value && uoParceira.value.id, evento);
          self.window.location.href = `${self.URLConducaoEconomica}?${filtros}`;
        },
        onClickColumnUO: function () {
          self.sendClickGTM();
          let evento = this.series.name,
              chart = self.getChart(this.series.chart.title.textStr),
              periodo = this.series.stackKey === 'columnanterior' ? self.comparativoChart.columnSeries.opcoes.periodos[0] : chart.columnSeries.opcoes.periodos[this.index],
              uoChart = chart.columnSeries.opcoes.uos && chart.columnSeries.opcoes.uos[this.index],
              uoPai = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).value.id,
              listaUos = self.serviceVfiltro.filtroCompleto.find(item => item.key === self.keys.uo.name).defaultValues,
              uoParceira = !listaUos.some(x => x.id === uoChart.uoId) && uoChart.uoId,
              filtros = self.indicadoresService.getFiltrosParaRanking(periodo, !uoParceira ? uoChart.uoId : uoPai, uoParceira, evento);
          self.window.location.href = `${self.URLConducaoEconomica}?${filtros}`;
        }
      };
    }

    getChart(chartLabel) {
      switch (chartLabel) {
        case this.tituloPeriodoChart:
          return this.periodoChart;
        case this.tituloComparativoChart:
          return this.comparativoChart;
        case this.tituloUoChart:
          return this.uoChart;
        default:
          return undefined;
      }
    }

    traduzLabelsCharts() {
      this.tituloComparativoChart = this.translate.instant('ce.indicadores.common.charts.comparativoChart.titulo');
      this.comparativoChart.setTitleText(this.tituloComparativoChart);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.comparativoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.tituloPeriodoChart = this.translate.instant('ce.indicadores.conducaoEconomica.charts.periodoChart.titulo');
      this.periodoChart.setTitleText(this.tituloPeriodoChart);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.periodoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
      this.tituloUoChart = this.translate.instant('ce.indicadores.conducaoEconomica.charts.uoChart.titulo');
      this.uoChart.setTitleText(this.tituloUoChart);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.conducaoEconomica.charts.yHoras'), 0);
      this.uoChart.setYAxisTitle(this.translate.instant('ce.indicadores.common.charts.yVeiculosMonitorados'), 1);
    }

    sendClickGTM() {
      this.googleTagManagerUtils.sendEventClickGTM('indicadores_ce_ir_para_ranking_conducao', this.serviceVfiltro.factoryVfiltro.user);
    }
  }

  angular
    .module('indicadores.ConducaoEconomica')
    .controller('ConducaoEconomicaCtrl', ConducaoEconomicaCtrl);
}());
