/* global document */
(function () {
  'use strict';

  class SegurancaCtrl {
    constructor(FeatureFlag, Authenticator, ServiceVfiltro, FactoryVfiltro) {
      this.featureFlag = FeatureFlag;
      this.authenticator = Authenticator;

      ServiceVfiltro.init(FactoryVfiltro.get([]))
        .then(() => {
          this.initPredicaoAcidenteComponent();
        });
    }

    initPredicaoAcidenteComponent() {
      if (this.featureFlag.MENU_INSIGHTS) {
        /* eslint-disable */
        const componentView = document.querySelector('#vfwc-predicao-acidentes-view');
        if (componentView) {
          this.authenticator.getUser().then(user => {
            const predicaoAcidentesElement = document.createElement('vfwc-external-predicao-acidentes');
            predicaoAcidentesElement.user = user;
            predicaoAcidentesElement.showHarshEventsRiskRegion = this.featureFlag.SHOW_HARSH_EVENTS_RISK_REGION;
            /* eslint-enable */
            componentView.appendChild(predicaoAcidentesElement);
          });
        }
      }
    }
  }

  angular
    .module('segurancaModule')
    .controller('SegurancaCtrl', SegurancaCtrl);
}());
