(function () {
  'use strict';
  class InfoItemCtrl {}

  angular
    .module('conducaoEconomica')
    .component('infoItem', {
      controller: InfoItemCtrl,
      templateUrl: 'quadro-ocorrencias/info-item/info-item.tpl.html',
      bindings: {
        item: '='
      }
    });
}());
