(function () {
  'use strict';

  class RankingSafetyCtrl {

    constructor($httpParamSerializer, $window, $filter, Uos, Motoristas, IDRankingSafety, Keys, ServiceVfiltro, FactoryVfiltro, $translate,
       VtabelaFactory, uiGridConstants, ObjetosVfiltroService, RankingSafety, v3gTableLimitService, Onboarding, MockRankingSafety, UrlConfigService) {
      this.httpParamSerializer = $httpParamSerializer;
      this.window = $window;
      this.filter = $filter;
      this.uoService = Uos;
      this.motoristasService = Motoristas;
      this.rankingSafetyService = RankingSafety;
      this.v3gTableLimitService = v3gTableLimitService;
      this.onboarding = Onboarding;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.mockRankingSafety = MockRankingSafety;
      this.isDemo = this.onboarding.startOnboarding(IDRankingSafety);
      this.uoService.isDemo = this.isDemo;
      this.motoristasService.isDemo = this.isDemo;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.translate = $translate;
      this.labelSemMotorista = this.translate.instant('ce.ranking.direcaoSegura.labelSemMotorista');
      this.urlConfigService = UrlConfigService;

      this.vtabela = null;
      this.exportsPdf = false;
      this.uoSelecionada = null;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo_id',
          key: this.keys.uo.name
        },
        {
          name: 'uo_parceira_id',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'motorista_id',
          key: this.keys.motoristas.name,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.roleVisualizarBdm = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_MOTORISTA_VISUALIZAR');
        this.roleVisualizarBdv = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('BOLETIM_VEICULO_VISUALIZAR');
        this.consultar(ServiceVfiltro.getFiltroBackendAntigo(filtro));
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'rankingTable', 'v1');
      });
    }

    initVtabela() {
      let drilDownSorting, drilDownCol, objVtabela = {
            exporterPdfFilename: 'Ranking Direção Segura PDF.pdf',
            exporterCsvFilename: 'Ranking Direção Segura CSV.csv',
            exporterExcelFilename: 'Ranking Direção Segura XLSX.xlsx',
            appScopeProvider: this,
            enableRowSelection: false,
            enableRowHeaderSelection: false,
            multiSelect: false,
            enableSelectAll: false,
            showColumnFooter: true,
            isRowSelectable: () => {
              return false;
            },
            columnDefs: [
                {
                  name: 'motorista',
                  displayName: 'ce.ranking.direcaoSegura.colunas.motorista',
                  pinnedLeft: true,
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false,
                  width: 150,
                  aggregationTotalTitle: 'Total',
                  cellTemplate: `
                  <div class='ui-grid-cell-contents' title="{{row.entity.ic}}">
                    <a
                    ng-href='{{(row.entity.linkBdm)}}'
                    target='_blank'
                    ng-if='grid.appScope.roleVisualizarBdm && row.entity.motorista !== grid.appScope.labelSemMotorista && row.entity.linkBdm'>
                      {{row.entity.motorista }}
                    </a>
                    <span ng-if='!grid.appScope.roleVisualizarBdm || row.entity.motorista === grid.appScope.labelSemMotorista'> {{row.entity.motorista | splitComposedLabels:true}} </span>
                  </div>
                  `
                },
                {
                  name: 'departamento',
                  displayName: 'ce.ranking.direcaoSegura.colunas.departamento',
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false
                },
                {
                  name: 'veiculos',
                  displayName: 'ce.ranking.direcaoSegura.colunas.veiculos',
                  enableHiding: false,
                  filterCellFiltered: true,
                  cellTemplate: `
                  <div class='ui-grid-cell-contents' title="{{row.entity.ic}}">
                    <a ng-href='{{(row.entity.linkBdv)}}'
                    target='_blank'
                    ng-if='grid.appScope.roleVisualizarBdv && row.entity.veiculos && row.entity.linkBdv'
                    title="{{row.entity.veiculos}}">
                      {{row.entity.veiculos }}
                    </a>
                    <span ng-if='!grid.appScope.roleVisualizarBdv || !row.entity.veiculos || !row.entity.linkBdv'
                    title="{{row.entity.veiculos}}">
                      {{row.entity.veiculos}}
                    </span>
                  </div>
                  `
                },
                {name: 'totalEventos', displayName: 'ce.ranking.direcaoSegura.colunas.totalEventos', type: 'number', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellClass: 'text-center', enableFiltering: false},
                {name: 'eventosPorTempo', displayName: 'ce.ranking.direcaoSegura.colunas.eventosPorTempo', type: 'number', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'decimal', cellClass: 'text-center', enableFiltering: false},
                {name: 'tempoIgnicaoLigada', displayName: 'ce.ranking.direcaoSegura.colunas.tempoIgnicaoLigada', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellFilter: 'humanizedDuration', cellClass: 'text-center', enableFiltering: false},
                {name: 'eventosPorEspaco', displayName: 'ce.ranking.direcaoSegura.colunas.eventosPorEspaco', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellFilter: 'decimal', cellClass: 'text-center', enableFiltering: false},
                {name: 'distanciaPercorrida', displayName: 'ce.ranking.direcaoSegura.colunas.distanciaPercorrida', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellFilter: 'decimalWithLabel:\'Km\'', cellClass: 'text-center', enableFiltering: false},
                {name: 'aceleracoesBruscas', displayName: 'ce.ranking.direcaoSegura.colunas.aceleracoesBruscas', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false},
                {name: 'frenagensBruscas', displayName: 'ce.ranking.direcaoSegura.colunas.frenagensBruscas', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false},
                {name: 'curvasBruscas', displayName: 'ce.ranking.direcaoSegura.colunas.curvasBruscas', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false},
                {name: 'banguelas', displayName: 'ce.ranking.direcaoSegura.colunas.banguelas', type: 'number', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                cellClass: 'text-center', enableFiltering: false},
                {name: 'exVelocidadeFaixa1', displayName: 'ce.ranking.direcaoSegura.colunas.exVelocidadeFaixa1', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false},
                {name: 'exVelocidadeFaixa2', displayName: 'ce.ranking.direcaoSegura.colunas.exVelocidadeFaixa2', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false},
                {name: 'exVelocidadeFaixa3', displayName: 'ce.ranking.direcaoSegura.colunas.exVelocidadeFaixa3', aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                type: 'number', cellClass: 'text-center', enableFiltering: false}
            ]
          };
      drilDownSorting = this.getDrilDownSortingDirection(this.tableState);
      drilDownCol = objVtabela.columnDefs.find(column => column.name === this.tableState.sort.predicate);

      if (drilDownSorting && drilDownCol) {
        angular.merge(drilDownCol, drilDownSorting);
      }
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        id: item.motoristaId,
        motorista: this.filter('withLabelToUndefined')(item.nomeMotorista, this.labelSemMotorista),
        departamento: item.departamento,
        veiculos: item.veiculos,
        totalEventos: item.totalEventos,
        eventosPorTempo: item.eventosPorTempo,
        tempoIgnicaoLigada: item.tempoEmDirecao,
        eventosPorEspaco: item.eventosPorEspaco,
        distanciaPercorrida: item.distanciaPercorrida,
        aceleracoesBruscas: item.aceleracoesBruscas,
        frenagensBruscas: item.frenagensBruscas,
        curvasBruscas: item.curvasBruscas,
        banguelas: item.banguelas,
        exVelocidadeFaixa1: item.exVelocidadeFaixa1,
        exVelocidadeFaixa2: item.exVelocidadeFaixa2,
        exVelocidadeFaixa3: item.exVelocidadeFaixa3,
        linkBdv: this.getLinkBdv(item.veiculosIds),
        linkBdm: this.getLinkBdm(item.motoristaId)
      };
    }
    consultar(filtro) {
      if (this.isDemo) {
        this.setInfoTable(this.mockRankingSafety);
      } else {
        this.rankingSafetyService.one().get({filtros: filtro})
          .then(data => this.setInfoTable(data))
          .catch(() => this.vtabela.setError());
      }
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    getDrilDownSortingDirection(tableState) {
      if (tableState && tableState.sort.reverse) {
        return {
          sort: {
            direction: this.uiGridConstants.DESC
          }
        };
      }

      return {
        sort: {
          direction: this.uiGridConstants.ASC
        }
      };
    }

    getParams() {
      return {
        filtros: this.serviceVfiltro.getFiltroBackendAntigo(this.serviceVfiltro.filtroTopbar)
      };
    }

    getUrlCsv() {
      return `/boletim/ranking/safety/csv?${this.httpParamSerializer(this.getParams())}`;
    }

    getUrlPdf() {
      return `/boletim/ranking/safety/pdf?${this.httpParamSerializer(this.getParams())}`;
    }

    getLinkBdv(veiculosIds) {
      if (veiculosIds.length === 1) {
        return `/relatorios/bdv?${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.veiculos.name)};veiculos:${veiculosIds.toString()}`;
      }
      return null;
    }

    getLinkBdm(motoristaId) {
      return `/relatorios/bdm?${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.motoristas.name)};motoristas:${motoristaId.toString()}`;
    }

    goToBDXDemo(tipo, id) {
      let filtro = tipo === 'bdv' ?
      `${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.veiculos.name)};veiculos:${id.toString()}` :
      `${this.objetosVfiltroService.getVfiltroTableState(this.tableState, this.keys.motoristas.name)};motoristas:${id.toString()}`;
      this.window.location.href = `/relatorios/${tipo}?demo&${filtro}`;
    }
  }
  /**
   * @ngdoc object
   * @name relatorios.rankingSafety.controller:RankingSafetyCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.rankingSafety')
    .controller('RankingSafetyCtrl', RankingSafetyCtrl);
}());
