  class SafetyService {
    constructor($document, $window, $log, UrlConfigService) {
      this.$log = $log;
      this.$document = $document[0];
      this.$window = $window;
      this.urlConfigService = UrlConfigService;
      this.remoteEntryUrl = `${this.urlConfigService.getSafetyComponentsUrl()}/remoteEntry.js`;
      // this.remoteEntryUrl = 'https://localhost:3002/remoteEntry.js';
      this.reactLoaded = false;
      this.reactDOMLoaded = false;
      this.remoteEntryLoaded = false;
      this.initializationPromise = null;
      this.initialize();
      this.roots = {};
    }

    initialize() {
      if (!this.initializationPromise) {
        this.initializationPromise = Promise.all([
          this.loadReactAndReactDOM(),
          this.loadRemoteEntry()
        ]).then(() => {
          this.reactLoaded = true;
          this.remoteEntryLoaded = true;
        }).catch(error => {
          this.$log.error('Error loading React or RemoteEntry:', error);
        });
      }
      return this.initializationPromise;
    }

    loadReactAndReactDOM() {
      if (this.reactLoaded && this.reactDOMLoaded) {
        return Promise.resolve();
      }

      const reactPromise = new Promise((resolve, reject) => {
        const reactScript = this.$document.createElement('script');
        reactScript.onload = () => {
          this.reactLoaded = true;
          resolve();
        };
        reactScript.onerror = () => reject(new Error('An error occurred while loading react'));
        reactScript.src = 'https://unpkg.com/react@18.2.0/umd/react.development.js';
        this.$document.head.appendChild(reactScript);
      });

      return reactPromise.then(() => {
        return new Promise((resolve, reject) => {
          const reactDomScript = this.$document.createElement('script');
          reactDomScript.onload = () => {
            this.reactDOMLoaded = true;
            resolve();
          };
          reactDomScript.onerror = () => reject(new Error('An error occurred while loading reactDom'));
          reactDomScript.src = 'https://unpkg.com/react-dom@18.2.0/umd/react-dom.development.js';
          this.$document.head.appendChild(reactDomScript);
        });
      });
    }

    loadRemoteEntry() {
      return new Promise((resolve, reject) => {
        const script = this.$document.createElement('script');
        script.src = this.remoteEntryUrl;
        script.onload = resolve;
        script.onerror = () => reject(new Error('An error occurred while loading remoteEntry.js'));
        this.$document.head.appendChild(script);
      });
    }

    loadReactComponent(appName, componentPath, componentId, props) {
      this.unmountReactComponent(componentId);
      return this.getComponent(appName, componentPath)
        .then((component) => {
          const container = this.$document.getElementById(componentId);
          if (container) {
            let root = this.roots[componentId];
            if (!root) {
              root = this.$window.ReactDOM.createRoot(container);
              this.roots[componentId] = root;
            }
            root.render(
              this.$window.React.createElement(component().default, props)
            );
          }
        })
        .catch((error) => {
          this.$log.error(`Error loading component at ${componentPath}: `, error);
        });
    }

    unmountReactComponent(componentId) {
      const container = this.$document.getElementById(componentId),
          root = this.roots[componentId];
      if (container && root) {
        root.unmount();
        delete this.roots[componentId];
      }
    }

    getComponent(appName, componentName) {
      return new Promise((resolve, reject) => {
        if (!this.$window || !this.$window[appName]) {
          reject(new Error(`${appName} is not loaded or does not exist`));
        } else {
          const checkAppLoaded = () => {
            if (!this.$window[appName] || Object.keys(this.$window[appName]).length === 0) {
              reject(new Error(`${appName} is empty or not exporting anything`));
            } else {
              resolve(this.$window[appName].get(componentName));
            }
          };

          if (!this.$window[appName]) {
            this.$window.addEventListener('appLoaded', checkAppLoaded);
          } else {
            checkAppLoaded();
          }
        }
      });
    }
  }

  SafetyService.$inject = ['$document', '$window', '$log', 'UrlConfigService'];

  angular.module('safetyModule', []).service('safetyService', SafetyService);
