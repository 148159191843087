(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.rankingEconomy', {
      url: '/relatorios/ranking/economy?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/ranking-economy/ranking-economy.tpl.html',
          controller: 'RankingEconomyCtrl',
          controllerAs: 'rankingEconomy'
        }
      },
      data: {
        titulo: 'ce.ranking.conducaoEconomica.titulo',
        role: 'RANKING_ECONOMIA_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.rankingEconomy')
    .config(pageConfig);
}());
