(function () {
  'use strict';
  const ICON_ANCHOR_DEFAULT = [15, 40],
      POPUP_ANCHOR_DEFAULT = [0, -40],
      MAX_ZINDEX = 9999999,
      MIN_ZINDEX = 0;

  /* global L, document, _ */
  class MarkerService {

    constructor(Eventos, PopupService, $translate) {
      this.eventos = Eventos;
      this.popupService = PopupService;
      this.lastActiveItem = null;
      this.translate = $translate;
    }

    buildMarkers(trechos, filtro, prefix, idElementoPai) {
      return _.chain(trechos)
        .map(posicoes => this.filtraEventos(posicoes, filtro))
        .map(posicoes => posicoes.map((p, index) => {
          let tipo = p.evento;
          if (index === 0 && tipo === null) {
            tipo = 'Início';
          }
          if (index === posicoes.length - 1 && tipo === null) {
            tipo = 'Fim';
          }
          return p.temCoordenada ? this.buildMarker(p, tipo, index, prefix, idElementoPai) : false;
        }))
        .flatten();
    }

    buildMarkersAssociadosAsPosicoes(posicoes, filtro, prefix, dateTimeFormat = 'HH:mm:ss') {
      return this.filtraEventos(posicoes, filtro)
        .map((posicao, i) => {
          let tipo = posicao.evento;
          if (i === 0) {
            tipo = 'Início';
          } else if (i === posicoes.length - 1) {
            tipo = 'Fim';
          }
          return [
            posicao,
            posicao.temCoordenada ? this.buildMarker(posicao, tipo, i, prefix, null, dateTimeFormat) : null
          ];
        })
        .reduce((acc, atual) => acc.set(atual[0], atual[1]), new Map());
    }

    filtraEventos(posicoes, filtro) {
      return posicoes.filter((p, index) => {
        const isPrimeira = index === 0,
            isUltima = index === posicoes.length - 1,
            semFiltro = !filtro,
            filtroUpperCase = filtro.toUpperCase(),
            descidaFaixa1 = this.translate.instant(this.eventos.DESCIDA_FAIXA1.tipo).toUpperCase(),
            descidaFaixa2 = this.translate.instant(this.eventos.DESCIDA_FAIXA2.tipo).toUpperCase(),
            descidaFaixa3 = this.translate.instant(this.eventos.DESCIDA_FAIXA3.tipo).toUpperCase(),
            isDescidaFaixa1 = filtroUpperCase === descidaFaixa1,
            isDescidaFaixa2 = filtroUpperCase === descidaFaixa2,
            isDescidaFaixa3 = filtroUpperCase === descidaFaixa3;

        return isPrimeira || isUltima || semFiltro ||
          filtroUpperCase === this.translate.instant(this.eventos.EXCESSO_VELOCIDADE.tipo).toUpperCase() && this.eventos.isExcessoVelocidade(p.evento) ||
          filtroUpperCase === this.translate.instant(this.eventos.CURVA_BRUSCA.tipo).toUpperCase() && this.eventos.isCurvaBrusca(p.evento) ||
          isDescidaFaixa1 && p.evento === this.translate.instant(this.eventos.DESCIDA_FAIXA1_FIM.tipo) ||
          isDescidaFaixa2 && p.evento === this.translate.instant(this.eventos.DESCIDA_FAIXA2_FIM.tipo) ||
          isDescidaFaixa3 && p.evento === this.translate.instant(this.eventos.DESCIDA_FAIXA3_FIM.tipo) ||
          filtroUpperCase === p.evento.toUpperCase();
      });
    }

    buildMarker(posicao, tipo, index, prefix, idElementoPai, dateTimeFormat) {
      return L.marker(
        [posicao.latitude, posicao.longitude],
        {
          evento: tipo,
          index,
          icon: L.divIcon({
            iconSize: [0, 0],
            html: this.getMarkerIcon(posicao.icon, tipo),
            iconAnchor: [15, 40],
            popupAnchor: [1, -35]
          })
        })
        .bindPopup(dateTimeFormat ? this.popupService.build(posicao, dateTimeFormat) : this.popupService.build(posicao))
          .on('popupopen', event => {
            this.onMarkerPopup(true, event, prefix + index, idElementoPai);
            angular.element('#obd-detalhes-' + index).hide();
            angular.element('#obd-mostrar-detalhes-' + index).click(evt => {
              angular.element('#obd-mostrar-detalhes-' + index).hide();
              angular.element('#obd-detalhes-' + index).show();
              evt.preventDefault();
            });
          })
          .on('popupclose', event => {
            this.onMarkerPopup(false, event, prefix + index, idElementoPai);
            angular.element('#obd-mostrar-detalhes-' + index).show();
          });
    }

    onMarkerPopup(open, event, idElemento, idElementoPai) {
      event.target.setZIndexOffset(open ? MAX_ZINDEX : MIN_ZINDEX);
      /*eslint-disable */
      let element = document.getElementById(idElemento);// jshint ignore:line
      /*eslint-enable */
      if (element) {
        if (idElementoPai) {
          /*eslint-disable */
          let container = document.getElementById(idElementoPai),// jshint ignore:line
              elements = container.getElementsByClassName('active');// jshint ignore:line
          /*eslint-enable */
          while (elements[0]) {
            elements[0].classList.remove('active');
          }
        }

        if (this.lastActiveItem) {
          this.lastActiveItem.classList.remove('active');
        }

        if (open) {
          this.lastActiveItem = element;
          element.scrollIntoView();
          element.classList.add('active');
        }
      }
    }

    getMarkerIcon(icon, tipo) {
      if (icon) {
        return {
          iconUrl: icon,
          iconAnchor: ICON_ANCHOR_DEFAULT,
          popupAnchor: POPUP_ANCHOR_DEFAULT
        };
      }

      if (this.eventos.isLocalizacao(tipo)) {
        return '';
      }
      if (tipo === 'Início' || tipo === 'Fim') {
        return this.getIconEvento('blue', 'fa-bus');
      }

      const evento = this.eventos.getEvento(tipo);
      if (angular.isDefined(evento)) {
        return this.getIconEvento(evento.cor, evento.icone);
      }

      return this.getIconEvento('yellow', 'fa-exclamation-triangle');
    }

    getIconEvento(cor, icone) {
      if (icone.includes('material')) {
        const matIcon = icone.replace('material ', '');
        return `<img src='images/icons-map/marker-${cor}.png' class="img-icon"/>
        <span class='circle-icon material-icons-map-marker bg-${cor}'>${matIcon}</span>`;
      }
      return `<img src='images/icons-map/marker-${cor}.png' class="img-icon"/>
        <span class='fa ${icone} bg-${cor} circle-icon'></span>`;
    }
  }

  angular
    .module('mapas')
    .service('MarkerService', MarkerService);
}());
