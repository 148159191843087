(function () {
  'use strict';

  class DescarteEventosCtrl {
    constructor($scope, $uibModal, Restangular, AlertMessage,
      Keys, ServiceVfiltro, FactoryVfiltro, $translate) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;

      this.uoId = 0;
      this.roleCadastrar = true;
      this.model = {configuracoes: []};
      this.ptsMask = this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.descarte.segMask');

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.uoId = filtro.find(x => x.key === this.keys.uo.name).value.id;
        this.roleCadastrar = this.temPermissaoParaConfigurar();
        this.init();
      });
    }

    temPermissaoParaConfigurar() {
      return this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_DESCARTE_EDITAR') &&
      this.serviceVfiltro.factoryVfiltro.user.uo.uoPai.id === 1 &&
      this.serviceVfiltro.factoryVfiltro.user.uo.id === this.uoId;
    }

    init() {
      this.restangular.one(`configuracao/configuracoes/${this.uoId}/speed-limit-ativo`).get()
      .then((speedLimit) => this.setConfiguracoes(speedLimit))
      .catch(() => this.alertMessage.create({
        type: 'error',
        message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.msgErroCarregarConfiguracoes'),
        appendTo: '.alerta-aqui-geral'
      }));
    }

    setConfiguracoes(speedLimit) {
      this.model.speedLimitEmbarcado = speedLimit.speedLimitEmbarcado;
      this.model.speedLimitVia = speedLimit.speedLimitVia;

      this.model.habilitarSpeedLimitPorcentagem = speedLimit.speedLimitPorcentagemDescarte > 0;
      this.model.speedLimitPorcentagemDescarte = speedLimit.speedLimitPorcentagemDescarte;

      this.model.habilitarSpeedLimitDuracaoDescarte = speedLimit.speedLimitDuracaoDescarte > 0;
      this.model.speedLimitDuracaoDescarte = speedLimit.speedLimitDuracaoDescarte;

      this.model.habilitarSpeedLimitViaPorcentagem = speedLimit.speedLimitViaPorcentagemDescarte > 0;
      this.model.speedLimitViaPorcentagemDescarte = speedLimit.speedLimitViaPorcentagemDescarte;

      this.model.habilitarSpeedLimitViaDuracaoDescarte = speedLimit.speedLimitViaDuracaoDescarte > 0;
      this.model.speedLimitViaDuracaoDescarte = speedLimit.speedLimitViaDuracaoDescarte;

      this.model.habilitarVelDescarteCurva = speedLimit.velDescarteCurva > 0;
      this.model.velDescarteCurva = speedLimit.velDescarteCurva;

      this.model.habilitarVelDescarteCameraObstruida = speedLimit.velDescarteCameraObstruida > 0;
      this.model.velDescarteCameraObstruida = speedLimit.velDescarteCameraObstruida;

      this.model.habilitarDistanciaMinimaFreioDescida = speedLimit.distanciaMinimaFreioDescida > 0;
      this.model.distanciaMinimaFreioDescida = speedLimit.distanciaMinimaFreioDescida;

      this.model.backupConfiguracoes = speedLimit;
    }

    descartarAlteracoes() {
      this.setConfiguracoes(this.model.backupConfiguracoes);
    }

    getConfiguracoes() {
      return {
        uoId: this.uoId,
        speedLimitEmbarcado: this.model.speedLimitEmbarcado,
        speedLimitVia: this.model.speedLimitVia,
        speedLimitPorcentagemDescarte: this.model.habilitarSpeedLimitPorcentagem ? this.model.speedLimitPorcentagemDescarte : 0,
        speedLimitDuracaoDescarte: this.model.habilitarSpeedLimitDuracaoDescarte ? this.model.speedLimitDuracaoDescarte : 0,
        speedLimitViaPorcentagemDescarte: this.model.habilitarSpeedLimitViaPorcentagem ? this.model.speedLimitViaPorcentagemDescarte : 0,
        speedLimitViaDuracaoDescarte: this.model.habilitarSpeedLimitViaDuracaoDescarte ? this.model.speedLimitViaDuracaoDescarte : 0,
        velDescarteCurva: this.model.habilitarVelDescarteCurva ? this.model.velDescarteCurva : 0,
        velDescarteCameraObstruida: this.model.habilitarVelDescarteCameraObstruida ? this.model.velDescarteCameraObstruida : 0,
        distanciaMinimaFreioDescida: this.model.habilitarDistanciaMinimaFreioDescida ? this.model.distanciaMinimaFreioDescida : 0
      };
    }

    salvar() {
      const configDescarte = this.getConfiguracoes();

      this.restangular.all(`configuracao/configuracoes/${this.uoId}/speed-limit-ativo`).post(configDescarte)
      .then((speedLimit) => {
        this.rebuildSpeedLimitAtivo(speedLimit);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.botaoRestaurarConfiguracoes.modal.erroSalvarConfiguracoes'),
          appendTo: '.alerta-aqui'
        });
      });
    }

    rebuildSpeedLimitAtivo(speedLimit) {
      this.scope.formDescarte.$setPristine();
      this.setConfiguracoes(speedLimit);
      this.alertMessage.create({
        type: 'success',
        message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.pesoEventos.botaoRestaurarConfiguracoes.modal.salvoComSucesso'),
        appendTo: '.alerta-aqui'
      });
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('DescarteEventosCtrl', DescarteEventosCtrl);
}());
