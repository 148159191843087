(function () {
  'use strict';

  /* global _ */
  class RankingInfracoesCtrl {

    constructor(RankingInfracoes, AlertasRanking, Keys, ServiceVfiltro, FactoryVfiltro,
       Uos, $scope, AlertasEnum, $uibModal, $interval, $timeout, moment, $filter, $translate, GoogleTagManagerUtils) {
      this.rankingInfracoesService = RankingInfracoes;
      this.alertasRankingService = AlertasRanking;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.factoryVfiltro = FactoryVfiltro;
      this.uoService = Uos;
      this.scope = $scope;
      this.alertasEnum = AlertasEnum;
      this.uibModal = $uibModal;
      this.interval = $interval;
      this.timeout = $timeout;
      this.moment = moment;
      this.filter = $filter;
      this.translate = $translate;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.STATUS_VIDEO_ASSISTIVEL = ['CONCLUIDO', 'ASSISTIDO'];

      this.orders = {
        PONTUACAO: this.translate.instant('ce.tempoReal.infracoes.common.pontos'),
        PONTOS_NAO_TRATADOS: this.translate.instant('ce.tempoReal.infracoes.common.pontosNaoTratados')
      };
      this.orderBy = this.orders.PONTUACAO;
      this.motoristaNaoIdentificado = this.translate.instant('ce.tempoReal.infracoes.common.mensagem.motoristaNaoIdentificado');
      this.naoIdentificado = this.translate.instant('ce.tempoReal.infracoes.common.mensagem.naoIdentificado');

      this.reloadDataInvertal = 60 * 1000;

      this.infracoes = [];
      this.details = null;
      this.isDrawerActive = false;
      this.isDetailsReloaded = false;
      this.itemsReloaded = new Set();
      this.limit = 100;

      ServiceVfiltro.init(
        FactoryVfiltro.get([
          {
            name: 'uoId',
            key: this.keys.uo.name
          }
        ])
      ).then(() => {
        this.init(this.serviceVfiltro.factoryVfiltro.user);
      });
    }

    init(user) {
      this.user = user;

      this.interval(() => {
        this.loadData();
      }, this.reloadDataInvertal);
      this.loadData();
    }

    loadData() {
      const filtro = this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar);
      this.rankingInfracoesService.one().get(filtro).then(data => {
        this.loadInfracoes(this.parseData(data.plain()));
        this.updateDetails();
      });
    }

    parseData(data) {
      data.forEach(item => {
        item.id = angular.isDefined(item.motorista) ?
          item.veiculo.id + '-' + item.motorista.id : item.veiculo.id;

        item.totalPontuacao = item.pontuacoes.map(alerta => alerta.total)
          .reduce((a, b) => a + b, 0);

        item.totalResolvido = item.pontuacoes.map(alerta => alerta.resolvido)
          .reduce((a, b) => a + b, 0);
      });

      return this.ranquear(data);
    }

    ranquear(data) {
      if (this.orderBy === this.orders.PONTUACAO) {
        data.sort((a, b) => a.totalPontuacao - b.totalPontuacao);
      } else {
        data.sort((a, b) => a.totalPontuacao - a.totalResolvido - (b.totalPontuacao - b.totalResolvido));
      }

      data.forEach((item, index) => {
        item.ranking = index + 1;
        const infracao = this.getInfracao(item.id);
        if (angular.isDefined(infracao)) {
          if (item.totalPontuacao !== infracao.totalPontuacao ||
            item.totalResolvido !== infracao.totalResolvido) {
            this.setItemReloaded(item.id);
          }
        }
      });

      return data;
    }

    setItemReloaded(id) {
      this.itemsReloaded.add(id);
      this.timeout(() => {
        this.itemsReloaded.delete(id);
        this.scope.$apply();
      }, 5000);
    }

    isItemReloaded(id) {
      return this.itemsReloaded.has(id);
    }

    loadInfracoes(data) {
      this.infracoes = data;
    }

    updateDetails() {
      if (this.details !== null) {
        const infracao = this.getInfracao(this.details.id);
        if (angular.isDefined(infracao)) {
          if (!angular.equals(this.details.pontuacoes, infracao.pontuacoes)) {
            this.loadDetails(infracao.id);
          }
        }
      }
    }

    loadDetails(id) {
      this.details = this.getInfracao(id);

      if (!this.isDetailsReloaded) {
        this.isDetailsReloaded = true;

        this.timeout(() => {
          this.isDetailsReloaded = false;
          this.scope.$apply();
        }, 500);
      }

      this.loadAlertas();
    }

    loadAlertas() {
      const params = this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar);
      params.rankingCardIds = this.details.ids.join(',');
      this.alertasRankingService.one().get(params).then(data => {
        this.alertasDetalhados = _.groupBy(data.plain(), 'tipo');
      });
    }

    getInfracao(id) {
      return this.infracoes.find(item => item.id === id);
    }

    getAlertaDescricao(tipo) {
      return this.translate.instant(this.alertasEnum[tipo].descricao);
    }

    openAcoesModal(status, tipo) {
      this.uibModal.open({
        templateUrl: 'ranking/infracoes/acoes-alerta.html',
        controller: 'AcoesAlertaCtrl as acoesAlertaModal',
        resolve: {
          details: () => this.details,
          status: () => status,
          tipo: () => tipo
        }
      }).result.finally(() => this.loadData());
    }

    onVideoClick() {
      this.googleTagManagerUtils.sendEventClickGTM('infracoes_reproduzir_video_vfleets', this.serviceVfiltro.factoryVfiltro.user);
    }

    toggleDrawer() {
      this.isDrawerActive = !this.isDrawerActive;
    }

    formatar(numero, casasDecimais = 1) {
      return numero.toFixed(casasDecimais).replace(/\.0+$/, '');
    }

    formataLimiar(alerta) {
      switch (alerta.tipo) {
        case 'PERMANENCIA_PONTO':
        case 'PARADO_LIGADO':
          return this.filter('humanizedDuration')(alerta.limiar);

        default:
          if (angular.isNumber(alerta.limiar)) {
            return this.filter('number')(alerta.limiar, 2);
          }
          return '';
      }
    }

    toggleDropdownMenuAcoes(tipoAlerta, event) {
      event.preventDefault();
      event.stopPropagation();
      this.dropdownMenuAcoes = this.dropdownMenuAcoes === tipoAlerta ? null : tipoAlerta;
    }

    getLinkHistoricoDetalhado(tipoAlerta) {
      if (this.serviceVfiltro.filtroTopbar && this.details) {
        const dataInicio = this.moment().subtract(12, 'hours').format('YYYY.MM.DD.HH.mm.ss.ZZ'),
            dataFim = this.moment().format('YYYY.MM.DD.HH.mm.ss.ZZ'),
            filtro = this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar);
        if (tipoAlerta) {
          return '/historico-timeline?vfiltro=' +
          `dateTime:${dataInicio},${dataFim};` +
          `uo:${filtro.uoId};` +
          `veiculo:${this.details.veiculo.id}` +
          `&tipoEvento=${tipoAlerta}`;
        }
        return '/historico-timeline?vfiltro=' +
        `dateTime:${dataInicio},${dataFim};` +
        `uo:${filtro.uoId};` +
        `veiculo:${this.details.veiculo.id}`;
      }
      return '';
    }

    isIntensidadeValida(intensidade) {
      return !!intensidade && intensidade !== '-';
    }

    getClasseIcone(tipoAlerta) {
      return this.alertasEnum[tipoAlerta].icone;
    }

    loadMore() {
      this.limit += 100;
    }

    hasVideo(alerta) {
      return alerta.videoId && _.contains(this.STATUS_VIDEO_ASSISTIVEL, alerta.videoStatus);
    }

    hasSnapshot(alerta) {
      return !!alerta.snapshotId;
    }
  }

  angular
    .module('ranking.Infracoes')
    .controller('RankingInfracoesCtrl', RankingInfracoesCtrl);
}());
