(function () {
  'use strict';

  function graficoOnePageDirective() {
    return {
      templateUrl: 'one-page/view/grafico/grafico-one-page.tpl.html',
      controller: 'GraficoOnePageCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class GraficoOnePageCtrl {
    constructor($scope, CustomGridster, Authenticator, OnePageService) {
      this.scope = $scope;
      this.customGridster = CustomGridster;
      this.authenticator = Authenticator;
      this.onePageService = OnePageService;

      this.gridster = this.customGridster.getOptions(125);

      this.scope.$on('gridster-item-resized', () => this.scope.$broadcast('highchartsng.reflow'));
      this.scope.$on('gridster-item-transition-end', () => this.scope.$broadcast('highchartsng.reflow'));

      this.posicoesGridster = {alertaTurnos: {}, alertas: {}, semSinal: {}, paradoMovimento: {}};
      this.visualizarHistoricoAlerta = false;
      this.visualizarLocalizao = false;
      this.visualizarNotificacoes = false;

      this.scope.$on('$destroy', () => {
        this.gridster.resizable.resize = null;
        this.gridster.draggable.drag = null;
      });

      this.authenticator.getUser().then(user => {
        if (this.authenticator.hasRole('HISTORICO_ALERTA_VISUALIZAR')) {
          this.visualizarHistoricoAlerta = true;
        }
        if (this.authenticator.hasRole('LOCALIZACAO_ATUAL_VISUALIZAR')) {
          this.visualizarLocalizao = true;
        }
        if (this.authenticator.hasRole('NOTIFICACOES_VISUALIZAR')) {
          this.visualizarNotificacoes = true;
        }
        this.init(user);
      });
    }

    init(user) {
      this.gridster.resizable.resize = () => this.scope.$broadcast('highchartsng.reflow');
      this.gridster.draggable.drag = () => this.scope.$broadcast('highchartsng.reflow');
      this.user = user;
      this.setPosicaoGrafico();
    }

    setPosicaoGrafico() {
      this.setPosicaoAlertaTurno();
      this.setPosicaoAlerta();
      this.setPosicaoSemSinal();
      this.setPosicaoParadoMovimento();
    }

    setPosicaoAlertaTurno() {
      this.posicoesGridster.alertaTurnos = this.scope.onePage.getPosicaoGrafico(`g-alertaTurno-${this.user.id}`, {
        row: 0,
        col: 0,
        sizeX: 12,
        sizeY: 3,
        minSizeY: 3,
        maxSizeY: 7,
        minSizeX: 8,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `g-alertaTurno-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.alertaTurnos);
    }

    setPosicaoAlerta() {
      this.posicoesGridster.alertas = this.scope.onePage.getPosicaoGrafico(`g-alerta-${this.user.id}`, {
        row: 0,
        col: 0,
        sizeX: 12,
        sizeY: 3,
        minSizeY: 3,
        maxSizeY: 3,
        minSizeX: 8,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `g-alerta-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.alertas);
    }

    setPosicaoSemSinal() {
      this.posicoesGridster.semSinal = this.scope.onePage.getPosicaoGrafico(`g-semsinal-${this.user.id}`, {
        row: 0,
        col: 0,
        sizeX: 6,
        sizeY: 3,
        minSizeY: 3,
        maxSizeY: 3,
        minSizeX: 4,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `g-semsinal-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.semSinal);
    }

    setPosicaoParadoMovimento() {
      this.posicoesGridster.paradoMovimento = this.scope.onePage.getPosicaoGrafico(`g-paradoMovimento-${this.user.id}`, {
        row: 0,
        col: this.visualizarHistoricoAlerta ? 6 : 0,
        sizeX: 6,
        sizeY: 3,
        minSizeY: 3,
        maxSizeY: 3,
        minSizeX: 4,
        maxSizeX: 12,
        versao: this.scope.onePage.versaoLocalstorage,
        name: `g-paradoMovimento-${this.user.id}`
      });
      this.scope.onePage.setPosicaoLocalStorage(this.posicoesGridster.paradoMovimento);
    }

    exibeAlertaTurnos() {
      return this.visualizarHistoricoAlerta && this.onePageService.exibirAlertaTurnos;
    }

    exibeAlertaDia() {
      return this.visualizarNotificacoes && !this.onePageService.exibirAlertaTurnos;
    }

    exibeSemSinal() {
      return this.visualizarHistoricoAlerta && this.posicoesGridster.semSinal;
    }

    exibeParadoMovimento() {
      return this.visualizarLocalizao && this.posicoesGridster.paradoMovimento;
    }
  }

  angular
    .module('onePageModule')
    .controller('GraficoOnePageCtrl', GraficoOnePageCtrl)
    .directive('graficoOnePageDirective', graficoOnePageDirective);
}());
