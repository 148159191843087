(function () {
  'use strict';

  angular
    .module('informativoKeysModule', [
      'ui.router',
      'v3gAuthenticatorModule'
    ])
    .constant('InformativoKeys', {
      'root.conducaoEconomica': [{key: 'INDICADORES_CONDUCAO_ECONOMICA'}],
      'root.direcaoSegura': [{key: 'INDICADORES_DIRECAO_SEGURA'}],
      'root.utilizacaoVeiculo': [{key: 'INDICADORES_OCIOSIDADE_FROTA'}],
      'root.quadroOcorrencias': [
        {key: 'QUADRO_OCORRENCIAS'}
      ],
      'root.bdm': [{key: 'BOLETIM_DIARIO_MOTORISTA'}],
      'root.bdmDetalhes': [{key: 'BOLETIM_DIARIO_MOTORISTA'}],
      'root.bdv': [{key: 'BOLETIM_DIARIO_VEICULO'}],
      'root.bdvDetalhes': [{key: 'BOLETIM_DIARIO_VEICULO'}],
      'root.bdvChecklist': [{key: 'BOLETIM_DIARIO_VEICULO'}],
      'root.bds': [{key: 'BOLETIM_SERVICO'}],
      'root.bdsDetalhes': [{key: 'BOLETIM_SERVICO'}],
      'root.analiseOp': [{key: 'ANALISE_MOVIMENTO_VEICULO'}],
      'root.abastecimento': [{key: 'ANALISE_ABASTECIMENTO'}],
      'root.velocidadeRpm': [{key: 'ANALISE_VELOCIDADE_RPM'}],
      'root.permanencia': [{key: 'ANALISE_PERMANENCIA_PONTO'}],
      'root.ocorrencia': [
        {key: 'QUADRO_OCORRENCIAS'}
      ],
      'root.ocorrenciaDetalhes': [
        {key: 'QUADRO_OCORRENCIAS'}
      ],
      'root.rankingEconomy': [{key: 'RANKING_CONDUCAO_ECONOMICA'}],
      'root.rankingSafety': [{key: 'RANKING_DIRECAO_SEGURA'}],
      'root.indicadorPowerBi': [
        {key: 'INDICADORES_BI'}
      ],
      'root.pontoReferencia': [{key: 'PONTOS_REFERENCIA'}],
      'root.mapaCalor': [
        {key: 'MAPA_CALOR'}
      ],
      'root.onePage': [
        {key: 'STATUS_FROTA'}
      ],
      'root.rankingInfracoes': [
        {key: 'INFRACOES_TEMPO_REAL'}
      ],
      'root.rankingPontos': [
        {key: 'RANKING_MOTORISTA'}
      ],
      'root.prontuario': [
        {key: 'RANKING_MOTORISTA'}
      ],
      'root.configVdrivers': [
        {key: 'CONFIGURACOES_VDRIVERS'}
      ],
      'root.identificacoes': [
        {key: 'CONFIGURACOES_VDRIVERS'}
      ]
    });
}());
