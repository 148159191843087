(function () {
  'use strict';

  class PontosReferenciaFiltro {
    constructor($window, PontosReferencia, CategoriaPontosReferencia, AlertMessage, PesquisaPontoReferenciaService, $translate) {
      this.window = $window;
      this.pontosReferenciaService = PontosReferencia;
      this.pontosCategoriaReferenciaService = CategoriaPontosReferencia;
      this.alertMessage = AlertMessage;
      this.translate = $translate;

      this.filtroSelecionado = null;
      this.filtroSelecionadoLocalstorage = null;
      this.filtrosDisponiveis = [];
      this.filterObject = [];
      this.idLayers = [];
      this.pontos = [];
      this.selecionarFiltro = false;
      this.pesquisaService = PesquisaPontoReferenciaService;
    }

    init() {
      this.filtroSelecionado = null;
      this.filtroSelecionadoLocalstorage = null;
      this.filtrosDisponiveis = this.getFiltrosDisponiveis();
    }

    getFiltrosDisponiveis() {
      return [
        {
          titulo: this.translate.instant('ce.mapa.common.modal.checkBoxPontoReferencia.filtroUO'),
          lista: this.pontosReferenciaService.uos,
          key: 'uo',
          tipo: 'checkbox',
          total: this.pontosReferenciaService.uos && this.pontosReferenciaService.uos.length || 0,
          selecionado: this.getFiltroByKeyOrDefault('uo', {key: 'uo', itensEscondidos: []}).itensEscondidos.length
        },
        {
          titulo: this.translate.instant('ce.mapa.common.modal.checkBoxPontoReferencia.filtroCategorias'),
          lista: this.pontosReferenciaService.categorias,
          key: 'categoria',
          tipo: 'checkbox',
          total: this.pontosReferenciaService.categorias && this.pontosReferenciaService.categorias.length || 0,
          selecionado: this.getFiltroByKeyOrDefault('categoria', {key: 'categoria', itensEscondidos: []}).itensEscondidos.length
        },
        {
          titulo: this.translate.instant('ce.mapa.common.modal.checkBoxPontoReferencia.filtroPalavrasChave'),
          key: 'palavra',
          tipo: 'input',
          total: this.getFiltroByKeyOrDefault('palavra', {key: 'palavra', itensEscondidos: []}).itensEscondidos.length
        }
      ];
    }

    salvarFiltroLocalstorage(message) {
      if (this.filterObject.length > 0) {
        this.window.localStorage.setItem(`filtro-ponto-referencia-${this.pontosReferenciaService.user.id}`, angular.toJson(this.filterObject));
      } else {
        this.window.localStorage.removeItem(`filtro-ponto-referencia-${this.pontosReferenciaService.user.id}`);
      }

      this.alertMessage.create({
        type: 'success',
        message,
        appendTo: '.alerta-aqui-ponto'
      });

      this.setValueItensLocalstorage();
    }

    setValueItensLocalstorage() {
      this.filtrosDisponiveis = this.getFiltrosDisponiveis();
    }

    loadFiltroFromLocalStorage() {
      this.filterObject = angular.fromJson(this.window.localStorage.getItem(`filtro-ponto-referencia-${this.pontosReferenciaService.user.id}`)) || [];
      this.setValueItensLocalstorage();
    }

    verificaLocalStorage() {
      return this.filterObject.find(x => angular.isDefined(x.itensEscondidos) && x.itensEscondidos.length > 0);
    }

    verificaFiltroLocalstorage() {
      let tipoFiltroSelecionado = this.getFiltroByKeyOrDefault(this.filtroSelecionado.key);

      if (angular.isUndefined(tipoFiltroSelecionado) || tipoFiltroSelecionado.itensEscondidos.length === 0) {
        this.filtroSelecionado.lista.forEach(x => x.check = true);
      } else if (tipoFiltroSelecionado.itensEscondidos.length > 0) {
        this.filtroSelecionado.lista.forEach(x => {
          x.check = angular.isUndefined(tipoFiltroSelecionado.itensEscondidos.find(y => y === x.id));
        });
      }
    }

    /**
     * Verifica se o atributo da entidade é filtrado
     * @param  {string | number} categoria atributo da entidade
     * @param  {string | number} item item do filtro
     * @param  {boolean} contains utiliza o filtro escondido como contain(inverte verificação)
     * @return {boolean}      deve exibir a entidade ou não baseado no filtro
     */
    isVisible(categoria, item, contains = false) {
      if (categoria && categoria.itensEscondidos.length > 0) {
        return contains ?
            angular.isDefined(categoria.itensEscondidos.find(x => item.toLowerCase().includes(x)))
            : angular.isUndefined(categoria.itensEscondidos.find(x => x === item));
      }
      return true;
    }

    isPontoVisible(ponto) {
      if (ponto &&
          (this.isVisible(this.getFiltroByKeyOrDefault('uo'), ponto.permissao.uo.id) &&
          this.isVisible(this.getFiltroByKeyOrDefault('categoria'), ponto.entidade.categoria || 0) &&
          this.isVisible(this.getFiltroByKeyOrDefault('palavra'), ponto.entidade.nome, true))) {
        return true;
      }

      return false;
    }

    isCercaVisible(area) {
      if (area &&
          (this.isVisible(this.getFiltroByKeyOrDefault('uo'), area.uoId) &&
          this.isVisible(this.getFiltroByKeyOrDefault('categoria'), area.categoriaId || 0) &&
          this.isVisible(this.getFiltroByKeyOrDefault('palavra'), area.nome, true))) {
        return true;
      }

      return false;
    }

    verificaLayer(layer) {
      if (layer.entidadePermissao && layer.entidadePermissao.permissao.uo &&
          (this.isVisible(this.getFiltroByKeyOrDefault('uo'), layer.entidadePermissao.permissao.uo.id, false) &&
          this.isVisible(this.getFiltroByKeyOrDefault('categoria'), layer.entidadePermissao.objCategoria.id, false) &&
          this.isVisible(this.getFiltroByKeyOrDefault('palavra'), layer.entidadePermissao.entidade.nome, true))) {
        return true;
      }
      return false;
    }

    /**
     * Monta filtro baseado na categoria(escolha de atributo da entidade)
     * @param  {string} key parametro do filtro relacionado ao atributo de filtro[nome, categoria, palavra chave]
     * @param  {object} defaultWhenNotFound retorno default caso não encontre filtro
     * @return {object}      retorna objeto de filtro {key: string, itensEscondidos: string[] | number[]}
     */
    getFiltroByKeyOrDefault(key, defaultWhenNotFound) {
      let filter = this.filterObject.find(x => x.key === key);
      if (angular.isUndefined(filter)) {
        return defaultWhenNotFound;
      }
      if (angular.isUndefined(filter.itensEscondidos)) {
        filter.itensEscondidos = [];
      }
      return filter;
    }

    salvarFiltroItensEscondidos(key, lista) {
      let filtro = this.getFiltroByKeyOrDefault(key);

      if (filtro) {
        filtro.itensEscondidos = lista;
      } else {
        this.filterObject.push({
          key,
          itensEscondidos: lista
        });
      }

      this.idLayers = [];
      this.pontos = [];
      this.pesquisaService.pontos = [];

      this.salvarFiltroLocalstorage(this.translate.instant('ce.mapa.common.modal.checkBoxPontoReferencia.filtroSucesso'));
    }

    limparFiltro() {
      this.pontos = [];
      this.idLayers = [];
      this.filterObject = [];
      this.filtroSelecionado = null;
      this.selecionarFiltro = false;
      this.salvarFiltroLocalstorage(this.translate.instant('ce.mapa.common.modal.checkBoxPontoReferencia.filtroFalha'));
    }
  }

  angular
    .module('mapControlModule')
    .service('PontosReferenciaFiltro', PontosReferenciaFiltro);
}());
