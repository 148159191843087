(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.analiseOp', {
      url: '/relatorios/movimento-veiculo?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/movimento-veiculo/movimento-veiculo.tpl.html',
          controller: 'AnaliseOpCtrl',
          controllerAs: 'analiseOp'
        }
      },
      data: {
        titulo: 'ce.analise.movimentoVeiculo.titulo',
        role: 'HISTORICO_CONSOLIDADO_VISUALIZAR'
      }
    });
  }

  angular
    .module('relatorios.analiseOp')
    .config(pageConfig);
}());
