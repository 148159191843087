/* eslint-disable angular/di */
(function () {
  'use strict';

  angular
  .module('VTabelaCellFilter', [])
  .filter('splitComposedLabels', () => {
    return (value, isFirst) => {
      if (!value) {
        return '-';
      }
      let arrayValues, finalArrayValues, label;

      finalArrayValues = [];
      arrayValues = value.split(',');

      arrayValues.forEach(val => {
        label = val.split('-');

        if (label.length === 1) {
          finalArrayValues.push(val.trim());
        } else if (isFirst) {
          finalArrayValues.push(label[0].trim());
        } else {
          finalArrayValues.push(label[1].trim());
        }
      });

      if (finalArrayValues.length === 0) {
        return '';
      }

      if (finalArrayValues.length === 1) {
        return finalArrayValues.join('');
      }

      return finalArrayValues.join(', ');
    };
  })
  .filter('humanizedOrUndefinedWithLabel', ($filter) => {
    return (value, defaultLabel) => {
      if (value === null || angular.isUndefined(value) || value < 0) {
        return defaultLabel;
      }
      return $filter('humanizedDuration')(value);
    };
  })
  .filter('decimalWithLabel', ['ConvertersService', function (ConvertersService) {
    return (value, label) => {
      if (value === null || angular.isUndefined(value)) {
        return '-';
      }
      let val = value / 1000;
      if (val < 0) {
        return '-';
      }
      if (label === 'mi') {
        const valMiles = ConvertersService.handleKmToMiles(val);
        return parseFloat(valMiles.toFixed(2)) + ' ' + label;
      }
      return (parseFloat(val.toFixed(2)) + ' ' + label).replace('.', ',');
    };
  }])
  .filter('decimalPointWithLabel', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value)) {
        return '-';
      }
      let val = value / 1000;
      if (val < 0) {
        return '-';
      }
      return parseFloat(val.toFixed(2)) + ' ' + label;
    };
  })
  .filter('numberWithPoint', () => {
    return (value, fractionDigits) => {
      if (value === null || angular.isUndefined(value)) {
        return '-';
      }
      return value.toFixed(fractionDigits);
    };
  })
  .filter('decimal', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value) || value < 0) {
        return '-';
      }
      if (label === 'mi') {
        return value.toFixed(2);
      }
      return (parseFloat(value.toFixed(2)).toString()).replace('.', ',');
    };
  })
  .filter('withLabel', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value) || value < 0) {
        return '-';
      }

      return value + ' ' + label;
    };
  })
  .filter('withNumberLabel', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value) || value === '-') {
        return value;
      }

      return value + ' ' + label;
    };
  })
  .filter('withLabelToUndefined', () => {
    return (value, defaultLabel) => {
      return value === null || angular.isUndefined(value) || value === '' ? defaultLabel : value;
    };
  })
  .filter('decimalForPoints', ['ConvertersService', function (ConvertersService) {
    return (value, label) => {
      if (value === null || angular.isUndefined(value) || value < 0) {
        return '-';
      }
      if (label === 'mi') {
        const valMiles = ConvertersService.handleMilesToKm(value);
        return valMiles.toFixed(2);
      }
      return (parseFloat(value.toFixed(2)).toString()).replace('.', ',');
    };
  }])
  .filter('decimalWithLabelWithoutConversion', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value)) {
        return '-';
      }
      let val = value / 1000;
      if (val < 0) {
        return '-';
      }
      val = parseFloat(val.toFixed(2));
      if (label === 'mi' || label === 'gal' || label === 'mpg' || label === 'mi/kWh') {
        return val + ' ' + label;
      }
      return val.toString().replace('.', ',') + ' ' + label;
    };
  })
  .filter('decimalFormatterWithLabel', () => {
    return (value, label) => {
      if (value === null || angular.isUndefined(value) || value < 0) {
        return '-';
      }

      value = parseFloat(value.toFixed(2));
      if (label === 'mi' || label === 'gal' || label === 'mpg' || label === 'mi/kWh') {
        return value + ' ' + label;
      }

      return value.toString().replace('.', ',') + ' ' + label;
    };
  });
}());
