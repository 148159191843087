(function () {
  'use strict';

  class ModalEdicaoPontoReferenciaCtrl {
    constructor($uibModal, PontosReferencia, CategoriaPontosReferencia,
      AlertMessage, Uos, UoArvoreNivel, ConfiguracoesEvento, CadastroRotogramaService,
      Authenticator, PontosReferenciaMap, $translate, AdvisorRotogramasService, $rootScope, GoogleTagManagerUtils) {
      this.rootScope = $rootScope;
      this.pontoReferenciaMapService = PontosReferenciaMap;
      this.modalService = $uibModal;
      this.model = {};
      this.permissao = {};
      this.exibirCategorias = false;
      this.textoCategorias = 'ce.mapa.common.modal.editarPontoReferencia.textoCategoriasMostrar';
      this.alertMessage = AlertMessage;
      this.uoService = Uos;
      this.uoArvoreNivel = UoArvoreNivel;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.uosFiltradas = [];
      this.uoSelecionada = {};
      this.pontosReferenciaService = PontosReferencia;
      this.pontosCategoriaReferenciaService = CategoriaPontosReferencia;
      this.podeEditarUoDona = false;
      this.configuracoesEvento = ConfiguracoesEvento;
      this.cadastroRotogramaService = CadastroRotogramaService;
      this.configurarTempoMinimo = false;
      this.checkboxCadastrarRotograma = false;
      this.MINUTO_EM_SEGUNDOS = 60;
      this.config = {
        habilitarSpeedLimit: false
      };
      this.carregarUos();
      this.configuracoesEvento = ConfiguracoesEvento;
      this.authenticator = Authenticator;
      this.translate = $translate;
      this.advisorRotogramasService = AdvisorRotogramasService;
    }

    onChangeConfigTempoMinimo() {
      if (!this.configurarTempoMinimo) {
        this.model.tempoPermanenciaMinimo = null;
      }
    }

    $onInit() {
      this.pontoReferencia = this.resolve.pontoReferencia;
      this.rotogramas = this.resolve.rotogramas;
      this.suggestion = this.resolve.advisorSuggestion;
      this.idUoDona = this.resolve.uoSelecionada;
      this.temRoleEdicaoRotogramas = this.authenticator.hasRole('ROTOGRAMA_EDITAR');
      this.temRoleEditarPermanenciaPontoReferencia = this.authenticator.hasRole('PERMANENCIA_PONTO_REFERENCIA_EDITAR');
      this.checkboxCadastrarRotograma = this.temRoleEdicaoRotogramas && (this.rotogramas && this.rotogramas.length > 0) || angular.isDefined(this.suggestion);

      if (this.pontoReferencia.id) {
        this.carregarPonto();
      } else {
        this.model = {
          latitude: `${this.pontoReferencia.latitude}`.replace('.', ','),
          longitude: `${this.pontoReferencia.longitude}`.replace('.', ','),
          raio: this.pontoReferencia.raio,
          tempoPermanenciaMinimo: this.toMinutes(this.pontoReferencia.tempoPermanenciaMinimo),
          categorias: [],
          advisorSuggestion: this.suggestion
        };
        this.configurarTempoMinimo = !!this.model.tempoPermanenciaMinimo;
      }
    }

    toMinutes(time) {
      return time ? time / this.MINUTO_EM_SEGUNDOS : null;
    }

    tempoPermanenciaValid() {
      return () => {
        return !(this.model.tempoPermanenciaMinimo && this.model.tempoPermanenciaMinimo < 3);
      };
    }

    carregarPonto() {
      this.pontosReferenciaService.getPontoReferencia(this.pontoReferencia.id)
      .then(data => {
        let item = data.plain();
        this.permissao = item.permissao;
        this.model = item.entidade;
        this.model.latitude = item.entidade.latitude.toString().replace('.', ',');
        this.model.longitude = item.entidade.longitude.toString().replace('.', ',');
        this.model.tempoPermanenciaMinimo = this.toMinutes(item.entidade.tempoPermanenciaMinimo);
        this.configurarTempoMinimo = !!this.model.tempoPermanenciaMinimo;
        this.carregarCategorias(item.entidade.uo, item.entidade.categoria);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorCarregarPonto'),
          appendTo: '.alerta-aqui-cadastro-ponto'
        });
      });
    }

    carregarUos() {
      this.uoService.getFilhas()
      .then(data => {
        this.uosFiltradas = this.uoArvoreNivel.converterEmLista(data.plain());
        this.uoSelecionada = this.uosFiltradas.filter(uo => uo.id === this.idUoDona)[0];
        this.podeEditarUoDona = this.podeEditarUoDoPonto();
        if (!this.pontoReferencia.id && this.uoSelecionada) {
          this.carregarCategorias(this.uoSelecionada.id);
        }
      });
    }

    podeEditarUoDoPonto() {
      return angular.isDefined(this.uosFiltradas.find(uo => uo.id === this.idUoDona));
    }

    carregarConfiguracoes(uoId) {
      this.configuracoesEvento.getConfiguracoes(uoId).then(data => {
        this.config.habilitarSpeedLimit = data.speedlimitPontoHabilitar;
      }).catch(() => {
        this.config = {
          habilitarSpeedLimit: false
        };
      });
    }

    carregarCategorias(uoId, categoriaId) {
      this.pontosCategoriaReferenciaService.getListCategoriasPontoReferencia()
      .then(data => {
        this.model.categorias = data.filter(f => f.uo === uoId).sort(
          (a, b) => a.nome.localeCompare(b.nome)
        );
        this.model.categoria = this.model.categorias.filter(f => f.id === categoriaId)[0];
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorCarregarCategorias'),
          appendTo: '.alerta-aqui-cadastro-ponto'
        });
      });
      this.carregarConfiguracoes(uoId);
    }

    mostrarCategorias() {
      if (this.exibirCategorias) {
        this.exibirCategorias = false;
        this.textoCategorias = 'ce.mapa.common.modal.editarPontoReferencia.textoCategoriasMostrar';
      } else {
        this.exibirCategorias = true;
        this.textoCategorias = 'ce.mapa.common.modal.editarPontoReferencia.textoCategoriasOcultar';
      }
    }

    reloadCategorias(msg, categoriaId) {
      this.alertMessage.create({
        type: 'success',
        message: msg,
        appendTo: '.alerta-aqui-cadastro-ponto'
      });
      this.carregarCategorias(this.uoSelecionada.id, categoriaId);
    }

    adicionarCategoria() {
      this.modalService.open({
        animation: true,
        templateUrl: 'directives/map-control/categoria-ponto-referencia/cadastrar-categoria-ponto-referencia.tpl.html',
        controller: 'CadastrarCategoriaPontoReferenciaCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          uoSelecionada: () => this.uoSelecionada
        }
      }).result.then(data => {
        this.reloadCategorias(this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoAdicionarCategoria'), data.id);
      });
    }

    editarCategoria(categoriaId) {
      this.modalService.open({
        animation: true,
        templateUrl: 'directives/map-control/categoria-ponto-referencia/cadastrar-categoria-ponto-referencia.tpl.html',
        controller: 'EditarCategoriaPontoReferenciaCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          categoriaId: categoriaId,
          uoSelecionada: () => this.uoSelecionada
        }
      }).result.then(data => {
        this.reloadCategorias(this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoEditarCategoria'), data.id);
      });
    }

    excluirCategorias(categoriaId) {
      this.modalService.open({
        animation: true,
        templateUrl: 'directives/map-control/categoria-ponto-referencia/excluir-categoria-ponto-referencia.tpl.html',
        controller: 'ExcluirCategoriaPontoReferenciaCtrl',
        controllerAs: '$ctrl',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          categoriaId: categoriaId
        }
      }).result.then(() => {
        this.reloadCategorias(this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoExcluirCategoria'));
      });
    }

    ok() {
      let ponto = {
        id: this.model.id,
        uo: angular.isDefined(this.uoSelecionada) ? this.uoSelecionada.id : this.idUoDona,
        nome: this.model.nome,
        latitude: this.model.latitude.replace(',', '.'),
        longitude: this.model.longitude.replace(',', '.'),
        raio: this.model.raio,
        limiteVelocidade: this.model.limiteVelocidade,
        tempoPermanenciaMinimo: this.model.tempoPermanenciaMinimo ? this.model.tempoPermanenciaMinimo * this.MINUTO_EM_SEGUNDOS : null
      };
      if (angular.isDefined(this.model.observacao) && this.model.observacao !== null) {
        ponto.observacao = this.model.observacao;
      }
      if (angular.isDefined(this.model.categoria) && this.model.categoria !== null) {
        ponto.categoria = this.model.categoria.id;
      }
      this.salvar(ponto);
    }

    salvar(pontoReferencia) {
      if (!pontoReferencia.id) {
        this.pontosReferenciaService.postPontoReferencia(pontoReferencia)
          .then(data => {
            if (this.suggestion) {
              this.updateSugestaoRotograma('APROVADO');
              this.sendAcceptedAdvisorToGTM();
            }
            const categoria = !this.model.categoria ? this.pontosCategoriaReferenciaService.categoriaDefault : this.model.categoria;
            if (this.checkboxCadastrarRotograma && this.temRoleEdicaoRotogramas) {
              this.cadastroRotogramaService.pontoReferenciaId = data.id;
              this.cadastroRotogramaService.salvar(data.id);
              data.texto = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoCadastrarPontoRotograma');
            } else {
              data.texto = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoCadastrarPonto');
            }
            this.close({
              $value: {
                isNew: true,
                entidade: data.plain(),
                permissao: {
                  uo: this.uoSelecionada && this.podeEditarUoDoPonto() ? this.uoSelecionada : this.idUoDona,
                  edicao: true
                },
                objCategoria: categoria
              }
            });
          })
          .catch((err) => {
            if (err && err.status === 409) {
              this.updateSugestaoRotograma('PONTO_SOBREPOSTO');
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorCadastrarSobreposto'),
                appendTo: '.alerta-aqui-cadastro-ponto'
              });
            } else if (err && err.status === 403) {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorRegraEmbarque'),
                appendTo: '.alerta-aqui-cadastro-ponto'
              });
            } else {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorCadastroPonto'),
                appendTo: '.alerta-aqui-cadastro-ponto'
              });
            }
          });
      } else {
        this.pontosReferenciaService.putPontoReferencia(pontoReferencia)
          .then((pontoReferenciaResponse) => {
            const categoria = angular.isDefined(this.model.categoria) && this.model.categoria !== null ?
              this.model.categoria : this.pontosCategoriaReferenciaService.categoriaDefault;
            if (angular.isDefined(this.uoSelecionada)) {
              this.permissao.uo = this.uoSelecionada;
            }
            if (this.temRoleEdicaoRotogramas) {
              if (this.checkboxCadastrarRotograma) {
                pontoReferenciaResponse.texto = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoEditarPontoRotograma');
                this.cadastroRotogramaService.pontoReferenciaId = pontoReferenciaResponse.id;
                this.cadastroRotogramaService.salvar(pontoReferenciaResponse.id);
              } else {
                pontoReferenciaResponse.texto = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoEditarPonto');
                this.cadastroRotogramaService.excluir(pontoReferenciaResponse.id);
              }
            } else {
              pontoReferenciaResponse.texto = this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.sucessoEditarPonto');
            }
            this.close({
              $value: {
                entidade: pontoReferenciaResponse,
                permissao: this.permissao,
                objCategoria: categoria,
                oldId: pontoReferencia.id
              }
            });
          })
          .catch((pontoReferenciaError) => {
            if (pontoReferenciaError && pontoReferenciaError.status === 409) {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorEditarSobreposto'),
                appendTo: '.alerta-aqui-cadastro-ponto'
              });
            } else {
              this.alertMessage.create({
                type: 'error',
                message: this.translate.instant('ce.mapa.common.modal.editarPontoReferencia.errorEditarPonto'),
                appendTo: '.alerta-aqui-cadastro-ponto'
              });
            }
          });
      }
    }

    updateSugestaoRotograma(status) {
      this.advisorRotogramasService
        .updateStatus(this.uoSelecionada.id, this.suggestion.uuid, status)
        .then(() => this.rootScope.$broadcast('advisorAtualizado'));
    }

    sendAcceptedAdvisorToGTM() {
      this.authenticator.getUser().then(user => {
        this.googleTagManagerUtils.sendEventClickGTM('advisor_aceito', user);
      });
    }
  }

  angular
    .module('mapControlModule')
    .component('modalEdicaoPontoReferencia', {
      controller: ModalEdicaoPontoReferenciaCtrl,
      templateUrl: 'directives/map-control/cadastrar-ponto-referencia/editar-ponto-referencia.tpl.html',
      bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
      }
    });
}());
