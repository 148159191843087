/* global _ */
(function () {
  'use strict';
  class OcorrenciaHistoricoCtrl {

    $onInit() {
      this.ocorrencia = _.cloneDeep(this.item);
      this.veiculoId = this.ocorrencia.veiculoId;
    }
  }

  angular
    .module('ocorrencia.component')
    .component('ocorrenciaHistorico', {
      controller: OcorrenciaHistoricoCtrl,
      templateUrl: 'ocorrencia/ocorrencia-historico/ocorrencia-historico.tpl.html',
      bindings: {
        item: '='
      }
    });
}());
