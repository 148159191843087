(function () {
  'use strict';

  class TempoPermanenciaCtrl {
    constructor($uibModalInstance, HttpServiceV3g, AlertMessage, pontos, $translate) {
      this.modalInstance = $uibModalInstance;
      this.alertMessage = AlertMessage;
      this.httpService = HttpServiceV3g;
      this.pontos = pontos;
      this.translate = $translate;

      this.tempo = null;
    }

    ok(remove = false) {
      let patchObject = {
        ids: this.pontos.map(i => i.id),
        patch: {
          tempoPermanenciaMinimo: this.tempo ? this.tempo * 60 : null
        }
      };

      this.httpService.post('/ponto-referencia/v2/pontos-referencia/batch/update', patchObject)
        .then((pontosAtualizados) => {
          this.modalInstance.close(pontosAtualizados, remove);
        }).catch(() => {
          this.modalInstance.dismiss({msg: this.translate.instant('ce.cadastro.pontosReferencia.tempoPermanenciaModal.errorMessage')});
        });
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular
    .module('cadastros.pontoReferencia')
    .controller('TempoPermanenciaCtrl', TempoPermanenciaCtrl);
}());
