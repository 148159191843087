(function () {
  'use strict';

  function paginacaoAlerta() {
    return {
      scope: {
        navigationSetting: '=',
        setpage: '='
      },
      templateUrl: 'historico-alerta/historico-paginacao-directive.tpl.html',
      link: function (scope) {
        scope.pagina = function pagina(i) {
          const paginasTotais = Math.min(5, scope.navigationSetting.numberOfPages);

          if (scope.navigationSetting.currentPage <= Math.floor(paginasTotais / 2)) {
            return i + 1;
          } else if (scope.navigationSetting.currentPage + Math.floor(paginasTotais / 2) > scope.navigationSetting.numberOfPages) {
            return scope.navigationSetting.numberOfPages - (paginasTotais - 1) + i;
          }
          return scope.navigationSetting.currentPage + i - Math.floor(paginasTotais / 2);
        };

        scope.isFirst = function isFirst() {
          return scope.navigationSetting.currentPage === 1;
        };

        scope.isLast = function isLast() {
          return scope.navigationSetting.currentPage === scope.navigationSetting.numberOfPages;
        };

        scope.lastRegistroPagina = function lastRegistroPagina() {
          return Math.min(scope.navigationSetting.start + scope.navigationSetting.number || 10, scope.navigationSetting.total);
        };
      }
    };
  }

  angular
    .module('historicoAlertaPaginacao', [])
    .directive('paginacaoAlerta', paginacaoAlerta);
}());
