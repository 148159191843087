(function () {
  'use strict';

  /* global _, Highcharts */
  class CommonChart {
    getYAxis(yAxis, allowDecimals) {
      let ret;
      if (!yAxis) {
        ret = {
          allowDecimals,
          min: 0,
          title: {text: ''},
          labels: this.labelFormat()
        };
      } else {
        let axis = [];
        for (let i = 0; i < yAxis; i++) {
          axis.push({
            allowDecimals,
            min: 0,
            title: {text: ''},
            labels: this.labelFormat(),
            opposite: i > 0
          });
        }
        ret = axis;
      }
      return ret;
    }

    getXAxis() {
      return {
        stackLabels: {
          style: {color: 'black'},
          enabled: true
        },
        categories: []
      };
    }

    getSubtitle() {
      return {
        align: 'left',
        style: {
          color: '#333',
          font: '12px "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif'
        }
      };
    }

    getTitle() {
      return {
        text: '',
        align: 'left',
        style: {
          color: '#333',
          font: '18px "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif'
        }
      };
    }

    getGradientColors(colors) {
      let re = /(rgb)\(([0-9]+),+([0-9]+),+([0-9]+)/,
          subst = 'rgba($2,$3,$4,0.5';
      return colors.map(x => x.replace(re, subst));
    }

    labelFormat() {
      return {
        formatter: function () {
          return this.value.toLocaleString('pt-BR');
        }
      };
    }

    filterAllSeries() {
      let visibleSeries = [], wasVisible = this.visible;

      _.chain(Highcharts.charts)
        .filter(chart => angular.isDefined(chart))
        .filter(chart => chart.options.chart.type !== 'pie')
        .forEach(chart => {
          let showThis = false;

          chart.series.forEach(serie => {
            if (serie.visible) {
              visibleSeries.push(serie.name);
            }

            if (wasVisible) {
              if (serie.name !== this.name) {
                if (serie.visible) {
                  serie.hide();
                }
              }
            } else if (serie.name === this.name) {
              showThis = true;
            }
          });

          if (showThis) {
            _.chain(chart.series)
              .filter(serie => serie.name === this.name)
              .forEach(serie => serie.show());
          }
        });

      const uniqueSeries = _.unique(visibleSeries).length;

      if (wasVisible && (uniqueSeries === 1 || uniqueSeries === 0)) {
        _.chain(Highcharts.charts)
          .filter(chart => angular.isDefined(chart))
          .map(chart => chart.series)
          .flatten()
          .filter(serie => serie.name !== this.name)
          .forEach(serie => serie.show());
      }

      return false;
    }
  }

  angular
    .module('chartModule')
    .service('CommonChart', CommonChart);
}());
