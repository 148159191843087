(function () {
  'use strict';

  class AcoesAlertaCtrl {
    constructor($uibModalInstance, details, status, tipo, Alertas, Usuarios, AlertasEnum, HttpServiceV3g, $translate) {
      this.modalInstance = $uibModalInstance;
      this.alertasService = Alertas;
      this.usuarioService = Usuarios;
      this.httpService = HttpServiceV3g;
      this.alertasEnum = AlertasEnum;
      this.translate = $translate;

      this.usuario = null;
      this.usuarios = [];
      this.alerta = null;

      this.motoristaNaoIdentificado = this.translate.instant('ce.tempoReal.infracoes.common.mensagem.motoristaNaoIdentificado');

      this.statusType = {
        atribuir: this.translate.instant('ce.tempoReal.infracoes.statusType.atribuir'),
        resolver: this.translate.instant('ce.tempoReal.infracoes.statusType.resolver')
      };

      this.statusTypeMapBackend = {
        atribuir: 'ATRIBUIDO',
        resolver: 'RESOLVIDO'
      };

      this.status = status;
      this.details = details;
      this.tipo = tipo;

      this.init();
    }

    init() {
      this.loadUsuarios();
    }

    loadUsuarios() {
      this.usuarioService.getUsuarios({role: 'HISTORICO_ALERTA_VISUALIZAR'})
        .then(data => {
          this.usuarios = data;
          if (this.usuario !== null) {
            this.usuario = data[0];
          }
        });
    }

    fechar() {
      this.modalInstance.dismiss('cancel');
    }

    salvar() {
      this.httpService.put('/alerta/ranking-infracoes/tratar/' + this.statusTypeMapBackend[this.status],
        this.details.ids,
        {params: {tipoAlerta: this.tipo, usuarioId: this.usuario !== null ? this.usuario.id : null}}
      ).then(() => {
        this.fechar();
      }, () => {
        this.setAlerta(this.translate.instant('ce.tempoReal.infracoes.common.mensagem.erroAoSalvarAlteracao'));
      });
    }

    getAlertaDescricao() {
      if (angular.isUndefined(this.tipo)) {
        return this.translate.instant('ce.tempoReal.infracoes.common.todos');
      }
      return this.translate.instant(this.alertasEnum[this.tipo].descricao);
    }

    getTitulo() {
      if (this.tipo === null || angular.isUndefined(this.tipo)) {
        return this.translate.instant('ce.tempoReal.infracoes.common.mensagem.titulo.tratarTodosAlertas');
      }
      const stringTraduzida = this.translate.instant('ce.tempoReal.infracoes.common.mensagem.titulo.tratarAlertasDe');
      return `${stringTraduzida} "${this.translate.instant(this.alertasEnum[this.tipo].descricao)}"`;
    }

    setAlerta(message) {
      this.alerta = message;
    }
  }

  angular
    .module('ranking.Infracoes')
    .controller('AcoesAlertaCtrl', AcoesAlertaCtrl);
}());
