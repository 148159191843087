(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('root.grupoVeic', {
      url: '/grupoVeic?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'cadastros/grupo-veic/grupo-veic.tpl.html',
          controller: 'GrupoVeicCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'ce.cadastro.grupoDeVeiculos.titulo',
        role: 'GRUPO_VEICULO_VISUALIZAR'
      }
    });
  }

  angular
    .module('cadastros.grupoVeic')
    .config(pageConfig);
}());
