(function () {
  'use strict';

  /* global _ */
  function menuAjustarPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/menu-map-control/menu-map-control.tpl.html',
      controller: 'MenuAjustarPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class MenuAjustarPontoReferenciaCtrl {
    constructor($scope, $uibModal, AlertMessage, PontosReferenciaTools, PontosReferenciaMap,
      PontosReferenciaMenu, PontosReferencia, MapControlService, $translate) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.alertMessage = AlertMessage;
      this.pontosReferenciaToolsService = PontosReferenciaTools;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.mapControlService = MapControlService;
      this.translate = $translate;
      this.init();
    }

    init() {
      if (this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas && this.pontosReferenciaMapService.layer.entidadePermissao.entidade.rotogramas.length > 0) {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.mapa.common.menuAjustarPonto.erroRotograma'),
          appendTo: '.alerta-aqui-ponto'
        });
        this.pontosReferenciaMenuService.setFABDefault();
        this.pontosReferenciaMapService.setLayerDefault();
        return;
      }
      this.pontosReferenciaMapService.layer.options.editing = true;
      this.mapControlService.isDrawingPontoRef = true;
      this.pontosReferenciaMapService.layer.editing.enable();
      let buttonsPrincipal = {
            active: 'fa fa-pencil-square-o',
            resting: 'fa fa-pencil-square-o'
          },
          buttons = [
              {
                titulo: 'ce.mapa.common.tituloBtConfirmar',
                icon: 'fa fa-check',
                color: 'success',
                tipo: 'default',
                funcao: this.salvarPosicao.bind(this)
              },
              {
                titulo: 'ce.mapa.common.tituloBtCancelar',
                icon: 'fa fa-times',
                color: 'danger',
                tipo: 'editar',
                funcao: this.pontosReferenciaMapService.cancelarAjuste.bind(this.pontosReferenciaMapService)
              }
          ];
      this.pontosReferenciaMenuService.setMenu(buttons, buttonsPrincipal);
      this.pontosReferenciaMenuService.drawerClose();
      this.pontosReferenciaMenuService.menuOpen();
      this.pontosReferenciaMapService.markerOut(this.pontosReferenciaMapService.getIdLayer(this.pontosReferenciaMapService.layer));
    }

    salvarPosicao() {
      let latlngs = this.pontosReferenciaMapService.layer.getLatLngs()[0],
          latlngCentro = this.pontosReferenciaToolsService.geraLatLngCentro(latlngs),
          raio = this.pontosReferenciaToolsService.geraDiametro(latlngs) / 2,
          entidade = angular.copy(this.pontosReferenciaMapService.layer.entidadePermissao.entidade);

      entidade.latitude = latlngCentro.latitude;
      entidade.longitude = latlngCentro.longitude;
      entidade.raio = raio;

      this.pontosReferenciaService.putPontoReferencia(entidade)
      .then((data) => {
        let ponto = _.find(
          this.pontosReferenciaMapService.pontosReferencia,
          (p) => p.entidade.id === entidade.id),
            copia = angular.copy(ponto);
        if (copia) {
          copia.entidade = data.plain();
          copia.oldId = entidade.id;
          copia.entidade.texto = this.translate.instant('ce.mapa.common.menuAjustarPonto.editarSucesso');
          this.pontosReferenciaMapService.atualizaPonto(copia);
        }
      })
      .catch((err) => {
        this.pontosReferenciaMapService.cancelarAjuste();
        if (err && err.status === 409) {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.common.menuAjustarPonto.editarSobreposto'),
            appendTo: '.alerta-aqui-ponto'
          });
        } else {
          this.alertMessage.create({
            type: 'error',
            message: this.translate.instant('ce.mapa.common.menuAjustarPonto.editarErro'),
            appendTo: '.alerta-aqui-ponto'
          });
        }
      });

      this.pontosReferenciaMapService.layer.options.editing = false;
      this.pontosReferenciaMapService.layer.editing.disable();
      this.pontosReferenciaMenuService.setFABDefault();
      this.pontosReferenciaMapService.setLayerDefault();
      this.pontosReferenciaMapService.markerIn(this.pontosReferenciaMapService.getIdLayer(this.pontosReferenciaMapService.layer), [latlngCentro.latitude, latlngCentro.longitude]);
    }
  }

  angular
    .module('mapControlModule')
    .controller('MenuAjustarPontoReferenciaCtrl', MenuAjustarPontoReferenciaCtrl)
    .directive('menuAjustarPontoReferenciaDirective', menuAjustarPontoReferenciaDirective);
}());
