(function () {
  'use strict';

  function pageConfig($stateProvider) {
    return $stateProvider.state('unprotected.planos-solucoes-novo-usuario', {
      url: '/planos-solucoes-novo-usuario',
      views: {
        'guest@': {
          templateUrl: 'planos-solucoes-usuario-novo/planos-solucoes.tpl.html',
          controller: 'PlanosSolucoesNovoUsuarioCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
  }

  angular
    .module('planosSolucoesNovoUsuarioModule')
    .config(pageConfig);
}());
