(function () {
  'use strict';

  /* global _ */
  class TurnosEventosCtrl {
    constructor($scope, $uibModal, moment, Restangular, AlertMessage,
      Keys, UrlService, ServiceVfiltro, FactoryVfiltro, $translate, Authenticator, FeatureFlag, SolucoesEnum) {
      this.scope = $scope;
      this.modalService = $uibModal;
      this.moment = moment;
      this.restangular = Restangular;
      this.alertMessage = AlertMessage;
      this.keys = Keys;
      this.urlService = UrlService;
      this.serviceVfiltro = ServiceVfiltro;
      this.translate = $translate;
      this.authenticator = Authenticator;
      this.solucoesEnum = SolucoesEnum;
      this.featureFlag = FeatureFlag;

      this.uoId = 0;
      this.roleCadastrar = true;
      this.modelTurnosOriginal = null;
      this.modelTurnos = {habilitarTurnosAlertas: false, habilitarTurnosOcorrencias: false, qntTurnos: '1', turnos: [{inicio: '', fim: '', carga: ''}]};
      this.hasPermissaoConfigOcorrenciaSolucao = false;

      this.serviceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
      .then((filtro) => {
        this.roleCadastrar = this.serviceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIGURACAO_ALERTA_EDITAR');
        this.authenticator.getUser().then((user) => {
          this.usuario = user;
          this.hasPermissaoConfigOcorrenciaSolucao = user.permissoesSolucao.indexOf('CONFIG_OCORRENCIA_VISUALIZAR') !== -1;
          this.init(filtro.find(x => x.key === this.keys.uo.name).value.id);
        });
      });
    }

    init(uoId) {
      this.uoId = uoId;
      this.restangular.one(`configuracao/configuracoes/${uoId}/turnos`).get()
      .then((turnos) => {
        this.buildModelTurnos(turnos);
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.msgErroCarregarConfigs'),
          appendTo: '.alerta-aqui-geral'
        });
      });
    }

    buildModelTurnos(item) {
      this.modelTurnos.habilitarTurnosAlertas = item.turnos.length && item.turnos[0].alertas;
      this.modelTurnos.habilitarTurnosOcorrencias = item.turnos.length && item.turnos[0].ocorrencias;
      this.modelTurnos.qntTurnos = item.turnos.length.toString();
      this.modelTurnos.uoId = item.uoId;
      this.modelTurnos.turnos = item.turnos.map(x => {
        return {
          inicio: this.moment('2012-12-12 ' + x.inicio).format('HH:mm')
        };
      });
      for (let i = 0; i < this.modelTurnos.turnos.length; i++) {
        if (this.modelTurnos.turnos[i].inicio) {
          this.buildTurnos(i);
          this.modelTurnos.turnos[i].carga = this.modelTurnos.turnos.length === 1 ? '24:00' : this.buildCargaHoraria(i);
        }
      }
      this.modelTurnosOriginal = angular.copy(this.modelTurnos);
    }

    buildCargaHoraria(i) {
      const inicioAtual = this.modelTurnos.turnos[i].inicio,
          proximoInicio = this.modelTurnos.turnos[(i + 1) % this.modelTurnos.turnos.length].inicio;
      if (inicioAtual && proximoInicio) {
        let hrInicio = this.moment(inicioAtual, 'HH:mm'),
            hrFim = this.moment(proximoInicio, 'HH:mm'),
            dia = hrInicio.isAfter(hrFim) ? '13' : '12',
            dtInicio = this.moment('2012-12-12 ' + inicioAtual),
            dtFim = this.moment(`2012-12-${dia} ${proximoInicio}`),
            duration = this.moment.duration(dtFim.diff(dtInicio, 'minutes'), 'minutes').format('HH:mm');
        return duration.includes(':') ? duration : `00:${duration}`;
      }
      return '';
    }

    buildTurnos(i) {
      const turnoAnterior = this.modelTurnos.turnos[i - 1],
          turnoAtual = this.modelTurnos.turnos[i],
          ultimoTurno = this.modelTurnos.turnos[this.modelTurnos.turnos.length - 1];
      if (turnoAnterior) {
        turnoAnterior.fim = turnoAtual.inicio;
      } else {
        ultimoTurno.fim = turnoAtual.inicio;
      }
    }

    resetarTurnos() {
      if (this.modelTurnos.turnos.filter(x => x.inicio !== '').length > 0) {
        this.modalService.open({
          animation: true,
          templateUrl: 'configuracoes/eventos/modal/turnos/turnos-eventos.tpl.html',
          controller: 'ModalTurnoEventosCtrl',
          controllerAs: '$ctrl',
          resolve: {
            titulo: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.titulo'),
            texto: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricaoOcorrencias'),
            texto2: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricao2'),
            clique: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.clicandoAqui')
          }
        }).result.then(() => {
          this.limpaCamposTurnos();
          this.limparConfiguracoesAtribuicao();
        }, () => {
          this.modelTurnos.qntTurnos = this.modelTurnos.turnos.length.toString();
        });
      } else {
        this.limpaCamposTurnos();
      }
    }

    desabilitarTurnos(changed) {
      const desabilitouAlertas = changed === 'alertas' && !this.modelTurnos.habilitarTurnosAlertas && this.verificaSeTemTurnoOriginal(),
          desabilitouOcorrencias = changed === 'ocorrencias' && !this.modelTurnos.habilitarTurnosOcorrencias && this.verificaSeTemTurnoOriginal(),
          desabilitouAmbos = !this.modelTurnos.habilitarTurnosAlertas && !this.modelTurnos.habilitarTurnosOcorrencias && this.verificaSeTemTurnoOriginal();
      if (desabilitouAlertas) {
        this.modalService.open({
          animation: true,
          templateUrl: 'configuracoes/eventos/modal/turnos/turnos-eventos.tpl.html',
          controller: 'ModalTurnoEventosCtrl',
          controllerAs: '$ctrl',
          resolve: {
            titulo: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalTurnoDesabilitado.titulo'),
            texto: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalTurnoDesabilitado.descricaoAlertas'),
            texto2: () => {},
            clique: () => {}
          }
        }).result.then(() => {
          if (desabilitouAmbos) {
            this.limparTurnos();
          } else {
            this.enviarTurnos(this.buildSalvarTurnos());
          }
        }, () => {
          this.modelTurnos.habilitarTurnosAlertas = !this.modelTurnos.habilitarTurnosAlertas;
        });
      }

      if (desabilitouOcorrencias) {
        this.modalService.open({
          animation: true,
          templateUrl: 'configuracoes/eventos/modal/turnos/turnos-eventos.tpl.html',
          controller: 'ModalTurnoEventosCtrl',
          controllerAs: '$ctrl',
          resolve: {
            titulo: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalTurnoDesabilitado.titulo'),
            texto: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricaoOcorrencias'),
            texto2: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricao2'),
            clique: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.clicandoAqui')
          }
        }).result.then(() => {
          if (desabilitouAmbos) {
            this.limparTurnos();
          } else {
            this.enviarTurnos(this.buildSalvarTurnos());
            this.limparConfiguracoesAtribuicao();
          }
        }, () => {
          this.modelTurnos.habilitarTurnosOcorrencias = !this.modelTurnos.habilitarTurnosOcorrencias;
        });
      }
    }

    limpaCamposTurnos() {
      this.modelTurnos.turnos = [];
      for (let i = 0; i < Number(this.modelTurnos.qntTurnos); i++) {
        this.modelTurnos.turnos[i] = {inicio: '', fim: '', carga: ''};
      }
    }

    modificaFim(index) {
      this.buildTurnos(index);
      if (this.modelTurnos.turnos.length > 1) {
        for (let i = 0; i < this.modelTurnos.turnos.length; i++) {
          if (this.modelTurnos.turnos[i].inicio) {
            this.modelTurnos.turnos[i].carga = this.buildCargaHoraria(i);
          }
          this.verificaTurnosRepetidos(i);
        }
        this.verificaCargaHoraria();
      } else {
        this.modelTurnos.turnos[0].carga = '24:00';
      }
    }

    validaBotaoSalvar() {
      const nennhumTurno = this.modelTurnos.qntTurnos === '0',
          opcoesHabilitadas = !this.modelTurnos.habilitarTurnosAlertas && !this.modelTurnos.habilitarTurnosOcorrencias,
          naoPreencheuTurnos = !this.verificaSeTemTurnoOriginal();
      return nennhumTurno || opcoesHabilitadas && naoPreencheuTurnos;
    }

    verificaSeTemTurnoOriginal() {
      return this.modelTurnosOriginal.turnos.filter(x => x.inicio !== '').length > 0;
    }

    verificaTurnosRepetidos(i) {
      const turnoAtual = this.modelTurnos.turnos[i],
          inputTurno = this.scope.formTurnos['txtTurnoInicio' + i];

      if (turnoAtual && inputTurno.$modelValue) {
        if (this.modelTurnos.turnos.filter(x => x.inicio === turnoAtual.inicio).length > 1) {
          inputTurno.$setValidity('turnoRepetido', false);
        } else {
          inputTurno.$setValidity('turnoRepetido', true);
        }
      }
    }

    verificaCargaHoraria() {
      const umDiaEmMinutos = 1440;
      let cargaTotal = _.reduce(this.modelTurnos.turnos.map(x => this.moment.duration(x.carga).asMinutes()), (sum, num) => sum + num);
      if (cargaTotal > umDiaEmMinutos) {
        this.setErroCargaHoraria(false);
      } else {
        this.setErroCargaHoraria(true);
      }
    }

    setErroCargaHoraria(erro) {
      for (let i = 0; i < this.modelTurnos.turnos.length; i++) {
        this.scope.formTurnos['txtTurnoInicio' + i].$setValidity('cargaHorariaExcedida', erro);
      }
    }

    goToIndicadoresTempoReal() {
      return '/tempo-real?vfiltro=' + this.urlService.getObjectVfiltro(this.serviceVfiltro.filtroTopbar);
    }

    salvarTurnos() {
      if (!this.modelTurnos.habilitarTurnosAlertas && !this.modelTurnos.habilitarTurnosOcorrencias) {
        this.modalService.open({
          animation: true,
          templateUrl: 'configuracoes/eventos/modal/turnos/turnos-eventos.tpl.html',
          controller: 'ModalTurnoEventosCtrl',
          controllerAs: '$ctrl',
          resolve: {
            titulo: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalTurnoDesabilitado.titulo'),
            texto: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalTurnoDesabilitado.descricaoAlertas') +
            '\n' + this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricaoOcorrencias'),
            texto2: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.descricao2'),
            clique: () => this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.modalDadosSeraoZerados.clicandoAqui')
          }
        }).result.then(() => {
          const configTurnos = {
            uoId: this.uoId,
            turnos: []
          };
          this.limparConfiguracoesAtribuicao();
          this.enviarTurnos(configTurnos);
          this.modelTurnos.qntTurnos = '1';
          this.modelTurnos.turnos = [{inicio: '', fim: '', carga: ''}];
        });
      } else {
        let ordem = 0;
        const configTurnos = {
          uoId: this.uoId,
          turnos: this.modelTurnos.turnos.map(x => {
            ordem++;
            return {
              inicio: x.inicio,
              fim: x.fim,
              ordem,
              alertas: this.modelTurnos.habilitarTurnosAlertas,
              ocorrencias: this.modelTurnos.habilitarTurnosOcorrencias
            };
          })
        };
        this.enviarTurnos(configTurnos);
      }
    }

    enviarTurnos(configTurnos) {
      this.restangular.all(`configuracao/configuracoes/${configTurnos.uoId}/turnos`).post(configTurnos)
      .then(() => {
        this.modelTurnosOriginal = angular.copy(this.modelTurnos);
        this.scope.formTurnos.$setPristine();
        this.alertMessage.create({
          type: 'success',
          message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.msgSalvoComSucesso'),
          appendTo: '.alerta-aqui-turnos'
        });
      })
      .catch(() => {
        this.alertMessage.create({
          type: 'error',
          message: this.translate.instant('ce.configuracao.eventos.itensConfiguraveis.turnos.configuracoes.turno.msgErroSalvarTurnos'),
          appendTo: '.alerta-aqui-turnos'
        });
      });
    }

    buildSalvarTurnos() {
      const configTurnos = {
        uoId: this.uoId,
        turnos: this.modelTurnos.turnos.map((x, index) => {
          index++;
          return {
            inicio: x.inicio,
            fim: x.fim,
            ordem: index,
            alertas: this.modelTurnos.habilitarTurnosAlertas,
            ocorrencias: this.modelTurnos.habilitarTurnosOcorrencias
          };
        })
      };
      return configTurnos;
    }

    limparConfiguracoesAtribuicao() {
      this.restangular.one(`configuracao/configuracoes/${this.uoId}/notificacao-ocorrencia/delete-config-atribuicao`).remove();
    }

    limparTurnos() {
      const configTurnos = {
        uoId: this.uoId,
        turnos: []
      };
      this.enviarTurnos(configTurnos);
      this.limparConfiguracoesAtribuicao();
      this.modelTurnos.qntTurnos = '1';
      this.modelTurnos.turnos = [{inicio: '', fim: '', carga: ''}];
      this.modelTurnosOriginal = angular.copy(this.modelTurnos);
    }
  }

  angular
    .module('SubpaginasEventos')
    .controller('TurnosEventosCtrl', TurnosEventosCtrl);
}());
