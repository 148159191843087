(function () {
  'use strict';

  angular
    .module('cadastros.veiculo')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.veiculo', {
      url: '/veiculo?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'cadastros/veiculo/veiculo.tpl.html',
          controller: 'VeiculoCtrl',
          controllerAs: 'veiculoCtrl'
        }
      },
      data: {
        titulo: 'ce.cadastro.veiculos.titulo',
        tituloDescricao: '',
        role: 'VEICULO_VISUALIZAR'
      }
    })
    .state('root.veiculo.qrcode', {
      url: '/qrcode',
      views: {
        'content@': {
          templateUrl: 'cadastros/veiculo/qrcode/qrcode-veiculo.tpl.html',
          controller: 'QrcodeVeiculoCtrl',
          controllerAs: 'ctrl'
        }
      },
      data: {
        titulo: 'QR Code Veículo',
        tituloDescricao: '',
        role: 'VEICULO_EDITAR'
      }
    });
  }
}());
