(function () {
  'use strict';

  angular
    .module('ranking.Pontos', [
      'ui.router',
      'angularMoment',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'UrlModule',
      'ConstantVfiltroModule',
      'ObjetosVfiltroModule',
      'VtabelaModule',
      'pascalprecht.translate',
      'urlConfigModule',
      'v3gTrimbleFilters'
    ])
    .factory('RankingPontos', Restangular => Restangular.service('boletim/ranking/pontuacao-motoristas/mensal'));
}());
