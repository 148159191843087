/* global document */
(function () {
  'use strict';

  class ConfigNotificacaoCtrl {
    constructor($state, Authenticator, FactoryVfiltro, Keys, ServiceVfiltro, FeatureFlag) {
      this.authenticator = Authenticator;
      this.state = $state;
      this.keys = Keys;
      this.configNotificacaoElement = null;
      this.serviceVfiltro = ServiceVfiltro;
      this.featureFlag = FeatureFlag;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.uo.name
        }
      ]))
        .then((filtro) => {
          if (this.featureFlag.CONFIG_NOTIFICACAO_OCORRENCIA) {
            this.uoId = filtro.find(x => x.key === this.keys.uo.name).value.id;
            this.init();
          }
        });
    }

    init() {
      /*eslint-disable */
      const configNotificacaoContainer = document.querySelector('#container-notificacao');

      this.authenticator.getUser().then(user => {
        this.user = user;
        this.user.uoId = user.uo.id;
        const configNotificacaoElement = document.createElement('vfwc-config-notificacao');
        configNotificacaoElement.usuario = this.user;
        configNotificacaoElement.uoId = this.uoId;
        configNotificacaoContainer.appendChild(configNotificacaoElement);

        configNotificacaoElement.addEventListener('voltar', () => {
          this.state.go('root.configEventos', {tipoSubpagina: 'ocorrencias'});
        });
      });
    }
  }

  angular
  .module('configEventos')
  .controller('ConfigNotificacaoCtrl', ConfigNotificacaoCtrl);
}());
