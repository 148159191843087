(function () {
  'use strict';

  function Ocorrencias() {
    return {
      templateUrl: `configuracoes/eventos/subpaginas/ocorrencias/ocorrencias.tpl.html`,
      controller: 'OcorrenciasCtrl',
      controllerAs: 'ctrl',
      restrict: 'E',
      scope: {}
    };
  }

  angular
    .module('SubpaginasEventos')
    .directive('ocorrenciasEventos', Ocorrencias);
}());
